.container {
  // border: 1px solid #ddd;
  background-color: var(--color4);
  margin: 12px 0;
  transition: all 1s ease;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.4);

  a {
    color: var(--text-color1);
  }

  img {
    transition: all 0.5s ease-in;
    filter: grayscale(0.75);
  }

  &:hover {
    // border-color: #ccc;
    background-color: var(--color3);
    transform: scale(1.1);

    img {
      transform: scale(1.2);
      filter: grayscale(0);
    }
  }
}
