// #et-header-button-731793 {
//   > a,
//   > button {
//     width: 282px;
//     height: 48px;
//     line-height: 47px;
//     padding-top: 0;
//     padding-bottom: 0;
//     font-size: 14px !important;
//     font-weight: 700;
//     line-height: 48px !important;
//     text-transform: uppercase;
//     color: var(--text-color1);
//     border-radius: 4px;
//   }
//   .icon {
//     font-size: 12px !important;
//   }
//   &:hover,
//   &:active {
//     > a,
//     > button {
//       color: #ffffff;
//     }
//   }
//   .regular {
//     background-color: var(--color2);
//   }
//   .hover {
//     background-color: var(--color-hover2);
//   }
//   .et-ink {
//     color: #212121;
//     background-color: #ffffff;
//   }
//   margin: 0px 0px 0px 0px;
// }

#megamenu-562 {
  width: 1200px;
  max-width: 1200px;
  box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 10px 0px;
}

#megamenu-tab-726989 {
  .tabset {
    background-color: var(--color-dark-grey);
    .tab-item.active {
      .arrow {
        border-color: transparent #ffffff transparent transparent;
      }
    }
  }
  .tabs-container {
    // background-color: rgba(#777777, 0.9);
    // background-color: rgba(var(--color-grey), 0.8);
    background-color: var(--color-light-grey);
  }
  .tab-item {
    color: var(--color-white);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    text-transform: none;
    &:hover {
      color: var(--color-white);
    }
    &:after {
      background-color: var(--color-red);
      box-shadow: none;
    }
  }
  .tab-item.active {
    color: var(--color-white);
  }
  .tab-content {
    padding: 0px 0px 0px 0px;
  }
}
