#header-menu-container-622660 {
  margin: 0px 0px 0px 16px;
}

#header-menu-622660 {
  & > .menu-item.depth-0 {
    margin-left: 40px;
    margin-left: 20px !important;
    margin-right: 20px !important;
    line-height: 48px;
    &:before {
      background: #e0e0e0;
      // background: var(--color5);
      left: -20px;
    }
    &:after {
      background: #e0e0e0;
      right: -20px;
    }
    > .mi-link {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      text-transform: none;
      .txt {
        &:after {
          border-bottom-color: #ffffff;
        }
      }
    }
    &:hover {
      > .mi-link {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
  > .menu-item.depth-0.current-menu-item {
    > .mi-link {
      color: #ffffff;
    }
  }
  > .menu-item.depth-0.current-menu-parent {
    > .mi-link {
      color: #ffffff;
    }
  }
  > .menu-item.depth-0.current-menu-ancestor {
    > .mi-link {
      color: #ffffff;
    }
  }
  > .menu-item.depth-0.one-page-active {
    > .mi-link {
      color: #ffffff;
    }
  }
  .sub-menu {
    // background-color: #ffffff;
    background-color: var(--color-white);
    box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 10px 0px;
  }
  > .menu-item {
    &:not(.mm-true) {
      .sub-menu {
        .menu-item {
          .mi-link {
            &:before {
              background-color: #616161;
            }
            // color: #616161;
            color: var(--color-dark-grey);
            font-weight: 500;
            text-transform: none;
          }
          &:hover {
            background-color: var(--color-red);
            .mi-link {
              color: var(--color-white);
            }
            // > .mi-link {
            //   color: var(--color1);
            // }
          }
        }
      }
    }
  }
}
