/* Auto icons 383
---------------*/

@font-face {
	font-family: 'auto';
	src:
		url('fonts/auto.ttf?cxmn12') format('truetype'),
		url('fonts/auto.woff?cxmn12') format('woff'),
		url('fonts/auto.svg?cxmn12#auto') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="enova-auto-"],
[class*=" enova-auto-"],
.yith-wcwl-add-to-wishlist a:before,
.loop-product .wooscp-btn:before,
.custom-product .wooscp-btn:before,
.star-rating:before,
.star-rating span:before,
.comment-form-rating a:after {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'auto' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.enova-hard {
	font-weight: 900;
}

.enova-auto-accelerator:before {
	content: "\a900"
}

.enova-auto-air-conditioner:before {
	content: "\a917"
}

.enova-auto-air-conditioner1:before {
	content: "\a918"
}

.enova-auto-air-conditioner2:before {
	content: "\a919"
}

.enova-auto-air-conditioner-1:before {
	content: "\a91a"
}

.enova-auto-air-conditioner-2:before {
	content: "\a91b"
}

.enova-auto-air-conditioner-3:before {
	content: "\a91c"
}

.enova-auto-air-conditioner-4:before {
	content: "\a91d"
}

.enova-auto-air-filter:before {
	content: "\a91e"
}

.enova-auto-air-filter1:before {
	content: "\a91f"
}

.enova-auto-air-filter-1:before {
	content: "\a920"
}

.enova-auto-air-filter-2:before {
	content: "\a921"
}

.enova-auto-air-pump:before {
	content: "\a92a"
}

.enova-auto-airbag:before {
	content: "\a92b"
}

.enova-auto-alloy-wheel:before {
	content: "\a92c"
}

.enova-auto-alloy-wheel-1:before {
	content: "\a92d"
}

.enova-auto-alloy-wheel-2:before {
	content: "\a92e"
}

.enova-auto-alloy-wheel-3:before {
	content: "\a92f"
}

.enova-auto-alloy-wheel-4:before {
	content: "\a930"
}

.enova-auto-alloy-wheel-5:before {
	content: "\a931"
}

.enova-auto-alloy-wheel-6:before {
	content: "\a932"
}

.enova-auto-alloy-wheel-7:before {
	content: "\a933"
}

.enova-auto-alloy-wheel-8:before {
	content: "\a934"
}

.enova-auto-alloy-wheel-9:before {
	content: "\a935"
}

.enova-auto-antenna:before {
	content: "\a93c"
}

.enova-auto-antenna1:before {
	content: "\a93d"
}

.enova-auto-antenna-1:before {
	content: "\a93e"
}

.enova-auto-automatic-transmission:before {
	content: "\a947"
}

.enova-auto-automatic-transmission1:before {
	content: "\a948"
}

.enova-auto-automotive:before {
	content: "\a949"
}

.enova-auto-automotive-1:before {
	content: "\a94a"
}

.enova-auto-automotive-2:before {
	content: "\a94b"
}

.enova-auto-automotive-3:before {
	content: "\a94c"
}

.enova-auto-battery:before {
	content: "\a953"
}

.enova-auto-brake-pad:before {
	content: "\a96c"
}

.enova-auto-brake-pad1:before {
	content: "\a96d"
}

.enova-auto-brake-pedal:before {
	content: "\a96e"
}

.enova-auto-break:before {
	content: "\a96f"
}

.enova-auto-break-1:before {
	content: "\a970"
}

.enova-auto-break-2:before {
	content: "\a971"
}

.enova-auto-break-3:before {
	content: "\a972"
}

.enova-auto-bumper:before {
	content: "\a987"
}

.enova-auto-bumper1:before {
	content: "\a988"
}

.enova-auto-car:before {
	content: "\a999"
}

.enova-auto-car1:before {
	content: "\a99a"
}

.enova-auto-car2:before {
	content: "\a99b"
}

.enova-auto-car-1:before {
	content: "\a99c"
}

.enova-auto-car-19:before {
	content: "\a99d"
}

.enova-auto-car-10:before {
	content: "\a99e"
}

.enova-auto-car-11:before {
	content: "\a99f"
}

.enova-auto-car-12:before {
	content: "\a9a0"
}

.enova-auto-car-13:before {
	content: "\a9a1"
}

.enova-auto-car-14:before {
	content: "\a9a2"
}

.enova-auto-car-15:before {
	content: "\a9a3"
}

.enova-auto-car-16:before {
	content: "\a9a4"
}

.enova-auto-car-17:before {
	content: "\a9a5"
}

.enova-auto-car-18:before {
	content: "\a9a6"
}

.enova-auto-car-2:before {
	content: "\a9a7"
}

.enova-auto-car-21:before {
	content: "\a9a8"
}

.enova-auto-car-3:before {
	content: "\a9a9"
}

.enova-auto-car-31:before {
	content: "\a9aa"
}

.enova-auto-car-4:before {
	content: "\a9ab"
}

.enova-auto-car-41:before {
	content: "\a9ac"
}

.enova-auto-car-5:before {
	content: "\a9ad"
}

.enova-auto-car-51:before {
	content: "\a9ae"
}

.enova-auto-car-6:before {
	content: "\a9af"
}

.enova-auto-car-61:before {
	content: "\a9b0"
}

.enova-auto-car-7:before {
	content: "\a9b1"
}

.enova-auto-car-8:before {
	content: "\a9b2"
}

.enova-auto-car-9:before {
	content: "\a9b3"
}

.enova-auto-car-door:before {
	content: "\a9b4"
}

.enova-auto-car-key:before {
	content: "\a9b5"
}

.enova-auto-car-key-1:before {
	content: "\a9b6"
}

.enova-auto-car-light:before {
	content: "\a9b7"
}

.enova-auto-car-lights:before {
	content: "\a9b8"
}

.enova-auto-car-lights-1:before {
	content: "\a9b9"
}

.enova-auto-car-lights-2:before {
	content: "\a9ba"
}

.enova-auto-car-parts:before {
	content: "\a9bb"
}

.enova-auto-car-parts1:before {
	content: "\a9bc"
}

.enova-auto-car-parts-1:before {
	content: "\a9bd"
}

.enova-auto-car-parts-110:before {
	content: "\a9be"
}

.enova-auto-car-parts-10:before {
	content: "\a9bf"
}

.enova-auto-car-parts-101:before {
	content: "\a9c0"
}

.enova-auto-car-parts-11:before {
	content: "\a9c1"
}

.enova-auto-car-parts-111:before {
	content: "\a9c2"
}

.enova-auto-car-parts-12:before {
	content: "\a9c3"
}

.enova-auto-car-parts-121:before {
	content: "\a9c4"
}

.enova-auto-car-parts-13:before {
	content: "\a9c5"
}

.enova-auto-car-parts-131:before {
	content: "\a9c6"
}

.enova-auto-car-parts-14:before {
	content: "\a9c7"
}

.enova-auto-car-parts-141:before {
	content: "\a9c8"
}

.enova-auto-car-parts-15:before {
	content: "\a9c9"
}

.enova-auto-car-parts-151:before {
	content: "\a9ca"
}

.enova-auto-car-parts-16:before {
	content: "\a9cb"
}

.enova-auto-car-parts-161:before {
	content: "\a9cc"
}

.enova-auto-car-parts-17:before {
	content: "\a9cd"
}

.enova-auto-car-parts-171:before {
	content: "\a9ce"
}

.enova-auto-car-parts-18:before {
	content: "\a9cf"
}

.enova-auto-car-parts-181:before {
	content: "\a9d0"
}

.enova-auto-car-parts-19:before {
	content: "\a9d1"
}

.enova-auto-car-parts-191:before {
	content: "\a9d2"
}

.enova-auto-car-parts-2:before {
	content: "\a9d3"
}

.enova-auto-car-parts-210:before {
	content: "\a9d4"
}

.enova-auto-car-parts-20:before {
	content: "\a9d5"
}

.enova-auto-car-parts-201:before {
	content: "\a9d6"
}

.enova-auto-car-parts-21:before {
	content: "\a9d7"
}

.enova-auto-car-parts-211:before {
	content: "\a9d8"
}

.enova-auto-car-parts-22:before {
	content: "\a9d9"
}

.enova-auto-car-parts-221:before {
	content: "\a9da"
}

.enova-auto-car-parts-23:before {
	content: "\a9db"
}

.enova-auto-car-parts-231:before {
	content: "\a9dc"
}

.enova-auto-car-parts-24:before {
	content: "\a9dd"
}

.enova-auto-car-parts-241:before {
	content: "\a9de"
}

.enova-auto-car-parts-25:before {
	content: "\a9df"
}

.enova-auto-car-parts-251:before {
	content: "\a9e0"
}

.enova-auto-car-parts-26:before {
	content: "\a9e1"
}

.enova-auto-car-parts-261:before {
	content: "\a9e2"
}

.enova-auto-car-parts-27:before {
	content: "\a9e3"
}

.enova-auto-car-parts-271:before {
	content: "\a9e4"
}

.enova-auto-car-parts-28:before {
	content: "\a9e5"
}

.enova-auto-car-parts-281:before {
	content: "\a9e6"
}

.enova-auto-car-parts-29:before {
	content: "\a9e7"
}

.enova-auto-car-parts-291:before {
	content: "\a9e8"
}

.enova-auto-car-parts-3:before {
	content: "\a9e9"
}

.enova-auto-car-parts-31:before {
	content: "\a9ea"
}

.enova-auto-car-parts-30:before {
	content: "\a9eb"
}

.enova-auto-car-parts-311:before {
	content: "\a9ec"
}

.enova-auto-car-parts-32:before {
	content: "\a9ed"
}

.enova-auto-car-parts-33:before {
	content: "\a9ee"
}

.enova-auto-car-parts-34:before {
	content: "\a9ef"
}

.enova-auto-car-parts-35:before {
	content: "\a9f0"
}

.enova-auto-car-parts-36:before {
	content: "\a9f1"
}

.enova-auto-car-parts-37:before {
	content: "\a9f2"
}

.enova-auto-car-parts-38:before {
	content: "\a9f3"
}

.enova-auto-car-parts-39:before {
	content: "\a9f4"
}

.enova-auto-car-parts-4:before {
	content: "\a9f5"
}

.enova-auto-car-parts-41:before {
	content: "\a9f6"
}

.enova-auto-car-parts-40:before {
	content: "\a9f7"
}

.enova-auto-car-parts-411:before {
	content: "\a9f8"
}

.enova-auto-car-parts-42:before {
	content: "\a9f9"
}

.enova-auto-car-parts-43:before {
	content: "\a9fa"
}

.enova-auto-car-parts-44:before {
	content: "\a9fb"
}

.enova-auto-car-parts-45:before {
	content: "\a9fc"
}

.enova-auto-car-parts-46:before {
	content: "\a9fd"
}

.enova-auto-car-parts-47:before {
	content: "\a9fe"
}

.enova-auto-car-parts-48:before {
	content: "\a9ff"
}

.enova-auto-car-parts-49:before {
	content: "\aa00"
}

.enova-auto-car-parts-5:before {
	content: "\aa01"
}

.enova-auto-car-parts-51:before {
	content: "\aa02"
}

.enova-auto-car-parts-50:before {
	content: "\aa03"
}

.enova-auto-car-parts-511:before {
	content: "\aa04"
}

.enova-auto-car-parts-6:before {
	content: "\aa05"
}

.enova-auto-car-parts-61:before {
	content: "\aa06"
}

.enova-auto-car-parts-7:before {
	content: "\aa07"
}

.enova-auto-car-parts-71:before {
	content: "\aa08"
}

.enova-auto-car-parts-8:before {
	content: "\aa09"
}

.enova-auto-car-parts-81:before {
	content: "\aa0a"
}

.enova-auto-car-parts-9:before {
	content: "\aa0b"
}

.enova-auto-car-parts-91:before {
	content: "\aa0c"
}

.enova-auto-car-wheel:before {
	content: "\aa0d"
}

.enova-auto-catalytic-converter:before {
	content: "\aa0e"
}

.enova-auto-chassis:before {
	content: "\aa0f"
}

.enova-auto-condenser:before {
	content: "\aa24"
}

.enova-auto-condenser1:before {
	content: "\aa25"
}

.enova-auto-construction-and-tools:before {
	content: "\aa26"
}

.enova-auto-construction-and-tools-1:before {
	content: "\aa27"
}

.enova-auto-construction-and-tools-2:before {
	content: "\aa28"
}

.enova-auto-construction-and-tools-3:before {
	content: "\aa29"
}

.enova-auto-construction-and-tools-4:before {
	content: "\aa2a"
}

.enova-auto-conveyor:before {
	content: "\aa2b"
}

.enova-auto-damper:before {
	content: "\aa34"
}

.enova-auto-dashboard:before {
	content: "\aa43"
}

.enova-auto-dashboard-1:before {
	content: "\aa44"
}

.enova-auto-dashboard-2:before {
	content: "\aa45"
}

.enova-auto-dashboard-3:before {
	content: "\aa46"
}

.enova-auto-dashboard-4:before {
	content: "\aa47"
}

.enova-auto-decibels:before {
	content: "\aa48"
}

.enova-auto-disc-car:before {
	content: "\aa59"
}

.enova-auto-door:before {
	content: "\aa5a"
}

.enova-auto-door1:before {
	content: "\aa5b"
}

.enova-auto-door-1:before {
	content: "\aa5c"
}

.enova-auto-drive:before {
	content: "\aa5d"
}

.enova-auto-engine:before {
	content: "\aa71"
}

.enova-auto-engine-1:before {
	content: "\aa72"
}

.enova-auto-engine-oil:before {
	content: "\aa73"
}

.enova-auto-exhaust:before {
	content: "\aa74"
}

.enova-auto-exhaust1:before {
	content: "\aa75"
}

.enova-auto-exhaust-1:before {
	content: "\aa76"
}

.enova-auto-exhaust-11:before {
	content: "\aa77"
}

.enova-auto-exhaust-2:before {
	content: "\aa78"
}

.enova-auto-exhaust-21:before {
	content: "\aa79"
}

.enova-auto-exhaust-3:before {
	content: "\aa7a"
}

.enova-auto-exhaust-4:before {
	content: "\aa7b"
}

.enova-auto-exhaust-5:before {
	content: "\aa7c"
}

.enova-auto-exhaust-6:before {
	content: "\aa7d"
}

.enova-auto-exhaust-pipe:before {
	content: "\aa7e"
}

.enova-auto-exhaust-pipe-1:before {
	content: "\aa7f"
}

.enova-auto-fender:before {
	content: "\aa80"
}

.enova-auto-fender1:before {
	content: "\aa81"
}

.enova-auto-fog-light:before {
	content: "\aaa0"
}

.enova-auto-frontal-part-of-old-car:before {
	content: "\aaa7"
}

.enova-auto-frost:before {
	content: "\aaa8"
}

.enova-auto-frost-1:before {
	content: "\aaa9"
}

.enova-auto-gas-station:before {
	content: "\aaaa"
}

.enova-auto-gearshift:before {
	content: "\aaab"
}

.enova-auto-gearshift-1:before {
	content: "\aaac"
}

.enova-auto-grid:before {
	content: "\aaad"
}

.enova-auto-grid1:before {
	content: "\aaae"
}

.enova-auto-grid-1:before {
	content: "\aaaf"
}

.enova-auto-guard:before {
	content: "\aab0"
}

.enova-auto-headlights:before {
	content: "\aad6"
}

.enova-auto-headlights1:before {
	content: "\aad7"
}

.enova-auto-hood:before {
	content: "\aaf5"
}

.enova-auto-hood1:before {
	content: "\aaf6"
}

.enova-auto-indicators:before {
	content: "\ab0c"
}

.enova-auto-info:before {
	content: "\ab11"
}

.enova-auto-information:before {
	content: "\ab12"
}

.enova-auto-light:before {
	content: "\ab50"
}

.enova-auto-light-bulb:before {
	content: "\ab51"
}

.enova-auto-light-bulb1:before {
	content: "\ab52"
}

.enova-auto-mechanic:before {
	content: "\ab60"
}

.enova-auto-metal:before {
	content: "\ab6c"
}

.enova-auto-mirror:before {
	content: "\ab6e"
}

.enova-auto-mirror1:before {
	content: "\ab6f"
}

.enova-auto-mirror-1:before {
	content: "\ab70"
}

.enova-auto-mirror-11:before {
	content: "\ab71"
}

.enova-auto-mirror-2:before {
	content: "\ab72"
}

.enova-auto-motor:before {
	content: "\ab79"
}

.enova-auto-motor-1:before {
	content: "\ab7a"
}

.enova-auto-motor-2:before {
	content: "\ab7b"
}

.enova-auto-navigator:before {
	content: "\ab7c"
}

.enova-auto-oil:before {
	content: "\ab8a"
}

.enova-auto-oil-1:before {
	content: "\ab8b"
}

.enova-auto-oil-filter:before {
	content: "\ab8c"
}

.enova-auto-oil-gauge:before {
	content: "\ab8d"
}

.enova-auto-oil-gauge1:before {
	content: "\ab8e"
}

.enova-auto-oil-gauge-1:before {
	content: "\ab8f"
}

.enova-auto-parking:before {
	content: "\ab95"
}

.enova-auto-parking-1:before {
	content: "\ab96"
}

.enova-auto-parking-2:before {
	content: "\ab97"
}

.enova-auto-part:before {
	content: "\ab98"
}

.enova-auto-pedal:before {
	content: "\ab99"
}

.enova-auto-pipe:before {
	content: "\abae"
}

.enova-auto-pipe-1:before {
	content: "\ebaf"
}

.enova-auto-piston:before {
	content: "\abb0"
}

.enova-auto-plug:before {
	content: "\abb1"
}

.enova-auto-porsche:before {
	content: "\abb9";
	color: #484c57
}

.enova-auto-race:before {
	content: "\abc1"
}

.enova-auto-race-1:before {
	content: "\abc2"
}

.enova-auto-radiator:before {
	content: "\abc3"
}

.enova-auto-radiator1:before {
	content: "\abc4"
}

.enova-auto-radiator2:before {
	content: "\abc5"
}

.enova-auto-rear-view-mirror:before {
	content: "\abc6"
}

.enova-auto-rear-view-mirror-1:before {
	content: "\abc7"
}

.enova-auto-repair:before {
	content: "\abce"
}

.enova-auto-seat:before {
	content: "\abd2"
}

.enova-auto-seat1:before {
	content: "\abd3"
}

.enova-auto-seat-1:before {
	content: "\abd4"
}

.enova-auto-seat-belt:before {
	content: "\abd5"
}

.enova-auto-seatbelt:before {
	content: "\abd6"
}

.enova-auto-seatbelt1:before {
	content: "\abd7"
}

.enova-auto-shock:before {
	content: "\abd8"
}

.enova-auto-spare:before {
	content: "\abe5"
}

.enova-auto-spare-part:before {
	content: "\abe6"
}

.enova-auto-spark:before {
	content: "\abe7"
}

.enova-auto-spark-plug:before {
	content: "\abe8"
}

.enova-auto-spark-plug1:before {
	content: "\abe9"
}

.enova-auto-spark-plug2:before {
	content: "\abea"
}

.enova-auto-speed:before {
	content: "\abeb"
}

.enova-auto-speed-1:before {
	content: "\abec"
}

.enova-auto-speedometer:before {
	content: "\abed"
}

.enova-auto-spoiler:before {
	content: "\abee"
}

.enova-auto-spoiler-1:before {
	content: "\abef"
}

.enova-auto-spoiler-2:before {
	content: "\abf0"
}

.enova-auto-start:before {
	content: "\abf1"
}

.enova-auto-starter:before {
	content: "\abf2"
}

.enova-auto-steal:before {
	content: "\abf3"
}

.enova-auto-steering:before {
	content: "\abf4"
}

.enova-auto-steering-wheel:before {
	content: "\abf5"
}

.enova-auto-steering-wheel-1:before {
	content: "\abf6"
}

.enova-auto-suspension:before {
	content: "\ac05"
}

.enova-auto-tailpipe:before {
	content: "\ac13"
}

.enova-auto-temperature:before {
	content: "\ac14"
}

.enova-auto-timing-belt:before {
	content: "\ac21"
}

.enova-auto-tire:before {
	content: "\ac22"
}

.enova-auto-tire1:before {
	content: "\ac23"
}

.enova-auto-tool:before {
	content: "\ac27"
}

.enova-auto-turbine:before {
	content: "\ac3c"
}

.enova-auto-tyre:before {
	content: "\ac4d"
}

.enova-auto-warning:before {
	content: "\ac98"
}

.enova-auto-warning-1:before {
	content: "\ac99"
}

.enova-auto-wheel:before {
	content: "\ac9a"
}

.enova-auto-wheel1:before {
	content: "\ac9b"
}

.enova-auto-wheel-1:before {
	content: "\ac9c"
}

.enova-auto-wheel-11:before {
	content: "\ac9d"
}

.enova-auto-window:before {
	content: "\ac9e"
}

.enova-auto-window1:before {
	content: "\ac9f"
}

.enova-auto-window2:before {
	content: "\aca0"
}

.enova-auto-window-1:before {
	content: "\aca1"
}

.enova-auto-windshield:before {
	content: "\aca2"
}

.enova-auto-windshield-1:before {
	content: "\aca3"
}

.enova-auto-worlwide:before {
	content: "\acad"
}

.enova-auto-heart:before {
	content: "\acae"
}

.enova-auto-reload:before {
	content: "\acaf"
}

.enova-auto-rate-star-button:before {
	content: "\adad";
}

.yith-wcwl-add-to-wishlist a:before {
	content: '\acae';
}

.loop-product .wooscp-btn:before,
.custom-product .wooscp-btn:before {
	content: "\acaf";
}

.star-rating:before,
.star-rating span:before {
	content: "\adad\adad\adad\adad\adad";
}

.comment-form-rating a:after {
	content: "\adad";
}

.comment-form-rating a.star-2:after {
	content: "\adad\adad";
}

.comment-form-rating a.star-3:after {
	content: "\adad\adad\adad";
}

.comment-form-rating a.star-4:after {
	content: "\adad\adad\adad\adad";
}

.comment-form-rating a.star-5:after {
	content: "\adad\adad\adad\adad\adad";
}

.demo-icon-pack {
	margin: 0 auto;
	display: grid;
	grid-row-gap: 8px;
	grid-column-gap: 8px;
	grid-template-columns: repeat(10, 10fr) !important;
}

.demo-icon-pack span {
	background-color: #f5f5f5;
	position: relative;
	font-size: 48px;
	line-height: 48px;
	padding: 12px;
	text-align: center;
	color: #000000;
	will-change: transform;
	-ms-transform: translateY(0);
	transform: translateY(0);
	cursor: pointer;
	transition: transform 600ms cubic-bezier(.19, 1, .22, 1), box-shadow 300ms ease-out, background-color 300ms ease-out;
}

.demo-icon-pack span:before {
	display: inline-block;
	width: 48px;
	text-align: right;
}

.demo-icon-pack span:hover {
	background-color: #f0f0f0;
}

.demo-icon-pack span.active {
	box-shadow: inset 0 0 0 1px #000000;
}

.demo-icon-pack span:after {
	content: "Copied!";
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -36px;
	width: 72px;
	height: 32px;
	line-height: 32px !important;
	font-size: 14px !important;
	background-color: #000000 !important;
	color: #ffffff !important;
	text-align: center;
	display: block;
	opacity: 0;
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.demo-icon-pack span.active:after {
	animation-name: ShowIconName;
	animation-duration: 300ms;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(.19, 1, .22, 1);
}

@keyframes ShowIconName {
	0% {
		-ms-transform: translateY(0);
		transform: translateY(0);
		opacity: 0;
	}

	100% {
		-ms-transform: translateY(-16px);
		transform: translateY(-16px);
		opacity: 1;
	}
}