.formContainer {
  :global(.field) {
    color: var(--color2);
    padding: 12px 16px 12px 16px;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);

    &:focus,
    &:hover {
      color: var(--color-hover2);
    }

    &:placeholder {
      color: var(--color2);
    }
  }
  // & > div {
  //   &:nth-child(2) .alert:not(.final) {
  //     transition: transform 700ms 100ms cubic-bezier(0.19, 1, 0.22, 1),
  //       opacity 300ms 100ms ease-out;
  //   }

  //   &:nth-child(3) .alert:not(.final) {
  //     transition: transform 700ms 150ms cubic-bezier(0.19, 1, 0.22, 1),
  //       opacity 300ms 150ms ease-out;
  //   }

  //   &:nth-child(4) .alert:not(.final) {
  //     transition: transform 700ms 200ms cubic-bezier(0.19, 1, 0.22, 1),
  //       opacity 300ms 200ms ease-out;
  //   }
  // }
}
