.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@for $i from 1 through 12 {
  .gap-#{$i} {
    gap: #{4 * $i}px;
  }
}
