.container {
  color: var(--color-white);
  background-color: var(--color-grey);
  padding: 16px 0px 32px 0px;

  .itemsContainer {
    > :global(.menu-item) > a {
      color: var(--color-white);
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
      letter-spacing: 0px;
      text-transform: none;

      &:hover {
        background-color: transparent;
        color: var(--color1);
      }
    }

    :global(.menu-item) > a:after {
      background-color: var(--color-dark-grey);
    }

    > :global(.menu-item) {
      &:last-child:after {
        background-color: var(--color-dark-grey);
      }

      :global(.sub-menu) :global(.menu-item) > a {
        color: var(--color-white);
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        text-transform: none;

        &:hover {
          color: var(--color1);
          background-color: transparent;
        }
      }
    }
  }
}
