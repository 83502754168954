.container {
  font-size: 1rem;
  line-height: 1.5rem;

  & :global(.icon-wrap) {
    vertical-align: top !important;
    padding-top: 5px !important;
  }

  & :global(.icon) {
    color: var(--color-red);
    border-radius: 0px;
  }

  a {
    color: var(--color-white);
  }
}
