/*
	Theme Name: Mobimax
	Theme URI: http://www.enovathemes.com/mobimax
	Description: Mobimax Auto Parts Woocommerce WordPress Theme
	Author: Enovathemes
	Author URI: http://www.enovathemes.com
	Version: 2.2
	License: GNU General Public License version 3.0
	License URI: http://www.gnu.org/licenses/gpl-3.0.html
	Tags: one-column, two-columns, right-sidebar, editor-style, featured-images, post-formats, translation-ready
	Text Domain: mobimax
*/

/*
	Table of Contents
	-----------------------------------------

	Enovathemes icons
	Auto icons
	Plyr
	General
	Photoswip
	Vc corrections
	Header
	Title section
	Forms
	Search/404
	Widgets
	Site loaders
	One page bullets
	Posts
	Filter
	Navigation
	Elements
	Effects

*/

/* Icon font
	---------------*/

.menu-item .arrow-down,
.shop_table .product-thumbnail .image-container:before,
.wpml-ls-item-toggle:after,
.overlay-read-more {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'enova' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.enovathemes-navigation {
  .next:before,
  .prev:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'enova' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.flex-prev,
.flex-next,
.slick-prev,
.slick-next,
.owl-prev,
.owl-next,
#to-top,
.nivo-lightbox-nav,
.nivo-lightbox-close,
.widget_calendar caption a,
#searchsubmit + .search-icon,
.widget_product_search form button:before,
.widget_nav_menu ul li a .toggle,
.widget_product_categories ul li a .toggle,
.widget_instagram ul li a:before,
.widget_flickr ul li a:before,
.widget_et_recent_entries .post-thumbnail a:before,
.widget_products
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before,
.widget_recently_viewed_products
  .product_list_widget
  > li
  .product-image
  .image-containera
  a:before,
.widget_recent_reviews
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before,
.widget_top_rated_products
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before,
.yith-woocompare-widget ul.products-list li .remove:before,
.woocommerce-product-gallery__trigger,
#yith-quick-view-close:before,
.et-link:after,
.et-accordion .toggle-ind:before,
.popup-banner-toggle:before,
.tagline-toggle:before,
.product-image .image-container a:before,
.et-breadcrumbs > *:before,
#cboxClose:before,
.currency-switcher .highlighted-currency:after,
.dgwt-wcas-close:not(.dgwt-wcas-inner-preloader) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'enova' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Arrow
    ---------------*/

.menu-item .arrow-down:before,
.wpml-ls-item-toggle:after {
  content: '\e903';
}

.enovathemes-navigation {
  .next:before,
  .prev:before {
    content: '\e903';
  }
}

.flex-prev:before,
.flex-next:before,
.slick-prev:before,
.slick-next:before,
.owl-prev:before,
.owl-next:before,
#to-top:before,
.nivo-lightbox-nav:before,
.widget_calendar caption a:before,
.widget_nav_menu ul li a .toggle:before,
.widget_product_categories ul li a .toggle:before,
.et-link:after,
.et-breadcrumbs > *:before,
.currency-switcher .highlighted-currency:after,
.et-accordion .toggle-ind:before {
  content: '\e903';
}

.mobile-menu .menu-item .arrow-down:before {
  content: '\e900';
}

.flex-prev:before,
.slick-prev:before,
.owl-prev:before,
.enovathemes-navigation .prev:before,
.nivo-lightbox-prev:before,
.widget_calendar caption a.prev:before {
  transform: rotate(180deg);
  position: relative;
  display: block;
}

#to-top:before {
  transform: rotate(-90deg);
  position: relative;
  display: block;
}

/* Close
    ---------------*/

.hbe-toggle {
  &[data-close-icon='ien-eclose-1'].active:before {
    content: '\e90e' !important;
  }

  &[data-close-icon='ien-eclose-2'].active:before {
    content: '\e90f' !important;
  }

  &[data-close-icon='ien-eclose-3'].active:before {
    content: '\e910' !important;
  }

  &[data-close-icon='ien-eclose-4'].active:before {
    content: '\e911' !important;
  }

  &[data-close-icon='ien-eclose-5'].active:before {
    content: '\e912' !important;
  }

  &[data-close-icon='ien-eclose-6'].active:before {
    content: '\e913' !important;
  }

  &[data-close-icon='ien-eclose-7'].active:before {
    content: '\e914' !important;
  }

  &[data-close-icon='ien-eclose-8'].active:before {
    content: '\e915' !important;
  }

  &[data-close-icon='ien-eclose-9'].active:before {
    content: '\e916' !important;
  }

  &[data-close-icon='ien-eclose-10'].active:before {
    content: '\e917' !important;
  }

  &[data-close-icon='ien-eclose-11'].active:before {
    content: '\e918' !important;
  }

  &[data-close-icon='ien-eclose-12'].active:before {
    content: '\e919' !important;
  }
}

.nivo-lightbox-close:before,
#yith-quick-view-close:before,
.popup-banner-toggle:before,
.tagline-toggle:before,
.yith-woocompare-widget ul.products-list li .remove:before,
#cboxClose:before,
.dgwt-wcas-close:not(.dgwt-wcas-inner-preloader):before {
  content: '\e912' !important;
}

/* Plus
    ---------------*/

.shop_table .product-thumbnail .image-container:before,
.overlay-read-more:before,
.widget_instagram ul li a:before,
.widget_flickr ul li a:before,
.widget_et_recent_entries .post-thumbnail a:before,
.widget_products
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before,
.widget_recently_viewed_products
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before,
.widget_recent_reviews
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before,
.widget_top_rated_products
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before {
  content: '\e986';
}

/* Search
    ---------------*/

#searchsubmit + .search-icon:before,
.widget_product_search form button:before,
.woocommerce-product-gallery__trigger:before {
  content: '\e953';
}

.widget_categories ul li a:after,
.widget_pages ul li a:after,
.widget_archive ul li a:after,
.widget_meta ul li a:after,
.widget_layered_nav ul li a:after,
.widget_rating_filter ul li a:after,
.user-vehicle-list li a:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  content: '\f00c' !important;
}

/* Plyr
  ---------------*/

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  font-family: Avenir, 'Avenir Next', 'Helvetica Neue', 'Segoe UI', Helvetica,
    Arial, sans-serif;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;

  audio,
  video {
    border-radius: inherit;
    height: auto;
    vertical-align: middle;
    width: 100%;
  }

  button {
    font: inherit;
    line-height: inherit;
    width: auto;
    border-radius: 4px !important;
  }

  &:focus {
    outline: 0;
  }
}

.plyr--full-ui {
  box-sizing: border-box;

  *,
  ::after,
  ::before {
    box-sizing: inherit;
  }

  a,
  button,
  input,
  label {
    touch-action: manipulation;
  }
}

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;

  .plyr__caption {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    line-height: 185%;
    padding: 0.2em 0.5em;
    white-space: pre-wrap;

    div {
      display: inline;
    }
  }

  span:empty {
    display: none;
  }
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
}

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  transition: all 0.3s ease;

  svg {
    display: block;
    fill: currentColor;
    height: 18px;
    pointer-events: none;
    width: 18px;
  }

  &:focus {
    outline: 0;
  }

  &.plyr__tab-focus {
    box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
    outline: 0;
  }

  &.plyr__control--pressed {
    .icon--not-pressed,
    .label--not-pressed {
      display: none;
    }
  }

  &:not(.plyr__control--pressed) {
    .icon--pressed,
    .label--pressed {
      display: none;
    }
  }
}

.plyr--audio .plyr__control {
  &.plyr__tab-focus,
  &:hover,
  &[aria-expanded='true'] {
    background: #1aafff;
    color: #fff;
  }
}

.plyr--video .plyr__control {
  svg {
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
  }

  &.plyr__tab-focus,
  &:hover,
  &[aria-expanded='true'] {
    background: #1aafff;
    color: #fff;
  }
}

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  color: #fff;
  display: none;
  left: 50%;
  padding: 25px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  svg {
    height: 20px;
    left: 2px;
    position: relative;
    width: 20px;
  }

  &:focus,
  &:hover {
    background: #1aafff;
  }
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui {
  &.plyr--video .plyr__control--overlaid {
    display: block;
  }

  ::-webkit-media-controls {
    display: none;
  }
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;

  .plyr__menu,
  .plyr__progress,
  .plyr__time,
  .plyr__volume,
  > .plyr__control {
    margin-left: 5px;
  }

  .plyr__menu + .plyr__control,
  .plyr__progress + .plyr__control {
    margin-left: 2px;
  }

  > .plyr__control {
    + {
      .plyr__control,
      .plyr__menu {
        margin-left: 2px;
      }
    }

    &:first-child {
      margin-left: 0;
      margin-right: auto;

      + [data-plyr='pause'] {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }

  &:empty {
    display: none;
  }
}

@media (min-width: 480px) {
  .plyr__controls {
    .plyr__menu,
    .plyr__progress,
    .plyr__time,
    .plyr__volume,
    > .plyr__control {
      margin-left: 10px;
    }
  }
}

.plyr--audio .plyr__controls {
  background: #f5f5f5;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px;
}

.plyr--video {
  .plyr__controls {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 20px 5px 5px;
    position: absolute;
    right: 0;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    z-index: 3;
  }

  &.plyr--hide-controls .plyr__controls {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%);
  }
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px;
  }
}

.plyr {
  [data-plyr='airplay'],
  [data-plyr='captions'],
  [data-plyr='fullscreen'],
  [data-plyr='pip'] {
    display: none;
  }
}

.plyr--airplay-supported [data-plyr='airplay'],
.plyr--captions-enabled [data-plyr='captions'],
.plyr--fullscreen-enabled [data-plyr='fullscreen'],
.plyr--pip-supported [data-plyr='pip'] {
  display: inline-block;
}

.plyr__video-embed {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
  }
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr__menu {
  display: flex;
  position: relative;

  .plyr__control {
    svg {
      transition: transform 0.3s ease;
    }

    &[aria-expanded='true'] {
      svg {
        transform: rotate(90deg);
      }

      .plyr__tooltip {
        display: none;
      }
    }
  }
}

.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;

  > div {
    overflow: hidden;
    transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1),
      width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &::after {
    border: 4px solid transparent;
    border-top-color: rgba(255, 255, 255, 0.9);
    content: '';
    height: 0;
    position: absolute;
    right: 15px;
    top: 100%;
    width: 0;
  }

  [role='menu'] {
    padding: 7px;
  }

  [role='menuitem'],
  [role='menuitemradio'] {
    margin-top: 2px;
  }

  [role='menuitem']:first-child,
  [role='menuitemradio']:first-child {
    margin-top: 0;
  }

  .plyr__control {
    align-items: center;
    color: #4f5b5f;
    display: flex;
    font-size: 14px;
    padding: 4px 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;

    > span {
      align-items: inherit;
      display: flex;
      width: 100%;
    }

    &::after {
      border: 4px solid transparent;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .plyr__control--forward {
    padding-right: 28px;

    &::after {
      border-left-color: rgba(79, 91, 95, 0.8);
      right: 5px;
    }

    &.plyr__tab-focus::after,
    &:hover::after {
      border-left-color: currentColor;
    }
  }

  .plyr__control--back {
    font-weight: 500;
    margin: 7px;
    margin-bottom: 3px;
    padding-left: 28px;
    position: relative;
    width: calc(100% - 14px);

    &::after {
      border-right-color: rgba(79, 91, 95, 0.8);
      left: 7px;
    }

    &::before {
      background: #b7c5cd;
      box-shadow: 0 1px 0 #fff;
      content: '';
      height: 1px;
      left: 0;
      margin-top: 4px;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 100%;
    }

    &.plyr__tab-focus::after,
    &:hover::after {
      border-right-color: currentColor;
    }
  }

  .plyr__control[role='menuitemradio'] {
    padding-left: 7px;

    &::after {
      border-radius: 100%;
    }

    &::before {
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.1);
      content: '';
      display: block;
      flex-shrink: 0;
      height: 16px;
      margin-right: 10px;
      transition: all 0.3s ease;
      width: 16px;
    }

    &::after {
      background: #fff;
      border: 0;
      height: 6px;
      left: 12px;
      opacity: 0;
      top: 50%;
      transform: translateY(-50%) scale(0);
      transition: transform 0.3s ease, opacity 0.3s ease;
      width: 6px;
    }

    &[aria-checked='true'] {
      &::before {
        background: #1aafff;
      }

      &::after {
        opacity: 1;
        transform: translateY(-50%) scale(1);
      }
    }

    &.plyr__tab-focus::before,
    &:hover::before {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .plyr__menu__value {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: -5px;
    overflow: hidden;
    padding-left: 25px;
    pointer-events: none;
  }
}

.plyr--full-ui {
  input[type='range'] {
    -webkit-appearance: none;
    background: 0 0;
    border: 0;
    border-radius: 28px;
    color: #1aafff;
    display: block;
    height: 20px;
    margin: 0;
    padding: 0;
    transition: box-shadow 0.3s ease;
    width: 100%;

    &::-webkit-slider-runnable-track {
      background: 0 0;
      border: 0;
      border-radius: 3px;
      height: 6px;
      transition: box-shadow 0.3s ease;
      -webkit-user-select: none;
      user-select: none;
    }

    &::-webkit-slider-thumb {
      background: #fff;
      border: 0;
      border-radius: 100%;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
      height: 14px;
      position: relative;
      transition: all 0.2s ease;
      width: 14px;
      -webkit-appearance: none;
      margin-top: -4px;
    }

    &::-moz-range-track {
      background: 0 0;
      border: 0;
      border-radius: 3px;
      height: 6px;
      transition: box-shadow 0.3s ease;
      -moz-user-select: none;
      user-select: none;
    }

    &::-moz-range-thumb {
      background: #fff;
      border: 0;
      border-radius: 100%;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
      height: 14px;
      position: relative;
      transition: all 0.2s ease;
      width: 14px;
    }

    &::-moz-range-progress {
      background: currentColor;
      border-radius: 3px;
      height: 6px;
    }

    &::-ms-track {
      background: 0 0;
      border: 0;
      border-radius: 3px;
      height: 6px;
      transition: box-shadow 0.3s ease;
      -ms-user-select: none;
      user-select: none;
      color: transparent;
    }

    &::-ms-fill-upper {
      background: 0 0;
      border: 0;
      border-radius: 3px;
      height: 6px;
      transition: box-shadow 0.3s ease;
      -ms-user-select: none;
      user-select: none;
    }

    &::-ms-fill-lower {
      background: 0 0;
      border: 0;
      border-radius: 3px;
      height: 6px;
      transition: box-shadow 0.3s ease;
      -ms-user-select: none;
      user-select: none;
      background: currentColor;
    }

    &::-ms-thumb {
      background: #fff;
      border: 0;
      border-radius: 100%;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
      height: 14px;
      position: relative;
      transition: all 0.2s ease;
      width: 14px;
      margin-top: 0;
    }

    &::-ms-tooltip {
      display: none;
    }

    &:focus {
      outline: 0;
    }

    &::-moz-focus-outer {
      border: 0;
    }

    &.plyr__tab-focus {
      &::-webkit-slider-runnable-track,
      &::-moz-range-track,
      &::-ms-track {
        box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
        outline: 0;
      }
    }
  }

  &.plyr--video input[type='range'] {
    &::-webkit-slider-runnable-track,
    &::-moz-range-track,
    &::-ms-track {
      background-color: rgba(255, 255, 255, 0.25);
    }

    &:active {
      &::-webkit-slider-thumb,
      &::-moz-range-thumb,
      &::-ms-thumb {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15),
          0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
      }
    }
  }

  &.plyr--audio input[type='range'] {
    &::-webkit-slider-runnable-track,
    &::-moz-range-track,
    &::-ms-track {
      background-color: rgba(183, 197, 205, 0.66);
    }

    &:active {
      &::-webkit-slider-thumb,
      &::-moz-range-thumb,
      &::-ms-thumb {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15),
          0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr__time {
  font-size: 14px;

  + .plyr__time::before {
    content: '\2044';
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2;

  &::before {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(255, 255, 255, 0.9);
    bottom: -4px;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2;
  }
}

.plyr .plyr__control {
  &.plyr__tab-focus .plyr__tooltip,
  &:hover .plyr__tooltip {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
}

.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control {
  &:first-child {
    .plyr__tooltip {
      left: 0;
      transform: translate(0, 10px) scale(0.8);
      transform-origin: 0 100%;

      &::before {
        left: 16px;
      }
    }

    + .plyr__control .plyr__tooltip {
      left: 0;
      transform: translate(0, 10px) scale(0.8);
      transform-origin: 0 100%;
    }

    + .plyr__control .plyr__tooltip::before {
      left: 16px;
    }
  }

  &:last-child .plyr__tooltip {
    left: auto;
    right: 0;
    transform: translate(0, 10px) scale(0.8);
    transform-origin: 100% 100%;

    &::before {
      left: auto;
      right: 16px;
      transform: translateX(50%);
    }
  }

  &:first-child {
    .plyr__tooltip--visible {
      transform: translate(0, 0) scale(1);
    }

    + .plyr__control {
      .plyr__tooltip--visible,
      &.plyr__tab-focus .plyr__tooltip,
      &:hover .plyr__tooltip {
        transform: translate(0, 0) scale(1);
      }
    }

    &.plyr__tab-focus .plyr__tooltip,
    &:hover .plyr__tooltip {
      transform: translate(0, 0) scale(1);
    }
  }

  &:last-child {
    .plyr__tooltip--visible,
    &.plyr__tab-focus .plyr__tooltip,
    &:hover .plyr__tooltip {
      transform: translate(0, 0) scale(1);
    }
  }
}

.plyr--video {
  background: #000;
  overflow: hidden;

  &.plyr--menu-open {
    overflow: visible;
  }
}

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__progress {
  flex: 1;
  left: 7px;
  margin-right: 14px;
  position: relative;

  input[type='range'] {
    margin-left: -7px;
    margin-right: -7px;
    width: calc(100% + 14px);
  }
}

.plyr__progress__buffer {
  margin-left: -7px;
  margin-right: -7px;
  width: calc(100% + 14px);
}

.plyr__progress {
  input[type='range'] {
    position: relative;
    z-index: 2;
  }

  .plyr__tooltip {
    font-size: 14px;
    left: 0;
  }
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 6px;
  left: 0;
  margin-top: -3px;
  padding: 0;
  position: absolute;
  top: 50%;

  &::-webkit-progress-bar {
    background: 0 0;
    transition: width 0.2s ease;
  }

  &::-webkit-progress-value {
    background: currentColor;
    border-radius: 100px;
    min-width: 6px;
  }

  &::-moz-progress-bar {
    background: currentColor;
    border-radius: 100px;
    min-width: 6px;
    transition: width 0.2s ease;
  }

  &::-ms-fill {
    border-radius: 100px;
    transition: width 0.2s ease;
  }
}

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25);
}

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66);
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(
    -45deg,
    rgba(47, 52, 61, 0.6) 25%,
    transparent 25%,
    transparent 50%,
    rgba(47, 52, 61, 0.6) 50%,
    rgba(47, 52, 61, 0.6) 75%,
    transparent 75%,
    transparent
  );
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;

  input[type='range'] {
    margin-left: 5px;
    position: relative;
    z-index: 2;
  }
}

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px;
  }
}

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px;
  }
}

.plyr--is-ios {
  .plyr__volume,
  &.plyr--vimeo [data-plyr='mute'] {
    display: none !important;
  }
}

.plyr {
  &:-webkit-full-screen,
  &:-moz-full-screen,
  &:-ms-fullscreen,
  &:fullscreen {
    background: #000;
    border-radius: 0 !important;
    height: 100%;
    margin: 0;
    width: 100%;
  }

  &:-webkit-full-screen video,
  &:-moz-full-screen video,
  &:-ms-fullscreen video,
  &:fullscreen video {
    height: 100%;
  }

  &:-webkit-full-screen .plyr__video-wrapper,
  &:-moz-full-screen .plyr__video-wrapper,
  &:-ms-fullscreen .plyr__video-wrapper,
  &:fullscreen .plyr__video-wrapper {
    height: 100%;
    width: 100%;
  }

  &:-webkit-full-screen .plyr__video-embed,
  &:-moz-full-screen .plyr__video-embed,
  &:-ms-fullscreen .plyr__video-embed,
  &:fullscreen .plyr__video-embed {
    overflow: visible;
  }

  &:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper,
  &:-moz-full-screen.plyr--vimeo .plyr__video-wrapper,
  &:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper,
  &:fullscreen.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:-webkit-full-screen .plyr__control .icon--exit-fullscreen,
  &:-moz-full-screen .plyr__control .icon--exit-fullscreen,
  &:-ms-fullscreen .plyr__control .icon--exit-fullscreen,
  &:fullscreen .plyr__control .icon--exit-fullscreen {
    display: block;
  }

  &:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg,
  &:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg,
  &:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg,
  &:fullscreen .plyr__control .icon--exit-fullscreen + svg {
    display: none;
  }

  &:-webkit-full-screen.plyr--hide-controls,
  &:-moz-full-screen.plyr--hide-controls,
  &:-ms-fullscreen.plyr--hide-controls,
  &:fullscreen.plyr--hide-controls {
    cursor: none;
  }

  &:-webkit-full-screen,
  &:-moz-full-screen,
  &:-ms-fullscreen {
    background: #000;
    border-radius: 0 !important;
    height: 100%;
    margin: 0;
    width: 100%;

    video {
      height: 100%;
    }

    .plyr__video-wrapper {
      height: 100%;
      width: 100%;
    }

    .plyr__video-embed {
      overflow: visible;
    }

    &.plyr--vimeo .plyr__video-wrapper {
      height: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .plyr__control .icon--exit-fullscreen {
      display: block;

      + svg {
        display: none;
      }
    }

    &.plyr--hide-controls {
      cursor: none;
    }
  }
}

@media (min-width: 1024px) {
  .plyr {
    &:-webkit-full-screen .plyr__captions,
    &:-moz-full-screen .plyr__captions,
    &:-ms-fullscreen .plyr__captions,
    &:fullscreen .plyr__captions {
      font-size: 21px;
    }
  }
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
}

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
}

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;

  video {
    height: 100%;
  }

  .plyr__video-wrapper {
    height: 100%;
    width: 100%;
  }

  .plyr__video-embed {
    overflow: visible;
  }

  &.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .plyr__control .icon--exit-fullscreen {
    display: block;

    + svg {
      display: none;
    }
  }

  &.plyr--hide-controls {
    cursor: none;
  }
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

  > div {
    height: 100%;
    position: absolute;
    width: 100%;

    iframe {
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  &::after {
    background: rgba(47, 52, 61, 0.8);
    border-radius: 2px;
    bottom: 10px;
    color: #fff;
    content: attr(data-badge-text);
    font-size: 11px;
    padding: 2px 6px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    z-index: 3;
  }

  &:empty::after {
    display: none;
  }
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 6px;
  left: 0;
  margin: -3px 0 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

.plyr--video {
  position: relative;

  .poster {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-color: #212121;
    background-repeat: no-repeat;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center center;
  }
}

.plyr--stopped .poster {
  z-index: 2;
}

.plyr--video .plyr__controls {
  background: rgba(255, 255, 255, 0.2) !important;
  padding: 8px 16px !important;
}

.plyr__control--overlaid,
.plyr__control {
  border-radius: 0 !important;
}

/* General
  ---------------*/

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: block;
  width: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

a {
  &:focus {
    outline: thin dotted;
  }

  &:active,
  &:hover {
    outline: 0;
  }
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

mark {
  background: #ffab00;
  color: #212121;
  display: inline-block;
  padding: 4px 8px;
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 700;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: '201C' '201D' '2018' '2019';
}

small {
  font-size: 80%;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

button,
input {
  line-height: normal;
}

button,
html input[type='button'] {
  -webkit-appearance: button;
  cursor: pointer;
}

input {
  &[type='reset'],
  &[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
  }
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input {
  &[type='checkbox'],
  &[type='radio'] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }

  &[type='search'] {
    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  outline: 0;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}

#page-links .post-page-numbers + .post-page-numbers {
  margin-left: 8px;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  width: 1px;
  overflow: hidden;
}

::selection {
  background: #cfd8dc;

  /* WebKit/Blink Browsers */
  color: #263238;
}

::-moz-selection {
  background: #cfd8dc;

  /* Gecko Browsers */
  color: #263238;
}

.footer {
  ::selection,
  ::-moz-selection {
    color: #ffffff;
  }
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

#page-links > a {
  margin-bottom: 24px !important;
}

hr {
  clear: both;
}

.wp-block-coblocks-social__button {
  color: #ffffff !important;
}

.vc_row.background-position-left-bottom {
  background-position: left bottom !important;
}

.background-position-left-bottom {
  background-position: left bottom !important;

  > .vc_column-inner {
    background-position: left bottom !important;
  }
}

.vc_row.background-position-left-center {
  background-position: left center !important;
}

.background-position-left-center {
  background-position: left center !important;

  > .vc_column-inner {
    background-position: left center !important;
  }
}

.vc_row.background-position-right-bottom {
  background-position: right bottom !important;
}

.background-position-right-bottom {
  background-position: right bottom !important;

  > .vc_column-inner {
    background-position: right bottom !important;
  }
}

.vc_row.background-position-right-center {
  background-position: right center !important;
}

.background-position-right-center {
  background-position: right center !important;

  > .vc_column-inner {
    background-position: right center !important;
  }
}

.vc_row.background-repeat-x {
  background-repeat: repeat-x !important;
}

.background-repeat-x {
  background-repeat: repeat-x !important;

  > .vc_column-inner {
    background-repeat: repeat-x !important;
  }
}

.vc_row.background-repeat-y {
  background-repeat: repeat-y !important;
}

.background-repeat-y {
  background-repeat: repeat-y !important;

  > .vc_column-inner {
    background-repeat: repeat-y !important;
  }
}

span.br {
  display: block;
}

figcaption {
  line-height: 22px !important;
}

.wp-block-image figcaption {
  margin-top: 1.5em !important;
}

.alignfull {
  margin-left: calc(-100vw / 2 + 100% / 2);
  margin-right: calc(-100vw / 2 + 100% / 2);
  max-width: 100vw !important;
  width: 100vw !important;
}

.alignwide:not(.is-provider-twitter) {
  margin-left: calc(-85vw / 2 + 100% / 2);
  margin-right: calc(-85vw / 2 + 100% / 2);
  max-width: 85vw !important;
  width: 85vw !important;
}

.wp-block-pullquote.alignfull,
.wp-block-columns.alignfull {
  margin-left: calc(-95vw / 2 + 100% / 2);
  margin-right: calc(-95vw / 2 + 100% / 2);
  max-width: 95vw !important;
  width: 95vw !important;
}

.blog-content .post-content * {
  &.alignwide,
  &.alignfull {
    max-width: 840px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.wp-block-latest-posts {
  list-style: inside !important;

  &.is-grid {
    list-style: none !important;
  }
}

.wp-block-cover-image {
  .wp-block-cover-image-text,
  .wp-block-cover-text,
  h2 {
    font-size: 24px !important;
    line-height: 32px !important;
    padding: 24px !important;
  }
}

.wp-block-cover {
  .wp-block-cover-image-text,
  .wp-block-cover-text,
  h2,
  p {
    font-size: 24px !important;
    line-height: 32px !important;
    padding: 24px !important;
  }
}

/* Owl Carousel
    ---------------*/

.owl-carousel {
  -webkit-tap-highlight-color: transparent;
  position: relative;

  .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }

  display: none;
  z-index: 1;

  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;

    &:after {
      content: '.';
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
  }

  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .owl-item,
  .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }

  .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;

    img {
      display: block;
      width: 100%;
    }
  }

  .owl-dots.disabled,
  .owl-nav.disabled {
    display: none;
  }
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel {
  &.owl-loaded {
    display: block;
  }

  .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-nav {
    .owl-next,
    .owl-prev {
      cursor: pointer;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  &.owl-loading {
    opacity: 0;
    display: block;
  }

  &.owl-hidden {
    opacity: 0;
  }

  &.owl-refresh .owl-item {
    visibility: hidden;
  }

  &.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-grab {
    cursor: move;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;

    .owl-item {
      float: right;
    }
  }

  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  .owl-animated-in {
    z-index: 0;
  }

  .owl-animated-out {
    z-index: 1;
  }

  .fadeOut {
    animation-name: fadeOut;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel {
  .owl-item {
    .owl-lazy {
      opacity: 0;
      transition: opacity 0.4s ease;
    }

    img.owl-lazy {
      transform-style: preserve-3d;
    }
  }

  .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }

  .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url(owl.video.play.png) no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 0.1s ease;

    &:hover {
      -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3);
    }
  }

  .owl-video-playing {
    .owl-video-play-icon,
    .owl-video-tn {
      display: none;
    }
  }

  .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 0.4s ease;
  }

  .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }

  .owl-nav > * {
    display: block;
    text-align: center;
    transition: all 300ms ease-out;
    position: absolute;
    top: 50%;
    left: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #f5f5f5;
    color: #212121 !important;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0 !important;
    min-width: 0;
  }
}

.slick-slider {
  .slick-prev,
  .slick-next {
    display: block;
    text-align: center;
    transition: all 300ms ease-out;
    position: absolute;
    top: 50%;
    left: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #f5f5f5;
    color: #212121 !important;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0 !important;
    min-width: 0;
  }
}

.owl-carousel {
  .owl-nav > *,
  .owl-dots {
    opacity: 0;
  }

  &:hover {
    .owl-nav > *,
    .owl-dots {
      opacity: 1;
    }
  }
}

.related-posts .owl-nav > * {
  opacity: 1;
}

.nav-visibility-always.owl-carousel {
  .owl-nav > *,
  .owl-dots {
    opacity: 1;
  }
}

.navigation-only-bullets .owl-nav > *,
.navigation-only-arrows .owl-dots {
  opacity: 0 !important;
}

.owl-carousel .owl-nav > *:hover {
  color: #ffffff !important;
}

.slick-slider {
  .slick-prev:hover,
  .slick-next:hover {
    color: #ffffff !important;
  }
}

.owl-carousel .owl-nav > .owl-next,
.slick-slider .slick-next {
  left: auto;
  right: 0;
}

.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 0;
  transition: all 300ms ease-out;
  line-height: 10px;
}

.et-testimonial-container.owl-carousel .owl-dots {
  margin-top: 24px;
}

.owl-carousel .owl-dots > * {
  display: inline-block;
  transition: all 300ms ease-out;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 0 6px;
  background-color: #bdbdbd;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.navigation-only-arrows .owl-dots,
.navigation-only-dottes .owl-nav {
  display: none;
}

.arrows-pos-top-right.owl-carousel,
.arrows-pos-top-left.owl-carousel {
  padding-top: 48px;
}

.arrows-pos-bottom-right.owl-carousel,
.arrows-pos-bottom-left.owl-carousel {
  padding-bottom: 24px;
}

.bullets-pos-top-right.owl-carousel,
.bullets-pos-top-left.owl-carousel {
  padding-top: 42px;
}

.bullets-pos-bottom-right.owl-carousel,
.bullets-pos-bottom-left.owl-carousel {
  padding-bottom: 18px;
}

.arrows-pos-top-right.owl-carousel .owl-nav > *,
.arrows-pos-bottom-right.owl-carousel .owl-nav > *,
.arrows-pos-top-left.owl-carousel .owl-nav > *,
.arrows-pos-bottom-left.owl-carousel .owl-nav > * {
  top: 0 !important;
  left: 0 !important;
  -ms-transform: none !important;
  transform: none !important;
  position: relative !important;
  display: inline-block !important;
  vertical-align: middle !important;
}

.arrows-pos-top-right.owl-carousel .owl-nav > .owl-next,
.arrows-pos-bottom-right.owl-carousel .owl-nav > .owl-next,
.arrows-pos-top-left.owl-carousel .owl-nav > .owl-next,
.arrows-pos-bottom-left.owl-carousel .owl-nav > .owl-next {
  margin-left: 8px;
}

.arrows-pos-top-right.owl-carousel .owl-nav,
.arrows-pos-bottom-right.owl-carousel .owl-nav,
.bullets-pos-top-right.owl-carousel .owl-dots,
.bullets-pos-bottom-right.owl-carousel .owl-dots {
  position: absolute !important;
  top: 0;
  right: 12px;
}

.arrows-pos-top-right.owl-carousel .owl-nav,
.arrows-pos-bottom-left.owl-carousel .owl-nav,
.bullets-pos-top-left.owl-carousel .owl-dots,
.bullets-pos-bottom-left.owl-carousel .owl-dots {
  position: absolute !important;
  bottom: 0;
  right: 12px;
}

.arrows-pos-top-left.owl-carousel .owl-nav {
  right: auto;
  left: 12px;
  position: absolute !important;
  top: 0;
}

.arrows-pos-bottom-left.owl-carousel .owl-nav {
  right: auto;
  left: 12px;
  position: absolute !important;
  bottom: 0;
}

.arrows-pos-top-right.owl-carousel[data-gap='0'] .owl-nav,
.bullets-pos-top-right.owl-carousel[data-gap='0'] .owl-dots,
.bullets-pos-bottom-right.owl-carousel[data-gap='0'] .owl-dots {
  right: 0px;
}

.arrows-pos-top-right.owl-carousel[data-gap='2'] .owl-nav,
.bullets-pos-top-right.owl-carousel[data-gap='2'] .owl-dots,
.bullets-pos-bottom-right.owl-carousel[data-gap='2'] .owl-dots {
  right: 1px;
}

.arrows-pos-top-right.owl-carousel[data-gap='4'] .owl-nav,
.bullets-pos-top-right.owl-carousel[data-gap='4'] .owl-dots,
.bullets-pos-bottom-right.owl-carousel[data-gap='4'] .owl-dots {
  right: 2px;
}

.arrows-pos-top-right.owl-carousel[data-gap='8'] .owl-nav,
.bullets-pos-top-right.owl-carousel[data-gap='8'] .owl-dots,
.bullets-pos-bottom-right.owl-carousel[data-gap='8'] .owl-dots {
  right: 4px;
}

.arrows-pos-top-right.owl-carousel[data-gap='16'] .owl-nav,
.bullets-pos-top-right.owl-carousel[data-gap='16'] .owl-dots,
.bullets-pos-bottom-right.owl-carousel[data-gap='16'] .owl-dots {
  right: 8px;
}

.arrows-pos-top-right.owl-carousel[data-gap='24'] .owl-nav,
.bullets-pos-top-right.owl-carousel[data-gap='24'] .owl-dots,
.bullets-pos-bottom-right.owl-carousel[data-gap='24'] .owl-dots {
  right: 12px;
}

.arrows-pos-top-right.owl-carousel[data-gap='32'] .owl-nav,
.bullets-pos-top-right.owl-carousel[data-gap='32'] .owl-dots,
.bullets-pos-bottom-right.owl-carousel[data-gap='32'] .owl-dots {
  right: 16px;
}

.arrows-pos-top-right.owl-carousel[data-gap='40'] .owl-nav,
.bullets-pos-top-right.owl-carousel[data-gap='40'] .owl-dots,
.bullets-pos-bottom-right.owl-carousel[data-gap='40'] .owl-dots {
  right: 20px;
}

.arrows-pos-top-left.owl-carousel[data-gap='0'] .owl-nav,
.bullets-pos-top-left.owl-carousel[data-gap='0'] .owl-dots,
.bullets-pos-bottom-left.owl-carousel[data-gap='0'] .owl-dots {
  left: 0px;
}

.arrows-pos-top-left.owl-carousel[data-gap='2'] .owl-nav,
.bullets-pos-top-left.owl-carousel[data-gap='2'] .owl-dots,
.bullets-pos-bottom-left.owl-carousel[data-gap='2'] .owl-dots {
  left: 1px;
}

.arrows-pos-top-left.owl-carousel[data-gap='4'] .owl-nav,
.bullets-pos-top-left.owl-carousel[data-gap='4'] .owl-dots,
.bullets-pos-bottom-left.owl-carousel[data-gap='4'] .owl-dots {
  left: 2px;
}

.arrows-pos-top-left.owl-carousel[data-gap='8'] .owl-nav,
.bullets-pos-top-left.owl-carousel[data-gap='8'] .owl-dots,
.bullets-pos-bottom-left.owl-carousel[data-gap='8'] .owl-dots {
  left: 4px;
}

.arrows-pos-top-left.owl-carousel[data-gap='16'] .owl-nav,
.bullets-pos-top-left.owl-carousel[data-gap='16'] .owl-dots,
.bullets-pos-bottom-left.owl-carousel[data-gap='16'] .owl-dots {
  left: 8px;
}

.arrows-pos-top-left.owl-carousel[data-gap='24'] .owl-nav,
.bullets-pos-top-left.owl-carousel[data-gap='24'] .owl-dots,
.bullets-pos-bottom-left.owl-carousel[data-gap='24'] .owl-dots {
  left: 12px;
}

.arrows-pos-top-left.owl-carousel[data-gap='32'] .owl-nav,
.bullets-pos-top-left.owl-carousel[data-gap='32'] .owl-dots,
.bullets-pos-bottom-left.owl-carousel[data-gap='32'] .owl-dots {
  left: 16px;
}

.arrows-pos-top-left.owl-carousel[data-gap='40'] .owl-nav,
.bullets-pos-top-left.owl-carousel[data-gap='40'] .owl-dots,
.bullets-pos-bottom-left.owl-carousel[data-gap='40'] .owl-dots {
  left: 20px;
}

.bullets-pos-top-right.owl-carousel .owl-dots,
.bullets-pos-bottom-right.owl-carousel .owl-dots,
.bullets-pos-top-left.owl-carousel .owl-dots,
.bullets-pos-bottom-left.owl-carousel .owl-dots {
  position: absolute;
}

.bullets-pos-bottom-right .owl-dots,
.bullets-pos-top-right .owl-dots {
  text-align: right;
}

.bullets-pos-bottom-left .owl-dots,
.bullets-pos-top-left .owl-dots {
  text-align: left;
}

/* Nivolightbox
    ---------------*/

.nivo-lightbox-content {
  height: 100%;
  width: 100%;
}

.nivo-lightbox-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99998;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &.nivo-lightbox-open {
    visibility: visible;
    opacity: 1;
  }
}

.nivo-lightbox-wrap {
  margin: 0 auto;
  text-align: center;
  height: 100%;
}

.nivo-lightbox-nav {
  display: none;
}

.nivo-lightbox-prev {
  transition: all 300ms ease-out;
  position: absolute;
  top: 50%;
  left: 0;
  color: #ffffff;
}

.nivo-lightbox-next {
  transition: all 300ms ease-out;
  position: absolute;
  top: 50%;
  right: 0;
  color: #ffffff;
}

.nivo-lightbox-close {
  transition: all 300ms ease-out;
  position: absolute;
  color: #ffffff !important;
  right: 0;
  top: 0;
}

.nivo-lightbox-prev,
.nivo-lightbox-next,
.nivo-lightbox-close {
  width: 56px;
  height: 56px;
  font-size: 16px;
  line-height: 56px;
  text-align: center;
}

.nivo-lightbox-prev,
.nivo-lightbox-next,
.nivo-lightbox-close {
  color: #212121 !important;
  background-color: #ffffff;
  opacity: 1;
}

.nivo-lightbox-prev:hover,
.nivo-lightbox-next:hover,
.nivo-lightbox-close:hover {
  color: #ffffff !important;
}

.nivo-lightbox-image {
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100vh;
    width: auto;
    height: auto;
    vertical-align: middle;
  }
}

.nivo-lightbox-content iframe,
.nivo-lightbox-error {
  width: 100%;
  height: 100%;
}

.nivo-lightbox-ajax,
.nivo-lightbox-inline {
  max-height: 100%;
  overflow: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.nivo-lightbox-error {
  display: table;
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 1px #000;

  p {
    display: table-cell;
    vertical-align: middle;
  }
}

.nivo-lightbox-notouch .nivo-lightbox-effect-fadeScale {
  -ms-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.nivo-lightbox-wrap {
  -ms-transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.nivo-lightbox-open .nivo-lightbox-wrap {
  -ms-transform: scale(1);
  transform: scale(1);
  -ms-transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

.nivo-lightbox-overlay {
  background: #000000;
}

.nivo-lightbox-nav {
  top: 50%;
}

.nivo-lightbox-close:hover {
  opacity: 1;
  color: #ffffff !important;
}

.nivo-lightbox-title {
  background: #000;
  color: #fff;
  padding: 8px 16px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999999999999999999;
}

.nivo-lightbox-image img {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

.nivo-lightbox-ajax,
.nivo-lightbox-inline {
  background: #fff;
  padding: 40px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

.nivo-lightbox-content.nivo-lightbox-loading {
  background: url(images/loading.gif) 50% 50% no-repeat;
}

.nivo-lightbox-close {
  display: block;
}

/* Slick carousel
    ---------------*/

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-loading {
  .slick-slide,
  .slick-track {
    visibility: hidden;
  }
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:focus {
    outline: 0;
  }

  &.dragging {
    cursor: pointer;
    cursor: move;
  }
}

.slick-slider {
  .slick-list,
  .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-track {
  top: 0;
  left: 0;

  &:after,
  &:before {
    display: table;
    content: '';
  }

  &:after {
    clear: both;
  }
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;

  img {
    display: block;
    -ms-transform: none !important;
    transform: none !important;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
  outline: none;
  border: none;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  -ms-transform: translate(0, -50%) !important;
  transform: translate(0, -50%) !important;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0 !important;
  margin: 0 !important;
  backface-visibility: hidden;
  transition: all 300ms ease-out;
}

.slick-slider button {
  &.slick-prev,
  &.slick-next {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  display: block;
  position: relative;
  text-align: center;
  line-height: inherit;
}

.slick-prev:before {
  left: -1px;
}

.slick-next:before {
  right: -1px;
}

/* Dots */

.slick-dots {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transform: translateY(60px);
  transform: translateY(60px);
}

.slick-slider:hover .slick-dots {
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 0 5px !important;
  padding: 0;
  cursor: pointer;

  button {
    font-size: 0;
    line-height: 0;
    display: block;
    min-width: 0;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    text-indent: -90000em;
    box-shadow: none !important;

    &:hover,
    &:focus {
      outline: none;
    }
  }
}

.slick-thumbnail-navigation {
  .slick-track {
    margin: 0 auto;
  }

  ul {
    margin-left: -2px;
    margin-right: -2px;
  }

  li {
    margin: 0px;
    padding: 4px 2px 0 2px;
    cursor: pointer;
    opacity: 0.2;
    transition: all 300ms ease-out;
    outline: none;

    &.slick-current,
    &:hover {
      opacity: 1;
    }
  }
}

/* Tipso
    ---------------*/

.tipso_bubble {
  box-sizing: border-box;

  > .tipso_arrow {
    box-sizing: border-box;
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid;
    pointer-events: none;
  }

  position: absolute;
  text-align: center;
  border-radius: 0px;
  z-index: 99;
  padding: 24px;

  &.top > .tipso_arrow {
    border-color: #000 transparent transparent;
    top: 100%;
    left: 50%;
    margin-left: -8px;
  }

  &.bottom > .tipso_arrow {
    border-color: transparent transparent #000;
    bottom: 100%;
    left: 50%;
    margin-left: -8px;
  }

  &.left > .tipso_arrow {
    border-color: transparent transparent transparent #000;
    top: 50%;
    left: 100%;
    margin-top: -8px;
  }

  &.right {
    > .tipso_arrow {
      border-color: transparent #000 transparent transparent;
      top: 50%;
      right: 100%;
      margin-top: -8px;
    }

    text-align: left;
  }

  &.left {
    text-align: left;
  }
}

.css-animated {
  animation-duration: 300ms;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes tipsoFadeOutDown {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 15%, 0);
  }
}

.fadeOutDown {
  animation-name: tipsoFadeOutDown;
}

@keyframes tipsoFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, 15%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: tipsoFadeInDown;
}

@keyframes tipsoFadeOutLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-5%, 0, 0);
  }
}

.tipso_bubble {
  &.fadeOutLeft {
    animation-name: tipsoFadeOutLeft;
  }

  &.fadeInLeft {
    animation-name: tipsoFadeInLeft;
  }

  &.fadeOutRight {
    animation-name: tipsoFadeOutRight;
  }

  &.fadeInRight {
    animation-name: tipsoFadeInRight;
  }
}

@keyframes tipsoFadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-5%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes tipsoFadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(5%, 0, 0);
  }
}

@keyframes tipsoFadeInRight {
  from {
    opacity: 0;
    transform: translate3d(5%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes tipsoFadeOutTop {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -15%, 0);
  }
}

.fadeOutTop {
  animation-name: tipsoFadeOutTop;
}

@keyframes tipsoFadeInTop {
  from {
    opacity: 0;
    transform: translate3d(0, -15%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInTop {
  animation-name: tipsoFadeInTop;
}

.et-clearfix:after {
  content: '';
  display: table;
  clear: both;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
}

html {
  // position: static;
  // height: 100% !important;
  // overflow-y: scroll;
  // overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (-moz-images-in-menus: 0) {
  body {
    font-weight: 300 !important;
  }
}

body {
  margin: 0;
  min-height: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

iframe,
object,
embed {
  border: none;
  vertical-align: bottom;
  max-width: 100%;
}

figure {
  max-width: 100%;
  margin: 0;
}

.wp-block-audio {
  margin-bottom: 24px;
}

figure > a {
  color: transparent !important;

  &:hover {
    color: transparent !important;
  }
}

.vp-player-layout {
  top: 0 !important;
  bottom: 0 !important;
}

.mejs-container {
  width: 100% !important;
}

.mejs-overlay-button {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  width: 75px !important;
  height: 75px !important;
  margin: -37.5px 0 0 -37.5px !important;

  /* background: url(images/video_icon.png) no-repeat !important; */
}

.mejs-overlay-play {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.wp-video-shortcode,
.wp-video {
  width: 100% !important;
  height: auto !important;
}

.wp-video-shortcode {
  .mejs-mediaelement {
    position: relative !important;
    height: auto !important;
  }

  .mejs-poster {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.mejs-button,
.mejs-currenttime,
.mejs-duration {
  word-wrap: inherit;
}

.header .mejs-button > button {
  height: 17px !important;
  margin: 12px 6px !important;
  width: 17px !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th {
  text-align: left;
}

tr,
th,
td {
  padding: 8px 16px;
}

th {
  color: #212121;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  background-color: #f5f5f5;
}

td {
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}

pre {
  display: block;
  font-family: monospace !important;
  white-space: pre-wrap;
  margin: 1em 0;
  background-color: #f5f5f5;
  padding: 16px !important;
  border-radius: 4px;
}

code {
  background-color: #f5f5f5;
  padding: 12px !important;
  display: inline-block;
  vertical-align: baseline;
  font-family: Menlo, Consolas, monaco, monospace;
  border-radius: 4px;
}

pre code {
  padding: 0 !important;
  margin: 0 !important;
}

ul,
ol {
  list-style-position: inside;
}

ul li,
ol li {
  padding: 2px 0;
}

ul ul,
ol ol {
  margin-left: 24px;
}

a {
  text-decoration: none;
  outline: 0;

  &:hover,
  &:active {
    outline: 0;
  }

  transition: color 300ms ease-out;
  color: #000000;
}

abbr[title],
dfn[title] {
  cursor: help;
}

ins,
mark {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

strong,
b {
  font-weight: 700;
}

del + ins {
  margin-left: 4px;
}

.footer {
  strong,
  b {
    font-weight: 700;
  }
}

var,
address {
  font-style: normal;
}

sub {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -8px;
}

sub {
  bottom: -4px;
}

p,
q,
pre,
address,
hr,
code,
samp,
dl,
ol,
ul {
  margin: 0 0 24px 0;
  padding: 0;
}

li {
  ul,
  ol {
    margin: 0 0 24px 0;
    padding: 0;
  }
}

form,
table,
fieldset,
menu,
kbd,
samp {
  margin: 0 0 24px 0;
  padding: 0;
}

dl {
  dd {
    margin-left: 24px;
  }

  padding: 24px;
  border: 1px solid #e0e0e0;

  dt {
    float: left;
    clear: left;
    width: 100px;
    text-align: right;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
  }

  dd {
    margin: 0 0 0 116px;
    padding: 0 0 4px;
    font-size: 18px;
    line-height: 32px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 24px;
}

#single-post-page .post-content {
  ul li ul,
  ol li ol {
    padding-left: 32px !important;
  }
}

.page-template-default .page-content {
  ul li ul,
  ol li ol {
    padding-left: 32px !important;
  }
}

.comment-content {
  ul li ul,
  ol li ol {
    padding-left: 32px !important;
  }
}

.page-template-default .post-comments-area {
  padding-top: 48px;
}

.gallery {
  width: 100%;
  display: block;
  padding: 0;
  margin-bottom: 32px;
  margin-left: -4px;
  margin-right: -4px;

  > .gallery-item {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding: 4px;
  }

  &.gallery-columns-1 > .gallery-item {
    width: 100%;
  }

  &.gallery-columns-2 > .gallery-item {
    width: 50%;
  }

  &.gallery-columns-3 > .gallery-item {
    width: 33.33333333333333%;
  }

  &.gallery-columns-4 > .gallery-item,
  &.gallery-columns-5 > .gallery-item {
    width: 50%;
  }

  &.gallery-columns-6 > .gallery-item {
    width: 33.33333333333333%;
  }

  &.gallery-columns-7 > .gallery-item,
  &.gallery-columns-8 > .gallery-item {
    width: 50%;
  }

  &.gallery-columns-9 > .gallery-item {
    width: 33.33333333333333%;
  }

  &.gallery-columns-10 > .gallery-item {
    width: 50%;
  }
}

.forcefullwidth_wrapper_tp_banner {
  overflow: hidden;
}

.mejs-controls .mejs-button button {
  line-height: 1em !important;
  padding: 0px !important;
  text-align: center !important;
  font-size: 1em !important;
  border-radius: 0 !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  -ms-transform: none !important;
  transform: none !important;
  box-shadow: none !important;
  min-width: 0;
}

.post-video {
  position: relative;

  .wp-video {
    width: 100% !important;
  }

  .mejs-mediaelement {
    position: relative !important;
    height: auto !important;
  }

  .wp-video-shortcode {
    height: auto !important;
    width: 100% !important;
  }
}

.post-audio {
  .mejs-container {
    width: 100%;

    .mejs-controls {
      width: 100%;
    }
  }

  .mejs-embed {
    width: 100%;

    body {
      width: 100%;
    }
  }
}

.post-video .mejs-poster {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.flex-mod {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.soundcloud iframe {
  max-width: 100%;
}

.wp-video-shortcode,
.wp-audio-shortcode {
  max-width: 100% !important;
  height: auto;
}

img + p:empty,
p:empty {
  display: none !important;
}

img {
  + br {
    height: 1px;
  }

  max-width: 100%;
  height: auto !important;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  margin: 0;

  &[class*='wp-image'] {
    margin-bottom: 24px;
  }
}

.wp-caption {
  margin-bottom: 24px;
}

.wp-block-media-text__media {
  img[class*='wp-image'],
  .wp-caption {
    margin-bottom: 0;
  }
}

.wp-block-gallery img,
.wp-block-image img {
  margin-bottom: 0;
}

.wp-caption img,
.image-container img,
.gallery-icon img {
  margin: 0 auto;
}

.alignright {
  float: right;
  margin: 0 0 1em 1em !important;
}

.alignleft {
  float: left;
  margin: 0 1em 1em 0 !important;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.wp-caption {
  &.alignright {
    float: right;
    margin: 0 0 1em 1em !important;
  }

  &.alignleft {
    float: left;
    margin: 0 1em 1em 0 !important;
  }

  &.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center !important;

    img {
      display: inline-block !important;
    }
  }
}

a img {
  &.alignright {
    float: right;
    margin: 0 0 1em 1em !important;
  }

  &.alignleft {
    float: left;
    margin: 0 1em 1em 0 !important;
  }

  &.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.et-image {
  &.alignright {
    float: right;
    margin: 0 0 1em 1em;
  }

  &.alignleft {
    float: left;
    margin: 0 1em 1em 0;
  }

  &.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.page-content > p,
.post-content > p {
  margin-bottom: 24px;
}

p.has-large-font-size {
  line-height: 1.25em;
}

#gen-wrap {
  position: relative;
  left: 0;
  height: 100%;
}

#wrap {
  height: 100%;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 99;
  background-color: #ffffff;

  &.full {
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    background-color: #ffffff;
  }
}

.layout-boxed:not(.sidebar-navigation) #wrap {
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.3);
}

#to-top {
  position: fixed;
  z-index: 99;
  bottom: 24px;
  right: 24px;
  display: block;
  transition: all 300ms ease-out;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  box-shadow: 0px 0 24px 0px rgba(0, 0, 0, 0.08);

  &.animate {
    visibility: visible;
    opacity: 1;
  }

  &:before {
    width: inherit;
    height: inherit;
    position: absolute;
    display: block;
  }
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: $color4;
}

.footer {
  position: relative;
  z-index: 78;

  &.sticky-true {
    overflow: hidden;
  }

  &[data-sticky='true'].active {
    opacity: 1;
    visibility: visible;
  }
}

.container,
.wpml-ls-statics-post_translations {
  width: 93.75%;
  max-width: 93.75%;
  margin: 0 auto 0 auto;
  position: relative;
}

.container-full {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto 0 auto;
  position: relative;
}

.stretch_row_content > .container {
  width: 100% !important;
  max-width: 100% !important;
}

#et-content {
  position: relative;
  z-index: 78;
  background-color: #ffffff;

  /*overflow: hidden;*/

  &.zindex {
    z-index: 100;
  }

  &.padding-true {
    padding: 0 0 48px 0;
  }
}

.woocommerce-page #et-content.padding-true {
  padding: 0 !important;
}

.container.page-full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.wp-caption {
  display: inline-block;
  max-width: 100%;
}

.wp-caption-text,
.gallery-caption {
  text-align: center;
  padding: 8px;
  color: #777777;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
}

.bypostauthor {
  position: relative;
}

.et-footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  vertical-align: middle;

  li {
    padding: 0 16px;
    margin: 0;
    display: inline-block;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

/* Slider
  ---------------*/

.tp-dottedoverlay {
  background-attachment: scroll;
  background-repeat: repeat;
  background-position: left top;
}

/* Photoswip
  ---------------*/

.pswp__button {
  width: 44px;
  height: 44px;
  position: relative;
  background: none;
  cursor: pointer;
  overflow: visible;
  -webkit-appearance: none;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  float: right;
  opacity: 0.75;
  transition: opacity 0.2s;
  box-shadow: none;

  &:focus,
  &:hover {
    opacity: 1;
  }

  &:active {
    outline: none;
    opacity: 0.9;
  }

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

.pswp__ui--over-close .pswp__button--close {
  opacity: 1;
}

.pswp__button,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  background: url(images/default-skin.png) 0 0 no-repeat;
  background-size: 264px 88px;
  width: 44px;
  height: 44px;
}

.pswp__button--close {
  background-position: 0 -44px;
}

.pswp__button--share {
  background-position: -44px -44px;
  display: none;
}

.pswp__button--fs {
  display: none;
}

.pswp--supports-fs .pswp__button--fs {
  display: block;
}

.pswp--fs .pswp__button--fs {
  background-position: -44px 0;
}

.pswp__button--zoom {
  display: none;
  background-position: -88px 0;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

.pswp--zoomed-in .pswp__button--zoom {
  background-position: -132px 0;
}

.pswp--touch {
  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    visibility: hidden;
  }
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
  background: none;
  top: 50%;
  margin-top: -50px;
  width: 70px;
  height: 100px;
  position: absolute;
}

.pswp__button--arrow--left {
  left: 0;
}

.pswp__button--arrow--right {
  right: 0;
}

.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  content: '';
  top: 35px;
  background-color: rgba(0, 0, 0, 0.3);
  height: 30px;
  width: 32px;
  position: absolute;
}

.pswp__button--arrow--left:before {
  left: 6px;
  background-position: -138px -44px;
}

.pswp__button--arrow--right:before {
  right: 6px;
  background-position: -94px -44px;
}

.pswp__counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pswp__share-modal {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 10px;
  position: absolute;
  z-index: 1600;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  backface-visibility: hidden;
  will-change: opacity;
}

.pswp__share-modal--hidden {
  display: none;
}

.pswp__share-tooltip {
  z-index: 1620;
  position: absolute;
  background: #fff;
  top: 56px;
  display: block;
  width: auto;
  right: 44px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  -ms-transform: translateY(6px);
  transform: translateY(6px);
  transition: transform 0.25s;
  backface-visibility: hidden;
  will-change: transform;

  a {
    display: block;
    padding: 8px 12px;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
}

.pswp__share-modal--fade-in {
  opacity: 1;

  .pswp__share-tooltip {
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.pswp--touch .pswp__share-tooltip a {
  padding: 16px 12px;
}

a.pswp__share--facebook:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: -12px;
  right: 15px;
  border: 6px solid transparent;
  border-bottom-color: #fff;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
}

.pswp__counter {
  position: absolute;
  left: 0;
  top: 0;
  height: 44px;
  font-size: 13px;
  line-height: 44px;
  color: #fff;
  opacity: 0.75;
  padding: 0 10px;
}

.pswp__caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 44px;

  small {
    font-size: 11px;
    color: #bdbdbd;
  }
}

.pswp__caption__center {
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 8px 16px;
}

.pswp__caption--empty {
  display: none;
}

.pswp__caption--fake {
  visibility: hidden;
}

.pswp__preloader {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -22px;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  will-change: opacity;
  direction: ltr;
}

.pswp__preloader__icn {
  width: 20px;
  height: 20px;
  margin: 12px;
}

.pswp__preloader--active {
  opacity: 1;

  .pswp__preloader__icn {
    background: url(images/preloader.gif) 0 0 no-repeat;
  }
}

.pswp--css_animation {
  .pswp__preloader--active {
    opacity: 1;

    .pswp__preloader__icn {
      animation: clockwise 500ms linear infinite;
    }

    .pswp__preloader__donut {
      animation: donut-rotate 1000ms cubic-bezier(0.4, 0, 0.22, 1) infinite;
    }
  }

  .pswp__preloader__icn {
    background: none;
    opacity: 0.75;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 15px;
    top: 15px;
    margin: 0;
  }

  .pswp__preloader__cut {
    position: relative;
    width: 7px;
    height: 14px;
    overflow: hidden;
  }

  .pswp__preloader__donut {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  .pswp__preloader {
    position: relative;
    left: auto;
    top: auto;
    margin: 0;
    float: right;
  }
}

@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes donut-rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-140deg);
  }

  100% {
    transform: rotate(0);
  }
}

.pswp__ui {
  -webkit-font-smoothing: auto;
  visibility: visible;
  opacity: 1;
  z-index: 1550;
}

.pswp__top-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 44px;
  width: 100%;
}

.pswp__caption,
.pswp__top-bar {
  backface-visibility: hidden;
  will-change: opacity;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
}

.pswp--has_mouse {
  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    backface-visibility: hidden;
    will-change: opacity;
    transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
  }

  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    visibility: visible;
  }
}

.pswp__top-bar,
.pswp__caption {
  background-color: rgba(0, 0, 0, 0.5);
}

.pswp__ui--fit {
  .pswp__top-bar,
  .pswp__caption {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.pswp__ui--idle {
  .pswp__top-bar,
  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    opacity: 0;
  }
}

.pswp__ui--hidden {
  .pswp__top-bar,
  .pswp__caption,
  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    opacity: 0.001;
  }
}

.pswp__ui--one-slide {
  .pswp__button--arrow--left,
  .pswp__button--arrow--right,
  .pswp__counter {
    display: none;
  }
}

.pswp__element--disabled {
  display: none !important;
}

.pswp--minimal--dark .pswp__top-bar {
  background: none;
}

.pswp {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  outline: none;

  * {
    box-sizing: border-box;
  }

  img {
    max-width: none;
  }
}

.pswp--animate_opacity {
  opacity: 0.001;
  will-change: opacity;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
}

.pswp--open {
  display: block;
}

.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--zoomed-in .pswp__img {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--dragging .pswp__img {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.pswp__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transform: translateZ(0);
  backface-visibility: hidden;
  will-change: opacity;
}

.pswp__scroll-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pswp__container,
.pswp__zoom-wrap {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.pswp__img--placeholder {
  display: none !important;
}

.pswp__container,
.pswp__img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
  position: absolute;
  width: 100%;
  transform-origin: left top;
  transition: transform 333ms cubic-bezier(0.4, 0, 0.22, 1);
}

.pswp__bg {
  will-change: opacity;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
}

.pswp--animated-in {
  .pswp__bg,
  .pswp__zoom-wrap {
    transition: none;
  }
}

.pswp.carousel {
  .pswp__bg,
  .pswp__zoom-wrap {
    transition: none !important;
  }
}

.pswp__container,
.pswp__zoom-wrap {
  backface-visibility: hidden;
}

.pswp__item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.pswp__img {
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
}

.pswp__img--placeholder {
  backface-visibility: hidden;
}

.pswp__img--placeholder--blank {
  background: #222;
}

.pswp--ie .pswp__img {
  width: 100% !important;
  height: auto !important;
  left: 0;
  top: 0;
}

.pswp__error-msg {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  margin-top: -8px;
  color: #ccc;

  a {
    color: #ccc;
    text-decoration: underline;
  }
}

.pp_pic_holder,
.pp_overlay {
  display: none !important;
}

/* Vc corrections
  ---------------*/

.enovathemes .vc_row {
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative;
  width: 100%;

  .container {
    z-index: 5;
  }
}

.vc_row {
  &.vc_row-flex > .container {
    box-sizing: border-box;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    > .vc_vc_column {
      box-sizing: border-box;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  &.vc_row-o-columns-stretch > .container {
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;

    > .vc_vc_column {
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
    }
  }

  &.vc_row-o-columns-top > .container {
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;

    > .vc_vc_column {
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
    }
  }

  &.vc_row-o-columns-bottom > .container {
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;

    > .vc_vc_column {
      -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
      align-content: flex-end;
    }
  }

  &.vc_row-o-columns-middle > .container {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;

    > .vc_vc_column {
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
    }
  }

  &.vc_row-flex > .container > {
    .vc_column_container > .vc_column-inner,
    .vc_vc_column > .vc_column_container > .vc_column-inner {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      z-index: 1;
    }
  }

  &.vc_row-o-equal-height > .container > {
    .vc_column_container,
    .vc_vc_column > .vc_column_container {
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
  }

  &.vc_row-flex > .container > {
    .vc_column_container,
    .vc_vc_column > .vc_column_container,
    .vc_vc_column_inner > .vc_column_container {
      display: flex;
    }
  }

  &.vc_row-o-content-top {
    > .container > {
      .vc_column_container > .vc_column-inner,
      .vc_vc_column > .vc_column_container > .vc_column-inner,
      .vc_vc_column_inner > .vc_column_container > .vc_column-inner {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
      }
    }

    &:not(.vc_row-o-equal-height) > .container > {
      .vc_column_container,
      .vc_vc_column > .vc_column_container,
      .vc_vc_column_inner > .vc_column_container {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }
  }

  &.vc_row-o-content-bottom {
    > .container > {
      .vc_column_container > .vc_column-inner,
      .vc_vc_column > .vc_column_container > .vc_column-inner,
      .vc_vc_column_inner > .vc_column_container > .vc_column-inner {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
      }
    }

    &:not(.vc_row-o-equal-height) > .container > {
      .vc_column_container,
      .vc_vc_column > .vc_column_container,
      .vc_vc_column_inner > .vc_column_container {
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
      }
    }
  }

  &.vc_row-o-content-middle {
    > .container > {
      .vc_column_container > .vc_column-inner,
      .vc_vc_column > .vc_column_container > .vc_column-inner,
      .vc_vc_column_inner > .vc_column_container > .vc_column-inner {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }

    &:not(.vc_row-o-equal-height) > .container > {
      .vc_column_container,
      .vc_vc_column > .vc_column_container,
      .vc_vc_column_inner > .vc_column_container {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
      }
    }
  }
}

.vc_col-has-fill > .vc_column-inner {
  padding-top: 0;
}

.vc_row-has-fill {
  + {
    .vc_row-full-width
      + .vc_row
      > .container
      > .vc_column_container
      > .vc_column-inner,
    .vc_row > .container > .vc_column_container > .vc_column-inner {
      padding-top: 0;
    }
  }

  > .container > .vc_column_container > .vc_column-inner {
    padding-top: 0;
  }
}

.vc_section.vc_section-has-fill {
  padding-top: 0;

  + {
    .vc_row-full-width + .vc_section,
    .vc_section {
      padding-top: 0;
    }
  }
}

.vc_row-has-fill {
  + {
    .vc_vc_row
      > .vc_row
      > .container
      > .vc_vc_column
      > .vc_column_container
      > .vc_column-inner,
    .vc_vc_row_inner
      > .vc_row
      > .container
      > .vc_vc_column_inner
      > .vc_column_container
      > .vc_column-inner {
      padding-top: 0;
    }
  }

  > {
    .vc_row
      > .container
      > .vc_vc_column
      > .vc_column_container
      > .vc_column-inner,
    .vc_vc_column_inner > .vc_column_container > .vc_column-inner {
      padding-top: 0;
    }
  }
}

.vc_general.vc_cta3.vc_cta3-icons-on-border.vc_cta3-icons-top.vc_cta3-icon-size-xl.vc_cta3-icons-in-box
  .vc_cta3_content-container {
  padding-top: 0;
}

.compose-mode .vc_element .vc_element-container,
.view-mode .vc_element .vc_element-container {
  min-height: 28px !important;
}

.compose-mode .vc_controls > div {
  line-height: 28px !important;
}

.vc_column_container {
  z-index: 2;
}

.compose-mode {
  .vc_control-btn-append {
    line-height: 1px !important;
  }

  .vc_element {
    &.vc_hold-hover > .wpb_row > .container > .vc_element:before,
    &.vc_hover > .wpb_row > .container > .vc_element:before,
    &:hover > .wpb_row > .container > .vc_element:before {
      content: '';
      margin: 0;
      padding: 0;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: 1px dashed #ebebeb;
      outline: 1px dashed rgba(125, 125, 125, 0.4);
    }
  }
}

.view-mode .vc_element {
  &.vc_hold-hover > .wpb_row > .container > .vc_element:before,
  &.vc_hover > .wpb_row > .container > .vc_element:before,
  &:hover
    > .wpb_row
    > .container
    > .vc_element
    > .vc_column_container
    > :before {
    content: '';
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 1px dashed #ebebeb;
    outline: 1px dashed rgba(125, 125, 125, 0.4);
  }
}

.compose-mode .vc_element {
  &.vc_et_vertical_align_top:hover:before,
  &.vc_et_vertical_align_middle:hover:before,
  &.vc_et_vertical_align_bottom:hover:before,
  &.vc_et_align_container:hover:before,
  &.vc_et_header_sidebar_container:hover:before,
  &.vc_et_header_mobile_container:hover:before,
  &.vc_et_header_modal_container:hover:before {
    content: '';
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 1px dashed #ebebeb;
    outline: 1px dashed rgba(125, 125, 125, 0.4);
  }

  &.vc_hold-hover > .wpb_row > .container > .vc_element:after,
  &.vc_hover > .wpb_row > .container > .vc_element:after,
  &:hover > .wpb_row > .container > .vc_element:after {
    clear: both;
  }
}

.view-mode .vc_element {
  &.vc_hold-hover > .wpb_row > .container > .vc_element:after,
  &.vc_hover > .wpb_row > .container > .vc_element:after,
  &:hover > .wpb_row > .container > .vc_element:after {
    clear: both;
  }

  &.vc_hold-hover > .wpb_row > .container > .vc_element:before,
  &.vc_hover > .wpb_row > .container > .vc_element:before,
  &:hover > .wpb_row > .container > .vc_element:before {
    outline: 0;
  }
}

.compose-mode.vc_sorting .wpb_row > .container > .vc_element {
  &:before {
    content: '';
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 1px dashed #ebebeb;
    outline: 1px dashed rgba(125, 125, 125, 0.4);
  }

  &:after {
    clear: both;
  }
}

.vc_empty-placeholder {
  margin-bottom: 0 !important;
  margin-top: 30px !important;
  border-bottom: 1px dashed rgba(125, 125, 125, 0.4);
}

.vc_empty-element > .vc_column-inner,
.compose-mode .vc_element.vc_empty .vc_empty-element,
.view-mode .vc_element.vc_empty .vc_empty-element {
  min-height: 28px !important;
}

.wpb_column {
  &.text-align-left {
    text-align: left;
  }

  &.text-align-right {
    text-align: right;
  }

  &.text-align-center {
    text-align: center;
  }
}

.vc_row {
  &.vc-parallax,
  &.vc-video-bg,
  &.vc-fixed-bg {
    z-index: 1;
    overflow: hidden;
    position: relative;
  }
}

.wpb_column {
  &.vc-parallax,
  &.vc-fixed-bg,
  &.vc-video-bg {
    z-index: 1;
    overflow: hidden;
    position: relative;
  }
}

.compose-mode {
  .vc-parallax .vc_controls > .vc_controls-out-tl,
  .vc-video-bg .vc_controls > .vc_controls-out-tl,
  .vc-fixed-bg .vc_controls > .vc_controls-out-tl {
    top: 1px !important;
  }

  .vc-parallax .vc_control-btn-append,
  .vc-video-bg .vc_control-btn-append,
  .vc-fixed-bg .vc_control-btn-append {
    top: 0 !important;
  }
}

.vc-parallax .parallax-container {
  top: 0 !important;
  left: 0 !important;
  min-height: 100% !important;
  width: 100% !important;
  height: 140% !important;
  z-index: -1000;
  overflow: hidden;
  max-width: none;
  position: absolute;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;
  background-position: center top;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.wpb_column {
  &.vc_col-lg-4.vc-parallax .parallax-container,
  &.vc_col-lg-3.vc-parallax .parallax-container,
  &.vc_col-lg-2.vc-parallax .parallax-container {
    height: 150% !important;
  }
}

.vc-fixed-bg .fixed-container {
  top: -70px;
  left: 0;
  width: 100%;
  z-index: -1;
  max-width: none;
  position: absolute;
  background-attachment: fixed;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
}

.vc-video-bg {
  .video-container,
  .video-container-overlay,
  .video-container-placeholder {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0px;
    width: auto;
    min-width: 100%;
    height: calc(100vh * 0.8);
    z-index: -1000;
  }

  &.vc-parallax .video-container {
    -ms-transform: translateY(0);
    transform: translateY(0);
    top: -5px;
    height: calc(100vh * 0.8) !important;
    width: auto !important;
  }

  .video-container-placeholder {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
    display: none;
  }
}

.vc-video-parallax .video-container {
  min-height: 150% !important;
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.vc-animated-bg {
  .animated-container {
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    position: absolute;
    background-position: 0 0;
    background-repeat: repeat !important;
    -webkit-background-size: auto !important;
    -moz-background-size: auto !important;
    background-size: auto !important;
  }

  .wpb_column {
    z-index: 1;
  }
}

.vc_row {
  .gradient {
    display: block;
    width: 100%;
    height: 25%;
    position: absolute;
    left: 0;
    z-index: 1;
  }

  .top-gradient {
    top: 0;
  }

  .bottom-gradient {
    bottom: 0;
  }

  .grid-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    background-repeat: repeat;
    background-position: left top;
  }
}

.wpb_text_column {
  position: relative;
}

.enovathemes {
  .vc_column_container > .vc_column-inner {
    padding-left: 0;
    padding-right: 0;
  }

  .wpb_button,
  .wpb_content_element {
    margin-bottom: 0;
  }

  ul.wpb_thumbnails-fluid > li {
    margin-bottom: 32px;
  }
}

.compose-mode {
  .vc_vc_section > .vc_controls > .vc_controls-out-tl {
    left: -1px !important;
  }

  .vc_et_testimonial_container .vc_controls > .vc_controls-out-tl,
  .vc_et_client_container .vc_controls > .vc_controls-out-tl,
  .vc_et_person_container .vc_controls > .vc_controls-out-tl,
  .vc_et_carousel .vc_controls > .vc_controls-out-tl,
  .vc_et_accordion .vc_controls > .vc_controls-out-tl,
  .vc_et_tab .vc_controls > .vc_controls-out-tl {
    display: none;
  }

  .vc_et_banner .vc_controls > .vc_controls-out-tl,
  .vc_et_ad .vc_controls > .vc_controls-out-tl,
  .vc_et_header_sidebar_container .vc_controls > .vc_controls-out-tl,
  .vc_et_header_mobile_container .vc_controls > .vc_controls-out-tl,
  .vc_et_header_modal_container .vc_controls > .vc_controls-out-tl,
  .vc_et_align_container .vc_controls > .vc_controls-out-tl,
  .vc_et_vertical_align_top .vc_controls > .vc_controls-out-tl,
  .vc_et_vertical_align_middle .vc_controls > .vc_controls-out-tl,
  .vc_et_vertical_align_bottom .vc_controls > .vc_controls-out-tl {
    left: auto !important;
    right: -1px !important;
  }
}

.vc_element {
  &[data-tag='et_icon_box_container'] .vc_controls-out-tl,
  &[data-tag='et_step_box_container'] .vc_controls-out-tl,
  &[data-tag='et_make_container'] .vc_controls-out-tl,
  &[data-tag='et_carousel'] .vc_controls-out-tl,
  &[data-tag='et_testimonial_container'] .vc_controls-out-tl,
  &[data-tag='et_megamenu_tab_item'] .vc_controls-out-tl,
  &[data-tag='et_animate_box'] .vc_controls-out-tl {
    left: auto !important;
    right: -1px !important;
  }
}

.vc_et_button,
.vc_et_icon,
.vc_et_gap_inline {
  display: inline-block !important;
}

*[data-animation-delay='50'],
[data-animation-delay='50'] .curtain {
  animation-delay: 50ms !important;
}

*[data-animation-delay='100'],
[data-animation-delay='100'] .curtain {
  animation-delay: 100ms !important;
}

*[data-animation-delay='150'],
[data-animation-delay='150'] .curtain {
  animation-delay: 150ms !important;
}

*[data-animation-delay='200'],
[data-animation-delay='200'] .curtain {
  animation-delay: 200ms !important;
}

*[data-animation-delay='250'],
[data-animation-delay='250'] .curtain {
  animation-delay: 250ms !important;
}

*[data-animation-delay='300'],
[data-animation-delay='300'] .curtain {
  animation-delay: 300ms !important;
}

*[data-animation-delay='350'],
[data-animation-delay='350'] .curtain {
  animation-delay: 350ms !important;
}

*[data-animation-delay='400'],
[data-animation-delay='400'] .curtain {
  animation-delay: 400ms !important;
}

*[data-animation-delay='450'],
[data-animation-delay='450'] .curtain {
  animation-delay: 450ms !important;
}

*[data-animation-delay='500'],
[data-animation-delay='500'] .curtain {
  animation-delay: 500ms !important;
}

*[data-animation-delay='550'],
[data-animation-delay='550'] .curtain {
  animation-delay: 550ms !important;
}

*[data-animation-delay='600'],
[data-animation-delay='600'] .curtain {
  animation-delay: 600ms !important;
}

*[data-animation-delay='650'],
[data-animation-delay='650'] .curtain {
  animation-delay: 650ms !important;
}

*[data-animation-delay='700'],
[data-animation-delay='700'] .curtain {
  animation-delay: 700ms !important;
}

*[data-animation-delay='750'],
[data-animation-delay='750'] .curtain {
  animation-delay: 750ms !important;
}

*[data-animation-delay='800'],
[data-animation-delay='800'] .curtain {
  animation-delay: 800ms !important;
}

*[data-animation-delay='850'],
[data-animation-delay='850'] .curtain {
  animation-delay: 850ms !important;
}

*[data-animation-delay='900'],
[data-animation-delay='900'] .curtain {
  animation-delay: 900ms !important;
}

*[data-animation-delay='950'],
[data-animation-delay='950'] .curtain {
  animation-delay: 950ms !important;
}

*[data-animation-delay='1000'],
[data-animation-delay='100'] .curtain {
  animation-delay: 1000ms !important;
}

*[data-animation-delay='1050'],
[data-animation-delay='1050'] .curtain {
  animation-delay: 1050ms !important;
}

*[data-animation-delay='1100'],
[data-animation-delay='1100'] .curtain {
  animation-delay: 1100ms !important;
}

*[data-animation-delay='1150'],
[data-animation-delay='1150'] .curtain {
  animation-delay: 1150ms !important;
}

*[data-animation-delay='1200'],
[data-animation-delay='1200'] .curtain {
  animation-delay: 1200ms !important;
}

*[data-animation-delay='1250'],
[data-animation-delay='1250'] .curtain {
  animation-delay: 1250ms !important;
}

*[data-animation-delay='1300'],
[data-animation-delay='1300'] .curtain {
  animation-delay: 1300ms !important;
}

*[data-animation-delay='1350'],
[data-animation-delay='1350'] .curtain {
  animation-delay: 1350ms !important;
}

*[data-animation-delay='1400'],
[data-animation-delay='1400'] .curtain {
  animation-delay: 1400ms !important;
}

*[data-animation-delay='1450'],
[data-animation-delay='1450'] .curtain {
  animation-delay: 1450ms !important;
}

*[data-animation-delay='1500'],
[data-animation-delay='1500'] .curtain {
  animation-delay: 1500ms !important;
}

*[data-animation-delay='1550'],
[data-animation-delay='1550'] .curtain {
  animation-delay: 1550ms !important;
}

*[data-animation-delay='1600'],
[data-animation-delay='1600'] .curtain {
  animation-delay: 1600ms !important;
}

*[data-animation-delay='1650'],
[data-animation-delay='1650'] .curtain {
  animation-delay: 1650ms !important;
}

*[data-animation-delay='1700'],
[data-animation-delay='1700'] .curtain {
  animation-delay: 1700ms !important;
}

*[data-animation-delay='1700'],
[data-animation-delay='1750'] .curtain {
  animation-delay: 1750ms !important;
}

*[data-animation-delay='1750'],
[data-animation-delay='1800'] .curtain {
  animation-delay: 1800ms !important;
}

*[data-animation-delay='1850'],
[data-animation-delay='1850'] .curtain {
  animation-delay: 1850ms !important;
}

*[data-animation-delay='1900'],
[data-animation-delay='1900'] .curtain {
  animation-delay: 1900ms !important;
}

*[data-animation-delay='1950'],
[data-animation-delay='1950'] .curtain {
  animation-delay: 1950ms !important;
}

*[data-animation-delay='2000'],
[data-animation-delay='2000'] .curtain {
  animation-delay: 2000ms !important;
}

.bottom-to-top,
.wpb_bottom-to-top,
.top-to-bottom,
.wpb_top-to-bottom,
.left-to-right,
.wpb_left-to-right,
.right-to-left,
.wpb_right-to-left,
.wpb_appear {
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1) !important;
  backface-visibility: hidden;
}

.vc_column_container.shadow > .vc_column-inner {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
}

.compose-mode #vc_templates-more-layouts {
  display: none !important;
}

/* Header
  ---------------*/

.header .wpb_column {
  position: static;
}

.et-desktop .megamenu .wpb_column {
  position: relative;
}

.header {
  display: block;
  width: 100%;
  z-index: 80;
  position: relative;

  &.shadow-true,
  &.sticky-true.shadow-sticky-true.active {
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  }

  &.transparent-true {
    position: absolute;
    top: 0;
    left: 0;
  }

  .vc_column_container,
  .vc_column-inner,
  .wpb_wrapper,
  .hbe {
    height: inherit;
    line-height: inherit;
  }
}

.et-desktop .megamenu {
  .vc_column_container,
  .vc_column-inner,
  .wpb_wrapper {
    height: auto;
    line-height: initial;
  }
}

.header {
  > .vc_row:not(.vc_inner) > {
    .container > .vc_column_container,
    .vc_column_container {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      > .vc_column-inner {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }

  .vc_column_container > .vc_column-inner > .wpb_wrapper:after {
    clear: both;
    content: '';
    display: table;
  }

  .hbe {
    position: relative;
    display: inline-block;
    vertical-align: top;
  }
}

.et-menu-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.header {
  .hbe.hide-default-true {
    display: none;
  }

  &.sticky-true.active .hbe {
    &.hide-sticky-true {
      display: none;
    }

    &.hide-default-true {
      display: inline-block;
    }
  }
}

.header-icon:after {
  display: none !important;
}

.header .hbe-right,
.menu-align-right {
  display: block;
  float: right;
}

.header .hbe-left,
.menu-align-left {
  display: block;
  float: left;
}

.menu-item .arrow-down {
  font-size: 8px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  margin-left: 4px;
  top: -1px;
  display: none;
  z-index: 2;
  transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: center;
}

.mobile-menu .menu-item .arrow-down {
  font-size: 14px;
}

.tl-submenu-ind-true {
  &.vertical .et-menu > .menu-item .arrow-down {
    font-size: 12px;
    transform: rotate(0);
    -ms-transform: rotate(0);
    margin-left: 0;
    top: 50%;
    right: 16px;
    position: absolute;
    margin-top: -6px;
  }

  .header-menu > .menu-item > a:not(:only-child) > .arrow-down {
    display: inline-block;
  }
}

.sl-submenu-ind-true
  .header-menu
  ul
  > .menu-item
  > a:not(:only-child)
  > .arrow-down,
.tl-submenu-ind-true
  .sidebar-menu
  > .menu-item
  > a:not(:only-child)
  > .arrow-down,
.sl-submenu-ind-true
  .sidebar-menu
  ul
  > .menu-item
  > a:not(:only-child)
  > .arrow-down,
.tl-submenu-ind-true .et-menu > .menu-item > a:not(:only-child) > .arrow-down,
.sl-submenu-ind-true
  .et-menu
  ul
  > .menu-item
  > a:not(:only-child)
  > .arrow-down {
  display: inline-block;
}

/* et_align_container
    ---------------*/

.align-container {
  > *:not(.et-gap):not(.et-separator) {
    display: inline-block !important;
    float: none !important;
  }

  &.align-left {
    text-align: left;
  }

  &.align-right {
    text-align: right;
  }

  &.align-center {
    text-align: center;
    float: none;
  }
}

/* sticky_header
    ---------------*/

.header.sticky-true {
  &.active {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-animation: stickyHeader 0.3s ease-out;
    animation: stickyHeader 0.3s ease-out;

    &.disable-sticky {
      -webkit-animation: none !important;
      animation: none !important;
    }

    &.transparent-false + .header-placeholder {
      display: block;
    }

    .vc_row.hide-sticky {
      display: none;
    }
  }

  + .header-placeholder {
    width: 100%;
    display: none;
  }

  .vc_row.hide-default {
    display: none;
  }

  &.active .vc_row.hide-default {
    display: block;
  }
}

@-webkit-keyframes stickyHeader {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes stickyHeader {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/* et_header_logo
    ---------------*/

.header-logo {
  a {
    position: relative;
    display: inline-block;
    vertical-align: top;
  }

  img {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
}

.header {
  &.sticky-true.active .header-logo .logo,
  &.sticky-false .header-logo .sticky-logo {
    display: none;
  }

  &.sticky-true {
    .header-logo .sticky-logo {
      display: none;
    }

    &.active .header-logo .sticky-logo {
      display: inline-block;
    }
  }
}

/* et_header_menu
    ---------------*/

.header-menu-container .header-menu,
.sidebar-menu-container .sidebar-menu,
.header-menu-container .sub-menu,
.sidebar-menu-container .sub-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: inherit;
}

.et-menu-container {
  .et-menu,
  .sub-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: inherit;
  }
}

.et-header-button .sub-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: inherit;
}

.et-menu-container.vertical {
  display: block;
}

.header-menu-container .menu-item {
  padding: 0;
  position: relative;
  float: left;
  line-height: inherit;
}

.et-menu-container {
  .menu-item {
    padding: 0;
    position: relative;
    float: left;
    line-height: inherit;
  }

  &.vertical .menu-item {
    display: block;
    float: none;
  }
}

.header-menu-container > .header-menu > .menu-item:first-child {
  margin-left: 0 !important;
}

.et-menu-container {
  > .et-menu > .menu-item:first-child {
    margin-left: 0 !important;
  }

  &.vertical > .et-menu > .menu-item:last-child {
    margin-bottom: 0 !important;
  }
}

.header-menu-container > .header-menu > .menu-item > .mi-link,
.sidebar-menu-container > .sidebar-menu > .menu-item > .mi-link {
  transition: color 300ms ease-out, background-color 300ms ease-out;
  line-height: inherit;
  display: block;
  height: 100%;
  position: relative;
  border-radius: 4px;
}

.et-menu-container {
  > .et-menu > .menu-item > .mi-link {
    transition: color 300ms ease-out, background-color 300ms ease-out;
    line-height: inherit;
    display: block;
    height: 100%;
    position: relative;
    border-radius: 4px;
  }

  &.vertical > .et-menu > .menu-item > .mi-link {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.header-menu .menu-item > .mi-link > span,
.sidebar-menu .menu-item > .mi-link > span,
.et-menu .menu-item > .mi-link > span,
.et-header-button .menu-item > .mi-link > span {
  position: relative;
  z-index: 2;
}

.top-separator-true .header-menu .menu-item.depth-0 {
  &:before {
    display: block;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    content: '';
    width: 1px;
    height: 100%;
  }

  &:after {
    display: block;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    content: '';
    width: 1px;
    height: 100%;
    left: auto;
    right: 0;
  }

  &:not(:last-child):after {
    display: none;
  }
}

.header-menu .hi + .txt,
.sidebar-menu .hi + .txt,
.et-menu .hi + .txt,
.et-header-button .hi + .txt {
  margin-left: 4px;
  display: inline-block;
  bottom: -1px;
}

.header-menu .sub-menu .hi,
.sidebar-menu .sub-menu .hi {
  margin-left: 8px;
}

.et-mm > .menu-item > .mi-link .hi,
.et-menu .sub-menu .hi {
  margin-left: 0;
  margin-right: 8px;
}

.header-menu .hi,
.sidebar-menu .hi,
.et-mm > .menu-item > .mi-link .hi,
.et-menu .hi,
.et-header-button .hi {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.header-menu > .menu-item > .mi-link > .label,
.et-menu > .menu-item > .mi-link > .label {
  position: absolute;
  top: 8px;
  left: 50%;
  line-height: 12px;
  font-size: 12px;
  padding: 4px 8px;
  text-transform: none;
  color: #ffffff;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.header-menu > .menu-item > .mi-link > .description,
.et-menu > .menu-item > .mi-link > .description {
  display: none !important;
}

.menu-item > .mi-link > .description {
  display: block;
  font-size: 80%;
  line-height: inherit;
}

.header-menu-container.menu-hover-fill
  .header-menu
  > .menu-item
  > .mi-link
  > .label,
.et-menu-container.menu-hover-fill .et-menu > .menu-item > .mi-link > .label {
  bottom: auto;
  top: 5%;
}

.header-menu .sub-menu > .menu-item > .mi-link > .label,
.et-header-button .sub-menu > .menu-item > .mi-link > .label,
.sidebar-menu .menu-item > .mi-link > .label,
.et-mm > .menu-item > .mi-link > .label,
.et-menu .sub-menu > .menu-item > .mi-link > .label {
  margin-left: 7px;
  top: -1px;
  vertical-align: middle;
  display: inline-block;
  padding: 4px 8px 4px 8px;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  position: relative;
  border-radius: 0;
  left: 0px !important;
  -ms-transform: none !important;
  transform: none !important;
  text-transform: none;
}

.header-menu-container {
  &.menu-hover-outline > .header-menu > .menu-item > .mi-link,
  &.menu-hover-box > .header-menu > .menu-item > .mi-link,
  &.menu-hover-fill > .header-menu > .menu-item > .mi-link {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.et-menu-container {
  &.menu-hover-outline > .et-menu > .menu-item > .mi-link,
  &.menu-hover-box > .et-menu > .menu-item > .mi-link,
  &.menu-hover-fill > .et-menu > .menu-item > .mi-link {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.header-menu-container {
  &.menu-hover-outline > .header-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-box > .header-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-fill > .header-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-outline
    > .header-menu
    > .menu-item.current-menu-item
    > .mi-link:after,
  &.menu-hover-box
    > .header-menu
    > .menu-item.current-menu-item
    > .mi-link:after,
  &.menu-hover-fill
    > .header-menu
    > .menu-item.current-menu-item
    > .mi-link:after,
  &.menu-hover-outline
    > .header-menu
    > .menu-item.current-menu-parent
    > .mi-link:after,
  &.menu-hover-box
    > .header-menu
    > .menu-item.current-menu-parent
    > .mi-link:after,
  &.menu-hover-fill
    > .header-menu
    > .menu-item.current-menu-parent
    > .mi-link:after,
  &.menu-hover-outline
    > .header-menu
    > .menu-item.current-menu-ancestor
    > .mi-link:after,
  &.menu-hover-box
    > .header-menu
    > .menu-item.current-menu-ancestor
    > .mi-link:after,
  &.menu-hover-fill
    > .header-menu
    > .menu-item.current-menu-ancestor
    > .mi-link:after,
  &.menu-hover-outline
    > .header-menu
    > .menu-item.one-page-active
    > .mi-link:after,
  &.menu-hover-box > .header-menu > .menu-item.one-page-active > .mi-link:after,
  &.menu-hover-fill
    > .header-menu
    > .menu-item.one-page-active
    > .mi-link:after {
    opacity: 1;
  }
}

.sidebar-menu-container {
  &.menu-hover-outline > .sidebar-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-box > .sidebar-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-fill > .sidebar-menu > .menu-item:hover > .mi-link:after {
    opacity: 1;
  }
}

.et-menu-container {
  &.menu-hover-outline > .et-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-box > .et-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-fill > .et-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-outline
    > .et-menu
    > .menu-item.current-menu-item
    > .mi-link:after,
  &.menu-hover-box > .et-menu > .menu-item.current-menu-item > .mi-link:after,
  &.menu-hover-fill > .et-menu > .menu-item.current-menu-item > .mi-link:after,
  &.menu-hover-outline
    > .et-menu
    > .menu-item.current-menu-parent
    > .mi-link:after,
  &.menu-hover-box > .et-menu > .menu-item.current-menu-parent > .mi-link:after,
  &.menu-hover-fill
    > .et-menu
    > .menu-item.current-menu-parent
    > .mi-link:after,
  &.menu-hover-outline
    > .et-menu
    > .menu-item.current-menu-ancestor
    > .mi-link:after,
  &.menu-hover-box
    > .et-menu
    > .menu-item.current-menu-ancestor
    > .mi-link:after,
  &.menu-hover-fill
    > .et-menu
    > .menu-item.current-menu-ancestor
    > .mi-link:after,
  &.menu-hover-outline > .et-menu > .menu-item.one-page-active > .mi-link:after,
  &.menu-hover-box > .et-menu > .menu-item.one-page-active > .mi-link:after,
  &.menu-hover-fill > .et-menu > .menu-item.one-page-active > .mi-link:after {
    opacity: 1;
  }
}

.header-menu-container {
  &.menu-hover-underline
    > .header-menu
    > .menu-item:hover
    > .mi-link
    .txt:after,
  &.menu-hover-overline > .header-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-underline
    > .header-menu
    > .menu-item.current-menu-item
    > .mi-link
    .txt:after,
  &.menu-hover-overline
    > .header-menu
    > .menu-item.current-menu-item
    > .mi-link:after,
  &.menu-hover-underline
    > .header-menu
    > .menu-item.current-menu-parent
    > .mi-link
    .txt:after,
  &.menu-hover-overline
    > .header-menu
    > .menu-item.current-menu-parent
    > .mi-link:after,
  &.menu-hover-underline
    > .header-menu
    > .menu-item.current-menu-ancestor
    > .mi-link
    .txt:after,
  &.menu-hover-overline
    > .header-menu
    > .menu-item.current-menu-ancestor
    > .mi-link:after,
  &.menu-hover-underline
    > .header-menu
    > .menu-item.one-page-active
    > .mi-link
    .txt:after,
  &.menu-hover-overline
    > .header-menu
    > .menu-item.one-page-active
    > .mi-link:after {
    transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.et-menu-container {
  &.menu-hover-underline > .et-menu > .menu-item:hover > .mi-link .txt:after,
  &.menu-hover-overline > .et-menu > .menu-item:hover > .mi-link:after,
  &.menu-hover-underline
    > .et-menu
    > .menu-item.current-menu-item
    > .mi-link
    .txt:after,
  &.menu-hover-overline
    > .et-menu
    > .menu-item.current-menu-item
    > .mi-link:after,
  &.menu-hover-underline
    > .et-menu
    > .menu-item.current-menu-parent
    > .mi-link
    .txt:after,
  &.menu-hover-overline
    > .et-menu
    > .menu-item.current-menu-parent
    > .mi-link:after,
  &.menu-hover-underline
    > .et-menu
    > .menu-item.current-menu-ancestor
    > .mi-link
    .txt:after,
  &.menu-hover-overline
    > .et-menu
    > .menu-item.current-menu-ancestor
    > .mi-link:after,
  &.menu-hover-underline
    > .et-menu
    > .menu-item.one-page-active
    > .mi-link
    .txt:after,
  &.menu-hover-overline
    > .et-menu
    > .menu-item.one-page-active
    > .mi-link:after {
    transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.header-menu-container > .header-menu > .menu-item > .mi-link {
  &:after,
  .txt:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    height: 40px;
    width: 100%;
    margin-top: -20px;
    transition: border-color 300ms ease-out, background-color 300ms ease-out,
      opacity 300ms ease-out, transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    z-index: 1;
    border-radius: inherit;
  }
}

.sidebar-menu-container > .sidebar-menu > .menu-item > .mi-link:after {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  content: '';
  height: 40px;
  width: 100%;
  margin-top: -20px;
  transition: border-color 300ms ease-out, background-color 300ms ease-out,
    opacity 300ms ease-out, transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  z-index: 1;
  border-radius: inherit;
}

.et-menu-container > .et-menu > .menu-item > .mi-link {
  &:after,
  .txt:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    height: 40px;
    width: 100%;
    margin-top: -20px;
    transition: border-color 300ms ease-out, background-color 300ms ease-out,
      opacity 300ms ease-out, transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    z-index: 1;
    border-radius: inherit;
  }
}

.header-menu-container > .header-menu > .menu-item:hover > .mi-link:after,
.sidebar-menu-container > .sidebar-menu > .menu-item:hover > .mi-link:after,
.et-menu-container > .et-menu > .menu-item:hover > .mi-link:after {
  transition: border-color 300ms ease-out, background-color 300ms ease-out,
    opacity 300ms ease-out, transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

.header-menu-container.menu-hover-fill
  > .header-menu
  > .menu-item
  > .mi-link:after {
  height: calc(100% + 1px);
  margin-top: 0;
  top: 0;
  border-radius: 0;
}

.sidebar-menu-container {
  &.menu-hover-fill > .sidebar-menu > .menu-item > .mi-link:after,
  &.menu-hover-outline > .sidebar-menu > .menu-item > .mi-link:after,
  &.menu-hover-box > .sidebar-menu > .menu-item > .mi-link:after {
    height: calc(100% + 1px);
    margin-top: 0;
    top: 0;
    border-radius: 0;
  }
}

.et-menu-container.menu-hover-fill > .et-menu > .menu-item > .mi-link:after {
  height: calc(100% + 1px);
  margin-top: 0;
  top: 0;
  border-radius: 0;
}

.sidebar-menu-container {
  &.menu-hover-outline > .sidebar-menu > .menu-item > .mi-link:after,
  &.menu-hover-box > .sidebar-menu > .menu-item > .mi-link:after {
    width: calc(100% - 32px);
    left: 16px;
  }
}

.tl-separator-true.sidebar-menu-container {
  &.menu-hover-outline > .sidebar-menu > .menu-item > .mi-link:after,
  &.menu-hover-box > .sidebar-menu > .menu-item > .mi-link:after {
    width: calc(100% - 80px);
    left: 40px;
  }
}

.header-menu-container.menu-hover-underline
  > .header-menu
  > .menu-item
  > .mi-link
  .txt:after,
.et-menu-container.menu-hover-underline
  > .et-menu
  > .menu-item
  > .mi-link
  .txt:after {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  opacity: 1 !important;
  top: 2px;
  width: 100%;
  height: 100%;
  margin-top: 0;
  z-index: -1;
  backface-visibility: hidden;
  transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform-origin: bottom right;
  -ms-transform-origin: bottom right;
}

.header-menu-container.menu-hover-overline
  > .header-menu
  > .menu-item
  > .mi-link:after,
.et-menu-container.menu-hover-overline
  > .et-menu
  > .menu-item
  > .mi-link:after {
  border-top-width: 4px;
  border-top-style: solid;
  border-radius: 0 !important;
  top: 0;
  margin-top: 0;
  opacity: 1 !important;
  height: 100%;
  transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform-origin: bottom right;
  -ms-transform-origin: bottom right;
}

.menu-item-language img {
  vertical-align: middle;
  display: inline-block;
  margin-right: 8px;
}

/* submenu
      ---------------*/

.header-menu .sub-menu:not(.megamenu-single),
.sidebar-menu .sub-menu:not(.megamenu-single),
.et-menu .sub-menu:not(.megamenu-single),
.et-header-button .megamenu {
  position: absolute;
  top: calc(100% - 1px);
  left: 0px;
  min-width: 256px;
  max-width: 256px;
  padding-top: 24px;
  padding-bottom: 24px;
  list-style: none;
  margin-left: 0;
  display: none;
  text-align: left;
  border-radius: 0 0 4px 4px;
}

.et-menu-container.vertical .et-menu .sub-menu:not(.megamenu-single) {
  top: 0;
  left: 100% !important;
  border-radius: 4px !important;
}

.submenu-appear-from-top .et-menu .sub-menu:not(.megamenu-single) {
  bottom: 100% !important;
  top: auto !important;
  border-radius: 4px !important;

  .sub-menu {
    bottom: 0 !important;
    top: auto !important;
  }
}

.sidebar-menu .sub-menu:not(.megamenu-single) {
  left: 100%;
  top: -24px;
}

.sidebar-navigation-right .sidebar-menu .sub-menu {
  left: -256px;
}

.header-menu .submenu-left .sub-menu,
.et-menu .submenu-left .sub-menu {
  left: auto !important;
  right: 0 !important;
}

.header-menu .sub-menu .submenu-left .sub-menu,
.et-menu .sub-menu .submenu-left .sub-menu {
  left: -100% !important;
  right: auto !important;
}

.header-menu .sub-menu .sub-menu,
.et-menu .sub-menu .sub-menu {
  left: 256px;
  top: -24px;
}

.header-menu .submenu-left .sub-menu .sub-menu,
.et-menu .submenu-left .sub-menu .sub-menu {
  left: auto !important;
  right: 256px !important;
}

.submenu-shadow-true {
  .header-menu .sub-menu,
  .sidebar-menu .sub-menu,
  .et-menu .sub-menu,
  &.et-header-button .megamenu {
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  }
}

.submenu-appear-none {
  .header-menu .sub-menu,
  .sidebar-menu .sub-menu,
  .et-menu .sub-menu,
  &.et-header-button .megamenu {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    display: block;
  }

  .header-menu .menu-item:hover > .sub-menu,
  .sidebar-menu .menu-item:hover > .sub-menu,
  .et-menu .menu-item:hover > .sub-menu,
  // &.et-header-button:hover > .megamenu {
  &.et-header-button.active > .megamenu {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
}

.header-menu {
  > .mm-true {
    &:hover {
      > .megamenu {
        height: auto;
      }
    }
  }
}
.sidebar-menu {
  > .mm-true {
    &:hover {
      > .megamenu {
        height: auto;
      }
    }
  }
}
.et-menu {
  > .mm-true {
    &:hover {
      > .megamenu {
        height: auto;
      }
    }
  }
}
.et-header-button {
  &:hover {
    .megamenu {
      height: auto;
    }
  }
}
.submenu-appear-move {
  .header-menu {
    .menu-item {
      &:hover {
        > .sub-menu {
          transition: opacity 150ms 300ms ease-out,
            transform 600ms 300ms cubic-bezier(0.19, 1, 0.22, 1);
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          z-index: 2;
        }
      }
    }
  }
  .sidebar-menu {
    .menu-item {
      &:hover {
        > .sub-menu {
          transition: opacity 150ms 300ms ease-out,
            transform 600ms 300ms cubic-bezier(0.19, 1, 0.22, 1);
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          z-index: 2;
        }
      }
    }
  }
  .et-menu {
    .menu-item {
      &:hover {
        > .sub-menu {
          transition: opacity 150ms 300ms ease-out,
            transform 600ms 300ms cubic-bezier(0.19, 1, 0.22, 1);
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          z-index: 2;
        }
      }
    }
  }
}
.submenu-appear-move.et-header-button {
  &:hover {
    > .megamenu {
      transition: opacity 150ms 300ms ease-out,
        transform 600ms 300ms cubic-bezier(0.19, 1, 0.22, 1);
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
      z-index: 2;
    }
  }
}

.submenu-appear-move {
  .header-menu .sub-menu,
  .sidebar-menu .sub-menu,
  .et-menu .sub-menu,
  &.et-header-button .megamenu {
    transform: translateY(-24px);
    opacity: 0;
    transition: opacity 100ms ease-out,
      transform 900ms cubic-bezier(0.19, 1, 0.22, 1);
    z-index: -1;
    visibility: hidden;
    display: block;
  }

  &:hover .megamenu {
    height: auto;
  }

  .header-menu .menu-item:hover > .sub-menu,
  .sidebar-menu .menu-item:hover > .sub-menu,
  .et-menu .menu-item:hover > .sub-menu,
  // &.et-header-button:hover > .megamenu {
  &.et-header-button.active > .megamenu {
    transition: opacity 150ms 300ms ease-out,
      transform 600ms 300ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    z-index: 2;
  }
}

.submenu-appear-fade {
  .header-menu .sub-menu,
  .sidebar-menu .sub-menu,
  .et-menu .sub-menu,
  &.et-header-button .megamenu {
    opacity: 0;
    transition: opacity 100ms ease-out,
      transform 900ms cubic-bezier(0.19, 1, 0.22, 1);
    z-index: -1;
    visibility: hidden;
    display: block;
  }

  .header-menu .menu-item:hover > .sub-menu,
  .sidebar-menu .menu-item:hover > .sub-menu,
  .et-menu .menu-item:hover > .sub-menu,
  // &.et-header-button:hover > .megamenu {
  &.et-header-button.active > .megamenu {
    transition: opacity 150ms 300ms ease-out,
      transform 600ms 300ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
}

.submenu-appear-fall {
  .header-menu,
  .sidebar-menu,
  .et-menu,
  &.et-header-button {
    perspective: 1000px;
    transform: translate3d(0, 0, 0);
  }

  .header-menu .sub-menu,
  .sidebar-menu .sub-menu,
  .et-menu .sub-menu,
  &.et-header-button .megamenu {
    display: block;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    backface-visibility: hidden;
    -ms-transform: rotateX(-25deg);
    transform: rotateX(-25deg);
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-transform-origin: top center;
    transform-origin: top center;
    transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.1s,
      visibility 0.1s;
  }

  .header-menu .menu-item:hover > .sub-menu,
  .sidebar-menu .menu-item:hover > .sub-menu,
  .et-menu .menu-item:hover > .sub-menu,
  // &.et-header-button:hover > .megamenu {
  &.et-header-button.active > .megamenu {
    opacity: 1;
    z-index: 2;
    visibility: visible;
    -ms-transform: rotateX(0) translateZ(0);
    transform: rotateX(0) translateZ(0);
    transition: transform 0.6s 0.2s cubic-bezier(0.19, 1, 0.22, 1),
      opacity 0.1s 0.2s, visibility 0.1s 0.2s;
  }
}

.header-menu .sub-menu .menu-item,
.sidebar-menu .sub-menu .menu-item,
.et-menu .sub-menu .menu-item,
.megamenu-tab .tab-item {
  display: block;
  float: none;
  line-height: 24px;
}

.header-menu .sub-menu .menu-item .mi-link,
.sidebar-menu .menu-item .mi-link,
.et-menu .sub-menu .menu-item .mi-link,
.megamenu-tab .tab-item,
.et-header-button .sub-menu .menu-item .mi-link {
  display: block;
  padding: 8px 29px;
  position: relative;
  backface-visibility: hidden;
  border-radius: 4px;
}

.megamenu-tab .tab-item {
  border-radius: 0;
}

.header-menu .sub-menu .menu-item .mi-link:after,
.sidebar-menu .menu-item .mi-link:after,
.et-menu .sub-menu .menu-item .mi-link:after,
.megamenu-tab .tab-item:after,
.et-header-button .sub-menu .menu-item .mi-link:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  height: 100%;
  width: 100%;
  transition: background-color 300ms ease-out, opacity 300ms ease-out,
    width 150ms ease-out;
  opacity: 0;
  z-index: 1;
  border-radius: inherit;
}

.submenu-hover-line {
  .header-menu
    > .menu-item:not(.mm-true)
    .sub-menu
    .menu-item
    .mi-link
    .txt:after,
  &.et-header-button .sub-menu .menu-item .mi-link .txt:after,
  .sidebar-menu
    > .menu-item:not(.mm-true)
    .sub-menu
    .menu-item
    .mi-link
    .txt:after,
  &.mm-container .sub-menu .menu-item .mi-link .txt:after,
  .et-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link .txt:after {
    display: block;
    position: absolute;
    content: '';
    opacity: 1 !important;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 4px;
    margin-top: 0;
    z-index: -1;
    transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    left: 0;
    transition: border-color 300ms ease-out, background-color 300ms ease-out,
      opacity 300ms ease-out, transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.megamenu-tab.hover-line .tab-item .txt:after {
  display: block;
  position: absolute;
  content: '';
  opacity: 1 !important;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 4px;
  margin-top: 0;
  z-index: -1;
  transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  left: 0;
  transition: border-color 300ms ease-out, background-color 300ms ease-out,
    opacity 300ms ease-out, transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

.submenu-hover-dot {
  .header-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link:after,
  &.et-header-button .sub-menu .menu-item .mi-link:after,
  .sidebar-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link:after,
  &.mm-container .sub-menu .menu-item .mi-link:after,
  .et-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link:after {
    height: 4px;
    width: 4px;
    top: 50%;
    margin-top: -2px;
    left: 16px;
  }
}

.megamenu-tab.hover-dot .tab-item:after {
  height: 4px;
  width: 4px;
  top: 50%;
  margin-top: -2px;
  left: 16px;
}

.submenu-hover-outline
  .header-menu
  > .menu-item:not(.mm-true)
  .sub-menu
  .menu-item
  .mi-link:after,
.submenu-hover-box.et-header-button .sub-menu .menu-item .mi-link:after,
.submenu-hover-outline.et-header-button .sub-menu .menu-item .mi-link:after,
.submenu-hover-box
  .header-menu
  > .menu-item:not(.mm-true)
  .sub-menu
  .menu-item
  .mi-link:after,
.submenu-hover-outline
  .sidebar-menu
  > .menu-item:not(.mm-true)
  .sub-menu
  .menu-item
  .mi-link:after,
.submenu-hover-box
  .sidebar-menu
  > .menu-item:not(.mm-true)
  .sub-menu
  .menu-item
  .mi-link:after,
.submenu-hover-outline.mm-container .sub-menu .menu-item .mi-link:after,
.submenu-hover-box.mm-container .sub-menu .menu-item .mi-link:after,
.submenu-hover-outline
  .et-menu
  > .menu-item:not(.mm-true)
  .sub-menu
  .menu-item
  .mi-link:after,
.submenu-hover-box
  .et-menu
  > .menu-item:not(.mm-true)
  .sub-menu
  .menu-item
  .mi-link:after,
.megamenu-tab.hover-box .tab-item:after {
  width: calc(100% - 32px);
  left: 16px;
}

.header-menu .sub-menu .menu-item:hover > .mi-link:after,
.et-header-button .sub-menu .menu-item:hover > .mi-link:after,
.sidebar-menu .sub-menu .menu-item:hover > .mi-link:after,
.et-menu .sub-menu .menu-item:hover > .mi-link:after {
  transition: background-color 300ms ease-out, opacity 300ms ease-out,
    width 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 1;
}

.megamenu-tab .tab-item {
  &:hover:after,
  &.active:after {
    transition: background-color 300ms ease-out, opacity 300ms ease-out,
      width 600ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1;
  }
}

.submenu-hover-line {
  .header-menu
    > .menu-item:not(.mm-true)
    .sub-menu
    .menu-item:hover
    > .mi-link
    .txt:after,
  &.et-header-button .sub-menu .menu-item:hover > .mi-link .txt:after,
  .sidebar-menu
    > .menu-item:not(.mm-true)
    .sub-menu
    .menu-item:hover
    > .mi-link
    .txt:after,
  &.mm-container .sub-menu .menu-item:hover > .mi-link .txt:after,
  .et-menu
    > .menu-item:not(.mm-true)
    .sub-menu
    .menu-item:hover
    > .mi-link
    .txt:after {
    transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.megamenu-tab.hover-line .tab-item {
  &:hover .txt:after,
  &.active .txt:after {
    transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.header-menu .sub-menu .menu-item .arrow-down,
.sidebar-menu .menu-item > .mi-link > .arrow-down,
.et-menu .sub-menu .menu-item .arrow-down {
  position: absolute;
  top: 50%;
  right: 32px;
  margin-top: -4px;
}

.header-menu .sub-menu .menu-item:hover > .mi-link > .arrow-down,
.sidebar-menu .menu-item:hover > .mi-link > .arrow-down,
.et-menu .sub-menu .menu-item:hover > .mi-link > .arrow-down {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}

/* separator-true
        ---------------*/

.separator-true {
  .header-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link:before,
  .sidebar-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link:before,
  &.mm-container .sub-menu .menu-item .mi-link:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 16px;
    content: '';
    height: 1px;
    width: calc(100% - 32px);
    opacity: 0.15;
  }
}

.et-mm > .menu-item > .mi-link:before,
.tl-separator-true .sidebar-menu > .menu-item > .mi-link:before,
.separator-true
  .et-menu
  > .menu-item:not(.mm-true)
  .sub-menu
  .menu-item
  .mi-link:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 16px;
  content: '';
  height: 1px;
  width: calc(100% - 32px);
  opacity: 0.15;
}

.tl-separator-true .sidebar-menu > .menu-item {
  &:last-child > .mi-link:before {
    display: none;
  }

  > .mi-link {
    &:before {
      left: 40px;
      width: calc(100% - 80px);
    }

    > .arrow-down {
      right: 56px;
    }
  }
}

.separator-true {
  .header-menu
    > .menu-item:not(.mm-true)
    .sub-menu
    > .menu-item:last-child
    > .mi-link:before,
  .sidebar-menu
    > .menu-item:not(.mm-true)
    .sub-menu
    > .menu-item:last-child
    > .mi-link:before,
  &.mm-container
    .sub-menu
    > .menu-item:last-child
    > .mi-link:only-child:before {
    display: none;
  }
}

.tl-separator-true
  .sidebar-menu
  > .menu-item:last-child
  > .mi-link:only-child:before {
  display: none;
}

.separator-true {
  .et-menu
    > .menu-item:not(.mm-true)
    .sub-menu
    > .menu-item:last-child
    > .mi-link:before {
    display: none;
  }

  &.mm-container .sub-menu > .menu-item:last-child > .mi-link:before {
    display: block;
  }

  .header-menu > .menu-item:not(.mm-true) .sub-menu,
  .sidebar-menu > .menu-item:not(.mm-true) .sub-menu,
  &.mm-container .sub-menu,
  .et-menu > .menu-item:not(.mm-true) .sub-menu {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .header-menu > .menu-item:not(.mm-true) .sub-menu .sub-menu,
  .sidebar-menu > .menu-item:not(.mm-true) .sub-menu .sub-menu,
  .et-menu > .menu-item:not(.mm-true) .sub-menu .sub-menu {
    top: -12px;
  }

  &.submenu-appear-from-top
    .et-menu
    > .menu-item:not(.mm-true)
    .sub-menu
    .sub-menu {
    bottom: -12px !important;
  }

  .header-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link,
  .sidebar-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link,
  &.mm-container .sub-menu .menu-item .mi-link {
    padding: 12px 32px;
  }
}

.tl-separator-true .sidebar-menu > .menu-item > .mi-link,
.separator-true
  .et-menu
  > .menu-item:not(.mm-true)
  .sub-menu
  .menu-item
  .mi-link {
  padding: 12px 32px;
}

.tl-separator-true {
  .sidebar-menu > .menu-item > .mi-link {
    padding: 12px 56px;
  }

  &.separator-false .sidebar-menu > .menu-item > .sub-menu {
    top: -20px;
  }

  &.separator-true .sidebar-menu > .menu-item > .sub-menu {
    top: -12px;
  }
}

/* megamenu
      ---------------*/

.header-menu .menu-item[data-width='100'],
.et-menu .menu-item[data-width='100'] {
  position: static !important;
}

.megamenu .arrow-down {
  display: none !important;
}

.header-menu > .mm-true > .megamenu,
.sidebar-menu > .mm-true > .megamenu,
.et-menu > .mm-true > .megamenu,
.et-header-button .megamenu {
  left: 0;
  padding: 0 !important;
  height: 0;
  overflow: hidden;
}

.header-menu > .mm-true:hover > .megamenu,
.sidebar-menu > .mm-true:hover > .megamenu,
.et-menu > .mm-true:hover > .megamenu,
// .et-header-button:hover .megamenu {
.et-header-button.active .megamenu {
  height: auto;
}

.sidebar-menu > .mm-true > .megamenu {
  left: 100% !important;
}

.submenu-left > .megamenu {
  left: auto;
  right: 0;
}

.sidebar-navigation-right .sidebar-menu .megamenu {
  left: auto !important;
  right: 100% !important;
}

.et-mm {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  margin: 0;
  padding: 0;

  > .menu-item {
    padding-bottom: 16px;
  }
}

.separator-true.mm-container .et-mm > .menu-item {
  padding-bottom: 8px;
}

.mm-container {
  &.column-6 .et-mm > .menu-item {
    width: 16.66666666666667%;
  }

  &.column-5 .et-mm > .menu-item {
    width: 20%;
  }

  &.column-4 .et-mm > .menu-item {
    width: 25%;
  }

  &.column-3 .et-mm > .menu-item {
    width: 33.2121213%;
  }

  &.column-2 .et-mm > .menu-item {
    width: 50%;
  }

  &.column-1 .et-mm > .menu-item {
    width: 100%;
  }
}

.megamenu {
  &[data-position='left'] {
    left: 0 !important;
    right: auto !important;
  }

  &[data-position='right'] {
    right: 0 !important;
    left: auto !important;
  }

  &[data-position='center'] {
    right: auto !important;
    left: 50% !important;
  }

  .sub-menu {
    display: block !important;
    opacity: 1 !important;
    transform: none !important;
    -ms-transform: none !important;
    position: static !important;
    top: auto !important;
    left: auto !important;
    height: auto !important;
    min-width: 100% !important;
    max-width: 100% !important;
    box-shadow: none !important;
    padding: 0 !important;
    background-color: transparent !important;
    visibility: visible !important;
    z-index: 1 !important;
    margin-bottom: 16px !important;
  }
}

.et-mm > .menu-item > .mi-link:before {
  opacity: 1;
}

.separator-true.mm-container .et-mm > .menu-item > .mi-link:before {
  height: 2px;
}

.et-mm > .menu-item > .mi-link {
  &:after {
    display: none !important;
  }

  margin-bottom: 8px;
}

.separator-true.mm-container .et-mm > .menu-item > .mi-link {
  margin-bottom: 0;
}

.et-mm .sub-menu .sub-menu {
  padding: 0 0 0 24px !important;
}

/* megamenu tab
      ---------------*/

.megamenu-tab {
  position: relative;
  display: table;
  width: 100%;
  max-width: 100%;

  .tabset {
    display: table-cell;
    vertical-align: top;
  }

  .tabs-container {
    display: table-cell;
    vertical-align: top;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    max-width: 100%;
  }

  &.small {
    .tabset {
      width: 23%;
      min-width: 282px;
    }

    .tabs-container {
      width: 77%;
    }
  }

  &.medium {
    .tabset {
      width: 28%;
      min-width: 336px;
    }

    .tabs-container {
      width: 72%;
    }
  }

  &.large {
    .tabset {
      width: 33.33333333333333%;
      min-width: 400px;
    }

    .tabs-container {
      width: 66.666666666666675%;
    }
  }

  .tab-content {
    display: none;
    opacity: 1;
    transition: none !important;
    -ms-transform: none !important;
    transform: none !important;

    &.active {
      display: block !important;
    }
  }

  .tabset {
    padding: 24px 0;
  }

  .tab-item {
    padding: 12px 24px;
    transition: all 300ms ease-out;
    cursor: pointer;

    span {
      position: relative;
      z-index: 2;
    }
  }

  .arrow {
    display: none;
  }

  .tab-item.active .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 16px 0;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -16px;
  }

  &.hover-dot .tab-item:after {
    left: 24px;
  }

  &.hover-outline .tab-item {
    padding: 16px 32px;
    margin: 1px 32px 0 32px;

    &.active .arrow {
      right: -32px;
    }
  }

  &.large.hover-outline .tab-item {
    margin: 1px 48px 0 48px;

    &.active .arrow {
      right: -48px;
    }
  }

  .tab-item {
    .icon {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 24px;
      margin-right: 12px;
      position: relative;
      vertical-align: middle;
      display: inline-block;
      top: -1px;
    }

    .txt + .icon {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -12px;
      margin-right: 0;
    }
  }
}

/* hbe_icon_element
    ---------------*/

.hbe-icon-element {
  position: relative;
}

.hbe-toggle {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  transition: color 300ms ease-out, background-color 300ms ease-out;
  position: relative;
  border-radius: 4px;
}

.size-small > .hbe-toggle {
  width: 32px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;

  &.active {
    font-size: 14px;
  }
}

.close-size-small.hbe .hbe-toggle.active {
  font-size: 14px;
}

.size-medium > .hbe-toggle {
  width: 36px;
  height: 36px;
  font-size: 20px;
  line-height: 36px;

  &.active {
    font-size: 16px;
  }
}

.close-size-medium.hbe .hbe-toggle.active {
  font-size: 16px;
}

.size-large > .hbe-toggle {
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;

  &.active {
    font-size: 18px;
  }
}

.close-size-large.hbe .hbe-toggle.active {
  font-size: 18px;
}

.hbe-toggle {
  &:before {
    position: relative;
  }

  &.active:before {
    left: 0 !important;
  }
}

/* et_search_toggle
    ---------------*/

.header-search {
  .search-toggle:before {
    left: 1px;
  }

  .search-box {
    display: none;
    padding: 11px;
    position: absolute;
    top: 100%;
    right: 0;
  }
}

.box-align-right .search-box {
  right: auto;
  left: 0;
}

.header-search .search-box.active {
  display: block;
}

.search-box {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
}

.header-search .search-form {
  position: relative;
  width: 256px;
  margin: 0 !important;
  line-height: initial;

  fieldset {
    margin: 0 !important;
    line-height: initial;
  }

  input {
    margin: 0 !important;
    line-height: initial;

    &[type='submit'],
    &[type='text'] {
      height: 48px;
    }
  }

  .search-icon {
    height: 48px;
  }

  input {
    &[type='text'] {
      padding-right: 72px;
      border: none !important;
    }

    &[type='submit'] {
      width: 48px;
      min-width: 0 !important;
      text-indent: -900em;
      padding: 0 !important;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .search-icon {
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    transition: all 300ms ease-out;
  }

  input[type='submit']:hover + .search-icon {
    opacity: 0.7;
  }
}

/* et_cart_toggle
    ---------------*/

.header-cart {
  width: auto !important;
  height: auto !important;

  .cart-toggle {
    padding-left: 48px;

    &:before {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -18px;
      font-size: 32px;
      line-height: 32px;
      width: 48px;
      height: 32px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - 40px);
      height: 100%;
      z-index: 2;
    }

    &.active:before {
      font-size: 22px;
      left: 2px !important;
      margin-top: -16px;
    }
  }

  .cart-contents {
    display: block;
    text-align: left;
    line-height: 18px;
    font-size: 12px;
    position: relative;

    /*z-index: -1;*/

    .cart-title {
      display: block;
      font-weight: 600;
      font-size: 16px;
    }

    .cart-info:before {
      content: '|';
      display: inline-block;
      vertical-align: baseline;
      margin: 0 4px 0 0;
    }
  }

  .cart-box {
    display: none;
    padding: 24px;
    position: absolute;
    top: 100%;
    right: 0;
    line-height: initial;
  }
}

.box-align-right .cart-box {
  right: auto;
  left: 0;
}

.cart-box {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
}

.header-cart .cart-box {
  .product_list_widget > li:not(:last-child):before,
  .woocommerce-mini-cart__total:before {
    opacity: 0.1;
  }

  &.active {
    display: block;
  }
}

/* et_search_form
    ---------------*/

.header-search-form .search-form {
  vertical-align: middle;
  display: inline-block;
  margin: 0;
  height: 56px;

  fieldset {
    height: inherit !important;
  }

  input {
    &[type='text'] {
      height: inherit !important;

      &#s {
        padding: 0 48px 0 16px !important;
      }
    }

    &[type='submit']:hover + .search-icon {
      opacity: 1;
    }
  }
}

/* et_woo_search_form
    ---------------*/

.header-woo-search-form {
  max-width: 100%;

  .dgwt-wcas-search-wrapp,
  form,
  input {
    max-width: 100%;
  }

  .dgwt-wcas-search-wrapp {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
    height: 48px;
  }

  .dgwt-wcas-sf-wrapp {
    height: inherit !important;
    border-radius: 4px !important;
  }

  .dgwt-wcas-search-form {
    height: inherit !important;
    border-radius: 4px !important;

    // input[type='search'] {
    //   height: inherit !important;
    //   border-radius: 4px !important;
    // }
  }

  .dgwt-wcas-search-submit {
    height: inherit !important;
    border-radius: 4px !important;
  }

  // input[type='search'] {
  //   padding: 0 48px 0 16px !important;
  //   border-width: 2px !important;
  //   border-style: solid;
  // }

  .dgwt-wcas-search-submit {
    opacity: 1 !important;
    border-radius: 0 4px 4px 0 !important;

    &:before {
      display: none !important;
    }
  }
}

.dgwt-wcas-close:not(.dgwt-wcas-inner-preloader) {
  background-image: none !important;
  text-align: center;

  &:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

/* et_login_toggle
    ---------------*/

.header-login {
  .login-toggle {
    width: auto;
    padding: 0 16px 0 12px;
  }

  .login-title {
    display: inline-block;
    vertical-align: top;
    line-height: inherit;
    margin-left: 8px;
  }
}

.logged-in .login-title.login,
.login-title.logout {
  display: none;
}

.logged-in .login-title.logout {
  display: inline-block;
}

.header-login .widget_reglog {
  display: none;
  margin: 0;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 24px;
  line-height: 24px;
  width: 256px;
}

.box-align-right .widget_reglog {
  right: auto;
  left: 0;
}

.header-login .widget_reglog {
  &.active {
    display: block;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  }

  form,
  .login-submit {
    margin-bottom: 0;
  }

  form {
    .input {
      border: none;
    }

    .button {
      margin-bottom: 0;
    }
  }
}

.login-toggle:before {
  display: inline-block;
}

.size-small > .login-toggle:before {
  width: 16px;
}

.size-medium > .login-toggle:before {
  width: 20px;
}

.size-large > .login-toggle:before {
  width: 24px;
}

.header-login .widget_reglog a:not(.button) {
  transition: all 200ms ease-out;

  &:hover {
    opacity: 0.7;
  }
}

.my-account-buttons {
  margin-top: 16px;

  a {
    width: 100%;
  }
}

/* et_language_switcher
    ---------------*/

.language-switcher ul {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 24px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 16px 0;

  > li {
    position: relative;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
  }
}

.box-align-right.language-switcher-wrapper ul {
  right: 0;
  left: auto;
}

.box-align-center.language-switcher-wrapper ul {
  left: 50%;
  margin-left: -25%;
}

.language-switcher {
  ul {
    &.active {
      display: block;
    }

    li a {
      padding: 2px 16px;
      font-weight: 500;
      line-height: 24px;
      font-size: 14px;
      display: block;
      transition: all 300ms ease-out;

      > {
        * {
          display: inline-block;
          vertical-align: middle;
        }

        img {
          margin-right: 8px;
        }
      }
    }
  }

  .wpml-ls-legacy-list-horizontal {
    li {
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .wpml-ls-legacy-list-vertical li {
    position: relative;
    display: block;
  }

  &.size-small {
    .wpml-ls-legacy-list-vertical li,
    .wpml-ls-legacy-list-horizontal a {
      height: 32px;
      line-height: 32px;
    }
  }

  &.size-medium {
    .wpml-ls-legacy-list-vertical li,
    .wpml-ls-legacy-list-horizontal a {
      height: 36px;
      line-height: 36px;
    }
  }

  &.size-large {
    .wpml-ls-legacy-list-vertical li,
    .wpml-ls-legacy-list-horizontal a {
      height: 40px;
      line-height: 40px;
    }
  }
}

.wpml-ls-item-toggle:after {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  position: relative;
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 12px;
}

.language-switcher ul {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
}

/* et_currency_switcher
    ---------------*/

.currency-switcher {
  position: relative;

  .currency-toggle {
    cursor: pointer;
    top: -1px;
  }

  .currency-list {
    display: none;
    margin: -1px 0 0 0;
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 24px;
    width: 256px;
    padding: 16px 0;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  }
}

.box-align-right.currency-switcher .currency-list {
  right: 0;
  left: auto;
}

.box-align-center.currency-switcher .currency-list {
  left: 50%;
  margin-left: -25%;
}

.currency-switcher {
  .currency-list {
    &.active {
      display: block;
    }

    a {
      display: block;
      padding: 2px 16px;
      font-weight: 500;
      line-height: 24px;
      font-size: 14px;
      transition: all 200ms ease-out;
    }
  }

  .highlighted-currency {
    display: inline-block;
    vertical-align: baseline;
    padding-left: 8px;

    &:after {
      font-size: 10px;
      display: inline-block;
      position: relative;
      vertical-align: baseline;
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      margin-left: 4px;
    }
  }
}

/* et_header_social_links
    ---------------*/

.header-social-links {
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  a {
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-align: center;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    vertical-align: top;
    transition: all 300ms ease-out;
  }
}

.et-social-links a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  vertical-align: top;
  transition: all 300ms ease-out;
  margin-bottom: 4px;
  border-radius: 4px;
}

.shadow-true.et-social-links a {
  box-shadow: 0px 0 24px 0px rgba(0, 0, 0, 0.08);
}

.header-social-links a:last-child,
.et-social-links a:last-child {
  margin-right: 0 !important;
}

.header-social-links.styling-original-true a,
.et-social-links.styling-original-true a {
  color: #ffffff !important;
  margin-right: 4px;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  backface-visibility: hidden;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.header-social-links.styling-original-true a:hover,
.et-social-links.styling-original-true a:hover {
  -ms-transform: translateY(-8px);
  transform: translateY(-8px);
}

.header-social-links.styling-original-true a.ien-youtube,
.et-social-links.styling-original-true a.ien-youtube {
  background-color: #bb0000;
}

.header-social-links.styling-original-true a.ien-vk,
.et-social-links.styling-original-true a.ien-vk {
  background-color: #4a76a8;
}

.header-social-links.styling-original-true a.ien-tripadvisor,
.et-social-links.styling-original-true a.ien-tripadvisor {
  background-color: #00a680;
}

.header-social-links.styling-original-true a.ien-google,
.et-social-links.styling-original-true a.ien-google {
  background-color: #dd4b39;
}

.header-social-links.styling-original-true a.ien-facebook,
.et-social-links.styling-original-true a.ien-facebook {
  background-color: #3b5998;
}

.header-social-links.styling-original-true a.ien-instagram,
.et-social-links.styling-original-true a.ien-instagram {
  background-color: #bc2a8d;
}

.header-social-links.styling-original-true a.ien-twitter,
.et-social-links.styling-original-true a.ien-twitter {
  background-color: #00aced;
}

.header-social-links.styling-original-true a.ien-vimeo,
.et-social-links.styling-original-true a.ien-vimeo {
  background-color: #1ab7ea;
}

.header-social-links.styling-original-true a.ien-dribbble,
.et-social-links.styling-original-true a.ien-dribbble {
  background-color: #ea4c89;
}

.header-social-links.styling-original-true a.ien-behance,
.et-social-links.styling-original-true a.ien-behance {
  background-color: #0057ff;
}

.header-social-links.styling-original-true a.ien-github,
.et-social-links.styling-original-true a.ien-github {
  background-color: #2ebc4f;
}

.header-social-links.styling-original-true a.ien-skype,
.et-social-links.styling-original-true a.ien-skype {
  background-color: #00b4f5;
}

.header-social-links.styling-original-true a.ien-linkedin,
.et-social-links.styling-original-true a.ien-linkedin {
  background-color: #007bb6;
}

.header-social-links.styling-original-true a.ien-pinterest,
.et-social-links.styling-original-true a.ien-pinterest {
  background-color: #cb2027;
}

.header-social-links.styling-original-true a.ien-email,
.et-social-links.styling-original-true a.ien-email {
  background-color: #424242;
}

.header-social-links {
  &.size-small > a {
    width: 32px;
    height: 32px;
    font-size: 16px;
    line-height: 32px;
  }

  &.size-medium > a {
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 36px;
  }
}

.et-social-links a {
  width: 36px;
  height: 36px;
  font-size: 20px;
  line-height: 36px;
}

.header-social-links.size-large > a {
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
}

/* header-icon
    ---------------*/

/*.hicon {
        vertical-align: top;
      }*/

span.hicon {
  cursor: default !important;
}

/* et_header_button
    ---------------*/

.et-header-button {
  > a,
  > button {
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;
    top: -1px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &.submenu-toggle-click {
    .sub-menu,
    &:hover .sub-menu {
      display: none;
      transform: none !important;
    }

    .sub-menu.active {
      opacity: 1 !important;
      visibility: visible !important;
      display: block !important;
      z-index: 2 !important;
      height: auto !important;
    }
  }
}

/* et_header_vertical_separator
    ---------------*/

.header-vertical-separator .line {
  vertical-align: middle;
  display: inline-block;
}

/* mobile header
    ---------------*/

.mobile-container-overlay,
.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  top: 0;
  left: -100%;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: url(images/close_icon_white.png), auto;
  opacity: 0;
  transition: left 0ms ease-out, opacity 300ms 50ms ease-out;
}

.mobile-container-overlay.active,
.sidebar-overlay.active {
  left: 0;
  opacity: 1;
}

.mobile-container {
  width: 100%;
  height: 100%;
  cursor: default;
  position: fixed;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 9999999;
  backface-visibility: hidden;
}

.header.sticky-true.active .mobile-container {
  transition: none !important;
}

.mobile-container-overlay.active + .mobile-container,
.header.sticky-true.active
  .mobile-container-overlay.active
  + .mobile-container {
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1) !important;
}

.mobile-container {
  &.effect-left {
    top: 0;
    left: 0;
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  &.effect-right {
    top: 0;
    left: 0;
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  &.effect-top {
    top: 0;
    left: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }
}

.mobile-container-overlay.active + .mobile-container {
  &.effect-left {
    top: 0;
    -ms-transform: translateX(0);
    transform: translateX(0);
    box-shadow: 1px 0 8px rgba(0, 0, 0, 0.3);
  }

  &.effect-right {
    top: 0;
    -ms-transform: translateX(0);
    transform: translateX(0);
    box-shadow: -1px 0 8px rgba(0, 0, 0, 0.3);
  }

  &.effect-top {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.mobile-container {
  .header-logo {
    .logo {
      display: block !important;
    }

    .sticky-logo {
      display: none !important;
    }
  }

  .hbe {
    height: auto !important;
    line-height: initial !important;
  }
}

/* mobile menu
      ---------------*/

.mobile-menu-container {
  display: block;
  width: 100%;

  &.text-align-left {
    text-align: left;
  }

  &.text-align-right {
    text-align: right;
  }

  &.text-align-center {
    text-align: center;
  }
}

.mobile-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    position: relative;

    a {
      display: block;
      padding: 12px 16px;
      line-height: 24px;
      position: relative;
      transition: all 300ms ease-out;
    }

    ul li a {
      padding-left: 24px !important;
      opacity: 0.8;
    }
  }
}

.mobile-menu-container.text-align-right
  .mobile-menu
  .menu-item-has-children
  > a {
  padding: 12px 36px 12px 16px;
}

.mobile-menu {
  li a:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
  }

  > li:last-child:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    top: auto;
    bottom: 0;
  }

  ul {
    margin-bottom: 0;
    display: none;
    position: relative;
  }

  .menu-item-has-children > a > .arrow-down {
    display: block;
    position: absolute;
    width: auto;
    height: 24px;
    line-height: 24px;
    text-align: center;
    right: 16px;
    top: 50%;
    margin-top: -12px;
    margin-left: 0;
    z-index: 5;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.mobile-menu-container.text-align-center
  .mobile-menu
  .menu-item-has-children
  > a
  > .arrow-down {
  display: inline-block;
  position: relative;
  width: auto;
  height: 24px;
  line-height: 24px;
  text-align: center;
  right: 0;
  top: 0;
  margin-top: 0;
  margin-left: 8px;
}

.mobile-menu .menu-item-has-children > a > .arrow-down.active {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* modal container
      ---------------*/

.modal-container {
  width: 100%;
  height: 100%;
  cursor: default;
  position: fixed;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 9999999;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header.sticky-true.active .modal-container {
  transition: none !important;
}

.modal-container.active,
.header.sticky-true.active .modal-container.active {
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1) !important;
}

.modal-container {
  &.effect-left {
    top: 0;
    left: 0;
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  &.effect-right {
    top: 0;
    left: 0;
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  &.effect-top {
    top: 0;
    left: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  &.effect-center {
    top: 0;
    left: 0;
    -ms-transform: scale(0.3);
    transform: scale(0.3);
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: center;
    opacity: 0;
    visibility: hidden;
  }

  &.effect-left.active {
    top: 0;
    -ms-transform: translateX(0);
    transform: translateX(0);
    box-shadow: 1px 0 8px rgba(0, 0, 0, 0.3);
  }

  &.effect-right.active {
    top: 0;
    -ms-transform: translateX(0);
    transform: translateX(0);
    box-shadow: -1px 0 8px rgba(0, 0, 0, 0.3);
  }

  &.effect-top.active {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  &.effect-center.active {
    opacity: 1;
    visibility: visible;
    -ms-transform: scale(1);
    transform: scale(1);
  }

  .header-logo {
    .logo {
      display: block !important;
    }

    .sticky-logo {
      display: none !important;
    }
  }

  .hbe {
    height: auto !important;
    line-height: initial !important;
  }
}

.modal-container-content {
  max-width: 100%;
  width: 100%;
}

.modal-container-close {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 5;
}

/* modal menu
          ---------------*/

.modal-menu-container {
  display: block;
  width: 100%;

  &.text-align-left {
    text-align: left;
  }

  &.text-align-right {
    text-align: right;
  }

  &.text-align-center {
    text-align: center;
  }
}

.modal-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    position: relative;

    a {
      display: block;
      padding: 16px 24px;
      line-height: 24px;
      position: relative;
      transition: all 300ms ease-out;
    }
  }
}

.modal-menu-container.text-align-right .modal-menu .menu-item-has-children > a {
  padding: 16px 48px 16px 24px;
}

.modal-menu {
  li a:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
  }

  > li:last-child:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    top: auto;
    bottom: 0;
  }

  ul {
    margin-bottom: 0;
    display: none;
    position: relative;
  }

  .menu-item-has-children > a > .arrow-down {
    display: block;
    position: absolute;
    width: auto;
    height: 24px;
    font-size: 40%;
    line-height: 24px;
    text-align: center;
    right: 24px;
    top: 50%;
    margin-top: -12px;
    margin-left: 0;
    z-index: 5;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.modal-menu-container.text-align-center
  .modal-menu
  .menu-item-has-children
  > a
  > .arrow-down {
  display: inline-block;
  position: relative;
  width: auto;
  height: 24px;
  line-height: 24px;
  text-align: center;
  right: 0;
  top: 0;
  margin-top: 0;
  margin-left: 8px;
}

.modal-menu .menu-item-has-children > a > .arrow-down.active {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* sidebar-navigation
      ---------------*/

.sidebar-navigation {
  .hbe {
    height: auto;
  }

  .header.side-true {
    width: 320px;
    max-width: 320px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all 0.5s;
    backface-visibility: hidden;
    box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
}

.sidebar-navigation-right .header.side-true {
  left: auto;
  right: 0;
  box-shadow: -1px 0px 5px rgba(0, 0, 0, 0.1);
}

.sidebar-navigation {
  .header.side-true {
    .vc_column_container,
    .vc_column-inner,
    .wpb_wrapper,
    .hbe {
      max-width: 100% !important;
    }
  }

  #wrap {
    padding-left: 320px;
  }
}

.sidebar-navigation-right #wrap {
  padding-left: 0;
  padding-right: 320px;
}

.sidebar-container {
  width: inherit;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > {
    .snva {
      width: 100%;
      display: block;
    }

    .vertical-align-top {
      align-self: flex-start;
    }

    .vertical-align-middle {
      align-self: center;
    }

    .vertical-align-bottom {
      align-self: flex-end;
    }
  }
}

.sidebar-navigation .header.side-true {
  > .vc_row {
    height: 100%;

    > .container {
      height: 100%;

      > .wpb_column {
        height: 100%;

        > .vc_column-inner {
          height: 100%;

          > .wpb_wrapper {
            height: 100%;
          }
        }
      }
    }
  }

  .container {
    width: 100%;
    max-width: 100%;
  }
}

.sidebar-menu-container {
  width: 100%;

  .menu-item {
    display: block;
    padding: 0;
    margin: 0;
    position: relative;
  }
}

.sidebar-menu .menu-item.megamenu-static,
.sidebar-menu-container {
  position: static !important;
}

.tl-text-align-left .sidebar-menu > .menu-item,
.sub-text-align-left .sidebar-menu .sub-menu .menu-item {
  text-align: left;
}

.tl-text-align-center .sidebar-menu > .menu-item,
.sub-text-align-center .sidebar-menu .sub-menu .menu-item {
  text-align: center;
}

.tl-text-align-right .sidebar-menu > .menu-item,
.sub-text-align-right .sidebar-menu .sub-menu .menu-item {
  text-align: right;
}

.tl-text-align-right .sidebar-menu > .menu-item > .mi-link > .arrow-down,
.sub-text-align-right
  .sidebar-menu
  .sub-menu
  .menu-item
  > .mi-link
  > .arrow-down {
  right: auto;
  left: 32px;
}

.tl-text-align-center .sidebar-menu > .menu-item > .mi-link > .arrow-down,
.sub-text-align-center
  .sidebar-menu
  .sub-menu
  .menu-item
  > .mi-link
  > .arrow-down {
  left: 0;
  right: 0;
  margin-left: 8px;
  margin-top: 0;
  top: 0;
  vertical-align: middle;
  position: relative;
}

.sidebar-navigation-right {
  .tl-text-align-center .sidebar-menu > .menu-item > .mi-link > .arrow-down,
  .sub-text-align-center
    .sidebar-menu
    .sub-menu
    .menu-item
    > .mi-link
    > .arrow-down {
    margin-left: 0;
    margin-right: 8px;
  }
}

.tl-text-align-right .sidebar-menu > .menu-item:hover > .mi-link > .arrow-down,
.sub-text-align-right
  .sidebar-menu
  .sub-menu
  .menu-item:hover
  > .mi-link
  > .arrow-down {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sidebar-navigation-right {
  .tl-text-align-center
    .sidebar-menu
    > .menu-item:hover
    > .mi-link
    > .arrow-down,
  .sub-text-align-center
    .sidebar-menu
    .sub-menu
    .menu-item:hover
    > .mi-link
    > .arrow-down {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

/* default header
      ---------------*/

.header {
  .vc-row-default,
  &.sticky-true.active .vc-row-default {
    height: 96px;

    .hbe {
      line-height: 96px;
    }
  }
}

#header-logo-default {
  .logo,
  .sticky-logo {
    max-height: 26px;
  }

  margin: 0px 72px 0px 0px;
}

#header-menu-default > .menu-item.depth-0 {
  margin-left: 40px;
}

.vc-row-default .container,
#title-section-default .container {
  display: block !important;
}

#header-menu-default {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      text-transform: none;
      font-family: 'Montserrat';
    }

    &:hover > .mi-link,
    &.current-menu-item > .mi-link,
    &.current-menu-parent > .mi-link,
    &.current-menu-ancestor > .mi-link,
    &.one-page-active > .mi-link {
      color: #000000;
    }
  }

  .sub-menu {
    background-color: #ffffff;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);

    &:not(.megamenu-single):not(.megamenu) {
      margin-top: 0;
    }
  }

  > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link {
    &:before {
      background-color: #bdbdbd;
    }

    color: $color4;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    text-transform: none;
    font-family: 'Montserrat';

    .txt:after {
      background-color: $mainColor;
      box-shadow: none;
    }
  }

  .et-menu .sub-menu:not(.megamenu-single):not(.megamenu) {
    margin-top: 0;
  }

  > .menu-item:not(.mm-true) .sub-menu .menu-item:hover > .mi-link {
    color: #000000;
  }
}

#header-menu-container-default {
  margin: 0px 0px 0px 0px;
}

/* default mobile header
      ---------------*/

#et-mobile-default {
  height: 88px;
  background-color: #ffffff;

  > .container {
    height: inherit;
  }

  .hbe {
    line-height: 88px;
  }

  .logo {
    max-width: 171px;
    max-height: 26px;
  }

  .mobile-toggle {
    color: #bdbdbd;

    &:hover {
      color: #212121;
    }
  }

  .mobile-container {
    color: $color4;
    background-color: #ffffff;
    padding: 48px 24px 48px 24px;
  }

  .mobile-close {
    color: #bdbdbd;
    background-color: transparent;
    box-shadow: none;

    :hover {
      color: #212121;
      box-shadow: none;
    }
  }

  .mobile-container-close {
    margin: -18px 0px 0px 0px;
  }
}

#mobile-menu-default {
  > .menu-item > a {
    color: #212121;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.25px;
    text-transform: none;
    font-family: 'Montserrat';

    &:hover {
      color: #000000;
    }
  }

  .menu-item > a:after {
    background-color: #eeeeee;
  }

  > .menu-item {
    &:last-child:after {
      background-color: #eeeeee;
    }

    .sub-menu .menu-item > a {
      color: #212121;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.25px;
      text-transform: none;
      font-family: 'Montserrat';

      &:hover {
        color: #000000;
      }
    }
  }
}

#et-mobile-default .mobile-menu-container {
  margin: 32px 0px 32px 0px;
}

/* default footer
      ---------------*/

#et-footer-default {
  width: 100%;
  background-color: #212121;
  text-align: center;
  color: #bdbdbd;
  padding: 32px 24px 28px 24px;
}

/* default title section
      ---------------*/

#title-section-default {
  padding: 24px 0 24px 0;
  position: relative;
  z-index: 78;
  background: #f5f5f5;
  margin-bottom: 48px;

  .et-gap {
    height: 8px;
  }

  .title-section-title {
    font-size: 32px;
    line-height: 48px;
  }
}

/* Title section
  ---------------*/

.tse {
  position: relative;
  clear: both;

  &.text-align-left {
    text-align: left;
  }

  &.text-align-right {
    text-align: right;
  }

  &.text-align-center {
    text-align: center;
  }

  &.align-left {
    float: left;
  }

  &.align-right {
    float: right;
  }

  &.align-center {
    text-align: center;
  }

  > * {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }
}

/* Breadcrumbs
    ---------------*/

.et-breadcrumbs {
  padding: 0px;
  display: inline-block;
  word-break: break-word;

  > * {
    transition: all 0.3s ease-out;
    position: relative;
  }

  a {
    position: relative;
    color: inherit !important;

    &:after {
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      content: '';
      bottom: 0;
      left: 0px;
      z-index: -1;
      transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform-origin: bottom right;
      -ms-transform-origin: bottom right;
      transition: all 300ms ease-out;
      transition: background-color 300ms ease-out,
        transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:not(:first-child):after {
      width: calc(100% - 32px) !important;
      left: 32px;
    }

    &:hover:after {
      transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  > * {
    &:before {
      display: inline-block;
      top: 50%;
      font-size: 10px;
      line-height: 16px;
      width: 32px;
      text-align: center;
      position: relative;
      vertical-align: middle;
    }

    &:first-child {
      &:before {
        display: none;
      }

      margin-left: 0px;
    }
  }
}

/* Forms
  ---------------*/

form:after {
  content: '';
  display: table;
  clear: both;
}

div {
  &.wpcf7-mail-sent-ok,
  &.wpcf7-mail-sent-ng,
  &.wpcf7-spam-blocked,
  &.wpcf7-validation-errors {
    clear: both;
  }
}

fieldset {
  border: none;
}

textarea {
  height: auto;
}

select {
  &[size],
  &[multiple] {
    height: auto;
  }
}

textarea {
  min-height: 100px;
  overflow: auto;
  resize: vertical;
  width: 100%;
}

optgroup {
  font-style: normal;
  font-weight: 400;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}

input {
  &[type='radio'],
  &[type='checkbox'] {
    margin: 4px 0;
    display: inline-block;
  }
}

textarea,
select {
  display: block;
  outline: none;
  // margin-left: 0;
  // margin-right: 0;
  // margin-top: 0;
  // margin-bottom: 24px;
  text-align: left;
  max-width: 100%;
  width: 100%;
  position: relative;
  backface-visibility: hidden;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: color 300ms ease-out, background-color 300ms ease-out,
    box-shadow 300ms ease-out, border-color 300ms ease-out;
  // height: 48px;
  // font-size: 16px;
  // line-height: 16px;
  // padding: 12px 16px 12px 16px;
  border-width: 1px;
  border-style: solid;
  // border-radius: 4px;
}

input {
  &[type='date'],
  &[type='datetime'],
  &[type='datetime-local'],
  &[type='email'],
  &[type='month'],
  &[type='number'],
  &[type='password'],
  &[type='search'],
  &[type='tel'],
  &[type='text'],
  &[type='time'],
  &[type='url'],
  &[type='week'],
  &[type='file'] {
    display: block;
    outline: none;
    // margin-left: 0;
    // margin-right: 0;
    // margin-top: 0;
    // margin-bottom: 24px;
    text-align: left;
    max-width: 100%;
    width: 100%;
    position: relative;
    backface-visibility: hidden;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: color 300ms ease-out, background-color 300ms ease-out,
      box-shadow 300ms ease-out, border-color 300ms ease-out;
    // height: 48px;
    // font-size: 16px;
    // line-height: 16px;
    // padding: 12px 16px 12px 16px;
    border-width: 1px;
    border-style: solid;
    // border-radius: 4px;
  }
}

.select2-container--default .select2-selection--single {
  display: block;
  outline: none;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 24px;
  text-align: left;
  max-width: 100%;
  width: 100%;
  position: relative;
  backface-visibility: hidden;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: color 300ms ease-out, background-color 300ms ease-out,
    box-shadow 300ms ease-out, border-color 300ms ease-out;
  height: 48px;
  font-size: 16px;
  line-height: 16px;
  padding: 12px 16px 12px 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}

textarea {
  line-height: 28px;
  backface-visibility: hidden;
  transition: all 300ms ease-out !important;
}

span.wpcf7-list-item {
  margin: 0 1em 0 0 !important;
}

.et-button {
  display: inline-block;
  margin-bottom: 4px;
  outline: 0;
  border: none;
  position: relative;
  backface-visibility: hidden;
  transition: color 300ms ease-out, background-color 300ms ease-out,
    box-shadow 300ms ease-out;
  z-index: 1;
  padding: 8px 16px 8px 16px;
  font-size: 14px;
  line-height: 24px;
  text-align: center !important;
  text-transform: uppercase;
  border-radius: 4px;
}

input {
  &[type='button'],
  &[type='reset'],
  &[type='submit'] {
    display: inline-block;
    margin-bottom: 4px;
    outline: 0;
    border: none;
    position: relative;
    backface-visibility: hidden;
    transition: color 300ms ease-out, background-color 300ms ease-out,
      box-shadow 300ms ease-out;
    z-index: 1;
    padding: 8px 16px 8px 16px;
    font-size: 14px;
    line-height: 24px;
    text-align: center !important;
    text-transform: uppercase;
    border-radius: 4px;
  }
}

// button:not(.plyr__control),
// .single_add_to_cart_button,
// .product .button {
button.theme-button {
  display: inline-block;
  margin-bottom: 4px;
  outline: 0;
  border: none;
  position: relative;
  backface-visibility: hidden;
  transition: color 300ms ease-out, background-color 300ms ease-out,
    box-shadow 300ms ease-out;
  z-index: 1;
  padding: 8px 16px 8px 16px;
  font-size: 14px;
  line-height: 24px;
  text-align: center !important;
  text-transform: uppercase;
  border-radius: 4px;
}

.yith-woocompare-widget a {
  &.compare,
  &.clear-all {
    display: inline-block;
    margin-bottom: 4px;
    outline: 0;
    border: none;
    position: relative;
    backface-visibility: hidden;
    transition: color 300ms ease-out, background-color 300ms ease-out,
      box-shadow 300ms ease-out;
    z-index: 1;
    padding: 8px 16px 8px 16px;
    font-size: 14px;
    line-height: 24px;
    text-align: center !important;
    text-transform: uppercase;
    border-radius: 4px;
  }
}

.added_to_cart,
.post-read-more,
.comment-reply-link,
.product-quick-view,
.woocommerce-mini-cart__buttons > a,
.checkout-button,
.return-to-shop a {
  display: inline-block;
  margin-bottom: 4px;
  outline: 0;
  border: none;
  position: relative;
  backface-visibility: hidden;
  transition: color 300ms ease-out, background-color 300ms ease-out,
    box-shadow 300ms ease-out;
  z-index: 1;
  padding: 8px 16px 8px 16px;
  font-size: 14px;
  line-height: 24px;
  text-align: center !important;
  text-transform: uppercase;
  border-radius: 4px;
}

.wishlist_table {
  .yith-wcqv-button,
  .product-add-to-cart a {
    display: inline-block;
    margin-bottom: 4px;
    outline: 0;
    border: none;
    position: relative;
    backface-visibility: hidden;
    transition: color 300ms ease-out, background-color 300ms ease-out,
      box-shadow 300ms ease-out;
    z-index: 1;
    padding: 8px 16px 8px 16px;
    font-size: 14px;
    line-height: 24px;
    text-align: center !important;
    text-transform: uppercase;
    border-radius: 4px;
  }
}

.woocommerce-button,
#page-links > a,
.edit-link a,
.et-ajax-loader,
.menu-logo-true .header-button,
.woocommerce .wishlist_table td.product-add-to-cart a,
.woocommerce-message .button,
.widget_tag_cloud .tagcloud a,
.post-tags a,
.widget_product_tag_cloud .tagcloud a,
.post-tags-single a {
  display: inline-block;
  margin-bottom: 4px;
  outline: 0;
  border: none;
  position: relative;
  backface-visibility: hidden;
  transition: color 300ms ease-out, background-color 300ms ease-out,
    box-shadow 300ms ease-out;
  z-index: 1;
  padding: 8px 16px 8px 16px;
  font-size: 14px;
  line-height: 24px;
  text-align: center !important;
  text-transform: uppercase;
  border-radius: 4px;
}

.et-button {
  overflow: hidden;
  vertical-align: top;
  z-index: 1;
}

.edit-link a {
  margin-bottom: 0;
  margin-top: 16px;
}

.woocommerce-mini-cart__buttons > a + a {
  margin-left: 8px !important;
}

/*	sizes
    ---------------*/

.et-button.small,
.shop_table .woocommerce-button {
  padding: 4px 12px 4px 12px !important;
  font-size: 12px !important;
  line-height: 24px !important;
}

.yith-woocompare-widget a {
  &.compare,
  &.clear-all {
    padding: 4px 12px 4px 12px !important;
    font-size: 12px !important;
    line-height: 24px !important;
  }
}

.added_to_cart,
.post-read-more,
.comment-reply-link {
  padding: 4px 12px 4px 12px !important;
  font-size: 12px !important;
  line-height: 24px !important;
}

.wishlist_table {
  .yith-wcqv-button,
  .product-add-to-cart a {
    padding: 4px 12px 4px 12px !important;
    font-size: 12px !important;
    line-height: 24px !important;
  }
}

.woocommerce-button,
#page-links > a,
.edit-link a,
.shop_table .woocommerce-button,
.woocommerce .wishlist_table td.product-add-to-cart a,
.widget_tag_cloud .tagcloud a,
.post-tags a,
.widget_product_tag_cloud .tagcloud a,
.post-tags-single a,
.woocommerce-message .button {
  padding: 4px 12px 4px 12px !important;
  font-size: 12px !important;
  line-height: 24px !important;
}

.et-button.medium {
  padding: 8px 16px 8px 16px !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.product {
  .button,
  .added_to_cart {
    padding: 8px 16px 8px 16px !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }
}

.woocommerce-mini-cart__buttons > a {
  padding: 8px 16px 8px 16px !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.widget_price_filter {
  .price_label,
  .button {
    padding: 8px 16px 8px 16px !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }
}

.full #loop-posts .post-read-more {
  padding: 8px 16px 8px 16px !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.product {
  .button,
  .added_to_cart {
    padding: 8px 15px 8px 15px !important;
  }
}

.et-button.large {
  padding: 12px 24px 12px 24px !important;
  font-size: 16px !important;
  line-height: 28px !important;
}

/* Search/404
  ---------------*/

.search-results-title {
  font-size: 120%;
  margin-bottom: 48px;
  max-width: 720px;
}

.search-posts {
  .search-term {
    margin-bottom: 32px;
    padding: 24px;
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px #e0e0e0;
  }

  .post-title {
    a {
      color: #212121;
    }

    + .search-button {
      margin-top: 0 !important;
    }
  }

  .post-excerpt {
    margin: 0 !important;
  }
}

.tech-page-search-form {
  height: 60px;
  line-height: 60px;
  width: 100%;
  margin: 0 0 24px 0;

  &.search404 {
    margin: 0;
  }
}

.error404 #et-content {
  height: 100vh;
  display: flex;
  align-items: center;
}

.message404 {
  text-align: center;
}

.error404-default-title {
  font-size: 114px;
  margin: 0px;
  padding: 0px;
  color: #212121;
  line-height: 96px;
  text-align: center;

  > span {
    position: relative;
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -ms-transform-origin: 50%;
    transform-origin: 50%;
    display: inline-block;
    top: -7px;
  }
}

.error404-default-subtitle {
  padding: 0px;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 0;
  text-align: center;
}

.error404-default-description {
  text-align: center;
  margin: 0;
}

.error404-button {
  margin-top: 16px;
}

/* Widgets
  ---------------*/

.widget {
  margin-bottom: 32px;
}

.shop-top-widgets .widget {
  margin-bottom: 24px;
}

.megamenu .widget {
  margin-bottom: 0 !important;
}

.widget_title,
.widgettitle {
  font-size: 14px !important;
  line-height: 28px !important;
  text-align: left !important;
  text-transform: uppercase;
  position: relative;
  margin-top: 0;
  margin-bottom: 32px;
}

.widget_title {
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: -9.5px;
    left: 0;
    display: block;
    height: 2px;
    background-color: #e0e0e0;
    width: 48px;
    z-index: 3;
  }
}

.widgettitle {
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: -9.5px;
    left: 0;
    display: block;
    height: 2px;
    background-color: #e0e0e0;
    width: 48px;
    z-index: 3;
  }
}

.widget_title:after,
.widgettitle:after {
  height: 1px;
  width: 100%;
  bottom: -10px;
  z-index: 2;
}

.widget_title a {
  color: inherit !important;
}

.widget h6 {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 24px !important;
}

.widget_title {
  img {
    vertical-align: middle;
    margin-right: 4px;
    position: relative;
    top: -1px;
    width: auto;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .image-preloader {
    display: none;
  }
}

.widget ul,
.yith-woocompare-widget ul.products-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget ul li {
  padding: 0 0 16px 0;
  margin: 0;
}

.widget_recent_entries {
  li a {
    display: block;
  }

  ul li {
    display: block;
    position: relative;
  }
}

.widget_rss ul li,
.widget_recent_comments ul li {
  display: block;
  position: relative;
}

.widget_categories ul li,
.widget_pages ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_schedule ul li,
.widget_layered_nav ul li,
.yith-woocompare-widget ul.products-list li,
.widget_rating_filter ul li {
  margin: 0;
  padding: 0;
  position: relative;
  transition: all 300ms ease-out;
  width: 100%;
  padding: 2px 0 2px 24px;
}

.widget_pages ul li ul li {
  box-shadow: none !important;
  background-color: transparent !important;
  border-bottom: none !important;
}

.widget_categories ul ul,
.widget_pages ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_schedule ul ul,
.widget_layered_nav ul ul {
  margin-left: 24px !important;
}

.widget_categories ul li:last-child,
.widget_pages ul li:last-child,
.widget_archive ul li:last-child,
.widget_meta ul li:last-child,
.widget_schedule ul li:last-child,
.widget_layered_nav ul li:last-child,
.yith-woocompare-widget ul.products-list li:last-child {
  border-bottom: none !important;
}

.widget_schedule ul li {
  padding: 8px 16px !important;
  background-color: #f5f5f5;

  &:nth-child(2n + 2) {
    background-color: transparent;
    color: inherit;
  }

  &:nth-child(2n + 1) {
    color: $color4;
  }
}

.widget_categories ul li a,
.widget_pages ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_nav_menu ul li a,
.widget_schedule ul li,
.widget_layered_nav ul li a,
.widget_product_categories ul li a,
.widget_rating_filter ul li a {
  display: inline-block;
  position: relative;
  outline: 0;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
}

.widget_categories ul li a:before,
.widget_pages ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before,
.widget_layered_nav ul li a:before,
.widget_rating_filter ul li a:before,
.widget_categories ul li a:after,
.widget_pages ul li a:after,
.widget_archive ul li a:after,
.widget_meta ul li a:after,
.widget_layered_nav ul li a:after,
.widget_rating_filter ul li a:after {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px #e0e0e0;
  position: absolute;
  top: 50%;
  left: -24px;
  margin-top: -8px;
  transition: all 200ms ease-out;
}

.widget_categories ul li a:before,
.widget_pages ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before {
  border-radius: 500px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
}

.widget_categories ul li a:after,
.widget_pages ul li a:after,
.widget_archive ul li a:after,
.widget_meta ul li a:after,
.widget_layered_nav ul li a:after,
.widget_rating_filter ul li a:after {
  box-shadow: none;
  opacity: 0;
  font-size: 10px;
  line-height: 16px;
  left: -23px;
  text-align: center;
}

.widget_layered_nav ul li {
  &:hover a:after,
  &.chosen a:after {
    opacity: 1;
  }
}

.widget_rating_filter ul li {
  &:hover a:after,
  &.chosen a:after {
    opacity: 1;
  }
}

.widget_categories ul li:hover a:after,
.widget_pages ul li:hover a:after,
.widget_archive ul li:hover a:after,
.widget_meta ul li:hover a:after {
  opacity: 0;
}

.widget_et_recent_entries {
  .post {
    width: 100%;
    display: table;
    vertical-align: top;
    background-color: #ffffff;
    padding: 16px 0;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: none !important;
    }
  }

  .post-thumbnail {
    width: 72px;
    height: 72px;
    min-width: 56px;
    display: table-cell;
    vertical-align: top;
    padding-top: 4px;
  }

  .post-body {
    height: auto !important;
    padding: 0 0 0 16px !important;
    background-color: transparent !important;
    display: table-cell;
    vertical-align: top;
  }

  .post {
    &.format-aside .post-body,
    &.format-link .post-body,
    &.format-quote .post-body,
    &.format-status .post-body {
      background-color: transparent !important;
      padding: 0 0 0 16px !important;
    }
  }

  .post-title {
    margin-bottom: 0;
  }

  .post-date {
    color: #9e9e9e;
    line-height: 1.2em;
    margin-top: 8px;
  }
}

.widget_nav_menu,
.widget_product_categories {
  border-radius: 4px;
  padding: 24px;
  box-shadow: inset 0 0 0 1px #e0e0e0;
}

.widget_nav_menu .widget_title:before,
.widget_product_categories .widget_title:before {
  display: none;
}

.widget_nav_menu .widget_title:after,
.widget_product_categories .widget_title:after {
  bottom: -16px;
}

.widget_nav_menu ul li,
.widget_product_categories ul li {
  padding: 0 !important;
  position: relative;
}

.widget_nav_menu ul li a,
.widget_product_categories ul li a {
  display: block;
  position: relative;
  outline: 0;
  padding: 4px 0 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
}

.widget_nav_menu ul li ul li a,
.widget_product_categories ul li ul li a {
  line-height: 22px !important;
}

.widget_nav_menu ul li a + ul li ul li a,
.widget_product_categories ul li a + ul li ul li a {
  padding-left: 16px !important;
  font-size: 14px !important;
}

.widget_nav_menu ul li a + ul li:before,
.widget_product_categories ul li a + ul li:before {
  width: 1px;
  height: 100%;
  background-color: #e0e0e0;
  content: '';
  display: block;
  top: 0;
  left: -16px;
  position: absolute;
}

.widget_nav_menu ul li a + ul li ul li:before,
.widget_product_categories ul li a + ul li ul li:before {
  display: none !important;
}

.widget_nav_menu ul li a + ul li ul li a:before,
.widget_product_categories ul li a + ul li ul li a:before {
  content: '';
  position: absolute;
  width: 6px;
  height: 2px;
  top: 50%;
  margin-top: -1px;
  background-color: #c0c0c0;
  left: 0;
}

.widget_nav_menu > div > ul > li > ul,
.widget_product_categories > ul > li > ul {
  display: none;
  padding: 0 0 0 32px !important;
}

.widget_nav_menu ul ul,
.widget_product_categories ul ul {
  display: none;
}

.widget_nav_menu ul li a .toggle,
.widget_product_categories ul li a .toggle {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 8px;
  text-align: right;
  content: '';
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  transition: all 300ms ease-out;
  transform-origin: center;
}

.widget_nav_menu ul ul li a .toggle,
.widget_product_categories ul ul li a .toggle {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.megamenu {
  .widget_nav_menu ul li a .toggle,
  .widget_product_categories ul li a .toggle {
    display: none;
  }
}

.widget_nav_menu ul li a .toggle:before,
.widget_product_categories ul li a .toggle:before {
  position: relative;
  display: inline-block;
}

.widget_nav_menu ul li a.animate .toggle:before {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.widget_product_categories {
  ul li a.animate .toggle:before {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .count {
    margin-left: 4px;
  }
}

.recentcomments a {
  color: inherit !important;
}

.widget_schedule {
  ul li {
    display: block;

    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .hours {
    float: right;
  }
}

.widget_calendar {
  width: 100%;

  table {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none !important;
    position: relative;
    border-spacing: 0;
    border-collapse: collapse;
    vertical-align: middle;
  }

  th {
    font-weight: normal;
    text-align: center;
    padding: 4px;
    background-color: transparent;

    &:first-child {
      border-left-style: solid;
      border-left-width: 1px;
      border-color: #e0e0e0;
    }

    &:last-child {
      border-right-style: solid;
      border-right-width: 1px;
      border-color: #e0e0e0;
    }
  }

  td {
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    text-align: center;
    text-decoration: none;
    padding: 4px;

    a {
      display: block;
      text-decoration: none;
      width: 100%;
      height: 100%;
    }

    &#today {
      font-weight: 700;
    }
  }

  caption {
    margin: 0 auto;
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    border-bottom: none;
    width: 100% !important;
    padding: 4px;
    position: relative;

    a {
      position: absolute;
      top: 50%;
      display: block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin-top: -15px;
      text-align: center;
    }
  }

  a {
    &.prev {
      left: 2px;
    }

    &.next {
      right: 2px;
    }
  }
}

.widget_reglog {
  form + a {
    margin-top: 16px;
    display: block;
  }

  p:last-child {
    margin-bottom: 0;
    margin-top: 8px;
  }
}

.search-form {
  form,
  fieldset,
  input {
    position: relative;
    margin: 0 !important;
  }
}

.widget_search {
  form,
  fieldset,
  input {
    position: relative;
    margin: 0 !important;
  }
}

.widget_product_search {
  form,
  fieldset,
  input {
    position: relative;
    margin: 0 !important;
  }

  label {
    display: none;
  }
}

input[type='text']#s,
.widget_product_search input[type='search'] {
  outline: 0;
  display: block;
  margin: 0 !important;
  padding-right: 64px !important;
}

form #searchsubmit,
.widget_product_search form button {
  width: 48px !important;
  height: 48px !important;
  min-width: 0 !important;
  line-height: 0 !important;
  font-size: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  background: transparent !important;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  display: block !important;
  text-indent: -9000000px !important;
  position: absolute !important;
  top: 50% !important;
  right: 0 !important;
  margin-top: -24px !important;
}

form #searchsubmit + .search-icon {
  font-size: 20px;
  width: 48px;
  height: 48px;
  line-height: 48px !important;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  right: 0px;
  margin-top: -24px;
  transition: all 300ms ease-out;
  backface-visibility: hidden;
  color: $color4 !important;
}

.widget_product_search form button:before {
  font-size: 20px;
  width: 48px;
  height: 48px;
  line-height: 48px !important;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  right: 0px;
  margin-top: -24px;
  transition: all 300ms ease-out;
  backface-visibility: hidden;
  color: $color4 !important;
  text-indent: 0 !important;
}

.widget_search input[type='submit']:hover + .search-icon,
.widget_product_search form button:hover:before {
  opacity: 0.8;
}

.widget_instagram ul li,
.widget_flickr ul li {
  float: left;
  margin: 0;
  padding: 4px;
}

.widget_instagram ul li a,
.widget_flickr ul li a,
.widget_et_recent_entries .post-thumbnail a {
  position: relative;
  display: block;
}

.widget_instagram ul li a img,
.widget_flickr ul li a img {
  display: block;
}

.widget_instagram ul {
  margin-left: -4px;
  margin-right: -4px;
}

.widget_flickr {
  ul {
    margin-left: -4px;
    margin-right: -4px;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.widget_instagram ul li a:before,
.widget_flickr ul li a:before,
.widget_products .product_list_widget > li > a .image-container:before,
.widget_recently_viewed_products
  .product_list_widget
  > li
  a
  .image-container:before,
.widget_recent_reviews .product_list_widget > li a .image-container:before,
.widget_top_rated_products .product_list_widget > li a .image-container:before,
.widget_shopping_cart_content .product_list_widget > li .image-container:before,
.shop_table .product-thumbnail .image-container:before,
.widget_et_recent_entries .post-thumbnail a:before,
.widget_products
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before,
.widget_recently_viewed_products
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before,
.widget_recent_reviews
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before,
.widget_top_rated_products
  .product_list_widget
  > li
  .product-image
  .image-container
  a:before {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  text-align: center;
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
  margin: -12px 0 0 -12px !important;
  font-size: 15px;
  display: block;
  color: #ffffff !important;
  opacity: 0;
  transition: all 300ms ease-out;
  backface-visibility: hidden;
  z-index: 5;
}

.widget_instagram ul li a:after,
.widget_flickr ul li a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 300ms ease-out;
  z-index: 2;
}

.widget_instagram ul li a:hover:before,
.widget_flickr ul li a:hover:before,
.widget_instagram ul li a:hover:after,
.widget_flickr ul li a:hover:after {
  opacity: 1;
}

.widget_tag_cloud .tagcloud a,
.post-tags a,
.widget_product_tag_cloud .tagcloud a,
.post-tags-single a {
  text-decoration: none;
  display: inline-block;
  line-height: 100%;
  font-size: 12px !important;
  transition: all 300ms ease-out;
  backface-visibility: hidden;
  position: relative;
  color: $color4 !important;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  background-color: #f5f5f5;
  margin-left: 0;
  margin-bottom: 4px;
}

.header {
  .widget_tag_cloud .tagcloud a,
  .post-tags a,
  .widget_product_tag_cloud .tagcloud a,
  .post-tags-single a {
    margin-left: 4px;
  }
}

.post-tags-single a {
  margin-left: 8px;
  margin-bottom: 8px;
}

.widget_tag_cloud .tagcloud a:hover,
.post-tags a:hover,
.widget_product_tag_cloud .tagcloud a:hover,
.post-tags-single a:hover {
  color: #212121 !important;
  box-shadow: inset 0 0 0 1px #212121;
}

.widget_icl_lang_sel_widget {
  img {
    vertical-align: middle;
    top: -1px;
    margin-right: 8px;
  }

  li {
    padding: 0 !important;
    position: relative;

    a {
      padding: 9px 24px !important;
      display: block;
      position: relative;
      outline: 0;
      border-width: 1px !important;
      border-style: solid !important;
      border-color: #e0e0e0;
    }
  }

  .toggle {
    position: absolute;
    top: 50%;
    right: 8px;
    display: block;
    color: inherit;
    width: 32px;
    height: 32px;
    font-size: 16px;
    line-height: 32px;
    margin-top: -16px;
    text-align: center;
  }
}

.wpml-ls-legacy-list-horizontal .toggle,
.wpml-ls-legacy-list-vertical .toggle {
  display: none;
}

.widget_icl_lang_sel_widget li ul {
  display: none;
  background-color: transparent !important;

  li a {
    border-top: none !important;
  }
}

.wpml-ls-legacy-list-horizontal li:not(:first-child) a,
.wpml-ls-legacy-list-vertical li:not(:first-child) a {
  border-top: none !important;
}

.widget_fast_contact_widget form {
  position: relative;
  width: 100%;

  div {
    position: relative;
    width: 100%;
  }
}

.widget_mailchimp {
  div {
    position: relative;
    width: 100%;
  }

  .mailchimp-form {
    padding: 24px;
    background: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #e0e0e0;
  }
}

.widget_fast_contact_widget {
  textarea {
    height: 90px !important;
    min-height: 90px !important;
  }

  .alert {
    box-shadow: none !important;
    padding: 0 !important;
  }
}

.widget_mailchimp .alert {
  box-shadow: none !important;
  padding: 0 !important;
}

.widget_fast_contact_widget .alert:not(.final),
.widget_mailchimp .alert:not(.final) {
  display: inline-block;
  position: absolute;
  top: -24px;
  left: 0;
  font-size: 14px;
  line-height: 24px;
  padding: 0 8px !important;
  transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 300ms ease-out;
  -ms-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
  z-index: -1;
}

.widget_fast_contact_widget input,
.widget_mailchimp input {
  z-index: 3;
}

.widget_fast_contact_widget {
  input[name='email'] + .alert:not(.final) {
    transition: transform 700ms 100ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms 100ms ease-out;
  }

  textarea[name='message'] + .alert:not(.final) {
    transition: transform 700ms 150ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms 150ms ease-out;
  }

  .visible.alert:not(.final) {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    z-index: 2;
  }
}

.widget_mailchimp .visible.alert:not(.final) {
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  z-index: 2;
}

.widget_fast_contact_widget .final,
.widget_mailchimp .final {
  display: none;
}

.mailchimp-description {
  line-height: 26px !important;
  font-weight: 600;
  line-height: 24px;
}

.visible {
  &.final,
  &.sending {
    display: block !important;
  }
}

.enovathemes-contact-form-submit-error {
  margin-top: 20px !important;
}

.send-div {
  position: relative;
}

.widget_fast_contact_widget .sending,
.widget_mailchimp .sending {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  margin-top: -12px;
  right: 0;
  z-index: 15;
  display: none;
}

.widget_fast_contact_widget .sending:before,
.widget_mailchimp .sending:before {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgba(0, 0, 0, 0.4);
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  right: 0%;
  font-size: 10px;
  top: 50%;
  left: 50%;
  font-size: 10px;
  margin: -12px 0 0 -12px;
  -webkit-animation: contact-form-loading 0.3s infinite linear;
  animation: contact-form-loading 0.3s infinite linear;
}

.widget_fast_contact_widget .sending {
  &:before,
  &:after {
    border-radius: 240px;
    width: 24px;
    height: 24px;
  }
}

.widget_mailchimp .sending {
  &:before,
  &:after {
    border-radius: 240px;
    width: 24px;
    height: 24px;
  }
}

@-webkit-keyframes contact-form-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes contact-form-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* woo_search_form
    ---------------*/

.widget-area {
  .dgwt-wcas-search-wrapp {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
    height: 48px;
  }

  .dgwt-wcas-sf-wrapp {
    height: inherit !important;
    border-radius: 4px !important;
  }

  .dgwt-wcas-search-form {
    height: inherit !important;
    border-radius: 4px !important;

    input[type='search'] {
      height: inherit !important;
      border-radius: 4px !important;
    }
  }

  .dgwt-wcas-search-submit {
    height: inherit !important;
    border-radius: 4px !important;
  }

  input[type='search'] {
    padding: 0 48px 0 16px !important;
    border-width: 2px !important;
    border-style: solid;
  }

  .dgwt-wcas-search-submit {
    opacity: 1 !important;
    border-radius: 0 4px 4px 0 !important;

    &:before {
      display: none !important;
    }
  }
}

.dgwt-wcas-close:not(.dgwt-wcas-inner-preloader) {
  background-image: none !important;
  text-align: center;

  &:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.dgwt-wcas-is-details .dgwt-wcas-si {
  width: 48px;
}

.dgwt-wcas-st,
.dgwt-wcas-pd-rest .product-title {
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
}

.dgwt-wcas-sp .woocommerce-Price-amount,
.dgwt-wcas-pd-price .woocommerce-Price-amount {
  font-weight: 700;
}

.dgwt-wcas-sp del {
  opacity: 1 !important;

  .woocommerce-Price-amount {
    color: $color4;
    font-weight: 400 !important;
    opacity: 1 !important;
  }
}

.dgwt-wcas-pd-price del .woocommerce-Price-amount {
  color: $color4;
  font-weight: 400 !important;
  opacity: 1 !important;
}

.dgwt-wcas-pd-image,
.dgwt-wcas-tpd-image {
  padding: 0 !important;
  border: none !important;
}

.dgwt-wcas-pd-image img,
.dgwt-wcas-tpd-image img {
  border: 1px solid #e0e0e0;
}

.dgwt-wcas-pd-addtc {
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;

  > .quantity {
    max-width: 146px !important;
  }

  input {
    margin: 0 !important;
    height: 40px !important;
  }
}

.woocommerce .dgwt-wcas-pd-rating .star-rating {
  margin-bottom: 4px !important;
}

/* Mailchimp
    ----------------*/

.widget_mailchimp form {
  margin-bottom: 0;
  position: relative;
}

.et-mailchimp-form > div {
  &:nth-child(2) .alert:not(.final) {
    transition: transform 700ms 100ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms 100ms ease-out;
  }

  &:nth-child(3) .alert:not(.final) {
    transition: transform 700ms 150ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms 150ms ease-out;
  }

  &:nth-child(4) .alert:not(.final) {
    transition: transform 700ms 200ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms 200ms ease-out;
  }
}

/* Woocommerce
    ----------------*/

.woocommerce-widget-layered-nav-dropdown {
  margin-bottom: 0 !important;
}

.widget_price_filter {
  form {
    margin-bottom: 0 !important;
  }

  .price_slider_wrapper {
    padding-top: 10px;
  }

  .ui-slider {
    position: relative;
    text-align: left;
    margin-bottom: 20px;

    .ui-slider-handle {
      position: absolute;
      z-index: 2;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      cursor: pointer;
      outline: none;
      top: -6px;
      box-shadow: none;

      &:last-child {
        margin-left: -14px;
      }
    }

    .ui-slider-range {
      position: absolute;
      z-index: 1;
      display: block;
      border: 0;
      border-radius: 4px;
    }
  }

  .price_slider_wrapper .ui-widget-content {
    border-radius: 4px;
  }

  .ui-slider-horizontal {
    height: 4px;
    background-color: #e0e0e0;

    .ui-slider-range {
      top: 0;
      height: 100%;
    }

    .ui-slider-range-min {
      left: -1px;
    }

    .ui-slider-range-max {
      right: -1px;
    }
  }

  .price_label,
  .button {
    display: inline-block !important;
    margin-bottom: 0;
  }

  .price_label {
    font-weight: 600 !important;
    padding-right: 0 !important;
  }
}

.widget_products .product_list_widget,
.widget_recently_viewed_products .product_list_widget,
.widget_recent_reviews .product_list_widget,
.widget_top_rated_products .product_list_widget,
.widget_rating_filter ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget_products .product_list_widget > li,
.widget_recently_viewed_products .product_list_widget > li,
.widget_recent_reviews .product_list_widget > li,
.widget_top_rated_products .product_list_widget > li {
  position: relative;
  display: block;
  margin: 0;
  min-height: 72px;
  background-color: #ffffff;
  padding: 8px 0;
  border-bottom: 1px solid #e0e0e0;
}

.widget_shopping_cart .product_list_widget > li {
  padding: 0 24px 8px 0 !important;
  margin: 0 0 8px 0 !important;
  position: relative;

  &:not(:last-child):before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    position: absolute;
    background-color: #e0e0e0;
  }
}

.cart-box
  .widget_shopping_cart
  .product_list_widget
  > li:not(:last-child):before {
  border: none !important;
}

.widget_products .product_list_widget > li:last-child,
.widget_recently_viewed_products .product_list_widget > li:last-child,
.widget_recent_reviews .product_list_widget > li:last-child,
.widget_top_rated_products .product_list_widget > li:last-child,
.widget_shopping_cart .product_list_widget > li:last-child {
  margin: 0 !important;
  border: none !important;
}

.widget_products .product_list_widget > li:first-child,
.widget_recently_viewed_products .product_list_widget > li:first-child,
.widget_recent_reviews .product_list_widget > li:first-child,
.widget_top_rated_products .product_list_widget > li:first-child,
.widget_shopping_cart .product_list_widget > li:first-child {
  margin-top: 0 !important;
}

.widget_products .product_list_widget > li,
.widget_recently_viewed_products .product_list_widget > li,
.widget_recent_reviews .product_list_widget > li,
.widget_top_rated_products .product_list_widget > li {
  display: table;
  width: 100%;
}

.widget_products .product_list_widget > li > *,
.widget_recently_viewed_products .product_list_widget > li > *,
.widget_recent_reviews .product_list_widget > li > *,
.widget_top_rated_products .product_list_widget > li > * {
  display: table-cell;
  vertical-align: top;
}

.widget_products .product_list_widget > li > .product-image,
.widget_recently_viewed_products .product_list_widget > li > .product-image,
.widget_recent_reviews .product_list_widget > li > .product-image,
.widget_top_rated_products .product_list_widget > li > .product-image {
  position: relative;
  bottom: -2px;
}

.widget_products .product_list_widget > li > .product-image,
.widget_recently_viewed_products .product_list_widget > li > .product-image,
.widget_recent_reviews .product_list_widget > li > .product-image,
.widget_top_rated_products .product_list_widget > li > .product-image,
.widget_products .product_list_widget > li > .product-image .image-container,
.widget_recently_viewed_products
  .product_list_widget
  > li
  > .product-image
  .image-container,
.widget_recent_reviews
  .product_list_widget
  > li
  > .product-image
  .image-container,
.widget_top_rated_products
  .product_list_widget
  > li
  > .product-image
  .image-container {
  max-width: 72px;
  width: 72px;
}

.widget_products .product_list_widget > li > .product-body,
.widget_recently_viewed_products .product_list_widget > li > .product-body,
.widget_recent_reviews .product_list_widget > li > .product-body,
.widget_top_rated_products .product_list_widget > li > .product-body {
  padding-left: 16px;
}

.widget_products .product_list_widget > li .product-image .image-container a,
.widget_recently_viewed_products
  .product_list_widget
  > li
  .product-image
  .image-containera
  a,
.widget_recent_reviews
  .product_list_widget
  > li
  .product-image
  .image-container
  a,
.widget_top_rated_products
  .product_list_widget
  > li
  .product-image
  .image-container
  a {
  display: block;
  position: relative;
}

.widget_products .product_list_widget .product-title,
.widget_recently_viewed_products .product_list_widget .product-title,
.widget_recent_reviews .product_list_widget .product-title,
.widget_top_rated_products .product_list_widget .product-title {
  margin-bottom: 0;
}

.widget_recent_reviews .star-rating,
.widget_top_rated_products .star-rating,
.widget_products .star-rating {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 0;
  margin-right: 0;
}

.widget_layered_nav_filters li {
  display: inline-block;
  padding: 0 !important;
  margin: 0 4px 6px 0 !important;
  position: relative;

  a {
    display: block;
    text-decoration: none;
    line-height: 100%;
    transition: all 300ms ease-out;
    backface-visibility: hidden;
    position: relative;
    padding: 10px 12px 10px 12px !important;
  }
}

.woocommerce-loop-category__title.post-title {
  border: none !important;
  margin: 0 0 16px 0 !important;
  padding: 0 !important;
  text-transform: uppercase;

  a {
    font-weight: 700 !important;
  }
}

.small .product-category .woocommerce-loop-category__title.post-title,
.medium .product-category .woocommerce-loop-category__title.post-title {
  font-size: 14px !important;
}

.woocommerce-loop-category__title mark {
  background: transparent;
  color: inherit;
  display: inline-block;
  padding: 0px;
  border-radius: 0px;
  font-weight: inherit;
  text-decoration: inherit;
  margin: 0;
}

.widget_rating_filter {
  a:not(.button) {
    color: inherit !important;

    &:hover {
      color: inherit !important;
    }
  }

  span {
    display: inline-block;
    vertical-align: inherit;
    margin: 0;
  }
}

.woocommerce-widget-layered-nav-dropdown__submit {
  margin-top: 16px;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: none !important;
}

.woocommerce-widget-layered-nav-list {
  .more {
    font-size: 14px;
    cursor: pointer;

    &:after {
      content: ' +';
    }

    &.active:after {
      content: ' -';
    }
  }

  .toggle-box {
    display: none;
    margin-left: 0 !important;
  }
}

.yith-woocompare-widget ul.products-list li {
  &:not(.list_empty) {
    padding: 10px 16px !important;
    border-bottom: 1px solid #f5f5f5 !important;
  }

  &:last-child {
    padding: 10px 16px !important;
    border-bottom: none !important;
  }
}

.widget.yith-woocompare-widget ul.products-list li .remove {
  color: inherit !important;
  background-color: transparent !important;
  font-size: 0 !important;
  left: auto;
  right: 8px;
  top: 50% !important;
  margin-top: -8px !important;
}

.yith-woocompare-widget {
  ul.products-list li {
    .remove:before {
      font-size: 8px;
      display: block;
      position: relative;
      width: inherit;
      height: inherit;
      text-align: center;
      line-height: inherit;
    }

    .title {
      text-transform: initial;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  a {
    &.compare {
      border-radius: 0;
      float: none;
    }

    &.clear-all,
    &.compare {
      color: #ffffff;
      margin-bottom: 0;
      margin-top: 24px;
    }

    &.clear-all + a.compare {
      margin-left: 3px;
    }
  }
}

/* Car filter
    ----------------*/

.car-filter {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.vin-decoder {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  div {
    position: relative;
  }

  .widget_title {
    &:after,
    &:before {
      display: none;
    }

    margin: 0;
  }
}

.car-filter .car-filter-description,
.vin-decoder .widget_title {
  border-radius: 4px 4px 0 0;
  padding: 16px 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.car-filter form,
.vin-decoder form {
  border-radius: 0 0 4px 4px;
  padding: 24px 24px 16px 24px;
  overflow: hidden;
  position: relative;
}

.car-filter form.loading:after,
.add-vehicle form.loading:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 15;
}

.car-filter form.loading:before,
.add-vehicle form.loading:before {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: rgba(0, 0, 0, 0.7) transparent rgba(0, 0, 0, 0.7) transparent;
  animation: lds-dual-ring 0.5s linear infinite;
  z-index: 16;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.vin-decoder form {
  padding: 24px;
}

.add-vehicle form,
.vin-decoder form {
  margin: 0;
}

.car-filter form {
  select,
  input {
    margin-bottom: 8px;
    width: 100%;
  }
}

.add-vehicle form {
  select,
  input {
    margin-bottom: 8px;
    width: 100%;
  }
}

.vin-decoder form input {
  margin-bottom: 8px;
  width: 100%;
}

.car-filter form input[type='submit'],
.add-vehicle form input[type='submit'],
.vin-decoder form input[type='submit'] {
  height: 48px;
}

.add-vehicle form input[type='submit'],
.vin-decoder form input[type='submit'] {
  margin-bottom: 0 !important;
}

.vin-label {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;

  .vin-text {
    margin-bottom: 8px;
    display: inline-block;
  }
}

.vertical-vin-text {
  padding: 0 8px;
  position: relative;
  z-index: 5;
}

.car-filter {
  &.vertical .vin-label {
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      margin-left: -100%;
      margin-top: -4px;
      height: 1px;
      display: block;
      content: '';
      z-index: 3;
    }
  }

  &.horizontal .vertical-vin-text,
  &.vertical .horizontal-vin-text {
    display: none;
  }

  &.depth-make {
    .model,
    .year,
    .trim {
      display: none;
    }
  }

  &.depth-model {
    .year,
    .trim {
      display: none;
    }
  }
}

.filter-holder {
  position: relative;

  input,
  select {
    position: relative;
    z-index: 3;
  }
}

.car-filter .alert,
.add-vehicle .alert,
.vin-decoder .alert {
  position: absolute;
  bottom: 100%;
  transform: translateY(50%);
  left: 4px;
  background-color: #ffffff;
  padding: 0px 8px;
  font-size: 12px;
  line-height: 22px;
  color: #212121;
  text-align: left;
  margin: 0;
  opacity: 0;
  z-index: 2;
}

.car-filter {
  &.vertical .alert {
    left: 0;
  }

  .vin .alert {
    min-width: 163px;
  }
}

.vin-decoder .alert {
  min-width: 163px;
}

.car-filter .vin .example,
.vin-decoder .alert .example {
  font-size: 12px;
  line-height: 22px;
  min-width: 207px;
}

.car-filter .alert.active,
.add-vehicle .alert.active,
.vin-decoder .alert.active {
  opacity: 1;
  z-index: 15;
  transform: translateY(0);
  transition: opacity 200ms ease-out,
    transform 400ms cubic-bezier(0.19, 1, 0.22, 1);
}

.car-filter form select[name='model'] + .alert.active,
.add-vehicle form select[name='model'] + .alert.active {
  transition-delay: 50ms;
}

.car-filter form select[name='year'] + .alert.active,
.add-vehicle form select[name='year'] + .alert.active {
  transition-delay: 100ms;
}

.car-filter form select[name='trim'] + .alert.active,
.add-vehicle form select[name='trim'] + .alert.active {
  transition-delay: 150ms;
}

.car-filter form select option[data-active='true'],
.add-vehicle form select option[data-active='true'] {
  display: block;
}

.atts-holder span {
  &:not(.default) {
    display: inline-block;
    vertical-align: baseline;
    padding: 4px 16px;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }

  &:only-child {
    display: none !important;
  }
}

#loop-categories .product-category[data-active='false'] {
  display: none;
}

.widget .vin-result li {
  padding: 4px 8px !important;
  margin: 0 4px;
  float: left;
  width: calc(50% - 8px);
  font-size: 14px;
  line-height: 24px;
}

.widget_vin_decoder_widget .vin-result li {
  margin: 0;
  width: 100%;
}

.widget .vin-result li {
  &:nth-child(4n + 1),
  &:nth-child(4n + 2) {
    background-color: #f5f5f5;
  }
}

.widget_vin_decoder_widget .vin-result li {
  &:nth-child(4n + 1),
  &:nth-child(4n + 2) {
    background-color: #ffffff;
  }

  &:nth-child(2n + 2) {
    background-color: #f5f5f5;
  }
}

.widget .vin-result {
  li span {
    font-weight: 600;
  }

  padding: 16px 8px !important;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

.widget_vin_decoder_widget .vin-result {
  margin-top: 16px;
}

.user-vehicle-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.scroll-list {
    max-height: 280px;
    overflow-y: scroll;
  }

  li {
    padding: 2px 0 !important;
    position: relative;

    &:first-child {
      padding-top: 0 !important;
    }

    &:last-child {
      padding-bottom: 0 !important;
    }

    a {
      display: block;
      padding: 16px 16px 16px 56px;
      border-radius: 4px;
      background-color: #f5f5f5;
      color: #212121;
      transition: box-shadow 200ms ease-out, opacity 200ms ease-out,
        transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
      position: relative;
      transform: translateX(0);
      opacity: 1;
    }

    &.new a {
      opacity: 0;
      transform: translateX(24px);
    }

    a {
      &:before {
        content: '\f1b9' !important;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        text-align: center;
        position: absolute;
        top: 12px;
        left: 8px;
        color: #bdbdbd;
      }

      &:hover,
      &.active {
        box-shadow: inset 0 0 0 1px #c0c0c0;
      }

      span {
        display: block;
        font-size: 14px;
        line-height: 24px;
      }

      h6 {
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
}

.add-more-vehicle {
  width: 100%;
  height: 48px;
  margin-top: 4px;
  display: none !important;

  &.visible {
    display: block !important;
  }

  &.active {
    margin-bottom: 8px;
  }

  &.visible + div {
    display: none;
  }
}

.add-vehicle {
  h5 {
    margin-bottom: 0;

    + p {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .remove {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 4px;
    text-align: center;
    font-size: 10px;
    color: #f95759;
    position: absolute;
    top: 12px;
    right: 8px;
    cursor: pointer;
    opacity: 0;
    transition: all 200ms ease-out;
    z-index: 5;
  }
}

.user-vehicle-list li {
  &:first-child .remove {
    top: 8px;
  }

  &:last-child .remove {
    top: 10px;
  }

  &:hover .remove {
    opacity: 1;

    &:hover {
      background-color: #f95759 !important;
      color: #ffffff !important;
    }
  }
}

.save-vehicle {
  position: relative;
  margin-bottom: 16px;
  display: none;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: #212121;
  transition: all 200ms ease-out;

  &.visible {
    display: inline-block;
  }

  &:before {
    background-color: #212121;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    position: absolute;
    content: '';
    display: block;
    transition: all 200ms ease-out;
  }

  &:hover {
    opacity: 0.5;
  }
}

.vehicle-header {
  display: none;

  &.visible {
    display: block;
  }
}

.add-vehicle {
  position: relative;

  .load {
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 99;

    &:before {
      content: '';
      border-radius: 50%;
      width: 48px;
      height: 48px;
      margin-left: -24px;
      margin-top: -24px;
      top: 50%;
      left: 50%;
      position: absolute;
      display: block;
      border: 1px solid #e0e0e0;
      border-left-color: #bdbdbd;
      animation-name: loader-spin;
      animation-duration: 300ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      position: relative;
      display: inline-block;
      vertical-align: top;
    }
  }
}

.et-header-button .nicescroll-rails-hr {
  display: none !important;
}

.vin-result-btn {
  margin-top: 8px;
  margin-bottom: 0;
}

/* Site loaders
  /*-------------*/

.site-loading {
  top: 0;
  left: 0;
  z-index: 9999999999999;
  position: fixed;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  transition: opacity 300ms 500ms ease-out;

  &.animate {
    opacity: 0;
  }
}

.site-loading-bar {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: transparent;
  z-index: 1;
  top: 0;
  left: 0;

  &:after {
    position: relative;
    width: 0;
    height: inherit;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    animation: loadingBar 0.7s ease-out 0.1s forwards;
    z-index: 2;
  }
}

@keyframes loadingBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-ms-keyframes loadingBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.site-loading-content {
  text-align: center;
  width: 100%;
}

/* One page bullets
  ---------------*/

.bullets-container {
  position: fixed;
  z-index: 99;
  top: 50%;
  right: 40px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  visibility: hidden;
  opacity: 0;

  &.animate {
    visibility: visible;
    opacity: 1;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 16px 8px;
    border-radius: 0;

    li {
      margin: 0;
      padding: 0;

      a {
        position: relative;
        text-align: center;

        &:after {
          content: '';
          display: block;
          position: relative;
          width: 1px;
          height: 24px;
          margin: 0 auto 4px auto;
        }
      }

      &:last-child a:after {
        display: none;
      }

      a {
        .tooltip {
          display: block;
          width: max-content;
          position: absolute;
          padding: 6px 8px;
          top: 50%;
          right: 24px;
          letter-spacing: 1px;
          font-weight: 700;
          font-size: 12px;
          line-height: 12px;
          text-transform: uppercase;
          -webkit-transform: translate(-24px, -50%);
          -ms-transform: translate(-24px, -50%);
          transform: translate(-24px, -50%);
          text-indent: 0;
          opacity: 0;
          visibility: hidden;
          -webkit-transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:hover .tooltip {
          opacity: 1;
          visibility: visible;
          -webkit-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
        }
      }
    }
  }
}

/* Posts
  ---------------*/

.blog-layout,
.blog-layout-single,
.product-layout {
  padding-top: 0;
  padding-bottom: 72px;
}

.product-layout-single {
  padding-top: 0;
  padding-bottom: 72px;
  padding-top: 24px;
}

.post-message {
  padding: 24px;
  background-color: #f5f5f5;
  text-align: center;
  color: #9e9e9e;
  max-width: 92%;
  width: 92%;
  margin: 0 auto;

  &.warning {
    background: #fef6df;
    color: #f2ab2e;
  }
}

.blog-layout-list .enovathemes-navigation {
  margin-top: 48px !important;
}

/* Post box
    ---------------*/

.grid .loop-posts,
.et-shortcode-posts .loop-posts,
.related-posts .loop-product {
  margin-left: -12px;
  margin-right: -12px;
}

.chess .loop-posts {
  margin: 0 !important;
}

.loop-posts .post .post-inner {
  overflow: initial;
}

.grid .loop-posts .post .post-inner,
.et-shortcode-posts .post .post-inner,
.related-posts .post .post-inner,
.loop-product .product .post-inner,
body:not(.addon-active) ul.products .post-inner {
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
}

.grid .loop-posts .post .post-inner-wrapper,
.et-shortcode-posts .post .post-inner-wrapper,
.related-posts .post .post-inner-wrapper,
.loop-product .product .post-inner-wrapper,
body:not(.addon-active) ul.products .post-inner-wrapper {
  border: 1px solid #e0e0e0;
}

.chess .loop-posts .post .post-inner {
  padding: 0 !important;
}

.grid #loop-posts .post .post-body,
.et-shortcode-posts .post .post-body,
.chess .loop-posts .post .post-body,
.related-posts .post .post-body,
.loop-product .product .post-body,
.custom-product .post-body,
.related-product .product .post-body,
body:not(.addon-active) ul.products .product .post-body {
  padding: 24px;
  background-color: #ffffff;
}

#loop-posts .post .post-body,
.related-posts .post .post-body,
.et-shortcode-posts .post .post-body {
  box-shadow: inset 0 0 0 1px #e0e0e0;
}

.chess .loop-posts .post {
  .post-body {
    box-shadow: none;
    overflow: hidden;
    display: flex;
    width: 100%;
    align-items: center;
    box-shadow: inset 0 0 0 1px #e0e0e0;

    &:before {
      width: 48px;
      height: 1px;
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      background-color: #ffffff;
      margin-left: -24px;
    }
  }

  &:nth-child(2n + 2) .post-body:before {
    bottom: auto;
    top: 0;
  }

  .post-image:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 5;
    margin-left: -24px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 24px 24px 0 24px;
    border-color: #ffffff transparent transparent transparent;
  }

  &:nth-child(2n + 2) .post-image:before {
    top: auto;
    bottom: 0;
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.loop-product .product-category .post-body {
  padding: 24px;
}

.loop-posts > .post:first-child {
  padding-top: 0 !important;

  .post-inner {
    padding-top: 0 !important;
  }
}

.list .loop-posts .post,
.full .loop-posts .post {
  width: 100% !important;
}

.loop-posts .post-inner,
body:not(.addon-active) ul.products .post-inner,
.loop-posts .post-body,
body:not(.addon-active) ul.products .post-body {
  position: relative;
}

.grid .loop-posts .sticky .post-body {
  overflow: hidden;

  &:before {
    display: block;
    width: 128px;
    height: 128px;
    line-height: 128px;
    opacity: 0.05;
    content: '';
    position: absolute;
    right: 40px;
    bottom: -32px;
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
    background: url(images/sticky.svg) no-repeat left top;
    background-size: 128px 128px;
  }
}

.post-body-inner-wrap {
  position: relative;
}

#loop-posts .post-title,
.et-shortcode-posts .post-title {
  color: #212121;
  margin-bottom: 0;

  a {
    color: #212121;
    margin-bottom: 0;
  }
}

#loop-posts .post-title,
.et-shortcode-posts .post-title,
.related-posts .post .post-title {
  font-size: 18px;
  line-height: 26px;
}

.post-title-section .post-date-inline {
  margin-bottom: 0;
}

.post-meta {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
  position: relative;
  letter-spacing: 0;
  color: #212121;

  > * {
    display: inline-block;
    vertical-align: baseline;
  }
}

.full #loop-posts .format-link .post-meta {
  color: #ffffff !important;

  > {
    *,
    a {
      color: #ffffff !important;
    }
  }
}

.post-meta a {
  color: inherit !important;
}

.blog-layout-list .post-body {
  vertical-align: top;
}

.blog-layout-full .post-body .post-body-inner-wrap {
  padding: 40px;
}

.blog-layout-list .post-body {
  padding: 24px 32px;
}

.blog-layout-full .post-body .post-excerpt,
.blog-layout-list .post-body .post-excerpt {
  font-size: 18px;
  line-height: 32px;
}

.post-body .post-excerpt {
  margin-top: 8px;
  padding-top: 16px;
  border-top: 1px solid #e0e0e0;
  line-height: 24px;
}

.post-title i {
  font-size: 70%;
  vertical-align: middle;
  display: inline;
}

/* Post media
    ---------------*/

.post-date-side {
  border-radius: 4px;
  padding: 12px 14px;
  text-align: center;
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 15;
}

.full #loop-posts .post-date-side {
  padding: 14px 16px;
}

.post-date-side > {
  * {
    display: block;
    color: #ffffff;
    font-weight: 700;
    line-height: 1.2em;
  }

  span:first-child {
    font-size: 18px;
  }
}

.full #loop-posts .post-date-side > span:first-child {
  font-size: 22px;
}

.post-date-side > span:last-child {
  font-size: 12px;
  text-transform: uppercase;
}

.full #loop-posts .post-date-side > span:last-child {
  font-size: 14px;
}

.post-image,
.post-media {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.post-audio {
  margin-bottom: 24px;
  overflow: visible;
}

.post-video {
  overflow: visible;
}

.post-image > a {
  display: block;
  height: 100%;
  width: 100%;
}

.post-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);

  > {
    .post-image-overlay-content {
      text-align: center;
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 0;
      text-align: center;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      display: block;
      transition: all 500ms 250ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    .overlay-read-more,
    .post-image-overlay-content > .overlay-read-more {
      position: absolute;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      top: 50%;
      left: 50%;
      border-radius: 4px;
      margin-left: -20px;
      margin-top: -20px;
      display: block;
      backface-visibility: hidden;
      transition: all 300ms ease-out;
      color: #ffffff;
      box-shadow: inset 0 0 0 1px #ffffff;
      font-size: 17px;
      cursor: pointer;
    }
  }
}

.post-image-overlay-content .post-title {
  color: #ffffff;
  font-size: 32px;
}

.post-image-overlay > {
  .post-image-overlay-content > .overlay-read-more {
    position: relative;
    margin: 0;
    display: inline-block;
    top: auto;
    left: auto;
    vertical-align: middle;
  }

  .overlay-read-more:before,
  .post-image-overlay-content > .overlay-read-more:before {
    width: 100%;
    height: 100%;
    display: block;
  }

  .overlay-read-more:hover,
  .post-image-overlay-content > .overlay-read-more:hover {
    color: #ffffff;
  }
}

.post-media {
  ul {
    list-style: none;
    margin: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }

  .flex-control-nav {
    padding: 0 0 15px 0;
    margin: 0;
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 75;
    backface-visibility: hidden;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover .flex-control-nav {
    bottom: -16px;
  }

  .flex-control-nav li {
    display: inline-block;
    padding: 0 7px;

    a {
      display: block;
      text-indent: -9000em;
      width: 10px;
      height: 10px;
      border-radius: 10px !important;
      outline: 0;
      backface-visibility: hidden;
      transition: all 300ms ease-out;
      background-color: #ffffff;
    }
  }
}

.slick-dots li button {
  display: block;
  text-indent: -9000em;
  width: 10px;
  height: 10px;
  border-radius: 10px !important;
  outline: 0;
  backface-visibility: hidden;
  transition: all 300ms ease-out;
  background-color: #ffffff;
}

.post-media .flex-direction-nav {
  padding: 0;
  margin: 0;
}

.owl-nav {
  position: static !important;
  height: 48px;
}

.arrows-pos-default .owl-nav,
.et-instagram .owl-nav,
.et-gallery .owl-nav {
  height: 0 !important;
}

.post-media .flex-direction-nav li {
  position: absolute;
  top: 50%;
  margin-top: -24px;
  left: -72px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  font-size: 16px !important;
  z-index: 75;
  backface-visibility: hidden;
  transition: left 500ms cubic-bezier(0.19, 1, 0.22, 1),
    right 500ms cubic-bezier(0.19, 1, 0.22, 1), background-color 300ms ease-out !important;
  display: block;
  outline: 0;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  color: #212121 !important;
  padding: 0 !important;
  min-width: 0 !important;
  overflow: hidden;
}

.product-gallery {
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    margin-top: -24px;
    left: -72px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    font-size: 16px !important;
    z-index: 75;
    backface-visibility: hidden;
    transition: left 500ms cubic-bezier(0.19, 1, 0.22, 1),
      right 500ms cubic-bezier(0.19, 1, 0.22, 1),
      background-color 300ms ease-out !important;
    display: block;
    outline: 0;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
    color: #212121 !important;
    padding: 0 !important;
    min-width: 0 !important;
    overflow: hidden;
  }
}

.et-gallery {
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    margin-top: -24px;
    left: -72px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    font-size: 16px !important;
    z-index: 75;
    backface-visibility: hidden;
    transition: left 500ms cubic-bezier(0.19, 1, 0.22, 1),
      right 500ms cubic-bezier(0.19, 1, 0.22, 1),
      background-color 300ms ease-out !important;
    display: block;
    outline: 0;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
    color: #212121 !important;
    padding: 0 !important;
    min-width: 0 !important;
    overflow: hidden;
  }
}

.post-media .flex-direction-nav li:hover {
  color: #ffffff !important;
  border-radius: 4px;
}

.product-gallery {
  .slick-prev:hover,
  .slick-next:hover {
    color: #ffffff !important;
    border-radius: 4px;
  }
}

.et-gallery {
  .slick-prev:hover,
  .slick-next:hover {
    color: #ffffff !important;
    border-radius: 4px;
  }
}

.post-media .flex-direction-nav {
  li a {
    color: inherit;
    line-height: inherit;
    width: inherit;
    height: inherit;
    display: block;
    transition: background-color 300ms ease-out, color 300ms ease-out !important;
  }

  .flex-nav-next {
    left: auto;
    right: -72px;
  }
}

.product-gallery .slick-next,
.et-gallery .slick-next {
  left: auto;
  right: -72px;
}

.post-media:hover .flex-direction-nav .flex-nav-prev,
.product-gallery:hover .slick-prev,
.et-gallery:hover .slick-prev {
  left: 48px;
}

.post-media:hover .flex-direction-nav .flex-nav-next,
.product-gallery:hover .slick-next,
.et-gallery:hover .slick-next {
  left: auto;
  right: 48px;
}

.post-gallery-navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    cursor: pointer;

    img {
      backface-visibility: hidden;
      transition: all 300ms ease-out;
      opacity: 0.2;
    }

    &.flex-active-slide img,
    &:hover img {
      opacity: 1;
    }
  }
}

/* Post read more
    ---------------*/

.post-read-more {
  margin-bottom: 0;
  margin-top: 16px;
}

.comment-reply-link {
  margin-bottom: 0;
  margin-top: 16px;
  margin-top: 0 !important;
}

/* Format corrections
    ---------------*/

.post-sticky {
  padding: 6px 8px 6px 8px;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 15;
}

.format-aside .post-title i {
  font-size: 80%;
}

.blog-layout-list .post-sticky {
  left: 50%;
  right: auto;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.full {
  #loop-posts {
    .et-item-inner {
      overflow: initial !important;
      padding-bottom: 48px;
    }

    .format-link {
      .post-body-inner {
        color: #ffffff;
      }

      .post-title {
        margin-bottom: 0 !important;
      }
    }
  }

  .format-aside .post-body {
    z-index: 5;
    position: relative;
  }
}

.single-post-page > .format-aside .post-body {
  z-index: 5;
  position: relative;
}

.full {
  .format-aside .post-body,
  .format-link .post-body,
  .format-quote .post-body {
    border: none !important;
  }

  .format-link {
    .post-link {
      display: block;
      position: relative;
      color: #ffffff;
    }

    .post-title {
      color: #ffffff !important;
    }
  }

  #loop-posts {
    .format-quote .post-excerpt,
    .format-status .post-excerpt {
      font-size: 24px !important;
      line-height: 40px !important;
      margin-bottom: 0;
    }
  }
}

.single-post-page > {
  .format-quote .post-content > p,
  .format-status .post-content > p {
    font-size: 24px !important;
    line-height: 40px !important;
    margin-bottom: 0;
  }
}

.full #loop-posts .format-status .post-excerpt,
.single-post-page > .format-status .post-excerpt {
  position: relative;
}

.full #loop-posts {
  .format-aside .post-excerpt > p:last-child,
  .format-quote .post-excerpt > p:last-child,
  .format-status .post-excerpt > p:last-child {
    margin-bottom: 0 !important;
  }
}

.single-post-page > {
  .format-aside .post-excerpt > p:last-child,
  .format-quote .post-excerpt > p:last-child,
  .format-status .post-excerpt > p:last-child {
    margin-bottom: 0 !important;
  }
}

.full {
  .format-quote {
    .post-excerpt {
      position: relative;

      &:before {
        font-size: 72px;
        letter-spacing: 0.5px;
        content: '"';
        display: inline-block;
        vertical-align: bottom;
        position: relative;
        -ms-transform: translateY(12px);
        transform: translateY(12px);
        margin-right: 8px;
        color: #000000;
        opacity: 0.1;
      }

      &:after {
        font-size: 72px;
        letter-spacing: 0.5px;
        content: '"';
        display: inline-block;
        vertical-align: bottom;
        position: relative;
        -ms-transform: translateY(12px);
        transform: translateY(12px);
        margin-right: 8px;
        color: #000000;
        opacity: 0.1;
        -ms-transform: rotate(180deg) translateY(12px);
        transform: rotate(180deg) translateY(12px);
        margin-left: 8px;
        margin-right: 0;
      }
    }

    .post-quote-author {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.5px;
      padding-top: 16px;
      color: #9e9e9e;
    }
  }

  .format-status .post-status-author {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    padding-top: 16px;
    color: #9e9e9e;
  }
}

.single-post-page > {
  .format-quote .post-quote-author,
  .format-status .post-status-author {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    padding-top: 16px;
    color: #9e9e9e;
  }
}

.full #loop-posts .format-status .post-body {
  padding: 0 !important;
}

/* Single post
    ---------------*/

.single-post-page > .post {
  &.format-chat > .post-inner > .post-body:first-child,
  &.format-standard > .post-inner > .post-body:first-child,
  &.format-video > .post-inner > .post-body:first-child,
  &.format-audio > .post-inner > .post-body:first-child {
    padding-top: 0 !important;
  }

  &.format-quote > .post-inner > .post-body > .post-body-inner,
  &.format-status > .post-inner > .post-body > .post-body-inner,
  &.format-link > .post-inner > .post-body > .post-body-inner {
    position: relative;
  }

  &.format-aside > .post-inner > .post-body > .post-body-inner {
    position: relative;

    .post-content {
      font-size: 18px;
      line-height: 32px;
    }
  }
}

.post-title-section {
  text-align: left;
  margin-bottom: 24px !important;
}

.single-post-page > .post {
  .post-body {
    position: relative;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 24px;

    .post-content {
      font-size: 18px;
      line-height: 32px;

      > *:last-child {
        margin-bottom: 0 !important;
      }

      .chat {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  > .post-inner .post-media {
    margin-bottom: 32px;
  }
}

.preloader-active
  .single-post-page
  > .post
  > .post-inner
  .post-content
  img[class*='wp-image'] {
  opacity: 0;
  transition: all 200ms 200ms ease-out;
}

.single-post-page > .post > .post-inner {
  .post-media.animate,
  .post-content img[class*='wp-image'].animate {
    opacity: 1;
  }
}

.post-tags-single {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  margin-top: 24px;

  a:last-child {
    margin-right: 0 !important;
  }
}

.post-social-share {
  display: block;
  margin: 32px auto 0 auto;
  width: 200px;
  position: static;

  > .social-links > {
    a {
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-radius: 40px;
      font-size: 18px;
      text-align: center;
      display: inline-block;
      backface-visibility: hidden;
      transition: all 300ms ease-out;
      color: #ffffff;

      &:hover {
        color: #ffffff !important;
        background-color: #212121;
      }
    }

    .post-facebook-share {
      background-color: #3b5998;
    }

    .post-twitter-share {
      background-color: #00aced;
    }

    .post-pinterest-share {
      background-color: #cb2027;
    }

    .post-linkedin-share {
      background-color: #007bb6;
    }
  }
}

.single-post-page > .post + .post-single-navigation {
  margin-top: 0 !important;
  border-top: none !important;
}

.post-author-box {
  margin-top: 32px !important;
  padding: 32px 32px 16px 32px;
  border-radius: 4px;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  > * {
    display: table-cell;
    vertical-align: top;
  }
}

.post-author-info {
  padding-left: 40px;
  top: -8px;
  position: relative;
}

.post-author-gavatar {
  width: 72px;
  height: 72px;
  position: relative;

  img {
    border-radius: 0px;
  }
}

.comment-gavatar img,
.commentlist .avatar {
  border-radius: 0px;
}

.post-author-gavatar img,
.post-author-description p {
  margin-bottom: 0;
}

.post-author-title {
  margin-top: 0;
  margin-bottom: 4px !important;

  a {
    transition: all 300ms;
  }
}

.post-author-description {
  color: $color4;
}

.related-posts-wrapper {
  margin-top: 56px !important;
}

.related-posts {
  position: static !important;
}

.related-posts-title,
.comment-reply-title,
.comments-title,
.upsells > h4,
.crosssells > h4,
.related > h4,
#reply-title {
  font-weight: 700;
}

.related-posts-title,
.comment-reply-title,
.comments-title,
.upsells > h4,
.crosssells > h4,
.related > h4 {
  position: relative;
  margin-bottom: 24px !important;
  font-size: 24px !important;
  position: relative;
}

.related-posts-title {
  margin-bottom: 32px;

  &:before {
    width: 4px;
    height: 24px;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
  }
}

.comment-reply-title:before,
.comments-title:before,
.upsells > h4:before,
.crosssells > h4:before,
.related > h4:before {
  width: 4px;
  height: 24px;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
}

.related-posts {
  > .post {
    float: left;
  }

  .post .post-title {
    margin: 0;

    a {
      color: #212121;
    }
  }

  .owl-controls {
    position: absolute;
    top: 10px;
    right: 0;
  }
}

.related-posts-wrapper {
  position: relative;
}

.related-posts {
  .owl-nav {
    position: absolute !important;
    top: -54px;
    right: 0;

    > * {
      position: static;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      transition: all 300ms ease-out;
      -ms-transform: none;
      transform: none;
    }
  }

  .owl-next {
    margin-left: 10px;
  }
}

ul.chat {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0 0 20px 0;

    > {
      * {
        display: inline-block;
        margin: 0;
        color: #777777 !important;
      }

      p {
        padding: 8px 16px;
        background-color: #f5f5f5;
        border-radius: 2px;
        font-size: 16px;
        line-height: 24px;
      }

      span {
        padding-right: 10px;
        color: #212121 !important;
      }
    }

    &:nth-child(2n + 2) {
      &:after {
        content: '';
        display: table;
        clear: both;
      }

      > {
        * {
          float: right;
          text-align: right;
        }

        span {
          padding-left: 10px;
          padding-right: 0;
        }

        p {
          background-color: #cfe6fe;
          color: #114071 !important;
        }
      }
    }
  }
}

/* Comment form
    ---------------*/

.post-comments-section {
  margin-top: 72px !important;
}

.post-comments-area {
  position: relative;

  .comment-navigation {
    margin: 24px 0;

    > {
      * {
        display: inline-block;
        vertical-align: middle;

        > a {
          display: block;
          transition: all 300ms ease-out;
        }
      }

      .nav-previous > a {
        margin-right: 24px;
      }
    }
  }

  #respond {
    #reply-title small {
      display: block;
      font-weight: normal;
      font-size: 12px;
      position: absolute;
      top: 0;
      right: 0;
    }

    form {
      margin-bottom: 0;
    }

    .form-submit {
      margin-bottom: 0;

      input[type='submit'] {
        margin-bottom: 0;
      }
    }

    form {
      margin-bottom: 24px;
    }
  }

  .comment-form-author,
  .comment-form-email,
  .comment-form-url,
  .respond-textarea {
    display: block;
    width: 100%;
    margin-bottom: 0;
  }

  .comment-form-author input,
  .comment-form-email input,
  .comment-form-url input,
  .respond-textarea textarea {
    width: 100%;
  }
}

/*.post-comments-area #respond {
        margin:24px 0 24px 0;
      }*/

/* Comment list
    ---------------*/

.comment-list {
  padding-top: 24px;
  border-top: 1px solid #e0e0e0;

  ul.children {
    margin: 0;
    list-style: none;
  }

  .comment {
    max-width: 100%;
    position: relative;
  }
}

.commentlist .review {
  max-width: 100%;
  position: relative;
}

.comment-list .comment #respond,
.commentlist .review #respond {
  margin-bottom: 72px;
}

.comment-list .comment .comment-body,
.commentlist .review {
  display: block;
  position: relative;
  padding: 0;
  padding-left: 96px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e0e0e0;
}

.comment-list {
  .comment .comment-body:after {
    content: '';
    display: table;
    clear: both;
  }

  .comment-gavatar,
  .comment-content {
    display: block;
  }
}

.commentlist .review {
  .avatar,
  .comment-text {
    display: block;
  }
}

.comment-list .comment-gavatar,
.commentlist .review .avatar {
  position: absolute;
  top: 8px;
  left: 0;
}

.post-author-ind {
  position: absolute;
  top: 90px;
  left: 0;
  width: 72px;
  text-align: center;
  line-height: 16px;
}

.comment-list .comment-content {
  max-width: 100%;
  padding: 0;
  position: relative;
}

.commentlist .review .comment-text {
  max-width: 100%;
  padding: 0;
  position: relative;
  position: relative;
  width: 100%;

  .star-rating {
    position: absolute;
    top: 12px;
    right: 0;
  }
}

.comment-list .comment .comment-gavatar {
  width: 72px;
  height: 72px;
}

.commentlist .review .avatar {
  width: 72px;
  height: 72px;
  max-width: none;
}

.comment-list .comment .comment-text p:last-child,
.commentlist .review .description p:last-child {
  margin-bottom: 0;
}

.comment-author a {
  color: inherit;
}

.comment-meta .comment-author {
  margin-bottom: 0px;
}

.woocommerce-review__author {
  margin-bottom: 0px;
  display: block;
}

.comment-meta .comment-author cite {
  font-style: inherit;

  a {
    color: inherit;
  }
}

cite a {
  color: inherit;
}

.comment-meta {
  .comment-author span {
    display: inline-block;
    padding: 8px 10px;
    font-size: 70%;
    text-transform: none !important;
    vertical-align: middle;
    position: relative;
    top: -2px;
    line-height: 70%;
  }

  .comment-date-time {
    display: inline-block;
    padding-top: 0px;
    padding-bottom: 10px;

    a {
      color: inherit;
    }
  }

  padding-right: 80px;
}

.review .meta {
  margin-bottom: 0;
}

.comment-meta .replay {
  display: block;
  position: absolute;
  top: 24px;
  right: 24px;
  margin-bottom: 16px;
}

.comment-reply-link {
  margin: 0;
}

.comment-content .edit-link a a {
  margin: 10px 0px 0 0;
}

.post-author-info-title a {
  color: inherit;
  transition: all 300ms;
}

.comment-list .pingback {
  padding: 4px 0;

  + .comment {
    margin-top: 48px;
  }

  .comment-content {
    .comment-edit-link {
      margin-top: 0 !important;
      padding: 0 8px !important;
      min-width: 0;
    }

    .edit-link {
      position: absolute;
      top: 50%;
      right: 0;
      -ms-transform: translateY(-65%);
      transform: translateY(-65%);
    }

    border-top: none;
    border-left: none;
    border-right: none;
    padding: 8px 56px 16px 0 !important;
    position: relative;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

/* Filter
  ---------------*/

.enovathemes-filter {
  position: relative;
  width: 50% !important;
  display: block !important;
  text-align: left !important;
  margin-bottom: 0 !important;
  float: left;

  > .container {
    max-width: 100% !important;
  }

  .filter-toggle {
    display: inline-block;
    text-align: center;
    color: #ffffff;
    position: relative;
    padding: 0 56px 0 16px;
    height: 48px;
    line-height: 48px;
    border-radius: 4px 0 0 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;

    > span {
      display: block;
      font-size: 22px;
      line-height: 48px;
      position: absolute;
      top: -1px;
      right: 6px;
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
    }

    &.active > span {
      font-size: 18px;

      &:before {
        content: '\e910' !important;
      }
    }
  }

  .catalog-container {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 200%;
    background-color: #ffffff;
    border-radius: 0 4px 4px 4px;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
    padding: 32px 16px;
    z-index: -1;
    visibility: hidden;
    opacity: 0;

    &.active {
      z-index: 99999999999999999999;
      transition: opacity 150ms ease-out;
      opacity: 1;
      visibility: visible;
    }
  }

  .filter-toggle.active {
    z-index: 99999999999999999999;
    border-radius: 4px 4px 0 0;
  }

  .catalog-container {
    + .catalog-overlay {
      opacity: 0;
      transition: opacity 150ms ease-out;
    }

    &.active + .catalog-overlay {
      position: fixed;
      z-index: 99;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      opacity: 1;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.shop-cat-list {
  list-style: none;
  margin: 0;
  padding: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.shop-cat-list-filter {
  margin-bottom: 24px;
}

.shop-cat-list {
  padding: 16px;
}

.shop-cat-list-wrapper {
  display: none;
  border-bottom: 1px solid #e0e0e0;
}

.shop-cat-list-title {
  padding: 10px 16px;
  margin-bottom: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  position: relative;
  cursor: pointer;

  span {
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -8px;

    &:before {
      width: 100%;
      height: 2px;
      content: '';
      display: block;
      top: 50%;
      left: 0;
      margin-top: -1px;
      position: absolute;
      transition: opacity 50ms ease-out,
        transform 150ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:after {
      width: 2px;
      height: 100%;
      content: '';
      display: block;
      top: 0;
      left: 50%;
      margin-left: -1px;
      position: absolute;
      transition: opacity 50ms 50ms ease-out,
        transform 150ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  &.active span {
    &:after,
    &:before {
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }

    &:after {
      opacity: 0;
    }
  }
}

.enovathemes-filter ul li,
.shop-cat-list li {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
}

.enovathemes-filter ul li a {
  margin: 0;
  display: block;
  padding: 4px 16px;
  line-height: 22px;
}

.shop-cat-list li a {
  margin: 0;
  display: block;
  padding: 4px 16px;
  line-height: 22px;
  padding: 4px 0;
}

.enovathemes-filter .filter-wrapper .catalog-container > ul,
.shop-cat-list {
  display: grid;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
}

.enovathemes-filter.column-5 .filter-wrapper .catalog-container > ul,
.shop-cat-list.column-5 {
  grid-template-columns: repeat(5, 5fr);
}

.enovathemes-filter.column-4 .filter-wrapper .catalog-container > ul,
.shop-cat-list.column-4 {
  grid-template-columns: repeat(4, 4fr);
}

.enovathemes-filter.column-3 .filter-wrapper .catalog-container > ul,
.shop-cat-list.column-3 {
  grid-template-columns: repeat(3, 3fr);
}

.enovathemes-filter.column-2 .filter-wrapper .catalog-container > ul,
.shop-cat-list.column-2 {
  grid-template-columns: repeat(2, 2fr);
}

.enovathemes-filter.column-1 .filter-wrapper .catalog-container > ul,
.shop-cat-list.column-1 {
  grid-template-columns: repeat(1, 1fr);
}

.filter-wrapper .catalog-container > ul > li,
.shop-cat-list li {
  align-items: flex-start;
}

.filter-wrapper .catalog-container > ul > li > a,
.shop-cat-list > li > a {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

.filter-wrapper .catalog-container > ul ul ul,
.shop-cat-list > ul ul ul {
  padding-left: 24px;
  font-size: 14px;
}

.woocommerce-before-shop-loop.filter {
  .woocommerce-result-count {
    float: right !important;
    line-height: 26px;
  }

  .woocommerce-ordering {
    float: right !important;
    line-height: 26px;
    padding-right: 24px;
    margin-right: 24px;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 12px;
      right: 0;
      width: 1px;
      height: 24px;
      background-color: #e0e0e0;
    }

    select {
      background-color: transparent;
      box-shadow: none;
      border: none;
      padding: 0 !important;
      width: 150px !important;
      min-width: 0 !important;
      font-weight: 600;
    }
  }
}

/* Woocommerce
  ---------------*/

.woocommerce-store-notice.demo_store {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  padding: 24px;
  color: #ffffff !important;
  text-align: center;
  margin: 0;

  a {
    color: #ffffff !important;
  }
}

.woocommerce-before-shop-loop {
  text-align: left;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 24px;

  &.filter {
    padding: 0;
  }
}

.woocommerce .term-description {
  padding-bottom: 48px;
}

.woocommerce-before-shop-loop > * {
  display: block;
  vertical-align: middle;
  margin: 0;
}

.woocommerce-result-count {
  font-size: 16px;
  line-height: 26px;
  color: $color4;
  font-weight: 600;
  position: relative;
  margin-bottom: 8px;
  display: inline-block !important;

  &:only-child {
    -ms-transform: none !important;
    transform: none !important;
    top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.woocommerce-ordering > select {
  margin-bottom: 0;
}

#loop-categories + #loop-products {
  margin-top: 24px;
  padding-top: 48px;

  &:before {
    width: calc(100% - 24px);
    height: 1px;
    content: '';
    background-color: #e0e0e0;
    position: absolute;
    top: 0;
    left: 12px;
  }
}

#loop-products > .ajax-scroll-overlay {
  background-color: #ffffff;
}

.loop-product,
ul.products,
.loop-product-category {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.loop-product li,
ul.products li,
.loop-product-category li,
.custom-product {
  display: block;
  text-align: left !important;
  padding: 0;
}

.product-image-gallery {
  position: relative;

  > {
    * {
      transition: all 300ms ease-out !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }

    .visible {
      opacity: 1 !important;
      visibility: visible !important;
    }

    img {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      -ms-transform: none !important;
      transform: none !important;
    }
  }
}

.product {
  .onsale {
    position: absolute;
    left: 12px !important;
    top: 12px !important;
    height: 32px;
    border-radius: 32px;
    line-height: 32px;
    padding-left: 8px;
    padding-right: 8px;
    min-width: 56px;
    z-index: 1;
    text-align: center;
    display: table;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;

    > span {
      display: table-cell;
      vertical-align: middle;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .post-inner {
    overflow: visible !important;
  }

  .post-body-inner,
  .post-body-inner-wrap {
    position: static;
  }

  .post-body-inner:before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
    margin-bottom: 24px;
  }

  .price {
    display: inline-block;
    font-weight: 700;
    font-size: 17px;
  }
}

.widget .woocommerce-Price-amount {
  display: inline-block;
  font-weight: 700;
  font-size: 17px;
}

.product .price {
  display: block;
}

.cart-data .woocommerce-Price-amount {
  display: inline;
}

.product .price del {
  font-size: 80%;
  color: #bdbdbd !important;
}

.widget.woocommerce del {
  font-size: 80%;
  color: #bdbdbd !important;
  font-size: 14px !important;
  display: inline-block;

  + ins {
    display: inline-block;
  }

  .woocommerce-Price-amount {
    color: inherit;
    font-size: inherit;
    text-decoration: inherit;
  }
}

.product {
  .button,
  .added_to_cart {
    position: relative;
    text-align: center !important;
    width: 100%;
    max-width: 232px;
    margin: 8px auto 0 auto;
  }
}

.dgwt-wcas-product-details .added_to_cart {
  margin-top: 0 !important;
}

.product {
  .summary .button {
    max-width: 186px;
  }

  .compare {
    width: 48px;
    height: 48px;
    line-height: 48px !important;
    text-indent: -9000em;
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    background-color: #f5f5f5 !important;
    z-index: 1;
  }

  .summary .compare {
    position: relative;
    width: auto;
    text-indent: 0;
    display: inline-block;
    margin-top: 8px !important;
    padding: 0 16px !important;
  }

  .compare:before {
    text-indent: 0;
    display: block;
    width: 100%;
    height: 100%;
    line-height: 48px;
    font-size: 16px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: inherit;
    color: #212121;
    z-index: 2;
    transition: all 300ms ease-out;
  }

  .summary .compare:before {
    width: auto;
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
    background-color: transparent !important;
    margin-right: 8px;
    top: -1px;
  }

  .compare {
    &:hover:before,
    &.added:before {
      background-color: #212121 !important;
      color: #ffffff !important;
    }
  }

  .summary .compare {
    &:hover,
    &.added {
      background-color: #212121 !important;
      color: #ffffff !important;
    }

    &:hover:before,
    &.added:before {
      background-color: transparent !important;
    }
  }

  .compare:after {
    display: none !important;
  }

  // .button:hover {
  //   background-color: #212121;
  // }
}

.added_to_cart:hover {
  background-color: #212121;
}

.post-body .yith-wcqv-button {
  display: none;
}

.product .post-title {
  margin: 0 auto 8px auto;
  color: #212121;
  font-weight: 600;

  a {
    color: #212121;
    font-weight: 600;
  }
}

.small .product .post-title {
  font-size: 16px !important;
  line-height: 24px !important;
}

.product .product-quick-view {
  position: absolute !important;
  left: 50%;
  top: 50%;
  width: 100% !important;
  max-width: 186px;
  height: auto !important;
  text-align: center;
  transition: all 300ms ease-out !important;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0 !important;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
  opacity: 0;
  text-transform: none;
  border-radius: 0;
  font-weight: 400 !important;
}

.overlay-fade .product .product-quick-view,
.overlay-fade-zoom .product .product-quick-view,
.overlay-move .product .product-quick-view,
.overlay-fade-zoom-extreme .product .product-quick-view {
  display: none;
}

.product .product-quick-view:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.post-image:hover .product-quick-view {
  opacity: 1;
}

.transform .product .post-image {
  overflow: hidden !important;
}

/* Woocommerce add_to_wishlist
    ---------------*/

.loop-product .yith-wcwl-add-to-wishlist,
.custom-product .yith-wcwl-add-to-wishlist,
.loop-product .wooscp-btn,
.custom-product .wooscp-btn {
  margin: 0 !important;
  height: 24px;
  width: 24px;
  line-height: 24px;
  position: absolute !important;
  top: 16px;
  right: 16px;
  z-index: 2;
  opacity: 0;
  -ms-transform: translateY(16px);
  transform: translateY(16px);
  transition: none !important;
}

.summary .yith-wcwl-add-to-wishlist {
  width: auto !important;
}

.loop-product .product:hover .yith-wcwl-add-to-wishlist,
.custom-product .product:hover .yith-wcwl-add-to-wishlist,
.loop-product .product:hover .wooscp-btn,
.custom-product .product:hover .wooscp-btn {
  opacity: 1;
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition: opacity 100ms ease-out,
    transform 600ms cubic-bezier(0.19, 1, 0.22, 1) !important;
}

.loop-product .wooscp-btn,
.custom-product .wooscp-btn {
  background: none !important;
  background-color: transparent !important;
  top: 52px;
}

.yith-wcwl-add-to-wishlist {
  .feedback,
  .ajax-loading,
  .active .ajax-loading {
    display: none !important;
  }

  a {
    display: block !important;
    text-align: center;
    position: relative;
    transition: all 300ms ease-out;
    color: #bdbdbd !important;
    text-indent: -9000em;
    padding: 0 !important;
  }
}

.loop-product .wooscp-btn,
.custom-product .wooscp-btn {
  display: block !important;
  text-align: center;
  position: relative;
  transition: all 300ms ease-out;
  color: #bdbdbd !important;
  text-indent: -9000em;
  padding: 0 !important;
}

.yith-wcwl-add-to-wishlist a {
  height: 24px;
  width: 24px;
  line-height: 24px;

  &:before,
  &:after {
    height: 24px;
    width: 24px;
    line-height: 24px;
  }
}

.loop-product .wooscp-btn:before,
.custom-product .wooscp-btn:before {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.yith-wcwl-add-to-wishlist a:before,
.loop-product .wooscp-btn:before,
.custom-product .wooscp-btn:before {
  display: block;
  text-indent: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
}

.yith-wcwl-add-to-wishlist a {
  &:after {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    content: '';
    visibility: hidden;
  }

  &.active {
    &:after {
      visibility: visible;
    }

    &:before {
      visibility: hidden;
    }
  }
}

.product-layout-single .summary .yith-wcwl-add-to-wishlist a {
  text-align: left;
  display: inline-block !important;
  padding-left: 32px !important;
  text-indent: 0 !important;
  width: auto !important;
  margin-top: 8px !important;
  color: $color4 !important;

  &:hover {
    background-color: transparent !important;
  }
}

#yith-wcwl-popup-message {
  text-align: center !important;
  border: none !important;
  font-size: 16px !important;
  line-height: 24px !important;
  min-width: 200px;
  border-radius: 0 !important;
  margin-left: -100px !important;
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999999999999999999999999999;
  background: #ffffff;
  padding: 8px 16px;
  border-radius: 4px;
}

.wishlist_table .yith-wcqv-button {
  display: inline-block;
  vertical-align: middle;
}

.woocommerce table.wishlist_table tfoot td {
  display: none;
}

/* Woocommerce AJAX add to cart
    ---------------*/

.post-inner-wrapper {
  position: relative;
}

.ajax_add_to_cart.added {
  display: none !important;
}

.added_to_cart {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.ajax-add-to-cart-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 55;
  display: none;
  background-color: #ffffff;

  &.active {
    display: block;
  }

  .circle-loader {
    display: block;
    border: 1px solid #bdbdbd;
    animation-name: loader-spin;
    animation-duration: 300ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
  }
}

.yith-wcwl-add-to-wishlist a:after {
  display: block;
  border: 1px solid #bdbdbd;
  animation-name: loader-spin;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #eeeeee;
}

.ajax-add-to-cart-loading {
  .circle-loader {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-left: -24px;
    margin-top: -24px;
    top: 50%;
    left: 50%;
    position: absolute;

    &:after {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      margin-left: -24px;
      margin-top: -24px;
      top: 50%;
      left: 50%;
      position: absolute;
    }
  }

  .load-complete {
    animation: none;
    transition: border 500ms ease-out;
  }

  .checkmark {
    display: none;
  }

  .load-complete .checkmark {
    display: block;
  }

  .checkmark {
    &.draw:after {
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      opacity: 1;
      height: 24px;
      width: 16px;
      transform-origin: left top;
      content: '';
      left: 10px;
      top: 21px;
      position: absolute;
    }
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 16px;
    opacity: 1;
  }

  40% {
    height: 24px;
    width: 16px;
    opacity: 1;
  }

  100% {
    height: 24px;
    width: 16px;
    opacity: 1;
  }
}

/* Woocommerce Quick view
    ---------------*/

#yith-quick-view-modal .yith-wcqv-main {
  box-shadow: none !important;
  transform: translateY(0) !important;
}

#yith-quick-view-content {
  .product.type-product:not(tr) {
    display: flex;
    align-content: center;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .product-status {
    top: 0 !important;
    left: 0 !important;
  }
}

#yith-quick-view-close {
  top: 0px !important;
  right: 8px !important;
  border: none !important;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 16px !important;
  text-indent: -9000em;
  text-align: center;
  transition: none;

  &:before {
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    text-indent: 0;
    transition: all 300ms ease-out;
    backface-visibility: hidden;
    width: inherit;
    height: inherit;
    line-height: inherit;
  }
}

#yith-quick-view-content .summary {
  position: relative;
}

#yith-quick-view-modal {
  .yith-wcqv-wrapper {
    left: 50% !important;
    top: 50% !important;
    position: absolute !important;
    display: block !important;
  }

  .woocommerce-product-gallery {
    max-width: 600px;
    width: 100%;
    margin-bottom: 0 !important;
  }
}

/* Woocommerce compare
    ---------------*/

#cboxTopLeft,
#cboxTopCenter,
#cboxTopRight,
#cboxBottomLeft,
#cboxBottomCenter,
#cboxBottomRight,
#cboxMiddleLeft,
#cboxMiddleRight {
  display: none !important;
}

#cboxClose {
  background: none !important;
  width: 48px !important;
  height: 48px !important;
  line-height: 48px !important;
  bottom: -48px;
  background-color: #ffffff !important;
  transition: all 300ms ease-out;

  &:before {
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    color: #212121 !important;
    font-size: 16px;
    text-align: center;
    line-height: 48px;
    text-indent: 0 !important;
  }

  &:hover:before {
    color: #ffffff !important;
  }
}

.wooscp-area .wooscp-inner .wooscp-table .wooscp-table-inner {
  .wooscp-table-items table {
    thead tr th {
      text-transform: none !important;
      font-size: 16px !important;
      line-height: 22px !important;
      font-weight: 600 !important;
    }

    tbody tr:hover td {
      background-color: #ffffff !important;
    }
  }

  .wooscp-table-close {
    padding: 10px !important;
  }
}

/* Woocommerce minicart
    ---------------*/

.cart-box {
  display: none;
  position: absolute;
  width: 320px;
  height: auto;
  padding: 24px;
  right: 0px;
  z-index: 15;
  opacity: 1;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  backface-visibility: hidden;

  &.scroll-cart .cart_list {
    max-height: 300px;
    overflow-y: scroll;
  }

  .widget_shopping_cart_content > p:only-child {
    margin-bottom: 0;
  }
}

.logopos-right .cart-box,
.menupos-right .cart-box {
  left: 0px;
  right: auto;
}

.cart-box {
  &.active {
    display: block;
  }

  .widget_shopping_cart {
    margin: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;

    .widgettitle {
      display: none !important;
    }
  }
}

.woocommerce-mini-cart-info {
  margin: 0 !important;
}

.woocommerce-mini-cart__total {
  padding-bottom: 24px;
  margin-bottom: 24px !important;
  padding-top: 24px;
  position: relative;

  &:before {
    position: absolute;
    display: block;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
  }
}

.cart-box .woocommerce-mini-cart__total:before {
  border: none !important;
}

.widget_shopping_cart {
  li {
    position: relative;
    margin: 0 0 10px 0 !important;
    padding: 0 0 10px 0 !important;
  }

  .cart-product-body {
    position: relative;
    display: table;
    vertical-align: top;
    width: 100%;
  }

  .cart-product-image,
  .cart-product-content {
    display: table-cell;
    vertical-align: top;
  }

  .cart-product-image {
    padding-top: 4px;
    width: 72px;
    height: 72px;

    img {
      border: 1px solid #e0e0e0;
    }
  }

  .cart-product-content {
    padding-left: 16px;
    padding-right: 8px;
  }

  .cart-product-title {
    margin: 0 0 2px 0 !important;
    font-family: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: 0;
  }

  .cart-product-data {
    padding-top: 4px;
  }

  .cart-product-title {
    a {
      color: inherit;
      transition: all 300ms ease-out;
    }

    &:hover > a {
      opacity: 0.7;
    }
  }

  .cart_list li .remove {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 20px !important;
    text-align: center;
    position: absolute;
    top: 0px !important;
    right: 0;
    display: block;
    transition: all 300ms ease-out;
  }
}

.yith-woocompare-widget ul.products-list li .remove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 20px !important;
  text-align: center;
  position: absolute;
  top: 0px !important;
  right: 0;
  display: block;
  transition: all 300ms ease-out;
}

.widget_shopping_cart .cart_list li .remove:hover,
.yith-woocompare-widget ul.products-list li .remove:hover {
  opacity: 0.7;
}

.widget_shopping_cart {
  .cart-data {
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;

    li {
      margin: 0 !important;
      padding: 0 !important;
      width: 100%;
      vertical-align: top;

      > {
        * {
          padding: 0 !important;
          display: inline-block;
        }

        .attribute {
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }
  }

  .quantity {
    display: block;
  }
}

/* Woocommerce cart/checkout...
    ---------------*/

.shop_table {
  .image-container {
    max-width: 144px !important;

    img {
      max-width: 144px !important;
    }
  }

  width: 100%;
  border-collapse: collapse;
  vertical-align: middle;

  .product-remove,
  .product-thumbnail,
  .product-price,
  .product-subtotal,
  th {
    text-align: center;
  }
}

.woocommerce-orders-table {
  text-align: center;
}

.cart_totals th,
.woocommerce-checkout-review-order-table th,
.woocommerce-table--order-details th,
.customer_details th {
  text-align: left;
}

.woocommerce-cart-form__contents tbody tr:last-child {
  background-color: transparent !important;
}

.shop_table {
  .product-quantity input {
    margin: 0 auto !important;
    max-width: 88px;
    padding: 8px !important;
  }

  .variation {
    margin: 0;
    padding: 0;
    border: none;
    list-style: none;
  }
}

.wc-item-meta {
  margin: 0;
  padding: 0;
  border: none;
  list-style: none;

  li {
    padding: 0;
  }
}

.shop_table .variation > dt {
  text-transform: capitalize;
  padding-left: 16px;
}

.wc-item-meta strong {
  text-transform: capitalize;
  padding-left: 16px;
  padding-left: 0;
}

.shop_table .variation {
  > {
    dt:first-child {
      padding-left: 0;
    }

    * {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding: 0;
      float: none;
      width: auto;
    }
  }

  p {
    margin: 0;
    display: inline-block;
  }
}

.wc-item-meta li p {
  margin: 0;
  display: inline-block;
  padding-left: 16px;
}

.shop_table {
  .product-remove a {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 24px;
    transition: all 300ms ease-out;
  }

  input[name='update_cart'],
  .coupon input[name='apply_coupon'] {
    margin: 0 !important;
  }

  td.actions {
    padding: 48px 0 0 0 !important;
    background-color: transparent !important;
    border: none !important;
  }

  .coupon {
    padding: 24px;
    margin-bottom: 48px;
  }

  .product-thumbnail {
    img {
      transition: all 200ms 0ms ease-out !important;
      max-width: 150px;
    }

    a:hover img {
      opacity: 0.7 !important;
    }
  }
}

.select2-container .select2-selection--single {
  height: 48px !important;
  position: relative;
}

.select2-container--default {
  .select2-selection--single,
  .select2-search--dropdown .select2-search__field {
    margin: 0 !important;
  }

  .select2-selection--single .select2-selection__arrow {
    height: 24px !important;
    width: 24px !important;
    position: absolute !important;
    top: 50% !important;
    margin-top: -12px !important;
    right: 0 !important;
  }
}

.wc_payment_methods,
#shipping_method {
  list-style: none;
  margin: 0 0 32px 0;
  padding: 0;
  width: 100%;
}

.wc_payment_methods > li {
  margin: 0;
  padding: 24px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 0;
}

#shipping_method > li {
  margin: 0;
  padding: 24px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.wc_payment_methods {
  label,
  input[name='payment_method'] {
    display: inline-block !important;
    vertical-align: top;
  }
}

#shipping_method > li {
  label,
  input[name='payment_method'] {
    display: inline-block !important;
    vertical-align: top;
  }
}

.wc_payment_methods label,
#shipping_method > li label {
  padding-left: 8px;
}

.wc_payment_methods img {
  width: auto !important;
  display: block !important;
}

.woocommerce-order-overview {
  list-style: none;
  margin: 0;
  padding: 0 0 32px 0;

  li {
    padding: 8px !important;
  }
}

.woocommerce-page address {
  padding: 16px 24px;
}

.shop_table .product-name {
  > a:not(.yith-wcqv-button) {
    font-size: 16px !important;
    line-height: 22px !important;
    font-weight: 600 !important;
  }

  max-width: 240px;

  .button {
    margin: 8px 0 0 0 !important;
    display: block;
    max-width: 120px;
  }
}

.woocommerce-page .wishlist-title {
  display: none;
}

.woocommerce {
  table.wishlist_table {
    font-size: 100%;
  }

  .wishlist_table {
    td.product-add-to-cart a {
      display: inline-block !important;
    }

    .dateadded {
      display: inline-block;
      padding-bottom: 16px;
    }

    .ajax_add_to_cart.added {
      display: none !important;
    }
  }
}

.wishlist_table .ajax_add_to_cart + .product .button {
  margin-right: 0 !important;
}

.woocommerce-MyAccount-navigation {
  padding: 24px !important;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin-bottom: 32px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      background-color: #ffffff;
      padding: 2px 0 !important;

      &:last-child {
        border-bottom: none;
      }

      a {
        display: block;
        font-weight: 600 !important;
      }
    }
  }
}

.shop_table .woocommerce-button {
  margin: 0 !important;
}

/* Woocommerce single
    ---------------*/

.product {
  .single-product-wrapper {
    position: relative;
  }

  .summary {
    .price {
      font-size: 24px;
      line-height: 26px;
      letter-spacing: 0;
      margin-bottom: 0;
      font-weight: 600 !important;

      del {
        font-size: 14px !important;
        line-height: 26px !important;
      }

      ins {
        font-size: inherit !important;
        margin-left: 4px;
        display: inline-block;
        vertical-align: baseline;
      }
    }

    .product_title {
      margin-top: 0 !important;
      margin-bottom: 0;
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
    }
  }
}

.woocommerce-product-details__short-description {
  font-size: 18px;

  p {
    margin-bottom: 0;
  }
}

.product .summary {
  form {
    &.variations_form.cart,
    &.cart {
      width: 100%;
      margin: 24px auto 0 auto;
      overflow-x: hidden !important;
    }
  }

  table {
    width: auto;

    input {
      margin: 0 !important;
    }
  }

  .quantity {
    input {
      margin-bottom: 4px;
      text-align: center;
      height: 44px;
      vertical-align: top;
      display: inline-block;
    }

    button {
      vertical-align: top;
      display: inline-block;
    }
  }

  table.variations {
    margin-top: 10px;
    margin-bottom: 0;
    width: auto;

    tr {
      padding: 0;
    }

    th {
      padding: 0;
      vertical-align: text-top;
    }

    td {
      padding: 0;
      vertical-align: text-top;
      text-align: right;
      border: none;
    }

    label,
    input,
    select {
      margin: 0;
    }

    label {
      padding-right: 16px;
      display: inline-block;
    }

    input {
      width: 256px;
      max-width: 100%;
      margin-bottom: 16px;
    }

    select {
      width: 256px;
      max-width: 100%;
      margin-bottom: 16px;
      display: inline-block;
    }

    .reset_variations {
      vertical-align: middle;
      margin-left: 8px;
      display: inline-block;
    }
  }

  .product_meta {
    margin-top: 16px;
    margin-bottom: 0;
    line-height: 26px;
  }
}

.product_meta > * {
  display: block;
}

.product {
  .summary {
    .post-social-share {
      float: none !important;
      margin: 24px 0 0 0 !important;
      width: auto !important;
      position: relative !important;

      > .social-links > a {
        display: inline-block;
        margin-bottom: 6px !important;
        margin-right: 6px !important;
        display: inline-block !important;
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 18px;

        &:hover {
          color: #ffffff !important;
          background-color: #212121;
        }
      }
    }

    .stock {
      font-weight: 600;
    }

    .in-stock {
      color: #53c95f;
      margin-bottom: 0;
    }
  }

  .stock-level span {
    display: inline-block;
    vertical-align: top;
    font-weight: 600;
  }

  .single-product-progress,
  .stock-level {
    max-width: 280px !important;
  }

  .single-product-progress .track {
    background-color: #f5f5f5;
  }

  .summary .et-timer {
    text-align: left;

    div {
      padding: 0 12px !important;
      min-width: 48px;
    }

    li {
      &:first-child div {
        padding-left: 0 !important;
      }

      &:last-child div {
        padding-right: 0 !important;
      }
    }

    .timer-count {
      font-size: 20px;
      line-height: 48px;
      height: 48px;
      min-width: 64px;
      border-radius: 4px;
      background-color: #f5f5f5;
      color: #212121;
      position: relative;

      &:before {
        display: block;
        width: 4px;
        height: 100%;
        content: ':';
        position: absolute;
        top: 0;
        right: -14px;
        text-align: center;
      }
    }

    li:last-child .timer-count:before {
      display: none;
    }

    .timer-title {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      color: #212121;
    }
  }
}

.single-product-wrapper {
  .onsale {
    width: 80px !important;
    height: 80px !important;
    border-radius: 80px !important;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 700;
    top: 15% !important;
    left: auto !important;
    right: 55% !important;
  }

  .onsale-inner {
    display: inline-block;
    vertical-align: middle;
  }
}

form.cart {
  margin: 0;

  .quantity {
    input {
      margin-bottom: 0;
      width: 80px;
      text-align: center;
    }

    display: inline-block;
    margin: 0 !important;
    vertical-align: top;
    min-height: 44px;
  }

  button {
    display: inline-block;
    margin: 0 !important;
    vertical-align: top;
    min-height: 44px;
  }
}

.woocommerce-product-gallery {
  position: relative;
  margin-bottom: 48px !important;
}

.product .single-product-wrapper .summary {
  position: relative;
  margin-bottom: 48px !important;
  padding: 24px;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  border-radius: 4px;
}

.woocommerce-product-gallery .flex-viewport {
  margin-bottom: 4px;
}

.woocommerce-product-gallery__wrapper {
  max-width: none !important;
}

.woocommerce-product-gallery__trigger {
  position: absolute;
  top: 16px;
  right: 48px;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 32px;
  font-size: 16px;
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 300ms ease-out;
  text-align: center;
  z-index: 5;
}

#yith-quick-view-content .woocommerce-product-gallery__trigger {
  right: 16px;
}

.woocommerce-product-gallery__trigger {
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  img {
    display: none !important;
  }
}

.woocommerce-product-gallery .flex-control-nav {
  list-style: none;
  margin-bottom: 0;
  text-align: center;

  li {
    display: inline-block;
    margin-top: 4px;
    margin-right: 8px;
    cursor: pointer;
  }
}

.product .single_add_to_cart_button {
  position: relative;
  backface-visibility: hidden;
  overflow: hidden;

  &:hover {
    color: #ffffff !important;
  }

  &:after {
    height: 100% !important;
    bottom: 0 !important;
    left: 0 !important;
    backface-visibility: hidden;
  }
}

.single-title-wrapper {
  margin-bottom: 8px;

  > {
    * {
      margin-bottom: 0;
      display: inline-block !important;
      vertical-align: middle;
    }

    .onsale {
      position: static;
      margin-right: 16px;
    }
  }
}

.related-products {
  padding-top: 72px;
  border-top: 1px solid #e0e0e0;

  + .related-products {
    margin-top: 48px;
  }
}

.upsells .et-item-set:not(.effect-none) .et-item-inner,
.crosssells .et-item-set:not(.effect-none) .et-item-inner,
.related .et-item-set:not(.effect-none) .et-item-inner {
  opacity: 1;
}

.woocommerce-product-gallery .flex-control-nav li img {
  box-shadow: 0 0 0 1px #e0e0e0;
}

.timer-label {
  margin-bottom: 0;
  font-weight: 600;
}

.woocommerce-product-details__short-description + .timer-label {
  padding-top: 16px;
}

.woocommerce-variation-add-to-cart {
  padding-top: 8px;
}

/* Woocommerce rating
    ---------------*/

.star-rating {
  overflow: hidden;
  position: relative;
  height: 12px;
  line-height: 12px;
  font-size: 12px;
  width: 60px;
  color: #ffd800;
  transition: all 0.3s ease-out;
  margin: 0 0 24px 0;
}

.widget .star-rating {
  color: #ffd800;
}

.comment-text .star-rating {
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  width: 100px;
}

.star-rating-wrap {
  line-height: 12px;
  margin-bottom: 8px;

  strong {
    display: none;
  }

  > {
    * {
      display: inline-block;
      vertical-align: middle;
      margin: 0 !important;
    }

    span {
      font-size: 12px;
      line-height: 12px;
      margin-left: 4px;
    }
  }
}

.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 12px;
  display: block;
}

.comment-text .star-rating span {
  padding-top: 20px;
}

.star-rating {
  &:before {
    color: #eeeeee;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    text-transform: none;
    font-size: inherit;
    line-height: inherit;
  }

  span:before {
    top: 0;
    position: absolute;
    left: 0;
    text-transform: none;
    font-size: inherit;
    line-height: inherit;
  }
}

.product .summary .woocommerce-product-rating .star-rating {
  margin-bottom: 0px;
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  width: 100px;

  span {
    padding-top: 20px;
  }
}

.comment-form-rating {
  margin-top: 20px;
  margin-bottom: 20px;

  p,
  label {
    margin: 0;
    vertical-align: middle;
  }

  p {
    margin: 16px 0 0 0;
  }

  select {
    display: none !important;
  }

  a {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
    color: #ffab00;
    transition: all 0.3s;
    text-indent: -9000em;
    display: inline-block;
    margin-right: 24px;
    position: relative;
  }

  .star-2 {
    width: 48px;
  }

  .star-3 {
    width: 72px;
  }

  .star-4 {
    width: 96px;
  }

  .star-5 {
    width: 120px;
  }

  a {
    &:after {
      color: #ffd800;
      top: 0;
      left: 0;
      position: absolute;
      text-transform: none;
      font-size: inherit;
      line-height: inherit;
      text-indent: 0;
      transition: all 0.3s ease-out;
    }

    &:hover:after,
    &.active:after {
      color: #212121;
    }
  }
}

.summary .woocommerce-product-rating {
  margin-bottom: 16px;
}

/* Woocommerce tabs
    ---------------*/

.woocommerce-tabs {
  width: 100%;
  margin-top: 0;

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .tabs {
    position: relative;

    li {
      padding: 0;
      position: relative;
      text-align: center;
      display: inline-block;
      margin-right: 2px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }

      a {
        display: inline-block;
        padding: 12px 24px 12px 24px !important;
        min-height: 48px;
        text-transform: uppercase !important;
        font-size: 14px !important;
        line-height: 24px !important;
        background-color: #f5f5f5;
        border-radius: 4px;
        transition: all 300ms ease-out;
      }

      &.active a,
      a:hover {
        color: #ffffff !important;
      }
    }
  }
}

.woocommerce-Tabs-panel {
  padding: 32px 0;
  margin-top: 0;
  background-color: #ffffff;
  border-radius: 4px;
  text-align: left;

  p:last-child {
    margin-bottom: 0;
  }

  h2 {
    text-align: center;
    display: none;
  }

  .shop_attributes {
    border-collapse: collapse;
    width: 100%;
  }

  table {
    width: 100% !important;
  }

  .shop_attributes {
    tr {
      padding: 0;
    }

    th {
      font-weight: 600 !important;
      vertical-align: middle;
      border: 1px solid #e0e0e0;
    }

    td {
      vertical-align: middle;
      border: 1px solid #e0e0e0;

      p {
        margin: 0;
      }
    }
  }

  .commentlist {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .comment-notes {
    margin-top: 15px;
  }
}

/* Woocommerce reviews
    ---------------*/

/* Woocommerce reletated
    ---------------*/

.related-products {
  .product .post-inner {
    position: relative;
  }

  .loop-product .post-title {
    min-height: 45px;
    max-height: 45px;
  }
}

/* Woocommerce pages
    ---------------*/

.shop_table {
  td,
  tfoot th {
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    line-height: 24px;
  }
}

.cart_totals th,
.customer_details th,
.woocommerce-checkout-review-order-table th,
.wc_payment_method,
.woocommerce-order-overview li,
.woocommerce-page address,
.woocommerce table.wishlist_table tbody td {
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  line-height: 24px;
}

.wc_payment_methods > li,
.woocommerce-order-overview li {
  margin-top: -1px !important;
}

.shop_table thead {
  background-color: #f5f5f5 !important;

  th {
    background-color: #f5f5f5 !important;
    color: #212121;
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
  }
}

.woocommerce table.wishlist_table thead th {
  color: #212121;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}

.woocommerce-MyAccount-navigation li a {
  color: $color4 !important;
}

.shop_table tbody tr,
.woocommerce-order-overview li,
.woocommerce-page address {
  background-color: #ffffff;
  color: $color4;
}

.shop_table {
  .coupon {
    background-color: #ffffff;
    color: $color4;
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
  }

  tbody tr:nth-child(2n + 2) {
    background-color: #ffffff;
  }
}

.woocommerce-order-overview li:nth-child(2n + 2) {
  background-color: #ffffff;
}

.shop_table .product-remove a {
  background-color: $color4;
  color: #ffffff !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 4px !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 24px !important;
}

.my-account:not(.logged-in) h2 {
  text-align: center;
}

.woocommerce-form-login {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.my-account .woocommerce h2 {
  margin-left: auto;
  margin-right: auto;
}

.woocommerce table.wishlist_table tbody td {
  padding: 8px;
  max-width: 240px;
}

.wishlist_table tr td.product-stock-status span {
  &.wishlist-out-of-stock {
    color: #d50000;
  }

  &.wishlist-in-stock {
    color: #4caf50;
  }
}

/* Navigation
  ---------------*/

.enovathemes-navigation {
  position: relative;
  text-align: center;
  position: relative;
  text-align: left;

  > .page-numbers {
    border-top: 1px solid #e0e0e0;
    list-style: none;
    margin: 0;
    margin-top: 24px;
    padding: 24px 0 0 0;
    display: block;
    z-index: 5;
    position: relative;

    > * {
      display: inline-block;
      padding: 0;
      vertical-align: middle;
    }
  }

  a,
  .current {
    margin: 0 8px 8px 0;
    display: inline-block;
    vertical-align: top;
    padding: 0 4px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    line-height: 40px;
    background-color: #ffffff;
    color: $color4;
    box-shadow: inset 0 0 0 1px #e0e0e0;
    border-radius: 4px;
    text-align: center;
    outline: 0;
    position: relative;
    overflow: hidden;
    transition: all 300ms ease-out;
  }

  &.default-page-template {
    a,
    .current {
      margin: 0 4px 4px 0;
    }
  }

  a:hover,
  .current {
    color: #ffffff;
  }

  .next:before,
  .prev:before {
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 12px;
  }
}

.ajax-container {
  position: relative;
  margin-top: 48px;
  text-align: center;
}

.et-ajax-error {
  display: none;
}

.et-ajax-loader {
  cursor: pointer;
  background-color: #f5f5f5;
  color: $color4;
  margin-bottom: 0 !important;

  &.disable {
    cursor: default;
  }

  &:before {
    content: '';
    display: none;
    width: 100%;
    height: 100%;
    background-color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
  }

  &.loading {
    &:before {
      display: block;
    }

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      margin: 0 0 0 16px;
      vertical-align: middle;
      top: 50%;
      left: 50%;
      margin-left: -12px;
      margin-top: -12px;
      position: absolute;
      border-radius: 50%;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border: 2px solid #616161;
      -ms-animation: ajax-loader 400ms infinite linear;
      animation: ajax-loader 400ms infinite linear;
      z-index: 5;
    }
  }
}

.ajax-scroll-overlay .ajax-scroll {
  content: '';
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 0 0 0 16px;
  vertical-align: middle;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  position: absolute;
  border-radius: 50%;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border: 2px solid #616161;
  -ms-animation: ajax-loader 400ms infinite linear;
  animation: ajax-loader 400ms infinite linear;
  z-index: 5;
}

@-ms-keyframes ajax-loader {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ajax-loader {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.et-ajax-scroll {
  &:before,
  &:after {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #e0e0e0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: ajax-scroll 1s infinite ease-in-out;
    animation: ajax-scroll 1s infinite ease-in-out;
  }

  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #e0e0e0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: ajax-scroll 1s infinite ease-in-out;
  animation: ajax-scroll 1s infinite ease-in-out;
  font-size: 16px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  animation-delay: -0.16s;
  display: block;
  opacity: 0;
  visibility: hidden;

  &.loading {
    opacity: 1;
    visibility: visible;
  }

  &.disable {
    opacity: 0 !important;
    visibility: hidden;
  }

  &:before {
    left: -40px;
    animation-delay: -0.32s;
  }

  &:after {
    left: 40px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
}

@-webkit-keyframes ajax-scroll {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes ajax-scroll {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.ajax-scroll-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 300ms ease-out;
  z-index: 5;
  text-align: center;

  .ajax-scroll {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    position: relative;
    top: auto;
    left: 0;
    border: none;
    animation: none;

    span {
      display: block;
      width: 30px;
      height: 30px;
      background-color: #f5f5f5;
      position: relative;

      &:before {
        display: block;
        width: inherit;
        height: inherit;
        background-color: #f5f5f5;
        content: '';
        position: absolute;
        top: 0;
        right: -34px;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: ajax-scroll-2 0.5s infinite ease-in-out;
        animation: ajax-scroll-2 0.5s infinite ease-in-out;
      }

      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: ajax-scroll-2 0.5s infinite ease-in-out;
      animation: ajax-scroll-2 0.5s infinite ease-in-out;

      &:first-child:before {
        -webkit-animation-delay: 100ms;
        animation-delay: 100ms;
      }

      &:last-child {
        -webkit-animation-delay: 200ms;
        animation-delay: 200ms;

        &:before {
          -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
        }

        margin-top: 4px;
      }
    }
  }

  .ajax-scroll-text {
    padding-bottom: 16px;
  }
}

@-webkit-keyframes ajax-scroll-2 {
  0%,
  100% {
    background-color: #f5f5f5;
  }

  50% {
    background-color: #e0e0e0;
  }
}

@keyframes ajax-scroll-2 {
  0%,
  100% {
    background-color: #f5f5f5;
  }

  50% {
    background-color: #e0e0e0;
  }
}

.ajax-container.hide {
  visibility: hidden;
  opacity: 0;
}

.et-ajax-scroll-status {
  position: absolute;
  top: 0;
  line-height: 16px;
}

.ajax-container.center .et-ajax-scroll-status {
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* Single post navigation
    ---------------*/

.post-single-navigation {
  margin-top: 48px !important;
  padding-top: 24px;
  border-top: 1px solid #e0e0e0;

  a {
    display: block;
    text-align: center;
    transition: all 300ms;
    font-size: 16px;
    line-height: 16px;

    &[rel='next'],
    &[rel='prev'] {
      color: $color4 !important;
    }

    &[rel='next']:hover {
      color: #212121 !important;
    }

    &[rel='prev'] {
      &:hover {
        color: #212121 !important;
      }

      float: left;
    }

    &[rel='next'] {
      float: right;
    }
  }
}

/* Elements
  ---------------*/

/* Itemset
    ---------------*/

.et-item-set {
  &[data-gap='2'] {
    margin-left: -1px;
    margin-right: -1px;

    .et-item {
      padding-left: 1px;
      padding-right: 1px;
      padding-bottom: 2px;
    }
  }

  &[data-gap='4'] {
    margin-left: -2px;
    margin-right: -2px;

    .et-item {
      padding-left: 2px;
      padding-right: 2px;
      padding-bottom: 4px;
    }
  }

  &[data-gap='8'] {
    margin-left: -4px;
    margin-right: -4px;

    .et-item {
      padding-left: 4px;
      padding-right: 4px;
      padding-bottom: 8px;
    }
  }

  &[data-gap='16'] {
    margin-left: -8px;
    margin-right: -8px;

    .et-item {
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 16px;
    }
  }

  &[data-gap='24'] {
    margin-left: -12px;
    margin-right: -12px;

    .et-item {
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 24px;
    }
  }

  &[data-gap='32'] {
    margin-left: -16px;
    margin-right: -16px;

    .et-item {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 32px;
    }
  }

  &[data-gap='40'] {
    margin-left: -20px;
    margin-right: -20px;

    .et-item {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px;
    }
  }
}

.owl-carousel.et-client-container.et-item-set {
  &[data-gap='2'] {
    margin-left: -1px;
    margin-right: -1px;

    .et-item {
      margin-left: 1px;
      margin-right: 1px;
    }
  }

  &[data-gap='4'] {
    margin-left: -2px;
    margin-right: -2px;

    .et-item {
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  &[data-gap='8'] {
    margin-left: -4px;
    margin-right: -4px;

    .et-item {
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  &[data-gap='16'] {
    margin-left: -8px;
    margin-right: -8px;

    .et-item {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &[data-gap='24'] {
    margin-left: -12px;
    margin-right: -12px;

    .et-item {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  &[data-gap='32'] {
    margin-left: -16px;
    margin-right: -16px;

    .et-item {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  &[data-gap='40'] {
    margin-left: -20px;
    margin-right: -20px;

    .et-item {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.et-item-set {
  &[data-gap='2'] .owl-prev {
    left: -49px;
  }

  &[data-gap='4'] .owl-prev {
    left: -50px;
  }

  &[data-gap='8'] .owl-prev {
    left: -52px;
  }

  &[data-gap='16'] .owl-prev {
    left: -56px;
  }

  &[data-gap='24'] .owl-prev {
    left: -60px;
  }

  &[data-gap='32'] .owl-prev {
    left: -64px;
  }

  &[data-gap='40'] .owl-prev {
    left: -68px;
  }

  &[data-gap='2'] .owl-next {
    right: -49px;
  }

  &[data-gap='4'] .owl-next {
    right: -50px;
  }

  &[data-gap='8'] .owl-next {
    right: -52px;
  }

  &[data-gap='16'] .owl-next {
    right: -56px;
  }

  &[data-gap='24'] .owl-next {
    right: -60px;
  }

  &[data-gap='32'] .owl-next {
    right: -64px;
  }

  &[data-gap='40'] .owl-next {
    right: -68px;
  }
}

.layout-boxed:not(.sidebar-navigation) .et-item-set {
  &[data-gap='2'] .owl-prev {
    left: 1px;
  }

  &[data-gap='4'] .owl-prev {
    left: 2px;
  }

  &[data-gap='8'] .owl-prev {
    left: 4px;
  }

  &[data-gap='16'] .owl-prev {
    left: 8px;
  }

  &[data-gap='24'] .owl-prev {
    left: 12px;
  }

  &[data-gap='32'] .owl-prev {
    left: 16px;
  }

  &[data-gap='40'] .owl-prev {
    left: 20px;
  }

  &[data-gap='2'] .owl-next {
    right: 1px;
  }

  &[data-gap='4'] .owl-next {
    right: 2px;
  }

  &[data-gap='8'] .owl-next {
    right: 4px;
  }

  &[data-gap='16'] .owl-next {
    right: 8px;
  }

  &[data-gap='24'] .owl-next {
    right: 12px;
  }

  &[data-gap='32'] .owl-next {
    right: 16px;
  }

  &[data-gap='40'] .owl-next {
    right: 20px;
  }
}

/* Parallax transform
    ---------------*/

.etp-parallax[data-parallax='true'] {
  z-index: 78;
  transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate3d(0, 0, 0);
  will-change: transform;
  transform-origin: center;
  float: none;
  position: absolute !important;
  top: 0;
  left: 0;
}

.compose-mode .etp-parallax[data-parallax='true'] > * {
  -ms-transform: none !important;
  transform: none !important;
}

.etp-parallax[data-parallax='true'].alignright {
  left: auto;
  right: 0;
}

/* Typography
    ---------------*/

/* et-heading
      ---------------*/

.et-heading,
.et-typeit,
.et-highlight-heading {
  margin-bottom: 0;
  position: relative;
}

.et-heading.text-align-left,
.et-typeit.text-align-left,
.et-highlight-heading.text-align-left {
  text-align: left;
}

.et-heading.text-align-right,
.et-typeit.text-align-right,
.et-highlight-heading.text-align-right {
  text-align: right;
}

.et-heading.text-align-center,
.et-typeit.text-align-center,
.et-highlight-heading.text-align-center {
  text-align: center;
}

.et-heading {
  .text-wrapper,
  .text,
  .word,
  .letter {
    display: inline-block;
    vertical-align: top;
  }
}

.et-typeit .text-wrapper,
.et-highlight-heading .text-wrapper {
  display: inline-block;
  vertical-align: top;
}

.et-heading .text-wrapper,
.et-typeit .text-wrapper {
  position: relative;
}

.et-highlight-heading .text-wrapper {
  position: relative;
  padding-left: 16px;

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 80%;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    left: 0;
  }
}

.et-heading.curtain .text-wrapper {
  overflow: hidden;
}

.letter-direct.et-heading .text-wrapper,
.letter-angle.et-heading .text-wrapper,
.words-direct.et-heading .text-wrapper,
.words-angle.et-heading .text-wrapper {
  opacity: 0;
}

.letter-direct.et-heading.active .text-wrapper,
.letter-angle.et-heading.active .text-wrapper,
.words-direct.et-heading.active .text-wrapper,
.words-angle.et-heading.active .text-wrapper {
  opacity: 1;
}

.et-heading {
  .text.highlight {
    background: none !important;
  }

  .word {
    word-wrap: break-word;
    background: none !important;

    &.small {
      font-size: 50%;
      line-height: 100%;
      vertical-align: middle;
    }
  }

  .in-block {
    display: inline-block;
    line-height: 1.15em !important;
    vertical-align: baseline !important;
    position: relative;
    top: -2px;
  }

  .letter,
  &.words-direct .word,
  &.words-angle .word {
    opacity: 0;
  }

  &.letter-angle .letter,
  &.words-angle .word {
    transform-origin: 0 100%;
  }

  .underline,
  .box {
    position: relative;
  }

  .small {
    line-height: 0.75em;
    font-size: 35%;
    vertical-align: middle;
  }

  .box.small {
    padding: 0 0.4em 0.2em 0.4em;
    border-radius: 4px;
  }

  .underline .after,
  .box .after {
    border-radius: inherit;
    content: '';
    display: block;
    width: 102%;
    height: 25%;
    min-height: 2px;
    position: absolute;
    bottom: 0;
    left: -1%;
    z-index: -1;
    backface-visibility: hidden;
    transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transition: transform 600ms 400ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  .underline .after {
    width: 101%;
    left: 0%;
  }
}

.text.highlight .after {
  width: 100% !important;
  left: 0 !important;
}

.et-heading {
  &.letter-angle .underline .after,
  &.letter-direct .underline .after,
  &.letter-angle .box .after,
  &.letter-direct .box .after {
    transition-delay: 600ms !important;
  }

  .box .after {
    height: 100%;
    transition: transform 900ms 400ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  .underline.animate .after {
    transform: scaleX(1);
    -ms-transform: scaleX(1);
  }
}

.active.et-heading {
  &.letter-angle .underline .after,
  &.letter-direct .underline .after {
    transform: scaleX(1);
    -ms-transform: scaleX(1);
  }
}

.et-heading .box.animate .after {
  transform: scaleX(1);
  -ms-transform: scaleX(1);
}

.active.et-heading {
  &.letter-angle .box .after,
  &.letter-direct .box .after,
  .text.highlight .after {
    transform: scaleX(1);
    -ms-transform: scaleX(1);
  }
}

.et-heading {
  .word:empty {
    display: none;
  }

  &.words-direct .word.animate {
    animation: animateDirect 600ms cubic-bezier(0.19, 1, 0.22, 1) normal
      forwards;
  }

  &.letter-direct .letter.animate {
    animation: animateDirect 300ms cubic-bezier(0.19, 1, 0.22, 1) normal
      forwards;
  }

  &.words-angle .word.animate {
    backface-visibility: hidden;
    animation: animateAngle 750ms cubic-bezier(0.19, 1, 0.22, 1) normal forwards;
  }

  &.letter-angle .letter.animate {
    backface-visibility: hidden;
    animation: animateAngle2 375ms cubic-bezier(0.19, 1, 0.22, 1) normal
      forwards;
  }
}

/* .direct
        ---------------*/

@-ms-keyframes animateDirect {
  0% {
    -ms-transform: translateY(32px);
    opacity: 0;
  }

  100% {
    -ms-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animateDirect {
  0% {
    transform: translateY(32px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* .angle
        ---------------*/

@-ms-keyframes animateAngle {
  0% {
    -ms-transform: translateY(32px) rotate(16deg);
    opacity: 0;
  }

  100% {
    -ms-transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes animateAngle {
  0% {
    transform: translateY(32px) rotate(16deg);
    opacity: 0;
  }

  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

@-ms-keyframes animateAngle2 {
  0% {
    -ms-transform: translateY(32px) rotate(32deg);
    opacity: 0;
  }

  100% {
    -ms-transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes animateAngle2 {
  0% {
    transform: translateY(32px) rotate(32deg);
    opacity: 0;
  }

  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

/* et-font-size
      ---------------*/

.et-font-size {
  display: inherit;

  &[data-fontsize='10px'] {
    font-size: 10px !important;
  }

  &[data-fontsize='12px'] {
    font-size: 12px !important;
  }

  &[data-fontsize='14px'] {
    font-size: 14px !important;
  }

  &[data-fontsize='16px'] {
    font-size: 16px !important;
  }

  &[data-fontsize='18px'] {
    font-size: 18px !important;
  }

  &[data-fontsize='20px'] {
    font-size: 20px !important;
  }

  &[data-fontsize='24px'] {
    font-size: 24px !important;
  }

  &[data-fontsize='28px'] {
    font-size: 28px !important;
  }

  &[data-fontsize='32px'] {
    font-size: 32px !important;
  }

  &[data-fontsize='36px'] {
    font-size: 36px !important;
  }

  &[data-fontsize='40px'] {
    font-size: 40px !important;
  }

  &[data-lineheight='10px'] {
    line-height: 10px !important;
  }

  &[data-lineheight='12px'] {
    line-height: 12px !important;
  }

  &[data-lineheight='14px'] {
    line-height: 14px !important;
  }

  &[data-lineheight='16px'] {
    line-height: 16px !important;
  }

  &[data-lineheight='18px'] {
    line-height: 18px !important;
  }

  &[data-lineheight='20px'] {
    line-height: 20px !important;
  }

  &[data-lineheight='24px'] {
    line-height: 24px !important;
  }

  &[data-lineheight='28px'] {
    line-height: 28px !important;
  }

  &[data-lineheight='32px'] {
    line-height: 32px !important;
  }

  &[data-lineheight='36px'] {
    line-height: 36px !important;
  }

  &[data-lineheight='40px'] {
    line-height: 40px !important;
  }

  &[data-lineheight='48px'] {
    line-height: 48px !important;
  }

  &[data-lineheight='56px'] {
    line-height: 56px !important;
  }

  &[data-lineheight='64px'] {
    line-height: 64px !important;
  }

  &[data-lineheight='72px'] {
    line-height: 72px !important;
  }
}

/* et_dropcap
      ---------------*/

.et-dropcap {
  display: block;
  float: left;
  font-size: 4em;
  line-height: 100%;
  margin: 2px 8px 0 0;

  &.empty {
    display: block;
    float: left;
    font-size: 4em;
    line-height: 100%;
    margin: 2px 8px 0 0;
  }

  &.full {
    color: #fff;
    text-align: center;
    padding: 16px 16px 16px 16px;
    margin: 10px 16px 0 0;
    min-width: 56px;
  }
}

/* et_highlight
      ---------------*/

.et-highlight {
  padding: 0px;
  color: #fff;
  position: relative;
  z-index: 3;
  display: initial;
  font-weight: 600;

  &.back-active {
    padding: 2px 8px;
  }

  &.border-active:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: inherit;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  > :last-child {
    margin-bottom: 0 !important;
  }

  &.tipso_style {
    cursor: help;
  }
}

/* et-blockquote
      ---------------*/

blockquote,
q {
  quotes: none;
  display: block;
  position: relative;
  padding: 24px;
  margin: 0 0 32px 0;
  font-size: 18px !important;
  line-height: 28px !important;
  color: #212121;
  font-weight: 600;
  border-radius: 4px;
}

.wp-block-quote {
  &.is-large {
    quotes: none;
    display: block;
    position: relative;
    padding: 24px;
    margin: 0 0 32px 0;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #212121;
    font-weight: 600;
    border-radius: 4px;

    p {
      font-size: 32px !important;
      line-height: 48px !important;
      margin-bottom: 0 !important;
    }
  }

  &.is-style-large p {
    font-size: 32px !important;
    line-height: 48px !important;
    margin-bottom: 0 !important;
  }

  &.is-large {
    cite,
    footer {
      font-size: 32px !important;
      line-height: 48px !important;
      margin-bottom: 0 !important;
    }
  }

  &.is-style-large {
    cite,
    footer {
      font-size: 32px !important;
      line-height: 48px !important;
      margin-bottom: 0 !important;
    }
  }
}

.wp-block-pullquote p {
  font-size: 32px !important;
  line-height: 48px !important;
  margin-bottom: 0 !important;
}

.wp-block-quote {
  &.is-large,
  &.is-style-large {
    padding: 24px !important;
  }

  &.is-large p,
  &.is-style-large p {
    font-size: 32px !important;
    line-height: 48px !important;
    margin-bottom: 0 !important;
  }

  &.is-large {
    cite,
    footer {
      font-size: 32px !important;
      line-height: 48px !important;
      margin-bottom: 0 !important;
    }
  }

  &.is-style-large {
    cite,
    footer {
      font-size: 32px !important;
      line-height: 48px !important;
      margin-bottom: 0 !important;
    }
  }
}

.wp-block-pullquote p {
  font-size: 32px !important;
  line-height: 48px !important;
  margin-bottom: 0 !important;
}

.wp-block-quote {
  &[style*='text-align:right'],
  &[style*='text-align: right'] {
    border-left: 0 !important;
  }
}

.has-text-color.has-very-dark-gray-color {
  color: #000000 !important;
}

.has-very-dark-gray-color {
  background-color: inherit !important;
  border-left-color: initial !important;
}

.wp-block-pullquote {
  &.is-style-solid-color blockquote {
    max-width: 100% !important;
  }

  padding: 0 !important;
  border-radius: 4px;
}

.alignfull blockquote,
q {
  padding-left: 32px;
  padding-right: 32px;
}

blockquote {
  p {
    margin: 0;
  }

  cite {
    margin-top: 16px !important;
    display: block;
  }
}

.wp-block-quote {
  &.is-large {
    cite,
    footer {
      text-align: left;
    }
  }

  &.is-style-large {
    cite,
    footer {
      text-align: left;
    }
  }
}

blockquote p br,
cite br {
  display: none;
}

blockquote p {
  &:only-child {
    margin: 0;
    display: inline;
    position: relative;
  }

  &:before {
    content: '"';
  }
}

q:before,
blockquote p:after,
q:after {
  content: '"';
}

.et-blockquote {
  blockquote {
    margin-bottom: 16px;
  }

  display: block;
  width: 100%;

  .author {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700 !important;
  }

  .title {
    margin-top: -8px;
    font-weight: 600;
  }

  .author-info-wrapper {
    text-align: left;

    > * {
      display: block;
      margin-bottom: 0;
    }
  }

  blockquote {
    opacity: 0;
    -ms-transform: translateY(32px);
    transform: translateY(32px);
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms ease-out;
  }

  .author-info-wrapper > * {
    opacity: 0;
    -ms-transform: translateY(32px);
    transform: translateY(32px);
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms ease-out;

    &:first-child {
      transition-delay: 100ms;
    }

    &:nth-child(2) {
      transition-delay: 200ms;
    }
  }
}

.active.et-blockquote {
  blockquote,
  .author-info-wrapper > * {
    opacity: 1;
    transform: translateY(0);
  }
}

/* UI
    ---------------*/

/* et-button
        ---------------*/

.et-button {
  backface-visibility: unset;

  .et-ink {
    z-index: 3;
  }

  .text {
    position: relative;
    z-index: 4;
    display: inline-block;
    vertical-align: middle;
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 4;
    line-height: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    top: 0.5px;
  }

  &.small .icon {
    font-size: 16px !important;
    line-height: 16px !important;
  }

  &.medium .icon {
    font-size: 20px !important;
    line-height: 20px !important;
  }

  &.large .icon {
    font-size: 24px !important;
    line-height: 24px !important;
  }

  &.icon-position-left .icon {
    margin-right: 4px;
  }

  &.icon-position-right .icon {
    margin-left: 4px;
  }

  &.icon-position-left.medium .icon {
    margin-right: 8px;
  }

  &.icon-position-right.medium .icon {
    margin-left: 8px;
  }

  &.icon-position-left.large .icon {
    margin-right: 12px;
  }

  &.icon-position-right.large .icon {
    margin-left: 12px;
  }

  &.small.has-icon {
    padding: 8px 16px 9px 16px !important;
  }
}

/*has icon
          ----------------*/

.border-radius-large.et-button.small.has-icon {
  padding: 8px 16px 9px 16px !important;
}

.et-button {
  &.medium.has-icon {
    padding: 12px 24px 13px 24px !important;
  }

  &.large.has-icon {
    padding: 16px 32px 17px 32px !important;
  }

  .hover {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    border-radius: inherit;
    transition: all 200ms linear !important;
  }

  .regular {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    border-radius: inherit;
    transition: all 200ms linear !important;
    opacity: 1;
    z-index: 2;
  }

  &:hover .hover {
    opacity: 1;
  }
}

.et-header-button:hover .hover {
  opacity: 1;
}

.et-button {
  &.hover-fill {
    .hover {
      opacity: 1;
      visibility: visible;
      transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1) !important;
      width: calc(100% + 1px) !important;
      height: calc(100% + 1px) !important;
      transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform-origin: bottom right;
      -ms-transform-origin: bottom right;
    }

    .regular {
      opacity: 1;
    }

    &:hover .hover {
      transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  &.hover-glint {
    > .glint {
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1) !important;
      transform: rotate3d(0, 0, 1, 45deg) translate3d(-10%, -150%, 0);
      z-index: 5;
    }

    &:hover > .glint {
      transform: rotate3d(0, 0, 1, 45deg) translate3d(-10%, 350%, 0);
    }
  }

  &.hover-scale {
    > .hover {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transition: transform 0.9s cubic-bezier(0.19, 1, 0.22, 1) !important;
      -ms-transform: translate3d(-50%, -50%, 0) scale(0);
      transform: translate3d(-50%, -50%, 0) scale(0);
      will-change: auto;
      border-radius: 500% !important;
    }

    &:hover > .hover {
      -ms-transform: translate3d(-50%, -50%, 0) scale(1);
      transform: translate3d(-50%, -50%, 0) scale(1);
    }
  }
}

/* et-separator
      ---------------*/

.et-separator,
.et-gap,
.et-gap-inline {
  line-height: 0;
}

.et-gap {
  display: block;
}

.et-gap-inline {
  display: inline-block;
  vertical-align: middle;
}

.et-separator {
  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  .line {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    width: 100%;
    height: 1px;
    border-bottom-width: 1px;
  }
}

.et-icon-separator {
  .line,
  .icon {
    display: inline-block;
    vertical-align: middle;
  }

  .line {
    height: 1px;
    width: 120px;
  }

  .icon {
    &.small {
      font-size: 24px;
      line-height: 24px;
      width: 24px;
      height: 24px;
      text-align: center;
    }

    &.medium {
      font-size: 40px;
      line-height: 40px;
      width: 40px;
      height: 40px;
      text-align: center;
    }

    &.large {
      font-size: 56px;
      line-height: 56px;
      width: 56px;
      height: 56px;
      text-align: center;
    }
  }

  &.left {
    .icon {
      margin-right: 24px;
    }

    .left {
      display: none;
    }
  }

  &.right {
    .icon {
      margin-left: 24px;
    }

    .right {
      display: none;
    }
  }

  &.center .icon {
    margin-left: 24px;
    margin-right: 24px;
  }
}

/* Animate
        ---------------*/

.et-separator.animate-true.horizontal {
  .line {
    width: 0;
    transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &.active .line {
    width: 100%;
  }
}

.et-separatorp.animate-true.vertical .line {
  height: 0;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1);
}

.et-separator.animate-true.vertical.active .line {
  height: 100%;
}

/* et-alert
      ---------------*/

.woocommerce-error {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block !important;
}

.alert,
.woocommerce-message,
.woocommerce-info,
.woocommerce-error li,
.cart-empty,
.woocommerce-notice {
  padding: 12px 72px 12px 12px;
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
}

.woocommerce-message,
.woocommerce-info,
.woocommerce-error li,
.cart-empty,
.woocommerce-notice {
  padding: 12px;
  margin-bottom: 32px;
}

.woocommerce-message a,
.woocommerce-info a,
.woocommerce-error li a,
.cart-empty a,
.woocommerce-notice a,
.woocommerce-message strong,
.woocommerce-info strong,
.woocommerce-error li strong,
.cart-empty strong,
.woocommerce-notice strong {
  color: inherit !important;
}

.woocommerce-message a:not(.button),
.woocommerce-info a:not(.button),
.woocommerce-error li a:not(.button),
.cart-empty a:not(.button),
.woocommerce-notice a:not(.button) {
  font-weight: 700;
}

div.wpcf7-response-output {
  border: none !important;
}

.alert.note,
div.wpcf7-response-output {
  color: #9e9e9e;
  box-shadow: inset 0 0 0 1px #9e9e9e;
}

.alert.success,
.woocommerce-message,
.woocommerce-notice,
div.wpcf7-mail-sent-ok {
  color: #4caf50;
  box-shadow: inset 0 0 0 1px #4caf50;
}

.woocommerce-message .button {
  margin-right: 16px;
  margin-bottom: 0;
}

.alert.warning,
div.wpcf7-spam-blocked {
  color: #ff9800;
  box-shadow: inset 0 0 0 1px #ff9800;
}

.alert.error,
.woocommerce-error li,
.cart-empty {
  color: #f95759;
  box-shadow: inset 0 0 0 1px #f95759;
}

div {
  &.wpcf7-mail-sent-ng,
  &.wpcf7-aborted,
  &.wpcf7-validation-errors,
  &.wpcf7-acceptance-missing {
    color: #f95759;
    box-shadow: inset 0 0 0 1px #f95759;
  }
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
  border: 1px solid #f95759;
}

span.wpcf7-not-valid-tip {
  color: #f95759;
}

.alert.information,
.woocommerce-info {
  color: #0073aa;
  box-shadow: inset 0 0 0 1px #0073aa;
}

.alert .close-alert {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: -15px;
}

/* et-icon
      ---------------*/

.et-icon {
  margin: 0;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  + .et-icon {
    margin-left: 4px;
  }
}

.vc_et_icon + .vc_et_icon {
  margin-left: 4px;
}

.et-icon {
  &.extra-small {
    font-size: 16px;
    line-height: 16px;
    min-width: 16px;
    min-height: 16px;
  }

  &.small {
    font-size: 24px;
    line-height: 24px;
    min-width: 24px;
    min-height: 24px;
  }

  &.medium {
    font-size: 40px;
    line-height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  &.large {
    font-size: 64px;
    line-height: 64px;
    min-width: 64px;
    min-height: 64px;
  }

  &.large-x {
    font-size: 88px;
    line-height: 88px;
    min-width: 88px;
    min-height: 88px;
  }

  &.large-xx {
    font-size: 128px;
    line-height: 128px;
    min-width: 128px;
    min-height: 128px;
  }
}

.full.et-icon {
  &.extra-small {
    font-size: 16px;
    width: 32px;
    height: 32px;

    > * {
      line-height: 32px;
    }
  }

  &.small {
    font-size: 24px;
    width: 40px;
    height: 40px;

    > * {
      line-height: 40px;
    }
  }

  &.medium {
    font-size: 28px;
    width: 48px;
    height: 48px;

    > * {
      line-height: 48px;
    }
  }

  &.large {
    font-size: 32px;
    width: 72px;
    height: 72px;

    > * {
      line-height: 72px;
    }
  }

  &.large-x {
    font-size: 40px;
    width: 96px;
    height: 96px;

    > * {
      line-height: 96px;
    }
  }

  &.large-xx {
    font-size: 64px;
    width: 128px;
    height: 128px;

    > * {
      line-height: 128px;
    }
  }

  .el-icon {
    vertical-align: top;
    margin: 0 !important;
  }
}

/* et-icon-list
      ---------------*/

.et-icon-list {
  list-style: none;

  li {
    padding: 0 !important;
    display: table;
    position: relative;
    width: 100%;
  }

  .icon-wrap {
    text-align: center;
    display: table-cell;
    vertical-align: middle;

    + div {
      display: table-cell;
      vertical-align: middle;
    }
  }

  &.large .icon-wrap {
    vertical-align: top;
    top: 4px;
    position: relative;
  }

  &.shadow .icon {
    box-shadow: 0px 0 24px 0px rgba(0, 0, 0, 0.08);
  }

  &.large .icon-wrap + div {
    vertical-align: top;
  }

  .icon > * {
    line-height: inherit;
    font-size: inherit;
  }

  &.small li {
    margin-bottom: 8px;
  }

  &.medium li {
    margin-bottom: 24px;
  }

  &.large li {
    margin-bottom: 40px;
  }
}

.full.et-icon-list {
  &.medium li {
    margin-bottom: 28px;
  }

  &.large li {
    margin-bottom: 44px;
  }
}

.et-icon-list {
  li:last-child {
    margin-bottom: 0px !important;
  }

  &.small > li > div:last-child {
    padding-left: 8px !important;
  }

  &.medium > li > div:last-child {
    padding-left: 16px !important;
  }

  &.large > li > div:last-child {
    padding-left: 24px !important;
  }
}

.small.et-icon-list {
  .icon-wrap,
  .icon {
    font-size: 16px;
    line-height: 16px;
    width: 16px;
    height: 16px;
  }
}

.medium.et-icon-list {
  .icon-wrap,
  .icon {
    font-size: 24px;
    line-height: 24px;
    width: 24px;
    height: 24px;
  }
}

.large.et-icon-list {
  .icon-wrap,
  .icon {
    font-size: 32px;
    line-height: 32px;
    width: 32px;
    height: 32px;
  }
}

.full {
  &.small.et-icon-list {
    .icon-wrap,
    .icon {
      font-size: 12px;
      line-height: 24px !important;
      width: 24px;
      height: 24px;
    }
  }

  &.medium.et-icon-list {
    .icon-wrap,
    .icon {
      font-size: 24px;
      line-height: 48px !important;
      width: 48px;
      height: 48px;
    }
  }

  &.large.et-icon-list {
    .icon-wrap,
    .icon {
      font-size: 32px;
      line-height: 64px !important;
      width: 64px;
      height: 64px;
    }
  }

  &.et-icon-list .icon {
    position: relative;
  }
}

/* et-more-box
      ---------------*/

.et-more-box {
  position: absolute;
  bottom: 0;
  display: block;

  .nicescroll-rails {
    display: none !important;
  }

  &.left {
    left: 0;
    right: auto;

    .et-more-box-icon,
    .et-more-box-content {
      left: 0;
      right: auto;
    }
  }

  &.right {
    left: auto;
    right: 0;

    .et-more-box-icon,
    .et-more-box-content {
      left: auto;
      right: 0;
    }
  }

  .et-more-box-icon {
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    display: block;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    z-index: 27;

    &:before {
      width: 20px;
      height: 4px;
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -10px;
      margin-top: -2px;
    }

    &:after {
      width: 20px;
      height: 4px;
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -10px;
      margin-top: -2px;
      width: 4px;
      height: 20px;
      margin-left: -2px;
      margin-top: -10px;
    }
  }

  &.active .et-more-box-icon:after {
    display: none;
  }

  .et-more-box-content {
    display: block;
    bottom: 0;
    position: absolute;
    z-index: 26;
    transform: scale(0);
    -ms-transform: scale(0);
    transform-origin: bottom right;
    will-change: transform;
  }

  &.active {
    z-index: 25;

    .et-more-box-content {
      transform: scale(1);
      -ms-transform: scale(1);
      transition: transform 300ms cubic-bezier(0.19, 1, 0.22, 1) !important;
    }
  }
}

/* et-accordion
      ---------------*/

.et-accordion {
  .toggle-title {
    text-align: left;
    cursor: pointer;
    width: 100%;
    margin-bottom: 4px;
    border-radius: 4px;
    position: relative;
    padding: 11px 64px 11px 24px;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    background-color: #f5f5f5;

    &.icon-true {
      padding: 11px 64px 11px 52px;
    }

    &.active {
      margin-bottom: 0;
      color: #ffffff;
    }

    .toggle-title-tag,
    .toggle-ind,
    .toggle-icon {
      -ms-transition: all 300ms ease-out;
      transition: all 300ms ease-out;
      color: #000000;
    }

    &.active {
      .toggle-title-tag,
      .toggle-ind,
      .toggle-icon {
        color: #ffffff !important;
      }
    }

    .toggle-title-tag {
      margin: 0;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  .toggle-ind,
  .toggle-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 20px;
    display: block;
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -12px;
    cursor: pointer;
  }

  .toggle-ind {
    left: auto;
    right: 12px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    margin-top: -12px;
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);

    &:before {
      display: block;
      position: absolute;
      font-size: 13px;
      line-height: 16px;
      top: 50%;
      left: 50%;
      margin-left: -8px;
      margin-top: -8px;
    }
  }

  .toggle-content {
    padding: 12px;
    position: relative;

    > :last-child {
      margin-bottom: 0;
    }
  }
}

/* et-tab
      ---------------*/

.et-tab .tabset {
  position: relative;
  z-index: 20;
  display: inline-block;
}

.tabset .tab {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 8px 24px 8px 24px;
  cursor: pointer;
  position: relative;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: #f5f5f5;

  * {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    color: #000000;
    text-align: center;
  }

  &.active * {
    color: #ffffff;
  }

  .icon {
    margin-right: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .tab-title-tag {
    font-size: 14px;
    text-transform: uppercase;
  }
}

.tabs-container {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
  padding: 24px 0 0 0;
  margin: 0 auto;

  .tab-content {
    opacity: 0;
    transition: opacity 200ms 300ms ease-out;

    &.active {
      opacity: 1;
    }

    > :last-child {
      margin-bottom: 0;
    }

    &:not(:first-child) {
      display: none;
    }
  }
}

.et-tab .tabs-container {
  .arrows-pos-top-right.owl-carousel,
  .arrows-pos-top-left.owl-carousel {
    margin-top: -48px;
  }

  .arrows-pos-top-right.owl-carousel .owl-nav,
  .arrows-pos-top-left.owl-carousel .owl-nav {
    top: -24px !important;
  }

  .arrows-pos-top-right.owl-carousel .owl-nav > *,
  .arrows-pos-top-left.owl-carousel .owl-nav > * {
    width: 48px;
    height: 48px;
    line-height: 48px;
  }
}

/* Social
    ---------------*/

/* et-mailchimp
      ---------------*/

.et-mailchimp {
  max-width: 720px;
  margin: 0 auto;

  .et-mailchimp-form > *:not(.alert) {
    display: inline-block;
    vertical-align: top;
  }

  .alert:not(.final) {
    background-color: #f5f5f5;
  }

  .mailchimp-form {
    padding: 0;
    background: none;
    border-radius: 0;
    box-shadow: none;
  }

  form {
    margin: 0;
    position: relative;
  }

  input {
    &[type='hidden'] {
      display: none;
    }

    &[type='text'] {
      height: 64px;
      min-width: 272px;
      max-width: 100%;
      width: 100%;
      min-height: 64px;
      margin: 0;
    }
  }

  .send-div {
    height: 40px;
    width: auto;
    min-height: 40px;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 4px;
    z-index: 3;

    .button {
      margin: 0;
      border: none !important;
    }
  }

  .sending {
    right: auto;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin-left: 0;
    margin-top: 0;
    border-radius: inherit;
  }

  .alert {
    &.success,
    &.error {
      position: absolute;
    }
  }
}

/* et-instagram
      ---------------*/

.et-instagram-image-list {
  overflow: hidden;

  .instagram-pic a {
    display: block;
    position: relative;
  }
}

.et-instagram {
  .instagram-item {
    text-align: center;

    .post-image-overlay-content {
      color: #ffffff;
      padding: 24px;
      left: 50%;
      top: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 300px;
      backface-visibility: hidden;
    }

    .feed-item-icons {
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 24px;
    }

    .et-icon-heart {
      font-size: 32px;
    }

    .feed-item-comments {
      margin-left: 16px;
    }

    .post-image-overlay-content p:last-child {
      margin-bottom: 0;
    }
  }

  &.grid {
    &[data-columns='1'] .instagram-item,
    &[data-columns='2'] .instagram-item,
    &[data-columns='3'] .instagram-item,
    &[data-columns='4'] .instagram-item,
    &[data-columns='5'] .instagram-item,
    &[data-columns='6'] .instagram-item {
      width: 100%;
    }
  }
}

/* et-facebook
      ---------------*/

.fb_iframe_widget {
  max-width: 100%;

  iframe,
  span {
    max-width: 100%;
  }
}

/* Selfhosted
    ---------------*/

/* et-icon-box
      ---------------*/

.et-icon-box-container {
  position: relative;
  display: -ms-grid;
  display: grid;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  justify-items: stretch;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 1px 0.5px 1px 1px;
}

.no-border.et-icon-box-container {
  grid-row-gap: 0;
  grid-column-gap: 0;
  padding: 0 !important;
}

.et-icon-box-container {
  &[data-gap='2'] {
    grid-row-gap: 2px;
    grid-column-gap: 2px;
  }

  &[data-gap='4'] {
    grid-row-gap: 4px;
    grid-column-gap: 4px;
  }

  &[data-gap='6'] {
    grid-row-gap: 6px;
    grid-column-gap: 6px;
  }

  &[data-gap='8'] {
    grid-row-gap: 8px;
    grid-column-gap: 8px;
  }

  &[data-gap='10'] {
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }

  &[data-gap='12'] {
    grid-row-gap: 12px;
    grid-column-gap: 12px;
  }

  &[data-gap='14'] {
    grid-row-gap: 14px;
    grid-column-gap: 14px;
  }

  &[data-gap='16'] {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }

  &[data-gap='18'] {
    grid-row-gap: 18px;
    grid-column-gap: 18px;
  }

  &[data-gap='20'] {
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }

  &[data-gap='22'] {
    grid-row-gap: 22px;
    grid-column-gap: 22px;
  }

  &[data-gap='24'] {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }

  &[data-gap='26'] {
    grid-row-gap: 26px;
    grid-column-gap: 26px;
  }

  &[data-gap='28'] {
    grid-row-gap: 28px;
    grid-column-gap: 28px;
  }

  &[data-gap='30'] {
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }

  &[data-gap='32'] {
    grid-row-gap: 32px;
    grid-column-gap: 32px;
  }

  &[data-gap='34'] {
    grid-row-gap: 34px;
    grid-column-gap: 34px;
  }

  &[data-gap='36'] {
    grid-row-gap: 36px;
    grid-column-gap: 36px;
  }

  &[data-gap='38'] {
    grid-row-gap: 38px;
    grid-column-gap: 38px;
  }

  &[data-gap='40'] {
    grid-row-gap: 40px;
    grid-column-gap: 40px;
  }

  &[data-gap='42'] {
    grid-row-gap: 42px;
    grid-column-gap: 42px;
  }

  &[data-gap='44'] {
    grid-row-gap: 44px;
    grid-column-gap: 44px;
  }

  &[data-gap='46'] {
    grid-row-gap: 46px;
    grid-column-gap: 46px;
  }

  &[data-gap='48'] {
    grid-row-gap: 48px;
    grid-column-gap: 48px;
  }

  &[data-gap='50'] {
    grid-row-gap: 50px;
    grid-column-gap: 50px;
  }

  &[data-gap='52'] {
    grid-row-gap: 52px;
    grid-column-gap: 52px;
  }

  &[data-gap='54'] {
    grid-row-gap: 54px;
    grid-column-gap: 54px;
  }

  &[data-gap='56'] {
    grid-row-gap: 56px;
    grid-column-gap: 56px;
  }

  &[data-gap='58'] {
    grid-row-gap: 58px;
    grid-column-gap: 58px;
  }

  &[data-gap='60'] {
    grid-row-gap: 60px;
    grid-column-gap: 60px;
  }

  &[data-gap='62'] {
    grid-row-gap: 62px;
    grid-column-gap: 62px;
  }

  &[data-gap='64'] {
    grid-row-gap: 64px;
    grid-column-gap: 64px;
  }

  &[data-gap='66'] {
    grid-row-gap: 66px;
    grid-column-gap: 66px;
  }

  &[data-gap='68'] {
    grid-row-gap: 68px;
    grid-column-gap: 68px;
  }

  &[data-gap='70'] {
    grid-row-gap: 70px;
    grid-column-gap: 70px;
  }

  &[data-gap='72'] {
    grid-row-gap: 72px;
    grid-column-gap: 72px;
  }

  &[data-gap='74'] {
    grid-row-gap: 74px;
    grid-column-gap: 74px;
  }

  &[data-gap='76'] {
    grid-row-gap: 76px;
    grid-column-gap: 76px;
  }

  &[data-gap='78'] {
    grid-row-gap: 78px;
    grid-column-gap: 78px;
  }

  &[data-gap='80'] {
    grid-row-gap: 80px;
    grid-column-gap: 80px;
  }

  &.top .et-icon-box {
    align-items: flex-start;
  }

  &.middle .et-icon-box {
    align-items: center;
  }

  &.bottom .et-icon-box {
    align-items: flex-end;
  }

  &.shadow {
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);

    &.no-border {
      padding: 0;
    }
  }

  &.column-2 {
    grid-template-columns: repeat(2, 2fr);
  }

  &.column-3 {
    grid-template-columns: repeat(3, 3fr);
  }

  &.column-4 {
    grid-template-columns: repeat(4, 4fr);
    padding-right: 1px;
  }
}

.browser-internet .et-icon-box-container {
  &.column-2 .et-icon-box {
    width: 50%;
  }

  &.column-3 .et-icon-box {
    width: 33.33333333333333%;
  }

  &.column-4 .et-icon-box {
    width: 25%;
  }
}

.compose-mode .et-icon-box-container .vc_element .et-icon-box {
  height: 100%;
  width: 100%;
}

.et-icon-box-container {
  &.column-2 .et-icon-box-inner {
    max-width: 600px;
  }

  &.column-3 .et-icon-box-inner {
    max-width: 400px;
  }

  &.column-4 .et-icon-box-inner {
    max-width: 300px;
  }
}

.et-icon-box {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  transition: color 150ms ease-out, background-color 150ms ease-out,
    box-shadow 150ms ease-out;
}

.icon-position-left.et-icon-box,
.icon-position-right.et-icon-box {
  justify-content: flex-start;
}

.et-icon-box {
  &.link {
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1),
      box-shadow 300ms ease-out, background-color 300ms ease-out,
      color 300ms ease-out !important;
  }

  position: relative;
  padding: 48px 32px;
}

.et-icon-box-inner {
  position: relative;
  z-index: 2;
}

.et-icon-box {
  a {
    display: block;
  }

  &.icon-alignment-left {
    text-align: left;
  }

  &.icon-alignment-center {
    text-align: center;
  }

  &.icon-alignment-right {
    text-align: right;
  }

  &.icon-position-left {
    text-align: left;
  }

  &.icon-position-right {
    text-align: right;
  }

  &.icon-position-top .et-icon,
  &.icon-position-left .et-icon,
  &.icon-position-right .et-icon {
    margin-bottom: 16px;
  }
}

.et-icon-box-title {
  br + br {
    display: none !important;
  }

  margin-bottom: 16px;
}

h4.et-icon-box-title {
  margin-bottom: 12px;
}

h5.et-icon-box-title {
  margin-bottom: 8px;
}

h6.et-icon-box-title {
  margin-bottom: 0;
}

.et-icon-box {
  .et-icon,
  .et-icon-box-title,
  .et-icon-box-content {
    transition: all 200ms ease-out !important;
  }
}

.et-icon-box-content {
  margin-bottom: 0;
}

.et-icon-box {
  .el-icon {
    transition: none;
    font-size: inherit;
    line-height: inherit;
  }

  &.icon-position-left .et-icon {
    position: absolute;
    top: 4px;
    left: 0;
  }

  &.icon-position-right .et-icon {
    position: absolute;
    top: 4px;
    left: 0;
    left: auto !important;
    right: 0 !important;
  }

  &.icon-position-left .et-icon.small + .et-icon-content {
    padding-left: 48px;
  }

  &.icon-position-right .et-icon.small + .et-icon-content {
    padding-right: 48px;
  }

  &.icon-position-left .et-icon.full.small + .et-icon-content {
    padding-left: 64px;
  }

  &.icon-position-right .et-icon.full.small + .et-icon-content {
    padding-right: 64px;
  }

  &.icon-position-left .et-icon.medium + .et-icon-content {
    padding-left: 64px;
  }

  &.icon-position-right .et-icon.medium + .et-icon-content {
    padding-right: 64px;
  }

  &.icon-position-left .et-icon.full.medium + .et-icon-content {
    padding-left: 72px;
  }

  &.icon-position-right .et-icon.full.medium + .et-icon-content {
    padding-right: 72px;
  }

  &.icon-position-left .et-icon.large + .et-icon-content {
    padding-left: 96px;
  }

  &.icon-position-right .et-icon.large + .et-icon-content {
    padding-right: 96px;
  }

  &.icon-position-left .et-icon.full.large + .et-icon-content {
    padding-left: 104px;
  }

  &.icon-position-right .et-icon.full.large + .et-icon-content {
    padding-right: 104px;
  }

  &.icon-position-left .et-icon.large-x + .et-icon-content {
    padding-left: 128px;
  }

  &.icon-position-right .et-icon.large-x + .et-icon-content {
    padding-right: 128px;
  }

  &.icon-position-left .et-icon.full.large-x + .et-icon-content {
    padding-left: 136px;
  }

  &.icon-position-right .et-icon.full.large-x + .et-icon-content {
    padding-right: 136px;
  }

  &.icon-position-left .et-icon.large-xx + .et-icon-content {
    padding-left: 164px;
  }

  &.icon-position-right .et-icon.large-xx + .et-icon-content {
    padding-right: 164px;
  }

  &.icon-position-left .et-icon.full.large-xx + .et-icon-content {
    padding-left: 172px;
  }

  &.icon-position-right .et-icon.full.large-xx + .et-icon-content {
    padding-right: 172px;
  }
}

.hover-scale.et-icon-box {
  .et-icon {
    &:after {
      content: '';
      -ms-transform: translate(-50%, -50%) scale(1.4);
      transform: translate(-50%, -50%) scale(1.4);
      transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
      border-radius: inherit;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      opacity: 0;
    }

    .el-icon {
      z-index: 5;
      position: relative;
    }
  }

  &:hover .et-icon:after {
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.hover-glint.et-icon-box {
  .et-icon {
    overflow: hidden;

    &:after {
      content: '';
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      width: 70%;
      height: 200%;
      background: rgba(255, 255, 255, 0.5);
      transition: transform 1.3s cubic-bezier(0.19, 1, 0.22, 1) !important;
      -ms-transform: rotate3d(0, 0, 1, -45deg) translate3d(300%, -10%, 0);
      transform: rotate3d(0, 0, 1, -45deg) translate3d(300%, -10%, 0);
      z-index: 5;
    }
  }

  &:hover .et-icon:after {
    transition: transform 1.6s cubic-bezier(0.19, 1, 0.22, 1) !important;
    -ms-transform: rotate3d(0, 0, 1, -45deg) translate3d(-170%, -10%, 0);
    transform: rotate3d(0, 0, 1, -45deg) translate3d(-170%, -10%, 0);
  }
}

.hover-ghost.et-icon-box {
  overflow: hidden;

  .ghost-icon {
    position: absolute;
    bottom: -64px;
    right: -64px;
    width: 256px;
    height: 256px;
    line-height: 256px;
    font-size: 256px;
    opacity: 0.1;
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1),
      color 200ms ease-out;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }

  &:hover .ghost-icon {
    -ms-transform: translate(25%, 25%) rotate(-32deg) scale(2);
    transform: translate(25%, 25%) rotate(-32deg) scale(2);
  }
}

/* et-makes
      ---------------*/

.et-make {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px 8px 12px 8px;

  .et-icon-box-title {
    margin-bottom: 0 !important;
  }
}

.et-make-container {
  &.small .et-make .et-icon + .et-icon-content .et-icon-box-title {
    font-size: 15px;
    line-height: 22px;
  }

  &.medium .et-make .et-icon + .et-icon-content .et-icon-box-title {
    font-size: 17px;
    line-height: 24px;
  }

  &.large .et-make .et-icon + .et-icon-content .et-icon-box-title {
    font-size: 21px;
    line-height: 26px;
  }
}

.et-make .et-icon {
  width: auto;
  min-height: 0;
  max-height: 100%;
  margin-bottom: 4px !important;
  overflow: hidden;

  &.no-title {
    margin-bottom: 0 !important;
  }

  svg {
    height: inherit;
    width: auto;
    position: relative;
  }

  &.rev svg {
    width: inherit;
    height: auto;
    transform: translateY(-12px);
  }

  svg path {
    transition: all 200ms ease-out !important;
  }
}

.et-make-container {
  &.small .et-make .et-icon {
    height: 48px;
  }

  &.medium .et-make .et-icon {
    height: 56px;
  }

  &.large .et-make .et-icon {
    height: 64px;
  }

  &.small .et-make .no-title.et-icon {
    height: 56px;
  }

  &.medium .et-make .no-title.et-icon {
    height: 64px;
  }

  &.large .et-make .no-title.et-icon {
    height: 72px;
  }

  &.small .et-make .et-icon.rev {
    width: 72px;
  }

  &.medium .et-make .et-icon.rev {
    width: 80px;
  }

  &.large .et-make .et-icon.rev {
    width: 88px;
  }

  &.small .et-make .no-title.et-icon.rev {
    width: 80px;
  }

  &.medium .et-make .no-title.et-icon.rev {
    width: 88px;
  }

  &.large .et-make .no-title.et-icon.rev {
    width: 96px;
  }
}

/* et-step-box
      ---------------*/

.et-step-box-container {
  position: relative;
  display: grid;
  justify-items: stretch;

  &.column-1 .et-step-box {
    justify-content: flex-start;
    max-width: 600px;

    &:nth-child(2n + 2) {
      margin-left: 50%;
    }
  }
}

.compose-mode
  .et-step-box-container.column-1
  .vc_element:nth-child(2n + 2)
  .et-step-box {
  margin-left: 50%;
}

.et-step-box-container {
  &.column-2 {
    grid-template-columns: repeat(2, 2fr);
  }

  &.column-3 {
    grid-template-columns: repeat(3, 3fr);
  }

  &.column-4 {
    grid-template-columns: repeat(4, 4fr);
  }
}

.compose-mode .et-step-box-container .vc_element .et-step-box {
  height: 100%;
  width: 100%;
}

.et-step-box-container {
  &.column-2 .et-step-box-inner {
    max-width: 600px;
  }

  &.column-3 .et-step-box-inner {
    max-width: 400px;
  }

  &.column-4 .et-step-box-inner {
    max-width: 300px;
  }
}

.et-step-box {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 24px 24px 24px;
}

.et-step-box-container {
  margin-left: -24px;
  margin-right: -24px;
}

.et-step-box-inner {
  position: relative;
  z-index: 2;
  opacity: 0;
  -ms-transform: translateY(32px);
  transform: translateY(32px);

  &.animate {
    animation: stepmoveUp 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }
}

@keyframes stepmoveUp {
  0% {
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.et-step-box-title {
  br + br {
    display: none !important;
  }

  margin-bottom: 8px;
  line-height: 1em !important;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
}

.step-dot {
  width: 16px;
  height: 16px;
  margin-right: 16px;
  display: inline-block;
  margin-bottom: 8px;
  border-radius: 16px;
  vertical-align: top;
  transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 200ms ease-out;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  position: relative;

  .before {
    display: block;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: inherit;
    width: inherit;
    line-height: 17px;
    font-weight: 700;
    font-size: 100%;
    text-align: center;
    color: #ffffff;
    backface-visibility: hidden;
  }
}

.et-step-box {
  &:hover .step-dot {
    .before {
      opacity: 1;
    }

    -ms-transform: scale(4);
    transform: scale(4);
  }

  .et-step,
  .et-step-box-title,
  .et-step-box-content {
    transition: all 200ms ease-out !important;
  }
}

.et-step-box-content {
  margin-bottom: 0;
}

.et-step-box .el-step {
  transition: none;
}

/* et-pricing-table
        ---------------*/

.et-pricing-table {
  position: relative;
  text-align: center;
}

.pricing-table-inner {
  padding: 40px 24px;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.et-pricing-table.highlight-true .pricing-table-inner {
  padding: 62px 24px;
  border-width: 2px;
  border-style: solid;
  box-shadow: none;
  z-index: 2;
  top: -24px;
}

.pricing-table-head {
  padding-bottom: 32px;
}

.et-pricing-table {
  .label {
    padding: 4px 16px;
    position: absolute;
    font-weight: 600;
    font-size: 14px;
    top: -1px;
    right: 0;
    display: block;
    color: #ffffff;
  }

  .currency {
    font-size: 32px;
    line-height: 32px;
    vertical-align: top;
    position: relative;
    bottom: -7px;
  }

  .price {
    font-size: 48px;
    line-height: 48px;
    vertical-align: middle;
  }
}

.vc_col-sm-2 .et-pricing-table .price,
.vc_col-sm-1\/5 .et-pricing-table .price,
.vc_col-sm-2 .et-pricing-table .currency,
.vc_col-sm-1\/5 .et-pricing-table .currency {
  font-size: 24px;
  line-height: 24px;
}

.vc_col-sm-2 .et-pricing-table .currency,
.vc_col-sm-1\/5 .et-pricing-table .currency {
  bottom: -3px;
}

.et-pricing-table {
  .title {
    margin-bottom: 0;
  }

  .plan {
    padding-top: 0;
    color: #bdbdbd;
    font-weight: 600;
    display: inline-block;
  }
}

.pricing-table-body ul {
  list-style: none;
  margin: 0 0 0px 0;
  padding: 24px 0;
  border-top: 1px dashed #e0e0e0;
  border-bottom: 1px dashed #e0e0e0;

  li {
    margin: 0;
    padding: 0;
    font-size: 16px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.pricing-table-footer {
  padding-top: 32px;

  .et-button {
    margin-bottom: 0;
    width: 200px;
    max-width: 100% !important;
  }
}

/* et-testimonial
      ---------------*/

.et-testimonial {
  .author-wrapper {
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px #e0e0e0;
  }

  .author-text {
    display: block;
    position: relative;
    margin-bottom: 0;
  }

  display: table;
  width: 100%;
  padding-bottom: 0 !important;

  > * {
    display: block;
  }

  .author-content-wrapper {
    width: 100%;
    display: table;
    position: relative;
    padding-top: 16px;

    > * {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .author-info-wrapper {
    > * {
      display: block;
      margin-bottom: 0;
    }

    .author {
      font-weight: 700;
      position: relative;
      display: inline;
      color: $color4;
    }
  }

  .author-image {
    max-width: 72px;
    width: 72px;
    transition-delay: 100ms;

    + .author-info-wrapper {
      padding-left: 16px;
    }
  }

  .author-info-wrapper .rating {
    &.poor span {
      width: 20%;
    }

    &.fair span {
      width: 40%;
    }

    &.average span {
      width: 60%;
    }

    &.good span {
      width: 80%;
    }

    &.excellent span {
      width: 100%;
    }
  }

  .author-text,
  .author-image {
    opacity: 0;
    -ms-transform: translateY(16px);
    transform: translateY(16px);
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms ease-out;
  }

  .author-info-wrapper > * {
    opacity: 0;
    -ms-transform: translateY(16px);
    transform: translateY(16px);
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms ease-out;

    &:nth-child(1) {
      transition-delay: 200ms;
    }

    &:nth-child(2) {
      transition-delay: 250ms;
    }
  }
}

.et-testimonial-container[data-columns='1'] {
  .author-text {
    transition-delay: 200ms;
  }

  .author-image {
    transition-delay: 250ms;
  }

  .author-info-wrapper > * {
    &:nth-child(1) {
      transition-delay: 300ms;
    }

    &:nth-child(2) {
      transition-delay: 350ms;
    }
  }
}

.active.et-testimonial {
  .author-text,
  .author-image,
  .author-info-wrapper > * {
    opacity: 1;
    transform: translateY(0);
  }
}

.et-testimonial-container {
  &[data-columns='1'] .active .et-testimonial {
    .author-text,
    .author-image,
    .author-info-wrapper > * {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:not([data-columns='1']) .et-testimonial {
    .author-text,
    .author-image,
    .author-info-wrapper > * {
      opacity: 1 !important;
      transform: translateY(0) !important;
      transition: none !important;
    }
  }
}

/* et-client
      ---------------*/

.grid.et-client-container {
  position: relative;
  display: grid;
  grid-row-gap: 0;
  grid-column-gap: 0;
  justify-items: stretch;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.et-client-container {
  &[data-gap='2'] {
    grid-row-gap: 2px;
    grid-column-gap: 2px;
  }

  &[data-gap='4'] {
    grid-row-gap: 4px;
    grid-column-gap: 4px;
  }

  &[data-gap='8'] {
    grid-row-gap: 8px;
    grid-column-gap: 8px;
  }

  &[data-gap='16'] {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }

  &[data-gap='24'] {
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  }

  &[data-gap='32'] {
    grid-row-gap: 32px;
    grid-column-gap: 32px;
  }

  &[data-gap='40'] {
    grid-row-gap: 40px;
    grid-column-gap: 40px;
  }
}

.border-true.et-client-container {
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
}

.et-client-container .et-client {
  align-items: center;
}

.grid.et-client-container {
  &[data-columns='2'] {
    grid-template-columns: repeat(2, 2fr);
  }

  &[data-columns='3'] {
    grid-template-columns: repeat(3, 3fr);
  }

  &[data-columns='4'] {
    grid-template-columns: repeat(4, 4fr);
  }

  &[data-columns='5'] {
    grid-template-columns: repeat(5, 5fr);
  }

  &[data-columns='6'] {
    grid-template-columns: repeat(6, 6fr);
  }
}

.browser-internet .grid.et-client-container {
  &[data-columns='2'] .et-client {
    width: 50%;
  }

  &[data-columns='3'] .et-client {
    width: 33.33333333333333%;
  }

  &[data-columns='4'] .et-client {
    width: 25%;
  }

  &[data-columns='5'] .et-client {
    width: 20%;
  }

  &[data-columns='6'] .et-client {
    width: 16.66666666666667%;
  }
}

.grid.et-client-container {
  &[data-columns='3'],
  &[data-columns='6'] {
    width: calc(100% - 2px);
  }

  &[data-columns='5'] {
    width: calc(100% - 4px);
  }
}

.compose-mode .grid.et-client-container .vc_element .et-client {
  height: 100%;
  width: 100%;
}

.et-client {
  padding: 4px 16px !important;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
}

.grid.et-client-container.border-true .et-client {
  padding: 24px !important;
  min-height: 150px;
}

.owl-carousel {
  &.et-client-container .et-client {
    padding: 24px !important;
    min-height: 150px;
  }

  .et-client {
    max-height: 200px;
  }
}

.et-client-container {
  &[data-gap='0'] .owl-nav > {
    .owl-prev {
      left: -48px !important;
    }

    .owl-next {
      right: -48px !important;
    }
  }

  .owl-dots {
    margin-top: 32px;
  }
}

.client-inner {
  z-index: 3;
}

.owl-carousel.et-client-container .client-inner {
  opacity: 1 !important;
}

.et-client {
  .back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 1;
    transition: background-color 150ms ease-out;
  }

  img,
  a {
    z-index: 2;
    position: relative;
  }

  img {
    width: auto !important;
    display: inline-block !important;

    &.hover {
      z-index: -1;
      display: none !important;
      backface-visibility: hidden;
    }
  }

  &:hover img {
    &.hover {
      display: inline-block !important;
      z-index: 2;
    }

    &.regular {
      display: none !important;
      z-index: -1;
    }
  }
}

/* et-person
      ---------------*/

.et-person {
  text-align: center;
  position: relative;

  .person-inner {
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px #e0e0e0;
  }

  .person-content {
    padding: 24px;
    overflow: hidden;
    position: relative;
    text-align: left;
  }

  .name,
  .title,
  .et-social-links {
    display: block;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
  }

  .name {
    font-weight: 700;
    position: relative;
    display: inline;
    line-height: 24px;
    font-size: 18px;
  }

  .title {
    font-weight: 600;
    line-height: 22px;
  }

  .et-social-links > a {
    color: #bdbdbd;
    width: auto;
    height: 20px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 0;
    margin-right: 16px;

    &:hover {
      color: #212121;
    }
  }

  .person-content > * {
    opacity: 0;
    -ms-transform: translateY(16px);
    transform: translateY(16px);
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 300ms ease-out;

    &:nth-child(1) {
      transition-delay: 100ms;
    }

    &:nth-child(2) {
      transition-delay: 150ms;
    }

    &:nth-child(3) {
      transition-delay: 200ms;
    }
  }
}

.et-person-container[data-columns='1'] .et-person .person-content > * {
  &:nth-child(1) {
    transition-delay: 300ms;
  }

  &:nth-child(2) {
    transition-delay: 350ms;
  }

  &:nth-child(3) {
    transition-delay: 400ms;
  }
}

.active.et-person .person-content > * {
  opacity: 1;
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.et-person-container {
  &[data-columns='1'] .active .et-person .person-content > * {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  &:not([data-columns='1']) .et-person .person-content > * {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
}

/* et-map
        ---------------*/

.gm-style-iw {
  text-align: center !important;
  max-width: 240px;
}

.map-title {
  margin-bottom: 8px;
  margin-top: 24px;
}

.map-content {
  font-size: 16px !important;
  line-height: 26px !important;
}

.map-image {
  max-width: 240px !important;
  margin-top: 24px;
}

/* et-banner
        ---------------*/

.et-popup-banner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-out;

  &.animate {
    opacity: 1;
    visibility: visible;
    -moz-perspective: 1300px;
    perspective: 1300px;
  }
}

.et-popup-banner {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999999999999999999999;
  padding: 32px;
  border-radius: 8px;
  background-attachment: scroll;
  background-position: left top;
  background-repeat: repeat;

  &.text-align-left {
    text-align: left;
  }

  &.text-align-right {
    text-align: right;
  }

  &.text-align-center {
    text-align: center;
  }
}

.popup-banner-toggle {
  position: absolute;
  top: -20px;
  right: -20px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  font-size: 16px;
  background-color: #000000;
  backface-visibility: hidden;
  text-align: center;
  transition: background-color 300ms ease-out,
    transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  z-index: 9999999999999999999999999999;

  &:before {
    display: block;
    width: inherit;
    height: inherit;
    line-height: inherit;
  }

  &:hover {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

/* Effect 1: Fade in and scale up */

.et-popup-banner.fade-in-scale {
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

.et-popup-banner-wrapper.animate .et-popup-banner.fade-in-scale {
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 2: Slide from the right */

.et-popup-banner.slide-in-right {
  -ms-transform: translateX(20%);
  transform: translateX(20%);
  opacity: 0;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

.et-popup-banner-wrapper.animate .et-popup-banner.slide-in-right {
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

/* Effect 3: Slide from the bottom */

.et-popup-banner.slide-in-bottom {
  -ms-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

.et-popup-banner-wrapper.animate .et-popup-banner.slide-in-bottom {
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Effect 4: 3D flip horizontal */

.et-popup-banner.flip-horizonatal {
  transform-style: preserve-3d;
  -ms-transform: rotateY(-70deg);
  transform: rotateY(-70deg);
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}

.et-popup-banner-wrapper.animate .et-popup-banner.flip-horizonatal {
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

/* Effect 5: 3D flip vertical */

.et-popup-banner.flip-vertical {
  transform-style: preserve-3d;
  -ms-transform: rotateX(-70deg);
  transform: rotateX(-70deg);
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}

.et-popup-banner-wrapper.animate .et-popup-banner.flip-vertical {
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

/* et-ad
        ---------------*/

.et-ad {
  position: relative !important;
  overflow: hidden;

  &.gradient:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 50%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);
    z-index: 3;
  }

  &.gradient-zoom-true.gradient {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 300ms ease-out;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 12%,
        rgba(0, 0, 0, 0) 50%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1);
      opacity: 0;
      z-index: 3;
    }

    &:hover:after {
      opacity: 1;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: scale(1);
    backface-visibility: hidden;
  }

  &.back-zoom-true {
    &:hover .background {
      transform: scale(1.05);
    }

    &.gradient:hover .background {
      transform: translateX(2%);
    }
  }
}

.et-ad-img {
  position: absolute;
  z-index: 4;
  display: block;
  width: auto;
  max-width: none;
  transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
  backface-visibility: hidden;
  top: 0;
  left: 0;
}

.et-ad {
  &.img-transform-true:hover .et-ad-img,
  &.back-zoom-true:hover .background,
  &.gradient-zoom-true.gradient:hover:after {
    transition-delay: 100ms;
  }

  &.img-transform-true {
    &.right:hover .et-ad-img {
      transform: translateX(8%);
    }

    &.left:hover .et-ad-img {
      transform: translateX(-8%);
    }

    &.top:hover .et-ad-img {
      transform: translateY(-8%);
    }

    &.bottom:hover .et-ad-img {
      transform: translateY(8%);
    }
  }
}

.et-ad-inner {
  position: relative;
  z-index: 5;
}

.et-ad {
  &.back-repeat-no-repeat .background {
    background-repeat: no-repeat;
  }

  &.back-repeat-repeat .background {
    background-repeat: repeat;
  }

  &.back-repeat-repeat-x .background {
    background-repeat: repeat-x;
  }

  &.back-repeat-repeat-y .background {
    background-repeat: repeat-y;
  }

  &.back-size-auto .background {
    background-size: auto;
  }

  &.back-size-cover .background {
    background-size: cover;
  }

  &.back-size-contain .background {
    background-size: contain;
  }
}

.banner-content > .vc_row > .container {
  width: 100% !important;
  max-width: 100% !important;
}

/* et-tagline
        ---------------*/

.et-tagline {
  position: relative;
  display: none;
  width: 100%;
  text-align: center;
  background-attachment: scroll;
  background-position: left top;
  background-repeat: repeat;

  > .container > * {
    display: inline-block;
    vertical-align: middle;
    margin: 0 !important;
  }

  .tagline-title {
    padding-right: 24px;
  }

  .tagline-button {
    border-radius: 4px;
  }

  .tagline-toggle {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1);

    &:hover {
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    &:before {
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }
}

/* Media
    ---------------*/

/* et-image
      ---------------*/

.et-image {
  position: relative;
  overflow: hidden;
  display: inline-block;

  &[data-curtain='true'] {
    overflow: hidden !important;
  }
}

/*curtain*/

.et-heading.curtain {
  overflow: hidden !important;
}

.et-animate-box.curtain {
  overflow: hidden !important;
  position: relative;

  .box-inner-wrapper {
    position: relative;
  }
}

.et-image[data-curtain='true'],
.et-animate-box[data-curtain='true'] {
  display: block;
}

.et-image[data-curtain='true']:before,
.et-animate-box[data-curtain='true']:before {
  display: none !important;
}

.et-image[data-curtain='true'] img,
.et-heading.curtain .text,
.et-animate-box.curtain .content {
  visibility: hidden;
  position: relative;
  transform-origin: center;
}

.et-image[data-curtain='true'].active img,
.et-heading.curtain.active .text,
.et-animate-box.curtain.active .content {
  -ms-animation: curtainShowMe 7s step-end forwards;
  animation: curtainShowMe 7s step-end forwards;
  animation-delay: 1000ms;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.et-image .curtain,
.et-heading.curtain .curtain,
.et-animate-box.curtain .curtain {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.et-image.curtain-left .curtain,
.et-heading.curtain-left .curtain,
.et-animate-box.curtain-left .curtain {
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}

.et-image.curtain-right .curtain,
.et-heading.curtain-right .curtain,
.et-animate-box.curtain-right .curtain {
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.et-image.curtain-top .curtain,
.et-heading.curtain-top .curtain,
.et-animate-box.curtain-top .curtain {
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
}

.et-image.curtain-bottom .curtain,
.et-heading.curtain-bottom .curtain,
.et-animate-box.curtain-bottom .curtain {
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
}

.et-image.curtain-left.active .curtain,
.et-heading.curtain-left.active .curtain,
.et-animate-box.curtain-left.active .curtain {
  -ms-animation: curtainSwipeLeft 1.5s cubic-bezier(0.6, 0, 0.4, 1);
  animation: curtainSwipeLeft 1.5s cubic-bezier(0.6, 0, 0.4, 1);
}

.et-image.curtain-right.active .curtain,
.et-heading.curtain-right.active .curtain,
.et-animate-box.curtain-right.active .curtain {
  -ms-animation: curtainSwipeRight 1.5s cubic-bezier(0.6, 0, 0.4, 1);
  animation: curtainSwipeRight 1.5s cubic-bezier(0.6, 0, 0.4, 1);
}

.et-image.curtain-top.active .curtain,
.et-heading.curtain-top.active .curtain,
.et-animate-box.curtain-top.active .curtain {
  -ms-animation: curtainSwipeTop 1.5s cubic-bezier(0.6, 0, 0.4, 1);
  animation: curtainSwipeTop 1.5s cubic-bezier(0.6, 0, 0.4, 1);
}

.et-image.curtain-bottom.active .curtain,
.et-heading.curtain-bottom.active .curtain,
.et-animate-box.curtain-bottom.active .curtain {
  -ms-animation: curtainSwipeBottom 1.5s cubic-bezier(0.6, 0, 0.4, 1);
  animation: curtainSwipeBottom 1.5s cubic-bezier(0.6, 0, 0.4, 1);
}

.et-image .curtain:after,
.et-heading.curtain .curtain:after,
.et-animate-box.curtain .curtain:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  content: '';
  display: block;
}

.et-image.curtain-left .curtain:after,
.et-heading.curtain-left .curtain:after,
.et-animate-box.curtain-left .curtain:after {
  left: -100%;
}

.et-image.curtain-right .curtain:after,
.et-heading.curtain-right .curtain:after,
.et-animate-box.curtain-right .curtain:after {
  left: 100%;
}

.et-image.curtain-top .curtain:after,
.et-heading.curtain-top .curtain:after,
.et-animate-box.curtain-top .curtain:after {
  top: -100%;
}

.et-image.curtain-bottom .curtain:after,
.et-heading.curtain-bottom .curtain:after,
.et-animate-box.curtain-bottom .curtain:after {
  top: 100%;
}

.et-image.active .curtain:after,
.et-heading.curtain.active .curtain:after,
.et-animate-box.curtain.active .curtain:after {
  animation: curtaintFadeOut 1.5s ease;
  animation-delay: inherit;
}

@-ms-keyframes curtaintFadeOut {
  0% {
  }

  50%,
  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes curtaintFadeOut {
  0% {
  }

  50%,
  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes curtainSwipeLeft {
  0% {
  }

  50%,
  60% {
    transform: translate(0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes curtainSwipeLeft {
  0% {
  }

  50%,
  60% {
    transform: translate(0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes curtainSwipeRight {
  0% {
  }

  50%,
  60% {
    -webkit-transform: translate(0, 0);
    transform: translate(0);
  }

  100% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}

@-ms-keyframes curtainSwipeRight {
  0% {
  }

  50%,
  60% {
    -webkit-transform: translate(0, 0);
    transform: translate(0);
  }

  100% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}

@keyframes curtainSwipeTop {
  0% {
  }

  50%,
  60% {
    -webkit-transform: translate(0, 0);
    transform: translate(0);
  }

  100% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }
}

@-ms-keyframes curtainSwipeTop {
  0% {
  }

  50%,
  60% {
    -webkit-transform: translate(0, 0);
    transform: translate(0);
  }

  100% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }
}

@-ms-keyframes curtainSwipeBottom {
  0% {
  }

  50%,
  60% {
    -ms-transform: translate(0, 0);
  }

  100% {
    -ms-transform: translate(-100%, 0);
  }
}

@keyframes curtainSwipeBottom {
  0% {
  }

  50%,
  60% {
    transform: translate(0);
  }

  100% {
    transform: translate(0, -100%);
  }
}

@-ms-keyframes curtainShowMe {
  from {
    visibility: hidden;
  }

  60% {
    visibility: visible;
  }

  100% {
    visibility: visible;
  }
}

@keyframes curtainShowMe {
  from {
    visibility: hidden;
  }

  60% {
    visibility: visible;
  }

  100% {
    visibility: visible;
  }
}

/* et-gallery
      ---------------*/

.et-gallery {
  text-align: center;

  &.carousel-thumbs {
    overflow: hidden;
  }

  .et-gallery-item .et-item-inner {
    overflow: initial;
  }

  &.grid {
    .image-container {
      overflow: initial;
    }

    .image-preloader:before {
      display: none;
    }

    &[data-columns='1'] {
      .et-gallery-item,
      .grid-sizer {
        width: 100%;
      }
    }

    &[data-columns='2'] {
      .et-gallery-item,
      .grid-sizer {
        width: 50%;
      }
    }

    &[data-columns='3'] {
      .et-gallery-item,
      .grid-sizer {
        width: 33.33333333333333%;
      }
    }

    &[data-columns='4'] {
      .et-gallery-item,
      .grid-sizer {
        width: 25%;
      }
    }

    &[data-columns='5'] {
      .et-gallery-item,
      .grid-sizer {
        width: 20%;
      }
    }

    &[data-columns='6'] {
      .et-gallery-item,
      .grid-sizer {
        width: 16.66666666666667%;
      }
    }

    &[data-columns='7'] {
      .et-gallery-item,
      .grid-sizer {
        width: 14.28571428571429%;
      }
    }

    &[data-columns='8'] {
      .et-gallery-item,
      .grid-sizer {
        width: 12.5%;
      }
    }

    &[data-columns='9'] {
      .et-gallery-item,
      .grid-sizer {
        width: 11.11111111111111%;
      }
    }

    &[data-columns='10'] {
      .et-gallery-item,
      .grid-sizer {
        width: 10%;
      }
    }
  }

  ul {
    list-style: none;
    margin-bottom: 0 !important;
  }

  .carousel-navs {
    display: inline-block !important;
    max-width: 100%;
  }

  .carousel-thumbs li {
    padding-left: 0 !important;
    padding-right: 0 !important;

    img {
      margin: 0 auto;
    }
  }

  .carousel-navs li {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    max-width: 150px;
  }

  &[data-gap='2'] .carousel-navs li {
    max-width: 152px;
  }

  &[data-gap='4'] .carousel-navs li {
    max-width: 154px;
  }

  &[data-gap='8'] .carousel-navs li {
    max-width: 158px;
  }

  &[data-gap='16'] .carousel-navs li {
    max-width: 166px;
  }

  &[data-gap='24'] .carousel-navs li {
    max-width: 174px;
  }

  &[data-gap='32'] .carousel-navs li {
    max-width: 182px;
  }

  &[data-gap='40'] .carousel-navs li {
    max-width: 190px;
  }
}

/* et-video
      ---------------*/

.et-video {
  transition: opacity 300ms 700ms ease-out;
  opacity: 0;

  &.animate {
    opacity: 1;
  }
}

.compose-mode .et-video {
  opacity: 1;
}

.et-video .modal-video-poster {
  position: relative;
  display: block;

  &:before {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 19px;
    text-align: center;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -35px;
    margin-top: -35px;
    color: #ffffff;
  }
}

/* playlist
      ---------------*/

.wp-playlist {
  border: none !important;
  padding: 0 !important;
  margin: 0 0 0 0 !important;
  background-color: #242427 !important;
  color: #ffffff !important;
}

.wp-playlist-current-item {
  height: auto !important;
  padding: 30px 30px 0 30px !important;
}

.wp-playlist .mejs-container {
  margin: 0 23px !important;
  max-width: 100% !important;
  width: calc(100% - 46px) !important;
}

.wp-playlist-current-item {
  img {
    width: 100px !important;
    height: 100px !important;
    max-width: 100px !important;
  }

  .wp-playlist-caption {
    color: #ffffff !important;
  }

  .wp-playlist-item-title {
    max-width: 100%;
    font-size: 16px !important;
    line-height: 26px !important;
    color: #ffffff !important;
  }

  .wp-playlist-item-album {
    font-size: 14px !important;
    line-height: 24px !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    font-style: normal !important;
    color: #ffffff !important;
    letter-spacing: 0.5px;
    display: inline-block;
    vertical-align: top;
  }

  .wp-playlist-item-artist {
    font-size: 14px !important;
    line-height: 24px !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    font-style: normal !important;
    color: #ffffff !important;
    letter-spacing: 0.5px;
    display: inline-block;
    vertical-align: top;
    padding-left: 20px !important;
    position: relative;

    &:before {
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -6px;
      left: 7px;
      width: 2px;
      height: 12px;
      content: '';
      background-color: #ffffff !important;
    }
  }
}

.wp-playlist {
  .mejs-controls {
    background-color: #242427 !important;

    .mejs-time-rail .mejs-time-total,
    .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
      background-color: #3a3a3d !important;
    }

    .mejs-time-rail {
      .mejs-time-total,
      .mejs-time-loaded {
        background-color: #3a3a3d !important;
      }

      .mejs-time-current {
        background-color: $mainColor !important;
      }
    }

    .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
      background-color: $mainColor !important;
    }
  }

  .wp-playlist-item {
    padding: 16px 32px 16px 32px !important;
    border-bottom: none !important;
    background-color: #2e2e31 !important;
    color: #ffffff !important;
    font-weight: 400 !important;

    &:nth-child(2n + 2) {
      background-color: #242427 !important;
    }

    .wp-playlist-item-artist {
      color: #9e9e9e !important;
      font-size: 14px !important;
      line-height: 24px !important;
      text-transform: capitalize !important;
    }
  }

  .wp-playlist-caption {
    color: #ffffff !important;
  }

  .wp-playlist-item .wp-playlist-item-length {
    top: 50% !important;
    right: 30px !important;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

/* Post based shortcodes
    ---------------*/

.et-woo-products,
.et-shortcode-posts {
  padding: 0 !important;
  position: relative;
}

.loop-posts.owl-carousel {
  .post {
    width: 100% !important;
  }

  .owl-prev {
    left: -56px;
  }

  .owl-next {
    right: -56px;
  }
}

.layout-boxed:not(.sidebar-navigation) .loop-posts.owl-carousel {
  .owl-prev {
    left: 12px;
  }

  .owl-next {
    right: 12px;
  }
}

/* woo-single-product
    ---------------*/

.woo-single-product {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  padding: 0;
  margin: 0 !important;

  .woocommerce-product-gallery__wrapper,
  .flex-viewport {
    max-height: 320px !important;
  }

  .product {
    padding-bottom: 0 !important;
  }

  .single-product-wrapper {
    padding-top: 0 !important;
  }

  .flex-control-nav {
    text-align: center !important;
  }

  .woocommerce-product-gallery {
    margin-bottom: 0 !important;
  }

  .product {
    .single-product-wrapper .summary {
      margin-bottom: 0 !important;
      box-shadow: none;
    }

    .woocommerce-product-rating,
    .woocommerce-product-details__short-description,
    .yith-wcwl-add-to-wishlist,
    .product_meta {
      display: none;
    }
  }

  &.arrows-pos-bottom-right.owl-carousel[data-gap='0'] .owl-nav,
  &.bullets-pos-bottom-right.owl-carousel[data-gap='0'] .owl-dots {
    bottom: 12px !important;
    right: 12px !important;
    top: auto !important;
  }
}

/* Infographics
    ---------------*/

/* et-counter
      ---------------*/

.et-counter {
  position: relative;
  opacity: 0;
  transition: all 0.2s ease-out;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
}

.et-counter-inner {
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: inherit;
  display: flex;
}

.et-counter {
  > span {
    display: inline-block;
    vertical-align: middle;
  }

  &.animate {
    opacity: 1;
  }
}

.counter-icon {
  margin-right: 12px;
}

.counter-title {
  margin-bottom: 0;
  font-weight: 700;
}

.counter-value {
  font-weight: 700 !important;
  line-height: 1em;

  .counter {
    font-weight: 700 !important;
    line-height: 1em;
  }
}

.et-counter *[data-font='14px'],
.et-circle-progress *[data-font='14px'] {
  font-size: 14px !important;
  line-height: 24px !important;
}

.et-counter *[data-font='16px'],
.et-circle-progress *[data-font='16px'] {
  font-size: 16px !important;
  line-height: 24px !important;
}

.et-counter *[data-font='18px'],
.et-circle-progress *[data-font='18px'] {
  font-size: 18px !important;
  line-height: 26px !important;
}

.et-counter *[data-font='24px'],
.et-circle-progress *[data-font='24px'] {
  font-size: 24px !important;
  line-height: 28px !important;
}

.et-counter *[data-font='32px'],
.et-circle-progress *[data-font='32px'] {
  font-size: 32px !important;
  line-height: 40px !important;
}

.et-counter *[data-font='40px'],
.et-circle-progress *[data-font='40px'] {
  font-size: 40px !important;
  line-height: 48px !important;
}

.et-counter *[data-font='48px'],
.et-circle-progress *[data-font='48px'] {
  font-size: 48px !important;
  line-height: 56px !important;
}

.et-counter *[data-font='56px'],
.et-circle-progress *[data-font='56px'] {
  font-size: 56px !important;
  line-height: 64px !important;
}

.et-counter *[data-font='64px'],
.et-circle-progress *[data-font='64px'] {
  font-size: 64px !important;
  line-height: 72px !important;
}

.et-counter *[data-font='72px'],
.et-circle-progress *[data-font='72px'] {
  font-size: 72px !important;
  line-height: 80px !important;
}

/* et-progress
      ---------------*/

.et-progress {
  width: 100%;
  max-width: 100%;
  margin-bottom: 24px;

  .text {
    position: relative;
    padding-bottom: 8px;
    text-align: left;
  }

  .track-bar {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    position: relative;

    > {
      * {
        height: inherit;
        width: inherit;
        border-radius: inherit;
      }

      .bar {
        position: absolute;
        width: 0;
      }
    }
  }

  .percent,
  .title {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }

  .percent {
    font-size: 20px;
    font-weight: 700 !important;
    position: absolute;
    top: 50%;
    right: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;

    &:after {
      content: '%';
      display: inline-block;
      vertical-align: middle;
    }

    &.visible {
      opacity: 1;
    }
  }

  .text * {
    color: inherit !important;
  }
}

/* etcirlce-progress
      ---------------*/

.et-circle-progress {
  text-align: center;
  display: block;
  margin: 0 auto;
  height: auto;
  width: 200px;
  height: 200px;
  position: relative;
  opacity: 0;

  &.medium {
    width: 240px;
    height: 240px;
  }

  &.large {
    width: 300px;
    height: 300px;
  }

  &.visible {
    opacity: 1;
  }

  .track {
    position: relative;
    display: block;
  }

  .circle-content {
    width: 100%;
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .title {
    margin: 0;
    font-weight: 700;
  }

  .percent {
    font-weight: 700;

    &:after {
      content: '%';
      display: inline-block;
      vertical-align: middle;
    }
  }

  .circle-content * {
    color: inherit !important;
  }

  canvas {
    -webkit-animation: rotateCounter 40s linear infinite;
    animation: rotateCounter 40s linear infinite;
  }
}

/* et-timer
      ---------------*/

.et-timer {
  text-align: center;
  display: block;

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    div {
      padding: 24px;
      min-width: 120px;
    }

    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    margin: 0;
    display: inline-block;
    padding: 0;
  }

  ul li {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .timer-count,
  .timer-title {
    display: block;
    margin: 0 auto;
    position: relative;
  }
}

/* Other
    ---------------*/

/* et-gap
      ---------------*/

.et-gap {
  clear: both;
}

.et-gap-inline {
  display: inline-block;
  vertical-align: middle;
}

.compose-mode .vc_et_gap {
  border: 1px dashed #eeeeee;
  position: relative;
  display: block;

  &:before {
    content: 'gap';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -14px;
    display: block;
    width: 100%;
    height: 28px;
    color: #e0e0e0;
    text-align: center;
  }
}

/* Effects
  ---------------*/

/* Lazy load
    ---------------*/

.lazy-load {
  opacity: 1;
  visibility: visible;
  position: relative;
  transition: all 300ms ease-out;

  &.lazy {
    opacity: 0;
    visibility: hidden;
  }
}

/* Preloader
    /*-------------*/

.image-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;
}

.image-preloader {
  display: block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 200ms 0ms ease-out;
  background-color: #ffffff;
}

.owl-carousel .image-preloader,
.slick-slider .image-preloader,
.widget_shopping_cart .cart-product-image .image-preloader,
.shop_table .image-preloader {
  display: none !important;
}

.single-post-page .post-media .image-preloader {
  transition: all 100ms 500ms ease-out;
}

.widget .image-preloader {
  z-index: 50 !important;
}

.shown .image-preloader,
.animate .image-preloader {
  opacity: 0 !important;
  visibility: hidden !important;
}

.shown .image-preloader:before,
.animate .image-preloader:before {
  opacity: 0;
}

.image-preloader:before {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  font-size: 30px;
  margin: -15px 0 0 -15px;
  transition: opacity 200ms 300ms ease-out;
  animation: preloader 400ms infinite linear;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #616161;
  border-left: 2px solid #616161;
  color: $color4;
}

.widget .image-preloader:before {
  display: none !important;
}

.gallery {
  .image-preloader:before {
    display: none !important;
  }

  .image-container {
    overflow: initial;
  }
}

@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Grid animation
    ---------------*/

.et-item-set {
  .et-item-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
  }

  &:not(.effect-none) .et-item-inner {
    opacity: 0;
  }
}

.no-js .et-item-set .et-item-inner,
.no-cssanimations .et-item-set .et-item-inner {
  opacity: 1;
}

.et-item-set {
  &.effect-none .appended .et-item-inner {
    opacity: 0;
  }

  &.effect-fadeIn .et-item-inner {
    &.animate,
    &.shown {
      animation: etFadeIn 400ms 200ms ease forwards;
    }
  }

  &.effect-none .appended .et-item-inner {
    animation: etFadeIn 400ms 200ms ease forwards;
  }

  &.effect-moveUp .et-item-inner {
    &.animate,
    &.shown {
      transform: translateY(20%);
      animation: etmoveUp 400ms ease forwards;
    }
  }
}

/* Effect 1: opacity */

@keyframes etFadeIn {
  0% {
  }

  100% {
    opacity: 1;
  }
}

@keyframes etFadeIn {
  0% {
  }

  100% {
    opacity: 1;
  }
}

/* Effect 2: Move Up */

@keyframes etmoveUp {
  0% {
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* Overlay/caption
    ---------------*/

/*.overlay-none
      ---------------*/

.overlay-none .overlay-hover {
  .post-image-overlay,
  &:hover .post-image-overlay {
    display: none;
  }

  img,
  &:hover img {
    -ms-transform: none !important;
    transform: none !important;
    opacity: 1;
    visibility: visible;
  }
}

/*.transform
      ---------------*/

.transform {
  .overlay-hover,
  .post-inner,
  .post-image {
    overflow: visible !important;
  }
}

.et-video .modal-video-poster {
  overflow: visible !important;
}

.transform {
  .gallery-transform-link {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 15;
  }

  .overlay-hover .post-image-overlay {
    display: none;
  }

  .image-container,
  .product > .post-inner > .overlay-hover {
    -ms-transform: translateY(0);
    transform: translateY(0);
    transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1),
      box-shadow 300ms ease-out;
    backface-visibility: hidden;
  }
}

.et-icon-box.hover-transform,
.et-image.link,
.et-gallery.grid .et-gallery-item a img,
.et-video .modal-video-poster {
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1),
    box-shadow 300ms ease-out;
  backface-visibility: hidden;
}

.transform {
  .product > .post-inner > .overlay-hover .image-container {
    -ms-transform: none;
    transform: none;
    transition: none;
    backface-visibility: unset;
  }

  .overlay-hover:hover .image-container,
  .product > .post-inner > .overlay-hover:hover {
    -ms-transform: translateY(-16px);
    transform: translateY(-16px);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  }
}

.et-icon-box.hover-transform:hover,
.et-gallery.grid .et-gallery-item a img:hover,
.et-video .modal-video-poster:hover {
  -ms-transform: translateY(-16px);
  transform: translateY(-16px);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
}

.transform .product > .post-inner > .overlay-hover:hover .image-container {
  -ms-transform: none;
  transform: none;
  box-shadow: none;
}

/*.overlay-fade
      ---------------*/

.overlay-fade .overlay-hover {
  .post-image-overlay {
    opacity: 0;
    transition: all 400ms ease-in-out;
  }

  &:hover .post-image-overlay {
    opacity: 1;
  }
}

/*.overlay-fall
      ---------------*/

.overlay-fall .overlay-hover {
  .post-image-overlay {
    opacity: 0;
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transform: translate(90%, 0);
    transform: translate(90%, 0);
  }

  &:hover .post-image-overlay {
    opacity: 1;
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1),
      opacity 100ms ease-out;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  img {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  &:hover img {
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transform: translate(-10%, 0);
    transform: translate(-10%, 0);
  }
}

/*.overlay-fade-zoom
      ---------------*/

.overlay-fade-zoom .overlay-hover {
  .post-image-overlay {
    opacity: 0;
    transition: all 200ms ease-out;
  }

  &:hover .post-image-overlay {
    opacity: 1;
  }

  img {
    transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover img {
    -ms-transform: scale(1.15, 1.15);
    transform: scale(1.15, 1.15);
  }
}

/*.overlay-fade-zoom-extreme
      ---------------*/

.overlay-fade-zoom-extreme .overlay-hover {
  .post-image-overlay {
    opacity: 0;
    transition: all 400ms ease-in-out;
  }

  &:hover .post-image-overlay {
    opacity: 1;
  }

  img {
    transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover img {
    -ms-transform: scale3d(1.7, 1.7, 1);
    transform: scale3d(1.7, 1.7, 1);
  }
}

/*.overlay-move
      ---------------*/

.overlay-move .overlay-hover .post-image-overlay {
  opacity: 1;
  left: -100%;
  top: -100%;
}

/*.caption-up
      ---------------*/

.caption-up .overlay-hover .post-body,
.caption-up-image .overlay-hover .post-body {
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
}

.caption-up .overlay-hover:hover .post-body {
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.caption-up-image .overlay-hover {
  &:hover .post-body {
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  .image-container {
    transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover .image-container {
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

/*.caption-up-image
      ---------------*/

/* Material
    ---------------*/

.material {
  overflow: hidden;
  outline: 0;
  border: none;
}

.et-ink {
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  -ms-transform: scale(0);
  transform: scale(0);

  &.click {
    -ms-animation: materialClick 0.35s linear;
    animation: materialClick 0.35s linear;
  }
}

@-ms-keyframes materialClick {
  100% {
    opacity: 0;
    -ms-transform: scale(1.5);
  }
}

@keyframes materialClick {
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

/* Max-width: 320px
  ---------------*/

@media only screen and (max-width: 320px) {
  .text320-align-center {
    text-align: center !important;
  }

  .text320-align-left {
    text-align: left !important;
  }

  .text320-align-right {
    text-align: right !important;
  }

  .header-search-modal {
    width: 100%;

    form {
      width: 92%;
      height: 56px;
    }

    input {
      &[type='text'] {
        width: 100%;
        height: 56px;
        padding-right: 56px;
        line-height: 56px;
      }

      &[type='submit'] {
        width: 56px;
        height: 56px;

        + .search-icon {
          width: 56px;
          height: 56px;
          line-height: 56px;
        }
      }
    }
  }

  .enovathemes-contact-form-submit {
    position: relative !important;
  }
}

/* Max-width: 374px
  ---------------*/

@media only screen and (max-width: 374px) {
  /* timer
      ---------------*/

  .et-timer {
    display: block !important;

    ul {
      display: block !important;
    }

    li {
      max-width: 70% !important;
      display: block !important;
      padding: 0 !important;
      margin: 0 auto 0 auto !important;
    }
  }
}

/* Max-width: 479px
  ---------------*/

@media only screen and (max-width: 479px) {
  .hide479 {
    display: none !important;
  }

  .disable479.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .text479-align-center {
    text-align: center !important;
  }

  .text479-align-left {
    text-align: left !important;
  }

  .text479-align-right {
    text-align: right !important;
  }

  .overflow-x {
    overflow-x: scroll !important;
  }

  .demo-icon-pack {
    grid-template-columns: repeat(2, 2fr) !important;
  }

  input {
    &[type='button'],
    &[type='reset'],
    &[type='submit'] {
      width: 100%;
      display: block;
      margin-right: 0;
      margin-left: 0;
    }
  }

  // button:not(.dgwt-wcas-search-submit):not(.plyr__control):not(.slick-arrow):not(.wooscp-btn),
  // #product-ajax-loader {
  button.theme-button {
    width: 100%;
    display: block;
    margin-right: 0;
    margin-left: 0;
  }

  /* Header cart
      ---------------*/

  .header-cart {
    position: static !important;

    .cart-box {
      right: auto;
      left: 50%;
      margin-left: -160px;
    }
  }

  /* Responsive column padding
      ---------------*/

  .vc_column-inner {
    &[data-480-left='0'] {
      padding-left: 0 !important;
    }

    &[data-480-left='5'] {
      padding-left: 5% !important;
    }

    &[data-480-left='10'] {
      padding-left: 10% !important;
    }

    &[data-480-left='15'] {
      padding-left: 15% !important;
    }

    &[data-480-left='20'] {
      padding-left: 20% !important;
    }

    &[data-480-left='25'] {
      padding-left: 25% !important;
    }

    &[data-480-left='30'] {
      padding-left: 30% !important;
    }

    &[data-480-left='35'] {
      padding-left: 35% !important;
    }

    &[data-480-left='40'] {
      padding-left: 40% !important;
    }

    &[data-480-left='45'] {
      padding-left: 45% !important;
    }

    &[data-480-left='50'] {
      padding-left: 50% !important;
    }

    &[data-480-right='0'] {
      padding-right: 0 !important;
    }

    &[data-480-right='5'] {
      padding-right: 5% !important;
    }

    &[data-480-right='10'] {
      padding-right: 10% !important;
    }

    &[data-480-right='15'] {
      padding-right: 15% !important;
    }

    &[data-480-right='20'] {
      padding-right: 20% !important;
    }

    &[data-480-right='25'] {
      padding-right: 25% !important;
    }

    &[data-480-right='30'] {
      padding-right: 30% !important;
    }

    &[data-480-right='35'] {
      padding-right: 35% !important;
    }

    &[data-480-right='40'] {
      padding-right: 40% !important;
    }

    &[data-480-right='45'] {
      padding-right: 45% !important;
    }

    &[data-480-right='50'] {
      padding-right: 50% !important;
    }
  }

  .modal-container {
    display: block !important;
  }

  textarea,
  select {
    width: 100%;
  }

  input {
    &[type='date'],
    &[type='datetime'],
    &[type='datetime-local'],
    &[type='email'],
    &[type='month'],
    &[type='number'],
    &[type='password'],
    &[type='search'],
    &[type='tel'],
    &[type='text'],
    &[type='time'],
    &[type='url'],
    &[type='week'],
    &[type='file'] {
      width: 100%;
    }
  }

  /* Posts
      ---------------*/

  .blog-layout-list .post-image {
    min-width: 100%;
    max-width: 100%;
  }

  .blog-layout-full #loop-posts .post .post-body,
  .blog-layout-list #loop-posts .post .post-body {
    padding: 24px;
  }

  .blog-layout-full #loop-posts .post .post-title,
  .blog-layout-list #loop-posts .post .post-title {
    margin-bottom: 16px;
  }

  .post-author-box {
    padding: 24px;
  }

  .full {
    .format-aside .post-body,
    .format-link .post-body,
    .format-quote .post-body {
      padding: 0 0 48px 0 !important;
    }
  }

  .single-post-page > {
    .format-aside .post-body,
    .format-link .post-body,
    .format-quote .post-body {
      padding: 0 0 48px 0 !important;
    }
  }

  .full {
    .format-aside .post-body-inner,
    .format-link .post-body-inner,
    .format-quote .post-body-inner {
      padding: 32px !important;
    }
  }

  .single-post-page > {
    .format-aside .post-content,
    .format-quote .post-content {
      padding: 32px !important;
    }
  }

  .blog-layout-full .post-body .post-body-inner-wrap {
    padding-bottom: 48px;
  }

  /* Products
      ---------------*/

  .loop-product,
  body:not(.addon-active) ul.products {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }

  .loop-product .product .post-inner,
  body:not(.addon-active) ul.products .post-inner {
    padding-bottom: 12px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .loop-product {
    &.arrows-pos-top-right.owl-carousel .owl-nav,
    &.arrows-pos-bottom-right.owl-carousel .owl-nav,
    &.bullets-pos-top-right.owl-carousel .owl-dots,
    &.bullets-pos-bottom-right.owl-carousel .owl-dots {
      right: 6px;
    }

    &.arrows-pos-top-left.owl-carousel .owl-nav,
    &.arrows-pos-bottom-left.owl-carousel .owl-nav,
    &.bullets-pos-top-left.owl-carousel .owl-dots,
    &.bullets-pos-bottom-left.owl-carousel .owl-dots {
      left: 6px;
    }

    .post {
      .post-inner-wrapper {
        padding: 12px !important;
      }

      .post-image {
        .image-container {
          width: 100px !important;
        }

        width: 112px !important;
      }

      .post-body {
        padding: 0 0 0 12px !important;
      }

      .post-image {
        padding-right: 12px !important;
      }
    }
  }

  .product {
    .button,
    .added_to_cart {
      padding: 4px 11px 4px 11px !important;
      font-size: 14px !important;
      line-height: 24px !important;
    }
  }

  #loop-categories + #loop-products {
    margin-top: 38px;

    &:before {
      width: calc(100% - 12px);
      left: 6px;
    }
  }

  #reviews {
    form,
    .form-submit {
      margin-bottom: 0;
    }
  }

  .related-products {
    padding-top: 24px;
  }

  .shop_attributes {
    margin-bottom: 0;
  }

  /* et-separator
      ---------------*/

  .et-icon-separator .line {
    max-width: 25%;
  }

  /* et-pricing-table
        ---------------*/

  .et-pricing-table {
    .price {
      font-size: 32px !important;
      line-height: 32px !important;
    }

    .currency {
      bottom: 0 !important;
    }
  }

  /* et-carousel
        ---------------*/

  .et-item-set {
    &[data-gap='0'] .owl-prev {
      left: 0;
    }

    &[data-gap='2'] .owl-prev {
      left: 1px;
    }

    &[data-gap='4'] .owl-prev {
      left: 2px;
    }

    &[data-gap='8'] .owl-prev {
      left: 4px;
    }

    &[data-gap='16'] .owl-prev {
      left: 8px;
    }

    &[data-gap='24'] .owl-prev {
      left: 12px;
    }

    &[data-gap='32'] .owl-prev {
      left: 16px;
    }

    &[data-gap='40'] .owl-prev {
      left: 20px;
    }

    &[data-gap='0'] .owl-next {
      right: 0;
    }

    &[data-gap='2'] .owl-next {
      right: 1px;
    }

    &[data-gap='4'] .owl-next {
      right: 2px;
    }

    &[data-gap='8'] .owl-next {
      right: 4px;
    }

    &[data-gap='16'] .owl-next {
      right: 8px;
    }

    &[data-gap='24'] .owl-next {
      right: 12px;
    }

    &[data-gap='32'] .owl-next {
      right: 16px;
    }

    &[data-gap='40'] .owl-next {
      right: 20px;
    }
  }

  /* et-counter
      ---------------*/

  .et-counter.size-large,
  .et-circle-progress.large {
    width: 240px !important;
    height: 240px !important;
  }

  /* et-icon-box
      ---------------*/

  .et-icon-box {
    &.icon-position-left .et-icon.large,
    &.icon-position-right .et-icon.large,
    &.icon-position-left .et-icon.large-x,
    &.icon-position-right .et-icon.large-x,
    &.icon-position-left .et-icon.large-xx,
    &.icon-position-right .et-icon.large-xx {
      position: relative;
      top: 0 !important;
      left: 0 !important;
      right: auto;
    }

    &.icon-position-left .et-icon.large + .et-icon-content {
      padding-left: 0 !important;
    }

    &.icon-position-right .et-icon.large + .et-icon-content {
      padding-right: 0 !important;
    }

    &.icon-position-left .et-icon.large-x + .et-icon-content {
      padding-left: 0 !important;
    }

    &.icon-position-right .et-icon.large-x + .et-icon-content {
      padding-right: 0 !important;
    }

    &.icon-position-left .et-icon.large-xx + .et-icon-content {
      padding-left: 0 !important;
    }

    &.icon-position-right .et-icon.large-xx + .et-icon-content {
      padding-right: 0 !important;
    }
  }

  /* et-mailchimp
      ---------------*/

  .et-mailchimp {
    input[type='text'] {
      min-width: 0 !important;
      min-height: 0 !important;
      height: auto !important;
    }

    .send-div {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      margin-top: 8px !important;
    }
  }
}

/* Min-width: 480px
  ---------------*/

@media only screen and (min-width: 480px) {
  .hide480 {
    display: none !important;
  }

  .disable480.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }
}

/* Min-width: 480px & max-width: 767px
  ---------------*/

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hide480-768 {
    display: none !important;
  }

  .disable480-768.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .text480-767-align-center {
    text-align: center !important;
  }

  .text480-767-align-left {
    text-align: left !important;
  }

  .text480-767-align-right {
    text-align: right !important;
  }

  .demo-icon-pack {
    grid-template-columns: repeat(5, 5fr) !important;
  }

  /* Responsive column padding
      ---------------*/

  .vc_column-inner {
    &[data-480-768-left='0'] {
      padding-left: 0 !important;
    }

    &[data-480-768-left='5'] {
      padding-left: 5% !important;
    }

    &[data-480-768-left='10'] {
      padding-left: 10% !important;
    }

    &[data-480-768-left='15'] {
      padding-left: 15% !important;
    }

    &[data-480-768-left='20'] {
      padding-left: 20% !important;
    }

    &[data-480-768-left='25'] {
      padding-left: 25% !important;
    }

    &[data-480-768-left='30'] {
      padding-left: 30% !important;
    }

    &[data-480-768-left='35'] {
      padding-left: 35% !important;
    }

    &[data-480-768-left='40'] {
      padding-left: 40% !important;
    }

    &[data-480-768-left='45'] {
      padding-left: 45% !important;
    }

    &[data-480-768-left='50'] {
      padding-left: 50% !important;
    }

    &[data-480-768-right='0'] {
      padding-right: 0 !important;
    }

    &[data-480-768-right='5'] {
      padding-right: 5% !important;
    }

    &[data-480-768-right='10'] {
      padding-right: 10% !important;
    }

    &[data-480-768-right='15'] {
      padding-right: 15% !important;
    }

    &[data-480-768-right='20'] {
      padding-right: 20% !important;
    }

    &[data-480-768-right='25'] {
      padding-right: 25% !important;
    }

    &[data-480-768-right='30'] {
      padding-right: 30% !important;
    }

    &[data-480-768-right='35'] {
      padding-right: 35% !important;
    }

    &[data-480-768-right='40'] {
      padding-right: 40% !important;
    }

    &[data-480-768-right='45'] {
      padding-right: 45% !important;
    }

    &[data-480-768-right='50'] {
      padding-right: 50% !important;
    }
  }

  /* Posts
      ----------------*/

  .list #loop-posts .post .post-body,
  .blog-layout-list .post .post-body {
    padding: 48px 48px 56px 48px;
  }

  .blog-layout-full #loop-posts .post .post-title {
    max-width: 100%;
  }

  .full {
    .format-aside .post-body,
    .format-link .post-body,
    .format-quote .post-body {
      padding: 0 0 64px 0 !important;
    }
  }

  .single-post-page > {
    .format-aside .post-body,
    .format-link .post-body,
    .format-quote .post-body {
      padding: 0 0 64px 0 !important;
    }
  }

  .full {
    .format-aside .post-body-inner,
    .format-link .post-body-inner {
      padding: 48px !important;
    }

    .format-quote .post-body-inner {
      padding: 48px !important;

      &:after {
        top: -16px;
      }

      &:before {
        bottom: -16px;
      }
    }
  }

  /* Products
      ---------------*/

  .loop-product,
  body:not(.addon-active) ul.products {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .loop-product .product .post-inner,
  body:not(.addon-active) ul.products .post-inner {
    padding-bottom: 16px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .loop-product .post {
    .post-inner-wrapper {
      padding: 16px;
    }

    .post-image .image-container {
      width: 132px;
    }

    .post-body {
      padding: 0 0 0 16px;
    }

    .post-image {
      padding-right: 16px;
    }
  }

  .woocommerce-product-gallery .flex-control-nav li {
    width: 72px;

    &:last-child,
    &:nth-child(5n + 5) {
      margin-right: 0;
    }
  }

  #loop-categories + #loop-products {
    margin-top: 32px;

    &:before {
      width: calc(100% - 16px);
      left: 8px;
    }
  }

  .product .summary .et-timer {
    .timer-count {
      min-width: 68px !important;
    }

    li {
      width: auto !important;
    }
  }

  /* et-separator
      ---------------*/

  .et-icon-separator .line {
    max-width: 35%;
  }
}

/* Min-width: 639px
  ---------------*/

@media only screen and (max-width: 639px) {
  .text639-align-center {
    text-align: center !important;
  }

  .text639-align-left {
    text-align: left !important;
  }

  .text639-align-right {
    text-align: right !important;
  }
}

/* Min-width: 640px and max-width 767px
  ---------------*/

@media only screen and (min-width: 640px) and (max-width: 767px) {
  .container,
  .wpml-ls-statics-post_translations {
    max-width: 394px;
  }

  .text640-767-align-center {
    text-align: center !important;
  }

  .text640-767-align-left {
    text-align: left !important;
  }

  .text640-767-align-right {
    text-align: right !important;
  }
}

/* Max-width: 767px
  ---------------*/

@media only screen and (max-width: 767px) {
  #gen-wrap,
  #wrap {
    // overflow-x: hidden;
  }

  .hide767 {
    display: none !important;
  }

  .disable767.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .text767-align-center {
    text-align: center !important;
  }

  .text767-align-left {
    text-align: left !important;
  }

  .text767-align-right {
    text-align: right !important;
  }

  .vc-video-bg .video-container-placeholder {
    display: block;
  }

  .vc_row.background-cover-767 {
    background-size: cover !important;
  }

  .blog-layout,
  .blog-layout-single,
  .product-layout,
  .product-layout-single {
    padding-bottom: 24px;
  }

  .vc-parallax .parallax-container {
    height: 200% !important;
  }

  .ajax-container {
    margin-top: 12px;
  }

  /* Header
      ---------------*/

  .et-desktop.mobile-false,
  .et-mobile.mobile-false,
  .mobile-hide-important:not(.single-header) .et-mobile {
    display: none !important;

    + .header-placeholder {
      display: none !important;
    }
  }

  /* Page title section
      ---------------*/

  .tse {
    &.mobile-align-left {
      float: left !important;
    }

    &.mobile-align-right {
      float: right !important;
    }

    &.mobile-align-center {
      text-align: center !important;
      float: none !important;
    }
  }

  /* mobile-font
      ---------------*/

  [data-mobile-font='14px'] {
    font-size: 14px !important;
  }

  [data-mobile-font='15px'] {
    font-size: 15px !important;
  }

  [data-mobile-font='16px'] {
    font-size: 16px !important;
  }

  [data-mobile-font='17px'] {
    font-size: 17px !important;
  }

  [data-mobile-font='18px'] {
    font-size: 18px !important;
  }

  [data-mobile-font='19px'] {
    font-size: 19px !important;
  }

  [data-mobile-font='20px'] {
    font-size: 20px !important;
  }

  [data-mobile-font='21px'] {
    font-size: 21px !important;
  }

  [data-mobile-font='22px'] {
    font-size: 22px !important;
  }

  [data-mobile-font='23px'] {
    font-size: 23px !important;
  }

  [data-mobile-font='24px'] {
    font-size: 24px !important;
  }

  [data-mobile-font='25px'] {
    font-size: 25px !important;
  }

  [data-mobile-font='26px'] {
    font-size: 26px !important;
  }

  [data-mobile-font='27px'] {
    font-size: 27px !important;
  }

  [data-mobile-font='28px'] {
    font-size: 28px !important;
  }

  [data-mobile-font='29px'] {
    font-size: 29px !important;
  }

  [data-mobile-font='30px'] {
    font-size: 30px !important;
  }

  [data-mobile-font='31px'] {
    font-size: 31px !important;
  }

  [data-mobile-font='32px'] {
    font-size: 32px !important;
  }

  [data-mobile-font='33px'] {
    font-size: 33px !important;
  }

  [data-mobile-font='34px'] {
    font-size: 34px !important;
  }

  [data-mobile-font='35px'] {
    font-size: 35px !important;
  }

  [data-mobile-font='36px'] {
    font-size: 36px !important;
  }

  [data-mobile-font='37px'] {
    font-size: 37px !important;
  }

  [data-mobile-font='38px'] {
    font-size: 38px !important;
  }

  [data-mobile-font='39px'] {
    font-size: 39px !important;
  }

  [data-mobile-font='40px'] {
    font-size: 40px !important;
  }

  [data-mobile-font='41px'] {
    font-size: 41px !important;
  }

  [data-mobile-font='42px'] {
    font-size: 42px !important;
  }

  [data-mobile-font='43px'] {
    font-size: 43px !important;
  }

  [data-mobile-font='44px'] {
    font-size: 44px !important;
  }

  [data-mobile-font='45px'] {
    font-size: 45px !important;
  }

  [data-mobile-font='46px'] {
    font-size: 46px !important;
  }

  [data-mobile-font='47px'] {
    font-size: 47px !important;
  }

  [data-mobile-font='48px'] {
    font-size: 48px !important;
  }

  [data-mobile-font='49px'] {
    font-size: 49px !important;
  }

  [data-mobile-font='50px'] {
    font-size: 50px !important;
  }

  [data-mobile-font='51px'] {
    font-size: 51px !important;
  }

  [data-mobile-font='52px'] {
    font-size: 52px !important;
  }

  [data-mobile-font='53px'] {
    font-size: 53px !important;
  }

  [data-mobile-font='54px'] {
    font-size: 54px !important;
  }

  [data-mobile-font='55px'] {
    font-size: 55px !important;
  }

  [data-mobile-font='56px'] {
    font-size: 56px !important;
  }

  [data-mobile-font='57px'] {
    font-size: 57px !important;
  }

  [data-mobile-font='58px'] {
    font-size: 58px !important;
  }

  [data-mobile-font='59px'] {
    font-size: 59px !important;
  }

  [data-mobile-font='60px'] {
    font-size: 60px !important;
  }

  [data-mobile-font='61px'] {
    font-size: 61px !important;
  }

  [data-mobile-font='62px'] {
    font-size: 62px !important;
  }

  [data-mobile-font='63px'] {
    font-size: 63px !important;
  }

  [data-mobile-font='64px'] {
    font-size: 64px !important;
  }

  [data-mobile-font='65px'] {
    font-size: 65px !important;
  }

  [data-mobile-font='66px'] {
    font-size: 66px !important;
  }

  [data-mobile-font='67px'] {
    font-size: 67px !important;
  }

  [data-mobile-font='68px'] {
    font-size: 68px !important;
  }

  [data-mobile-font='69px'] {
    font-size: 69px !important;
  }

  [data-mobile-font='70px'] {
    font-size: 70px !important;
  }

  [data-mobile-font='71px'] {
    font-size: 71px !important;
  }

  [data-mobile-font='72px'] {
    font-size: 72px !important;
  }

  [data-mobile-line-height='14px'] {
    line-height: 14px !important;
  }

  [data-mobile-line-height='15px'] {
    line-height: 15px !important;
  }

  [data-mobile-line-height='16px'] {
    line-height: 16px !important;
  }

  [data-mobile-line-height='17px'] {
    line-height: 17px !important;
  }

  [data-mobile-line-height='18px'] {
    line-height: 18px !important;
  }

  [data-mobile-line-height='19px'] {
    line-height: 19px !important;
  }

  [data-mobile-line-height='20px'] {
    line-height: 20px !important;
  }

  [data-mobile-line-height='21px'] {
    line-height: 21px !important;
  }

  [data-mobile-line-height='22px'] {
    line-height: 22px !important;
  }

  [data-mobile-line-height='23px'] {
    line-height: 23px !important;
  }

  [data-mobile-line-height='24px'] {
    line-height: 24px !important;
  }

  [data-mobile-line-height='25px'] {
    line-height: 25px !important;
  }

  [data-mobile-line-height='26px'] {
    line-height: 26px !important;
  }

  [data-mobile-line-height='27px'] {
    line-height: 27px !important;
  }

  [data-mobile-line-height='28px'] {
    line-height: 28px !important;
  }

  [data-mobile-line-height='29px'] {
    line-height: 29px !important;
  }

  [data-mobile-line-height='30px'] {
    line-height: 30px !important;
  }

  [data-mobile-line-height='31px'] {
    line-height: 31px !important;
  }

  [data-mobile-line-height='32px'] {
    line-height: 32px !important;
  }

  [data-mobile-line-height='33px'] {
    line-height: 33px !important;
  }

  [data-mobile-line-height='34px'] {
    line-height: 34px !important;
  }

  [data-mobile-line-height='35px'] {
    line-height: 35px !important;
  }

  [data-mobile-line-height='36px'] {
    line-height: 36px !important;
  }

  [data-mobile-line-height='37px'] {
    line-height: 37px !important;
  }

  [data-mobile-line-height='38px'] {
    line-height: 38px !important;
  }

  [data-mobile-line-height='39px'] {
    line-height: 39px !important;
  }

  [data-mobile-line-height='40px'] {
    line-height: 40px !important;
  }

  [data-mobile-line-height='41px'] {
    line-height: 41px !important;
  }

  [data-mobile-line-height='42px'] {
    line-height: 42px !important;
  }

  [data-mobile-line-height='43px'] {
    line-height: 43px !important;
  }

  [data-mobile-line-height='44px'] {
    line-height: 44px !important;
  }

  [data-mobile-line-height='45px'] {
    line-height: 45px !important;
  }

  [data-mobile-line-height='46px'] {
    line-height: 46px !important;
  }

  [data-mobile-line-height='47px'] {
    line-height: 47px !important;
  }

  [data-mobile-line-height='48px'] {
    line-height: 48px !important;
  }

  [data-mobile-line-height='49px'] {
    line-height: 49px !important;
  }

  [data-mobile-line-height='50px'] {
    line-height: 50px !important;
  }

  [data-mobile-line-height='51px'] {
    line-height: 51px !important;
  }

  [data-mobile-line-height='52px'] {
    line-height: 52px !important;
  }

  [data-mobile-line-height='53px'] {
    line-height: 53px !important;
  }

  [data-mobile-line-height='54px'] {
    line-height: 54px !important;
  }

  [data-mobile-line-height='55px'] {
    line-height: 55px !important;
  }

  [data-mobile-line-height='56px'] {
    line-height: 56px !important;
  }

  [data-mobile-line-height='57px'] {
    line-height: 57px !important;
  }

  [data-mobile-line-height='58px'] {
    line-height: 58px !important;
  }

  [data-mobile-line-height='59px'] {
    line-height: 59px !important;
  }

  [data-mobile-line-height='60px'] {
    line-height: 60px !important;
  }

  [data-mobile-line-height='61px'] {
    line-height: 61px !important;
  }

  [data-mobile-line-height='62px'] {
    line-height: 62px !important;
  }

  [data-mobile-line-height='63px'] {
    line-height: 63px !important;
  }

  [data-mobile-line-height='64px'] {
    line-height: 64px !important;
  }

  [data-mobile-line-height='65px'] {
    line-height: 65px !important;
  }

  [data-mobile-line-height='66px'] {
    line-height: 66px !important;
  }

  [data-mobile-line-height='67px'] {
    line-height: 67px !important;
  }

  [data-mobile-line-height='68px'] {
    line-height: 68px !important;
  }

  [data-mobile-line-height='69px'] {
    line-height: 69px !important;
  }

  [data-mobile-line-height='70px'] {
    line-height: 70px !important;
  }

  [data-mobile-line-height='71px'] {
    line-height: 71px !important;
  }

  [data-mobile-line-height='72px'] {
    line-height: 72px !important;
  }

  [data-mobile-line-height='73px'] {
    line-height: 73px !important;
  }

  [data-mobile-line-height='74px'] {
    line-height: 74px !important;
  }

  [data-mobile-line-height='75px'] {
    line-height: 75px !important;
  }

  [data-mobile-line-height='76px'] {
    line-height: 76px !important;
  }

  [data-mobile-line-height='77px'] {
    line-height: 77px !important;
  }

  [data-mobile-line-height='78px'] {
    line-height: 78px !important;
  }

  [data-mobile-line-height='79px'] {
    line-height: 79px !important;
  }

  [data-mobile-line-height='80px'] {
    line-height: 80px !important;
  }

  /* mobile-text-align
      ---------------*/

  .mobile-text-align-left {
    text-align: left !important;
  }

  .mobile-text-align-right {
    text-align: right !important;
  }

  .mobile-text-align-center {
    text-align: center !important;
  }

  /* Posts
      ---------------*/

  .small .loop-posts .post,
  .medium .loop-posts .post,
  .large .loop-posts .post,
  .small .loop-posts .grid-sizer,
  .medium .loop-posts .grid-sizer,
  .large .loop-posts .grid-sizer {
    width: 100%;
  }

  .fluid-masonry .loop-posts .post {
    width: 100% !important;
  }

  .post-meta:before {
    height: 100%;
    top: 0;
    margin-top: 0;
  }

  .chess .loop-posts {
    box-shadow: none;

    .post {
      margin-bottom: 40px;
    }
  }

  .blog-layout-list .post-inner {
    padding-bottom: 24px;
  }

  .commentlist .review {
    display: block;
    position: relative;
    padding: 0 0 24px 0;

    .comment-text {
      padding: 0;
    }
  }

  .comment-list {
    .comment .comment-body {
      display: block;
      padding: 32px;
    }

    .comment-gavatar,
    .comment-content {
      display: block;
      padding: 0;
    }
  }

  .commentlist .avatar {
    display: block;
    padding: 0;
  }

  .comment-list .comment-gavatar,
  .commentlist .avatar {
    margin: 0 auto 8px auto;
    position: static !important;
  }

  .bypostauthor .comment-gavatar {
    margin: 0 auto 20px auto;
  }

  .post-author-ind {
    display: block;
    width: 72px;
    margin: 0 auto 20px auto;
    position: static !important;
  }

  .commentlist .review .comment-text .star-rating {
    position: relative;
    margin: 0 auto 8px auto;
    top: 0;
    left: 0;
  }

  .comment-form-rating {
    .stars {
      display: none !important;
    }

    select {
      display: block !important;
      margin-top: 16px;
    }
  }

  .comment-meta,
  .commentlist .review .meta {
    margin-bottom: 16px;
    padding-right: 0;
    text-align: center;
  }

  .comment-meta .replay {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
  }

  .comment .edit-link {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .comment-meta .comment-author span {
    margin: 5px auto 5px auto;
    display: block;
    width: 50%;
  }

  .post-author-info {
    padding-left: 0;
  }

  .post-author-box > * {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .post-author-gavatar {
    top: 0;
    position: relative;
    margin-bottom: 25px;
  }

  .post-single-navigation.mob-hide-true {
    display: none;
  }

  .full #loop-posts {
    .format-quote .post-excerpt,
    .format-status .post-excerpt {
      font-size: 18px !important;
      line-height: 32px !important;
      letter-spacing: 0.5px;
    }
  }

  .single-post-page > {
    .format-quote .post-excerpt,
    .format-status .post-excerpt {
      font-size: 18px !important;
      line-height: 32px !important;
      letter-spacing: 0.5px;
    }
  }

  .full .format-quote .post-body-inner:before,
  .single-post-page > .format-quote .post-body-inner:before,
  .full .format-quote .post-body-inner:after,
  .single-post-page > .format-quote .post-body-inner:after {
    bottom: -16px;
  }

  .full .format-quote .post-body-inner:after {
    top: -16px;
  }

  .single-post-page > {
    .format-quote .post-body-inner:after {
      top: -16px;
    }

    .post > .post-inner .post-media {
      margin-bottom: 40px !important;
    }
  }

  .blog-layout-full .post-body .post-body-inner-wrap {
    padding: 0;
  }

  .related-posts .post .post-inner {
    padding: 0 !important;
  }

  .post-single-navigation {
    margin-top: 24px !important;
  }

  /* Widgets
      ---------------*/

  .widget {
    margin-bottom: 24px;

    .columns-mob-1 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 100%;
      }
    }

    .columns-mob-2 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 50%;
      }
    }

    .columns-mob-3 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 33.33333333333333%;
      }
    }

    .columns-mob-4 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 25%;
      }
    }

    .columns-mob-5 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 20%;
      }
    }

    .columns-mob-6 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 16.66666666666667%;
      }
    }

    .columns-mob-7 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 14.28571428571429%;
      }
    }

    .columns-mob-8 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 12.5%;
      }
    }

    .columns-mob-9 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 11.11111111111111%;
      }
    }

    .columns-mob-10 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 10%;
      }
    }
  }

  /* Car filter
      ---------------*/

  .car-filter form {
    padding: 12px 12px 4px 12px;
    overflow: initial;
  }

  .vin-decoder form {
    padding: 12px 12px 4px 12px;
    overflow: initial;
    padding: 12px;
  }

  .shop-cat-list-wrapper {
    max-height: 320px;
    overflow-y: scroll;
  }

  .shop-cat-list {
    grid-row-gap: 8px;
    grid-column-gap: 8px;
  }

  .atts-holder .default {
    display: block;
  }

  /* Products
      ---------------*/

  .loop-product {
    .post {
      .post-inner-wrapper {
        width: 100%;
        display: table;
      }

      .post-image {
        display: table-cell;
        vertical-align: middle;
      }

      .post-body {
        display: table-cell;
        vertical-align: middle;
        border-left: 1px solid #e0e0e0;
      }
    }

    .product {
      .post-image-overlay {
        display: none;
      }

      img {
        transform: none !important;
      }
    }

    .post .post-body-inner:before {
      display: none !important;
    }
  }

  .product-quick-view {
    display: none !important;
  }

  .loop-product .yith-wcwl-add-to-wishlist,
  .custom-product .yith-wcwl-add-to-wishlist,
  .loop-product .wooscp-btn,
  .custom-product .wooscp-btn {
    opacity: 1;
    -ms-transform: none;
    transform: none;
  }

  .loop-product .yith-wcwl-add-to-wishlist,
  .custom-product .yith-wcwl-add-to-wishlist {
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
  }

  .loop-product .wooscp-btn,
  .custom-product .wooscp-btn {
    top: auto;
    bottom: 0;
    right: auto;
    left: 32px;
  }

  .wishlist_table.responsive,
  .shop_table.cart.responsive {
    max-width: 480px;
  }

  .loop-product .post-title {
    font-size: 16px;
    line-height: 24px;
  }

  .shop_table {
    td,
    tr,
    tfoot th {
      padding: 8px;
    }
  }

  .cart_totals th,
  .customer_details th,
  .woocommerce-checkout-review-order-table th,
  .wc_payment_method,
  .woocommerce-order-overview li,
  .woocommerce-page address,
  .woocommerce table.wishlist_table tbody td {
    padding: 8px;
  }

  .shop_table {
    &.cart {
      td,
      tr,
      tfoot th {
        padding: 8px;
        display: block;
        width: 100%;
        max-width: 100% !important;
        text-align: center;
      }
    }

    .product-name .button {
      max-width: 100%;
      width: 100%;
    }
  }

  .wishlist_table .product-add-to-cart a {
    max-width: 100%;
    width: 100%;
  }

  .shop_table {
    &.cart {
      th,
      thead {
        display: none;
      }

      tr {
        td:not(:last-child) {
          border-bottom: 0;
        }

        &:not(:last-child) {
          padding-bottom: 24px;
        }

        padding: 0;
      }

      .product-quantity input {
        max-width: 100%;
      }
    }

    input {
      &[type='button'],
      &[type='reset'],
      &[type='submit'] {
        width: auto !important;
      }
    }

    button {
      width: auto !important;
    }
  }

  .woocommerce-message a:last-child,
  .woocommerce-info a:last-child,
  .woocommerce-error li a:last-child,
  .cart-empty a:last-child,
  .woocommerce-notice a:last-child {
    display: block !important;
    margin: 0 auto 16px auto !important;
  }

  .shop_table .product-name > a {
    font-size: inherit;
    line-height: inherit;
  }

  .product .summary table.variations {
    text-align: center;
    display: block;
    width: 100%;
    margin-top: 0;

    tbody,
    th,
    tr,
    td {
      display: block;
      width: 100%;
      margin-top: 0;
    }

    label {
      padding-right: 0;
      padding-bottom: 8px;
      text-align: left;
      display: block;
    }

    select,
    input {
      min-width: 100%;
    }
  }

  .woocommerce-tabs .tabs li.active a {
    z-index: 5;
  }

  form.cart {
    .quantity,
    button {
      width: 100%;
    }

    .quantity input {
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
    }
  }

  .woocommerce-tabs .tabs li {
    display: block;
    text-align: left;
    margin: 0;

    a {
      display: block;
      text-align: left;
      margin: 0;
    }

    margin-bottom: 4px;
  }

  #yith-quick-view-modal .yith-wcqv-wrapper {
    width: 100% !important;
    height: 100% !important;
    top: 0% !important;
    left: 0% !important;
  }

  #yith-quick-view-content div.summary {
    padding: 24px 6.6% !important;
  }

  .woocommerce-product-gallery__trigger {
    right: 16px;
  }

  .product .summary {
    table.variations {
      input,
      select {
        margin-bottom: 0;
      }
    }

    .quantity input {
      margin-bottom: 12px;
    }

    table.variations .reset_variations {
      line-height: 16px;
    }

    .button {
      width: 100% !important;
      max-width: 100% !important;
      margin-bottom: 24px !important;
    }
  }

  .woocommerce-product-gallery {
    margin-bottom: 24px !important;
  }

  .product {
    .single-product-wrapper .summary {
      margin-bottom: 24px !important;
    }

    .summary .et-timer li {
      max-width: 100% !important;
      display: inline-block !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    .single-product-progress,
    .stock-level {
      max-width: 100% !important;
    }
  }

  .single-product-wrapper .onsale {
    top: 48px !important;
    left: 24px !important;
    right: auto !important;
  }

  .woocommerce-product-gallery .flex-control-nav li {
    margin-right: 8px;
    width: 53px;
  }

  .woo-single-product .woocommerce-product-gallery .flex-control-nav li {
    width: 48px;
  }

  .woocommerce-product-gallery .flex-control-nav li {
    &:last-child,
    &:nth-child(5n + 5) {
      margin-right: 0;
    }
  }

  .product .single-product-wrapper .summary,
  .woo-single-product
    .product
    .single-product-wrapper
    .woocommerce-product-gallery {
    padding: 12px;
  }

  .product .summary .et-timer li {
    width: 50% !important;

    &:nth-child(3) div {
      padding-left: 0 !important;
    }

    &:nth-child(2) {
      div {
        padding-right: 0 !important;
      }

      span:before {
        display: none !important;
      }
    }
  }

  .loop-product .product .price,
  body:not(.addon-active) ul.products .product .price,
  .widget .woocommerce-Price-amount {
    font-size: 16px;
  }

  .woo-single-product.owl-carousel {
    padding-bottom: 32px !important;
  }

  /* car filter
        ---------------*/

  .car-filter .alert,
  .vin-decoder .alert {
    left: 0;
  }

  .widget .vin-result li {
    margin: 0;
    float: none;
    width: calc(100%);

    &:nth-child(4n + 1),
    &:nth-child(4n + 2) {
      background-color: #ffffff;
    }

    &:nth-child(even) {
      background-color: #f5f5f5 !important;
    }
  }

  /* Filter
      ---------------*/

  .enovathemes-filter {
    width: 100% !important;
    max-width: 100% !important;
    float: none;

    .filter-toggle {
      width: 100%;
      border-radius: 4px 4px 0 0;
      text-align: left;
    }

    .catalog-container {
      width: 100%;
      border-radius: 0 0 4px 4px;
    }

    .filter-wrapper .catalog-container > ul {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }

  .shop-cat-list {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .woocommerce-before-shop-loop.filter {
    .woocommerce-result-count,
    .woocommerce-ordering {
      float: none !important;
      display: block;
    }

    .woocommerce-result-count {
      padding-left: 8px !important;
      padding-right: 8px !important;
      margin-top: 16px;
    }

    .woocommerce-ordering {
      margin-right: 0 !important;
      padding-left: 8px !important;
      padding-right: 8px !important;

      &:after {
        display: none;
      }

      select {
        width: 100% !important;
      }
    }
  }

  /* et-client
      ---------------*/

  .grid.et-client-container {
    &[data-columns='3'],
    &[data-columns='5'] {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .browser-internet .grid.et-client-container {
    &[data-columns='3'] .et-client,
    &[data-columns='5'] .et-client {
      width: 100%;
    }
  }

  .grid.et-client-container {
    &[data-columns='2'],
    &[data-columns='4'],
    &[data-columns='6'] {
      grid-template-columns: repeat(2, 2fr);
    }
  }

  .browser-internet .grid.et-client-container {
    &[data-columns='2'] .et-client,
    &[data-columns='4'] .et-client,
    &[data-columns='6'] .et-client {
      width: 50%;
    }
  }

  .grid.et-client-container {
    &[data-columns='3'],
    &[data-columns='5'],
    &[data-columns='6'] {
      width: 100%;
    }
  }

  .border-true.et-client-container .plus {
    display: none !important;
  }

  /* et-icon-box
      ---------------*/

  .et-icon-box-container {
    &.column-2,
    &.column-3,
    &.column-4,
    &.column-5,
    &.column-6,
    &.column-7 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .browser-internet .et-icon-box-container {
    &.column-2 .et-icon-box,
    &.column-3 .et-icon-box,
    &.column-4 .et-icon-box,
    &.column-5 .et-icon-box,
    &.column-6 .et-icon-box,
    &.column-7 .et-icon-box {
      width: 100%;
    }
  }

  .et-icon-box {
    &.icon-position-right .et-icon {
      &.large + .et-icon-content,
      &.large-x + .et-icon-content {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }

    &.icon-position-left .et-icon {
      &.large + .et-icon-content,
      &.large-x + .et-icon-content {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }

  /* et-step-box
      ---------------*/

  .et-step-box-container {
    &.column-2,
    &.column-3,
    &.column-4 {
      grid-template-columns: repeat(1, 1fr);
    }

    &.column-1 .et-step-box:nth-child(2n + 2) {
      margin-left: 0;
    }
  }

  .compose-mode
    .et-step-box-container.column-1
    .vc_element:nth-child(2n + 2)
    .et-step-box {
    margin-left: 0;
  }

  /* et-testimonials
      ---------------*/

  .et-testimonial {
    .author-content-wrapper {
      width: 100%;
      display: table;
      position: relative;
      top: 0;
    }

    .author-info-wrapper {
      position: relative;
    }

    .author-content-wrapper > * {
      display: table-cell;
      vertical-align: top;
    }

    .author-image {
      max-width: 72px;
      width: 72px;
    }
  }

  /* et-gallery
      ---------------*/

  .et-gallery.grid {
    &[data-columns='6'] {
      .et-gallery-item,
      .grid-sizer {
        width: 33.33333333333333%;
      }
    }

    &[data-columns='9'] {
      .et-gallery-item,
      .grid-sizer {
        width: 33.33333333333333%;
      }
    }

    &[data-columns='7'] {
      .et-gallery-item,
      .grid-sizer {
        width: 20%;
      }
    }

    &[data-columns='10'] {
      .et-gallery-item,
      .grid-sizer {
        width: 20%;
      }
    }

    &[data-columns='4'] {
      .et-gallery-item,
      .grid-sizer {
        width: 50%;
      }
    }

    &[data-columns='8'] {
      .et-gallery-item,
      .grid-sizer {
        width: 50%;
      }
    }
  }

  .et-item-set {
    &[data-gap='2'] {
      margin-left: -1px;
      margin-right: -1px;

      .et-item {
        padding-left: 1px;
        padding-right: 1px;
        padding-bottom: 2px;
      }
    }

    &[data-gap='4'] {
      margin-left: -2px;
      margin-right: -2px;

      .et-item {
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 4px;
      }
    }

    &[data-gap='8'] {
      margin-left: -4px;
      margin-right: -4px;

      .et-item {
        padding-left: 4px;
        padding-right: 4px;
        padding-bottom: 8px;
      }
    }

    &[data-gap='16'] {
      margin-left: -8px;
      margin-right: -8px;

      .et-item {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 16px;
      }
    }
  }

  .et-gallery {
    &[data-gap='24'],
    &[data-gap='32'],
    &[data-gap='40'] {
      margin-left: -8px;
      margin-right: -8px;
    }

    &[data-gap='24'] .et-gallery-item,
    &[data-gap='32'] .et-gallery-item,
    &[data-gap='40'] .et-gallery-item {
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 16px;
    }
  }

  .compose-mode .et-gallery.grid {
    &[data-columns='6'],
    &[data-columns='9'] {
      grid-template-columns: repeat(3, 33.3333333333%);
    }

    &[data-columns='7'],
    &[data-columns='10'] {
      grid-template-columns: repeat(5, 20%);
    }

    &[data-columns='4'],
    &[data-columns='8'] {
      grid-template-columns: repeat(2, 50%);
    }
  }

  /* et-tabs
      ---------------*/

  .et-tab .tabset {
    display: block;
  }

  .tabset .tab {
    margin-bottom: 4px;
    padding-left: 32px;
    padding-right: 32px;
    text-align: left;
  }

  .tabs-container {
    .arrows-pos-top-right.owl-carousel,
    .arrows-pos-top-left.owl-carousel {
      margin-top: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 48px !important;
    }
  }

  .et-tab .tabs-container {
    .arrows-pos-top-right.owl-carousel .owl-nav,
    .arrows-pos-top-left.owl-carousel .owl-nav {
      top: auto !important;
      bottom: 0 !important;
    }
  }

  /* et-blockquote
      ---------------*/

  .et-blockquote {
    .author-image {
      display: none;
    }

    .author-wrapper {
      padding-left: 0;
    }
  }

  /* et-mailchimp
      ---------------*/

  .et-mailchimp .et-mailchimp-form > *:not(.alert) {
    display: block;
    margin-right: 0;
  }

  /* et-pricing-table
      ---------------*/

  .et-pricing-table.highlight-true .pricing-table-inner {
    padding: 64px 48px;
    top: 0;
  }

  /* et-banner
      ---------------*/

  .et-popup-banner-wrapper[data-mob='true'],
  .et-banner[data-mob='false'] {
    display: none;
  }

  /* timer
      ---------------*/

  .et-timer {
    display: block !important;

    ul {
      display: block !important;
    }

    li {
      max-width: 50%;
    }
  }

  /* popup
      ---------------*/

  @keyframes fadeOutLeft {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    to {
      opacity: 0;
      transform: translate3d(0, -15%, 0);
    }
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(0, -15%, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes fadeOutRight {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    to {
      opacity: 0;
      transform: translate3d(0, -15%, 0);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(0, -15%, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  /* et-makes
      ---------------*/

  .et-make-container {
    &.column-2,
    &.column-3,
    &.column-4,
    &.column-5,
    &.column-6,
    &.column-7,
    &.column-8 {
      grid-template-columns: repeat(2, 2fr) !important;
    }
  }

  .browser-internet .et-make-container {
    &.column-2 .et-item,
    &.column-3 .et-item,
    &.column-4 .et-item,
    &.column-5 .et-item,
    &.column-6 .et-item,
    &.column-7 .et-item,
    &.column-8 .et-item {
      width: 50%;
    }
  }

  /* Post based shortcodes
      ---------------*/

  .loop-posts.owl-carousel {
    .owl-prev {
      left: 6px;
    }

    .owl-next {
      right: 6px;
    }
  }

  .et-shortcode-posts .loop-posts.owl-carousel {
    .owl-prev {
      left: 11px;
    }

    .owl-next {
      right: 11px;
    }
  }

  .related .loop-posts.owl-carousel {
    .owl-prev {
      transform: none !important;
      top: auto !important;
      bottom: 8px !important;
      right: 54px !important;
      left: auto !important;
      opacity: 1 !important;
    }

    .owl-next {
      transform: none !important;
      top: auto !important;
      bottom: 8px !important;
      right: 6px !important;
      opacity: 1 !important;
    }
  }
}

/* Min-width: 768px
  ---------------*/

@media only screen and (min-width: 768px) {
  .hide768 {
    display: none !important;
  }

  .disable768.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .vc-video-bg .video-container {
    width: 170%;
    height: auto;
  }

  .wpb_column.vc-video-bg .video-container {
    width: 190% !important;
    height: auto !important;
    left: 50%;
    margin-left: -50% !important;
  }

  .gallery {
    &.gallery-columns-1 > .gallery-item {
      width: 100%;
    }

    &.gallery-columns-2 > .gallery-item {
      width: 50%;
    }

    &.gallery-columns-3 > .gallery-item {
      width: 33.33333333333333%;
    }

    &.gallery-columns-4 > .gallery-item {
      width: 25%;
    }

    &.gallery-columns-5 > .gallery-item {
      width: 20%;
    }

    &.gallery-columns-6 > .gallery-item {
      width: 16.66666666666667%;
    }

    &.gallery-columns-7 > .gallery-item {
      width: 14.28571428571429%;
    }

    &.gallery-columns-8 > .gallery-item {
      width: 12.5%;
    }

    &.gallery-columns-9 > .gallery-item {
      width: 11.11111111111111%;
    }

    &.gallery-columns-10 > .gallery-item {
      width: 10%;
    }
  }

  .text768-align-center {
    text-align: center !important;
  }

  .text768-align-left {
    text-align: left !important;
  }

  .text768-align-right {
    text-align: right !important;
  }

  .container,
  .wpml-ls-statics-post_translations,
  .header-menu > .mm-true > ul {
    width: 93.75%;
    max-width: 93.75%;
  }

  /* Row/column
      ---------------*/

  .vc_row {
    &.vc_column-gap-0 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }

    &.vc_column-gap-2 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 1px;
        padding-right: 1px;
      }
    }

    &.vc_column-gap-4 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 2px;
        padding-right: 2px;
      }
    }

    &.vc_column-gap-8 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    &.vc_column-gap-16 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &.vc_column-gap-24 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    &.vc_column-gap-32 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    &.vc_column-gap-40 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &.vc_column-gap-48 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    &.vc_column-gap-56 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 28px;
        padding-right: 28px;
      }
    }

    &.vc_column-gap-64 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    &.vc_column-gap-72 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 36px;
        padding-right: 36px;
      }
    }

    &.vc_column-gap-80 > {
      .vc_column_container,
      .container > .vc_column_container,
      .vc_element > .vc_column_container,
      .container > .vc_element > .vc_column_container {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    // &.vc_column-gap-2 {
    //   margin-left: -1px !important;
    //   margin-right: -1px !important;
    //   width: calc(100% + 2px);
    // }

    // &.vc_column-gap-4 {
    //   margin-left: -2px !important;
    //   margin-right: -2px !important;
    //   width: calc(100% + 4px);
    // }

    // &.vc_column-gap-8 {
    //   margin-left: -4px !important;
    //   margin-right: -4px !important;
    //   width: calc(100% + 8px);
    // }

    // &.vc_column-gap-16 {
    //   margin-left: -8px !important;
    //   margin-right: -8px !important;
    //   width: calc(100% + 16px);
    // }

    // &.vc_column-gap-24 {
    //   margin-left: -12px !important;
    //   margin-right: -12px !important;
    //   width: calc(100% + 24px);
    // }

    // &.vc_column-gap-32 {
    //   margin-left: -16px !important;
    //   margin-right: -16px !important;
    //   width: calc(100% + 32px);
    // }

    // &.vc_column-gap-40 {
    //   margin-left: -20px !important;
    //   margin-right: -20px !important;
    //   width: calc(100% + 40px);
    // }

    // &.vc_column-gap-48 {
    //   margin-left: -24px !important;
    //   margin-right: -24px !important;
    //   width: calc(100% + 48px);
    // }

    // &.vc_column-gap-56 {
    //   margin-left: -28px !important;
    //   margin-right: -28px !important;
    //   width: calc(100% + 56px);
    // }

    // &.vc_column-gap-64 {
    //   margin-left: -32px !important;
    //   margin-right: -32px !important;
    //   width: calc(100% + 64px);
    // }

    // &.vc_column-gap-72 {
    //   margin-left: -36px !important;
    //   margin-right: -36px !important;
    //   width: calc(100% + 72px);
    // }

    // &.vc_column-gap-80 {
    //   margin-left: -40px !important;
    //   margin-right: -40px !important;
    //   width: calc(100% + 80px);
    // }

    &.vc_column-gap-2 .grid-overlay {
      width: calc(100% - 2px);
      left: 1px;
    }

    &.vc_column-gap-4 .grid-overlay {
      width: calc(100% - 4px);
      left: 2px;
    }

    &.vc_column-gap-8 .grid-overlay {
      width: calc(100% - 8px);
      left: 4px;
    }

    &.vc_column-gap-16 .grid-overlay {
      width: calc(100% - 16px);
      left: 8px;
    }

    &.vc_column-gap-24 .grid-overlay {
      width: calc(100% - 24px);
      left: 12px;
    }

    &.vc_column-gap-32 .grid-overlay {
      width: calc(100% - 32px);
      left: 16px;
    }

    &.vc_column-gap-40 .grid-overlay {
      width: calc(100% - 40px);
      left: 20px;
    }

    &.vc_column-gap-48 .grid-overlay {
      width: calc(100% - 48px);
      left: 24px;
    }

    &.vc_column-gap-56 .grid-overlay {
      width: calc(100% - 56px);
      left: 28px;
    }

    &.vc_column-gap-64 .grid-overlay {
      width: calc(100% - 64px);
      left: 32px;
    }

    &.vc_column-gap-72 .grid-overlay {
      width: calc(100% - 72px);
      left: 36px;
    }

    &.vc_column-gap-80 .grid-overlay {
      width: calc(100% - 80px);
      left: 40px;
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-2
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-2 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-2 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-2 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-2 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-2 > .vc_element:before {
      left: 1px;
      width: calc(100% - 2px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-2
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-2 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-2
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 1px;
      width: calc(100% - 2px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-4
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-4 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-4 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-4 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-4 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-4 > .vc_element:before {
      left: 2px;
      width: calc(100% - 4px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-4
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-4 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-4
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 2px;
      width: calc(100% - 4px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-8
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-8 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-8 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-8 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-8 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-8 > .vc_element:before {
      left: 4px;
      width: calc(100% - 8px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-8
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-8 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-8
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 4px;
      width: calc(100% - 8px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-16
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-16 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-16 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-16 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-16 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-16 > .vc_element:before {
      left: 8px;
      width: calc(100% - 16px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-16
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-16 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-16
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 8px;
      width: calc(100% - 16px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-24
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-24 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-24 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-24 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-24 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-24 > .vc_element:before {
      left: 12px;
      width: calc(100% - 24px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-24
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-24 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-24
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 12px;
      width: calc(100% - 24px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-32
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-32 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-32 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-32 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-32 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-32 > .vc_element:before {
      left: 16px;
      width: calc(100% - 32px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-32
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-32 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-32
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 16px;
      width: calc(100% - 32px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-40
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-40 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-40 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-40 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-40 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-40 > .vc_element:before {
      left: 20px;
      width: calc(100% - 40px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-40
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-40 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-40
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 20px;
      width: calc(100% - 40px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-48
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-48 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-48 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-48 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-48 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-48 > .vc_element:before {
      left: 24px;
      width: calc(100% - 48px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-48
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-48 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-48
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 24px;
      width: calc(100% - 48px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-56
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-56 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-56 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-56 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-56 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-56 > .vc_element:before {
      left: 28px;
      width: calc(100% - 56px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-56
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-56 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-56
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 28px;
      width: calc(100% - 56px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-64
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-64 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-64 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-64 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-64 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-64 > .vc_element:before {
      left: 32px;
      width: calc(100% - 64px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-64
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-64 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-64
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 32px;
      width: calc(100% - 64px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-72
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-72 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-72 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-72 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-72 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-72 > .vc_element:before {
      left: 36px;
      width: calc(100% - 72px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-72
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-72 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-72
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 36px;
      width: calc(100% - 72px);
    }
  }

  .compose-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-80
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-80 > .container > .vc_element:before,
    &:hover > .wpb_row.vc_column-gap-80 > .container > .vc_element:before,
    &.vc_hold-hover > .vc_inner.vc_column-gap-80 > .vc_element:before,
    &.vc_hover > .vc_inner.vc_column-gap-80 > .vc_element:before,
    &:hover > .vc_inner.vc_column-gap-80 > .vc_element:before {
      left: 40px;
      width: calc(100% - 80px);
    }
  }

  .view-mode .vc_element {
    &.vc_hold-hover
      > .wpb_row.vc_column-gap-80
      > .container
      > .vc_element:before,
    &.vc_hover > .wpb_row.vc_column-gap-80 > .container > .vc_element:before,
    &:hover
      > .wpb_row.vc_column-gap-80
      > .container
      > .vc_element
      > .vc_column_container
      > :before {
      left: 40px;
      width: calc(100% - 80px);
    }
  }

  .wpb_row.vc_column-gap-4
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-4
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 1px;
  }

  .wpb_row.vc_column-gap-8
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-8
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 3px;
  }

  .wpb_row.vc_column-gap-16
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-16
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 7px;
  }

  .wpb_row.vc_column-gap-24
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-24
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 11px;
  }

  .wpb_row.vc_column-gap-32
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-32
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 15px;
  }

  .wpb_row.vc_column-gap-40
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-40
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 19px;
  }

  .wpb_row.vc_column-gap-48
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-48
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 23px;
  }

  .wpb_row.vc_column-gap-56
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-56
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 27px;
  }

  .wpb_row.vc_column-gap-64
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-64
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 31px;
  }

  .wpb_row.vc_column-gap-72
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-72
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 35px;
  }

  .wpb_row.vc_column-gap-80
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl,
  .vc_inner.vc_column-gap-80
    > .container
    > .vc_element
    > .vc_column_container
    + .vc_controls
    > .vc_controls-out-tl {
    left: 39px;
  }

  .compose-mode
    .vc_element.vc_empty:not(.vc_sorting-over)
    .vc_empty-element:after,
  .view-mode .vc_element.vc_empty:not(.vc_sorting-over) .vc_empty-element:after,
  .vc_add-element-not-empty-button {
    font-size: 12px !important;
    height: 28px !important;
    width: 28px !important;
    line-height: 28px !important;
    vertical-align: top;
  }

  .mobile-container {
    max-width: 320px;
  }

  /* Posts
      ---------------*/

  .grid .loop-posts,
  .et-shortcode-posts .loop-posts,
  .related-posts,
  .loop-product,
  body:not(.addon-active) ul.products {
    margin-left: -12px;
    margin-right: -12px;
  }

  .grid .loop-posts .post .post-inner,
  .et-shortcode-posts .post .post-inner,
  .related-posts .post .post-inner,
  .loop-product .product .post-inner,
  body:not(.addon-active) ul.products .post-inner {
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .blog-layout .loop-posts .post {
    float: left;
  }

  .small .loop-posts {
    .post,
    .grid-sizer {
      width: 50%;
    }
  }

  .large .loop-posts {
    .post,
    .grid-sizer {
      width: 50%;
    }
  }

  .medium .loop-posts {
    .post,
    .grid-sizer {
      width: 50%;
    }
  }

  body:not(.addon-active) ul.products:not(.owl-carousel) li {
    width: 50%;
  }

  .small .loop-product {
    .post,
    .grid-sizer {
      width: 33.33333333333333%;
    }
  }

  .medium .loop-product {
    .post,
    .grid-sizer {
      width: 33.33333333333333%;
    }
  }

  .large .loop-product {
    .post,
    .grid-sizer {
      width: 33.33333333333333%;
    }
  }

  .chess .loop-posts {
    .post,
    .grid-sizer {
      width: 50%;
    }

    .post {
      .post-body {
        height: 352px;
      }

      .post-excerpt {
        display: none;
      }
    }
  }

  .comment-list ul.children {
    position: relative;
    margin-left: 48px;

    .comment:before {
      content: '';
      display: block;
      position: absolute;
      left: 128px;
      top: -48px;
      height: 48px;
      width: 2px;
      background-color: #e0e0e0;
    }
  }

  .blog-layout-list {
    .post-image-wrapper + .post-body {
      padding-left: 48px;
    }

    .post-inner {
      position: relative;
      display: table;
      width: 100%;
    }

    .post-image,
    .post-body {
      position: relative;
      display: table-cell;
      vertical-align: middle;
    }

    .post-image,
    .post-body {
      width: 50%;
    }
  }

  .blog-content {
    #single-post-page > .post > .post-inner > .post-body,
    .related-posts-wrapper,
    .post-comments-section {
      margin: 0 auto;
      max-width: 100%;
    }

    #single-post-page {
      .post-title-section,
      .post-author-box,
      .post-single-navigation {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }

  .blog-layout-list {
    .post-inner {
      padding-bottom: 48px;
    }

    #loop-posts .post:first-child .post-inner {
      padding-top: 0;
    }
  }

  .full {
    .format-aside .post-body,
    .format-link .post-body,
    .format-quote .post-body {
      max-width: 100% !important;
      padding-top: 0 !important;
    }
  }

  .blog-layout-full #loop-posts .post .post-title {
    margin-bottom: 16px;
  }

  .blog-layout-list .post-image-wrapper {
    -ms-transform: translateY(8px);
    transform: translateY(8px);
  }

  .comment-list .comment .comment,
  .commentlist .review .comment {
    margin-left: 32px;
  }

  /* Products
      ---------------*/

  .woocommerce-product-gallery .flex-control-nav {
    text-align: left;
  }

  .woocommerce-MyAccount-navigation {
    width: 30%;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .woocommerce-MyAccount-content {
    width: 70%;
    padding-left: 24px;
  }

  .woocommerce-MyAccount-navigation {
    float: left;
  }

  .woocommerce-MyAccount-content {
    float: right;
  }

  .woocommerce-before-shop-loop {
    text-align: left;

    > * {
      display: inline-block;
      margin: 0;
    }
  }

  .woocommerce-result-count {
    float: right;
    top: -50%;
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    padding-right: 16px;
  }

  .product .single-product-wrapper {
    > *:not(.onsale) {
      width: 50%;
    }

    .summary {
      padding: 24px;
    }

    .woocommerce-product-gallery {
      padding-right: 24px;
    }

    display: flex;
  }

  /*list layoyt
        ---------------*/

  .list {
    .loop-product .product,
    .product-content .loop-product .product {
      width: 100% !important;
    }

    .loop-product {
      .post {
        .post-inner-wrapper {
          width: 100%;
          display: table;
        }

        .post-image {
          display: table-cell;
          vertical-align: middle;
        }

        .post-body {
          display: table-cell;
          vertical-align: middle;

          &:before {
            display: block;
            width: 1px;
            height: 100%;
            content: '';
            position: absolute;
            top: 0;
            background-color: #e0e0e0;
          }
        }
      }

      .product .post-body-inner:before {
        display: none;
      }

      .yith-wcwl-add-to-wishlist {
        right: 0;
        top: 0;
      }
    }

    .custom-product .yith-wcwl-add-to-wishlist,
    .loop-product .wooscp-btn,
    .custom-product .wooscp-btn {
      right: 0;
      top: 0;
    }

    .loop-product .wooscp-btn,
    .custom-product .wooscp-btn {
      top: 36px;
    }
  }

  .small.list .loop-product .post {
    .post-image {
      width: 150px;
    }

    .post-inner-wrapper {
      padding: 24px;
    }

    .post-body {
      padding: 0 0 0 48px !important;

      &:before {
        left: 24px;
      }
    }
  }

  .medium.list .loop-product .post {
    .post-image {
      width: 220px;
    }

    .post-inner-wrapper {
      padding: 32px;
    }

    .post-body {
      padding: 0 0 0 64px !important;

      &:before {
        left: 32px;
      }
    }
  }

  .large.list .loop-product .post {
    .post-image {
      width: 282px;
    }

    .post-inner-wrapper {
      padding: 40px;
    }

    .post-body {
      padding: 0 0 0 80px !important;

      &:before {
        left: 40px;
      }
    }
  }

  /* et-instagram
      ---------------*/

  .et-instagram.grid {
    &[data-columns='2'] .instagram-item {
      width: 50%;
    }

    &[data-columns='3'] .instagram-item {
      width: 33.33333333333333%;
    }

    &[data-columns='4'] .instagram-item,
    &[data-columns='5'] .instagram-item {
      width: 50%;
    }

    &[data-columns='6'] .instagram-item {
      width: 33.33333333333333%;
    }
  }

  /* et-timer
      ---------------*/

  .et-timer ul > li {
    width: auto;
    display: inline-block;
  }

  /* et-tabs
      ---------------*/

  .tabset .tab {
    width: auto;
    margin-right: 4px;
  }

  .center-true .tabset {
    text-align: center;
    width: 100%;

    .tab {
      padding: 16px 32px 16px 32px;
    }
  }

  .vertical {
    .tabset {
      width: 35%;
      float: left;
    }

    .tabs-container {
      width: 65%;
      float: left;
      padding: 0 0 0 24px;
    }

    .tabset .tab {
      padding: 16px 24px 16px 24px;
      width: 100%;
      margin-bottom: 4px;
      margin-right: 0;
      text-align: left;

      .tab-title-tag {
        font-size: 16px;
      }
    }
  }

  .center-true .tabset .tab .tab-title-tag {
    font-size: 16px;
  }

  .vertical .tabset .tab .icon,
  .center-true .tabset .tab .icon {
    margin-right: 8px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 24px;
  }

  .tabset .tab:last-child {
    margin-right: 0;
  }

  .vertical .tabset .tab {
    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      width: 3px;
      height: 0;
      left: auto;
      right: 0;
      top: 0;
      bottom: auto;
    }

    &.active:before {
      width: 4px;
      height: 100%;
    }
  }

  /* et-makes
      ---------------*/

  .et-make-container {
    &.column-3 {
      grid-template-columns: repeat(3, 3fr) !important;
    }

    &.column-4 {
      grid-template-columns: repeat(4, 4fr) !important;
    }

    &.column-5 {
      grid-template-columns: repeat(5, 5fr) !important;
    }

    &.column-6,
    &.column-7 {
      grid-template-columns: repeat(6, 6fr) !important;
    }

    &.column-8 {
      grid-template-columns: repeat(4, 4fr) !important;
    }
  }

  .browser-internet .et-make-container {
    &.column-3 .et-item {
      width: 33.33333333333333% !important;
    }

    &.column-4 .et-item {
      width: 25% !important;
    }

    &.column-5 .et-item {
      width: 20% !important;
    }

    &.column-6 .et-item,
    &.column-7 .et-item {
      width: 16.66666666666667% !important;
    }

    &.column-8 .et-item {
      width: 25% !important;
    }
  }

  /* woo-single-product
      ---------------*/

  .woo-single-product {
    padding: 24px !important;

    .product .single-product-wrapper .summary {
      padding: 0 0 0 24px;
      border-left: 1px solid #e0e0e0;
    }

    .woocommerce-product-gallery .flex-control-nav li {
      max-width: 53px;
    }

    &.arrows-pos-bottom-right.owl-carousel[data-gap='0'] .owl-nav,
    &.bullets-pos-bottom-right.owl-carousel[data-gap='0'] .owl-dots {
      bottom: 40px !important;
      right: 40px !important;
      top: auto !important;
    }
  }
}

/* Min-width: 768px and max-width: 1023px
  ---------------*/

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hide768-1024 {
    display: none !important;
  }

  .disable768-1024.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .text768-1023-align-center {
    text-align: center !important;
  }

  .text768-1023-align-left {
    text-align: left !important;
  }

  .text768-1023-align-right {
    text-align: right !important;
  }

  .demo-icon-pack {
    grid-template-columns: repeat(5, 5fr) !important;
  }

  /* Header
      ---------------*/

  .et-desktop.tablet-portrait-false,
  .et-mobile.tablet-portrait-false,
  .tablet-portrait-hide-important:not(.single-header) .et-mobile {
    display: none !important;

    + .header-placeholder {
      display: none !important;
    }
  }

  /* Page title section
      ---------------*/

  .tse {
    &.tablet-align-left {
      float: left !important;
    }

    &.tablet-align-right {
      float: right !important;
    }

    &.tablet-align-center {
      text-align: center !important;
      float: none !important;
    }
  }

  /* Responsive column padding
      ---------------*/

  .vc_column-inner {
    &[data-768-1024-left='0'] {
      padding-left: 0 !important;
    }

    &[data-768-1024-left='5'] {
      padding-left: 5% !important;
    }

    &[data-768-1024-left='10'] {
      padding-left: 10% !important;
    }

    &[data-768-1024-left='15'] {
      padding-left: 15% !important;
    }

    &[data-768-1024-left='20'] {
      padding-left: 20% !important;
    }

    &[data-768-1024-left='25'] {
      padding-left: 25% !important;
    }

    &[data-768-1024-left='30'] {
      padding-left: 30% !important;
    }

    &[data-768-1024-left='35'] {
      padding-left: 35% !important;
    }

    &[data-768-1024-left='40'] {
      padding-left: 40% !important;
    }

    &[data-768-1024-left='45'] {
      padding-left: 45% !important;
    }

    &[data-768-1024-left='50'] {
      padding-left: 50% !important;
    }

    &[data-768-1024-right='0'] {
      padding-right: 0 !important;
    }

    &[data-768-1024-right='5'] {
      padding-right: 5% !important;
    }

    &[data-768-1024-right='10'] {
      padding-right: 10% !important;
    }

    &[data-768-1024-right='15'] {
      padding-right: 15% !important;
    }

    &[data-768-1024-right='20'] {
      padding-right: 20% !important;
    }

    &[data-768-1024-right='25'] {
      padding-right: 25% !important;
    }

    &[data-768-1024-right='30'] {
      padding-right: 30% !important;
    }

    &[data-768-1024-right='35'] {
      padding-right: 35% !important;
    }

    &[data-768-1024-right='40'] {
      padding-right: 40% !important;
    }

    &[data-768-1024-right='45'] {
      padding-right: 45% !important;
    }

    &[data-768-1024-right='50'] {
      padding-right: 50% !important;
    }
  }

  /* tablet-font
      ---------------*/

  [data-tablet-portrait-font='14px'] {
    font-size: 14px !important;
  }

  [data-tablet-portrait-font='15px'] {
    font-size: 15px !important;
  }

  [data-tablet-portrait-font='16px'] {
    font-size: 16px !important;
  }

  [data-tablet-portrait-font='17px'] {
    font-size: 17px !important;
  }

  [data-tablet-portrait-font='18px'] {
    font-size: 18px !important;
  }

  [data-tablet-portrait-font='19px'] {
    font-size: 19px !important;
  }

  [data-tablet-portrait-font='20px'] {
    font-size: 20px !important;
  }

  [data-tablet-portrait-font='21px'] {
    font-size: 21px !important;
  }

  [data-tablet-portrait-font='22px'] {
    font-size: 22px !important;
  }

  [data-tablet-portrait-font='23px'] {
    font-size: 23px !important;
  }

  [data-tablet-portrait-font='24px'] {
    font-size: 24px !important;
  }

  [data-tablet-portrait-font='25px'] {
    font-size: 25px !important;
  }

  [data-tablet-portrait-font='26px'] {
    font-size: 26px !important;
  }

  [data-tablet-portrait-font='27px'] {
    font-size: 27px !important;
  }

  [data-tablet-portrait-font='28px'] {
    font-size: 28px !important;
  }

  [data-tablet-portrait-font='29px'] {
    font-size: 29px !important;
  }

  [data-tablet-portrait-font='30px'] {
    font-size: 30px !important;
  }

  [data-tablet-portrait-font='31px'] {
    font-size: 31px !important;
  }

  [data-tablet-portrait-font='32px'] {
    font-size: 32px !important;
  }

  [data-tablet-portrait-font='33px'] {
    font-size: 33px !important;
  }

  [data-tablet-portrait-font='34px'] {
    font-size: 34px !important;
  }

  [data-tablet-portrait-font='35px'] {
    font-size: 35px !important;
  }

  [data-tablet-portrait-font='36px'] {
    font-size: 36px !important;
  }

  [data-tablet-portrait-font='37px'] {
    font-size: 37px !important;
  }

  [data-tablet-portrait-font='38px'] {
    font-size: 38px !important;
  }

  [data-tablet-portrait-font='39px'] {
    font-size: 39px !important;
  }

  [data-tablet-portrait-font='40px'] {
    font-size: 40px !important;
  }

  [data-tablet-portrait-font='41px'] {
    font-size: 41px !important;
  }

  [data-tablet-portrait-font='42px'] {
    font-size: 42px !important;
  }

  [data-tablet-portrait-font='43px'] {
    font-size: 43px !important;
  }

  [data-tablet-portrait-font='44px'] {
    font-size: 44px !important;
  }

  [data-tablet-portrait-font='45px'] {
    font-size: 45px !important;
  }

  [data-tablet-portrait-font='46px'] {
    font-size: 46px !important;
  }

  [data-tablet-portrait-font='47px'] {
    font-size: 47px !important;
  }

  [data-tablet-portrait-font='48px'] {
    font-size: 48px !important;
  }

  [data-tablet-portrait-font='49px'] {
    font-size: 49px !important;
  }

  [data-tablet-portrait-font='50px'] {
    font-size: 50px !important;
  }

  [data-tablet-portrait-font='51px'] {
    font-size: 51px !important;
  }

  [data-tablet-portrait-font='52px'] {
    font-size: 52px !important;
  }

  [data-tablet-portrait-font='53px'] {
    font-size: 53px !important;
  }

  [data-tablet-portrait-font='54px'] {
    font-size: 54px !important;
  }

  [data-tablet-portrait-font='55px'] {
    font-size: 55px !important;
  }

  [data-tablet-portrait-font='56px'] {
    font-size: 56px !important;
  }

  [data-tablet-portrait-font='57px'] {
    font-size: 57px !important;
  }

  [data-tablet-portrait-font='58px'] {
    font-size: 58px !important;
  }

  [data-tablet-portrait-font='59px'] {
    font-size: 59px !important;
  }

  [data-tablet-portrait-font='60px'] {
    font-size: 60px !important;
  }

  [data-tablet-portrait-font='61px'] {
    font-size: 61px !important;
  }

  [data-tablet-portrait-font='62px'] {
    font-size: 62px !important;
  }

  [data-tablet-portrait-font='63px'] {
    font-size: 63px !important;
  }

  [data-tablet-portrait-font='64px'] {
    font-size: 64px !important;
  }

  [data-tablet-portrait-font='65px'] {
    font-size: 65px !important;
  }

  [data-tablet-portrait-font='66px'] {
    font-size: 66px !important;
  }

  [data-tablet-portrait-font='67px'] {
    font-size: 67px !important;
  }

  [data-tablet-portrait-font='68px'] {
    font-size: 68px !important;
  }

  [data-tablet-portrait-font='69px'] {
    font-size: 69px !important;
  }

  [data-tablet-portrait-font='70px'] {
    font-size: 70px !important;
  }

  [data-tablet-portrait-font='71px'] {
    font-size: 71px !important;
  }

  [data-tablet-portrait-font='72px'] {
    font-size: 72px !important;
  }

  [data-tablet-portrait-line-height='14px'] {
    line-height: 14px !important;
  }

  [data-tablet-portrait-line-height='15px'] {
    line-height: 15px !important;
  }

  [data-tablet-portrait-line-height='16px'] {
    line-height: 16px !important;
  }

  [data-tablet-portrait-line-height='17px'] {
    line-height: 17px !important;
  }

  [data-tablet-portrait-line-height='18px'] {
    line-height: 18px !important;
  }

  [data-tablet-portrait-line-height='19px'] {
    line-height: 19px !important;
  }

  [data-tablet-portrait-line-height='20px'] {
    line-height: 20px !important;
  }

  [data-tablet-portrait-line-height='21px'] {
    line-height: 21px !important;
  }

  [data-tablet-portrait-line-height='22px'] {
    line-height: 22px !important;
  }

  [data-tablet-portrait-line-height='23px'] {
    line-height: 23px !important;
  }

  [data-tablet-portrait-line-height='24px'] {
    line-height: 24px !important;
  }

  [data-tablet-portrait-line-height='25px'] {
    line-height: 25px !important;
  }

  [data-tablet-portrait-line-height='26px'] {
    line-height: 26px !important;
  }

  [data-tablet-portrait-line-height='27px'] {
    line-height: 27px !important;
  }

  [data-tablet-portrait-line-height='28px'] {
    line-height: 28px !important;
  }

  [data-tablet-portrait-line-height='29px'] {
    line-height: 29px !important;
  }

  [data-tablet-portrait-line-height='30px'] {
    line-height: 30px !important;
  }

  [data-tablet-portrait-line-height='31px'] {
    line-height: 31px !important;
  }

  [data-tablet-portrait-line-height='32px'] {
    line-height: 32px !important;
  }

  [data-tablet-portrait-line-height='33px'] {
    line-height: 33px !important;
  }

  [data-tablet-portrait-line-height='34px'] {
    line-height: 34px !important;
  }

  [data-tablet-portrait-line-height='35px'] {
    line-height: 35px !important;
  }

  [data-tablet-portrait-line-height='36px'] {
    line-height: 36px !important;
  }

  [data-tablet-portrait-line-height='37px'] {
    line-height: 37px !important;
  }

  [data-tablet-portrait-line-height='38px'] {
    line-height: 38px !important;
  }

  [data-tablet-portrait-line-height='39px'] {
    line-height: 39px !important;
  }

  [data-tablet-portrait-line-height='40px'] {
    line-height: 40px !important;
  }

  [data-tablet-portrait-line-height='41px'] {
    line-height: 41px !important;
  }

  [data-tablet-portrait-line-height='42px'] {
    line-height: 42px !important;
  }

  [data-tablet-portrait-line-height='43px'] {
    line-height: 43px !important;
  }

  [data-tablet-portrait-line-height='44px'] {
    line-height: 44px !important;
  }

  [data-tablet-portrait-line-height='45px'] {
    line-height: 45px !important;
  }

  [data-tablet-portrait-line-height='46px'] {
    line-height: 46px !important;
  }

  [data-tablet-portrait-line-height='47px'] {
    line-height: 47px !important;
  }

  [data-tablet-portrait-line-height='48px'] {
    line-height: 48px !important;
  }

  [data-tablet-portrait-line-height='49px'] {
    line-height: 49px !important;
  }

  [data-tablet-portrait-line-height='50px'] {
    line-height: 50px !important;
  }

  [data-tablet-portrait-line-height='51px'] {
    line-height: 51px !important;
  }

  [data-tablet-portrait-line-height='52px'] {
    line-height: 52px !important;
  }

  [data-tablet-portrait-line-height='53px'] {
    line-height: 53px !important;
  }

  [data-tablet-portrait-line-height='54px'] {
    line-height: 54px !important;
  }

  [data-tablet-portrait-line-height='55px'] {
    line-height: 55px !important;
  }

  [data-tablet-portrait-line-height='56px'] {
    line-height: 56px !important;
  }

  [data-tablet-portrait-line-height='57px'] {
    line-height: 57px !important;
  }

  [data-tablet-portrait-line-height='58px'] {
    line-height: 58px !important;
  }

  [data-tablet-portrait-line-height='59px'] {
    line-height: 59px !important;
  }

  [data-tablet-portrait-line-height='60px'] {
    line-height: 60px !important;
  }

  [data-tablet-portrait-line-height='61px'] {
    line-height: 61px !important;
  }

  [data-tablet-portrait-line-height='62px'] {
    line-height: 62px !important;
  }

  [data-tablet-portrait-line-height='63px'] {
    line-height: 63px !important;
  }

  [data-tablet-portrait-line-height='64px'] {
    line-height: 64px !important;
  }

  [data-tablet-portrait-line-height='65px'] {
    line-height: 65px !important;
  }

  [data-tablet-portrait-line-height='66px'] {
    line-height: 66px !important;
  }

  [data-tablet-portrait-line-height='67px'] {
    line-height: 67px !important;
  }

  [data-tablet-portrait-line-height='68px'] {
    line-height: 68px !important;
  }

  [data-tablet-portrait-line-height='69px'] {
    line-height: 69px !important;
  }

  [data-tablet-portrait-line-height='70px'] {
    line-height: 70px !important;
  }

  [data-tablet-portrait-line-height='71px'] {
    line-height: 71px !important;
  }

  [data-tablet-portrait-line-height='72px'] {
    line-height: 72px !important;
  }

  [data-tablet-portrait-line-height='73px'] {
    line-height: 73px !important;
  }

  [data-tablet-portrait-line-height='74px'] {
    line-height: 74px !important;
  }

  [data-tablet-portrait-line-height='75px'] {
    line-height: 75px !important;
  }

  [data-tablet-portrait-line-height='76px'] {
    line-height: 76px !important;
  }

  [data-tablet-portrait-line-height='77px'] {
    line-height: 77px !important;
  }

  [data-tablet-portrait-line-height='78px'] {
    line-height: 78px !important;
  }

  [data-tablet-portrait-line-height='79px'] {
    line-height: 79px !important;
  }

  [data-tablet-portrait-line-height='80px'] {
    line-height: 80px !important;
  }

  /* Posts
      ---------------*/

  .grid .loop-posts,
  .et-shortcode-posts .loop-posts,
  .loop-product {
    margin-left: -12px;
    margin-right: -12px;
  }

  .grid .loop-posts .post .post-inner,
  .et-shortcode-posts .post .post-inner,
  .loop-product .product .post-inner {
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .grid #loop-posts .post .post-body,
  .et-shortcode-posts .post .post-body,
  .medium .loop-product .product .post-body,
  .related-posts .post .post-body {
    padding: 24px 24px 24px 24px;
  }

  .chess .loop-posts .post .post-body {
    padding: 48px;
  }

  .medium .loop-product .product-category .post-body {
    padding: 24px;
  }

  .blog-layout-list .post-image {
    min-width: 200px !important;
    max-width: 100%;
  }

  .chess .loop-posts .post .post-title {
    font-size: 18px;
    line-height: 24px;
  }

  .fluid-masonry .loop-posts .post {
    width: 50% !important;
  }

  /* Widgets
      ---------------*/

  .widget {
    .columns-tablet-1 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 100%;
      }
    }

    .columns-tablet-2 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 50%;
      }
    }

    .columns-tablet-3 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 33.33333333333333%;
      }
    }

    .columns-tablet-4 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 25%;
      }
    }

    .columns-tablet-5 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 20%;
      }
    }

    .columns-tablet-6 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 16.66666666666667%;
      }
    }

    .columns-tablet-7 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 14.28571428571429%;
      }
    }

    .columns-tablet-8 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 12.5%;
      }
    }

    .columns-tablet-9 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 11.11111111111111%;
      }
    }

    .columns-tablet-10 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 10%;
      }
    }
  }

  /* Products
      ---------------*/

  .woocommerce-product-gallery .flex-control-nav li {
    width: 59px;

    &:last-child,
    &:nth-child(5n + 5) {
      margin-right: 0;
    }
  }

  #yith-quick-view-modal .yith-wcqv-wrapper {
    width: 600px !important;
    height: 85% !important;
    top: 7.5% !important;
    left: 50% !important;
    margin-left: -300px !important;
  }

  .product .summary .et-timer li {
    width: 50%;

    &:nth-child(3) div {
      padding-left: 0 !important;
    }

    &:nth-child(2) {
      div {
        padding-right: 0 !important;
      }

      span:before {
        display: none !important;
      }
    }
  }

  /* Filter
      ---------------*/

  .enovathemes-filter {
    width: 30% !important;

    .catalog-container {
      width: 333%;
    }

    &.column-3 .filter-wrapper .catalog-container > ul,
    &.column-5 .filter-wrapper .catalog-container > ul {
      grid-template-columns: repeat(3, 3fr);
    }
  }

  .shop-cat-list {
    &.column-2,
    &.column-3,
    &.column-4,
    &.column-5 {
      grid-template-columns: repeat(3, 3fr);
    }
  }

  .enovathemes-filter {
    &.column-2 .filter-wrapper .catalog-container > ul,
    &.column-4 .filter-wrapper .catalog-container > ul {
      grid-template-columns: repeat(2, 2fr);
    }
  }

  /* et-icon-box
      ---------------*/

  .et-icon-box-container {
    &.column-3,
    &.column-2 {
      grid-template-columns: repeat(1, 1fr);
    }

    &.column-4 {
      grid-template-columns: repeat(2, 2fr);
    }
  }

  .browser-internet .et-icon-box-container {
    &.column-2 .et-icon-box,
    &.column-3 .et-icon-box {
      width: 100%;
    }

    &.column-4 .et-icon-box {
      width: 50%;
    }
  }

  /* et-client
      ---------------*/

  .grid.et-client-container {
    &[data-columns='5'] .et-client {
      min-height: 130px;
    }

    &[data-columns='6'] {
      grid-template-columns: repeat(3, 3fr);
    }
  }

  .browser-internet .grid.et-client-container[data-columns='6'] .et-client {
    width: 33.33333333333333%;
  }

  /* et-step-box
      ---------------*/

  .et-step-box-container {
    &.column-3 {
      grid-template-columns: repeat(1, 1fr);
    }

    &.column-4 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  /* et-blockquote
      ---------------*/

  blockquote,
  q {
    font-size: 24px !important;
    line-height: 40px !important;
  }

  /* et-separator
      ---------------*/

  .et-icon-separator .line {
    max-width: 35%;
  }

  /* et-pricing-table
        ---------------*/

  .et-pricing-table {
    .price {
      font-size: 32px;
      line-height: 32px;
    }

    .currency {
      bottom: 0;
    }
  }

  /* et-person
      ---------------*/

  .et-person .person-content {
    padding: 32px;
  }

  /* et-banner
      ---------------*/

  .et-popup-banner-wrapper[data-tablet='true'],
  .et-banner[data-tablet='false'] {
    display: none;
  }

  /* et-instagram
      ---------------*/

  .et-instagram.grid {
    &[data-columns='3'] .feed-item-description,
    &[data-columns='6'] .feed-item-description {
      display: none;
    }

    &[data-columns='3'] .post-image-overlay-content p:first-child,
    &[data-columns='6'] .post-image-overlay-content p:first-child {
      margin-bottom: 0;
    }
  }
}

/* Min-width: 768px and max-width: 1279px
  ---------------*/

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  /* tablet-text-align
      ---------------*/

  .tablet-text-align-left {
    text-align: left !important;
  }

  .tablet-text-align-right {
    text-align: right !important;
  }

  .tablet-text-align-center {
    text-align: center !important;
  }
}

/* Max-width: 1023px
  ---------------*/

@media only screen and (max-width: 1023px) {
  .text1023-align-center {
    text-align: center !important;
  }

  .text1023-align-left {
    text-align: left !important;
  }

  .text1023-align-right {
    text-align: right !important;
  }

  /* Post
      ---------------*/

  .sidebar-toggle {
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 8px;
    text-align: center;
    width: 48px;
    height: 48px;
    line-height: 48px;
    cursor: pointer;

    &.inside {
      float: right;
    }
  }

  .layout-sidebar {
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-width: 320px;
    top: 0;
    left: 0;
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 12px !important;
    z-index: 9999999999999;
    background-color: #ffffff;
    overflow-y: scroll;
    transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1) !important;

    &.active {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
  }

  .inner-wrapper-sticky {
    position: static !important;
    width: auto !important;
    top: auto !important;
    left: auto !important;
    -ms-transform: none !important;
    transform: none !important;
  }

  /* Page
      ---------------*/

  .mob-page-title-text-align-center.rich-header {
    text-align: center !important;
  }

  .mob-page-title-text-align-left.rich-header {
    text-align: left !important;
  }

  .mob-page-title-text-align-right.rich-header {
    text-align: right !important;
  }

  .mob-page-title-text-align-left.rich-header {
    .rh-title {
      float: left;
    }

    .et-breadcrumbs {
      position: absolute;
      right: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      backface-visibility: hidden;
      margin-top: 0 !important;
    }
  }

  .mob-page-title-text-align-right.rich-header {
    float: right;

    .et-breadcrumbs {
      position: absolute;
      left: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      backface-visibility: hidden;
      margin-top: 0 !important;
    }
  }

  .mob-page-title-text-align-left.rich-header .rh-title > :only-child {
    margin-bottom: 0 !important;
  }

  /* Products
      ---------------*/

  #yith-quick-view-content {
    .product.type-product {
      display: block;

      &:not(tr) {
        display: block !important;
      }
    }

    div {
      &.woocommerce-product-gallery {
        width: 100% !important;
        opacity: 1 !important;
        float: none !important;
      }

      &.summary {
        width: 100% !important;
        opacity: 1 !important;
        float: none !important;
        padding: 56px 6.6%;
      }
    }
  }

  .related-products .owl-nav > {
    .owl-prev {
      left: 12px;
    }

    .owl-next {
      right: 12px;
    }
  }

  /* et-icon-box
      ---------------*/

  .et-icon-box {
    &.icon-alignment-right,
    &.icon-position-right {
      text-align: left;
    }

    &.icon-position-left .et-icon,
    &.icon-position-right .et-icon {
      position: absolute;
      top: 4px;
      left: 0 !important;
      right: auto !important;
    }

    &.icon-position-left .et-icon.small + .et-icon-content,
    &.icon-position-right .et-icon.small + .et-icon-content {
      padding-left: 48px;
      padding-right: 0;
    }

    &.icon-position-left .et-icon.full.small + .et-icon-content,
    &.icon-position-right .et-icon.full.small + .et-icon-content {
      padding-left: 64px;
      padding-right: 0;
    }

    &.icon-position-left .et-icon.large + .et-icon-content,
    &.icon-position-right .et-icon.large + .et-icon-content {
      padding-left: 96px;
      padding-right: 0;
    }

    &.icon-position-left .et-icon.large-x + .et-icon-content,
    &.icon-position-right .et-icon.large-x + .et-icon-content {
      padding-left: 128px;
      padding-right: 0;
    }
  }

  /* et-banner
      ---------------*/

  .et-banner .tagline-title {
    padding-right: 0;
    padding-bottom: 16px;
  }
}

/* Min-width: 1024px
  ---------------*/

@media only screen and (min-width: 1024px) {
  .hide1024 {
    display: none !important;
  }

  .disable1024.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .text1024-align-center {
    text-align: center !important;
  }

  .text1024-align-left {
    text-align: left !important;
  }

  .text1024-align-right {
    text-align: right !important;
  }

  .vc-video-bg .video-container {
    width: 150%;
  }

  /* Page
      ---------------*/

  .page-title-text-align-left.rich-header {
    .rh-title {
      float: left;
    }

    .et-breadcrumbs {
      position: absolute;
      right: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      backface-visibility: hidden;
      margin-top: 0 !important;
    }
  }

  .page-title-text-align-right.rich-header {
    .rh-title {
      float: right;
    }

    .et-breadcrumbs {
      position: absolute;
      left: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      backface-visibility: hidden;
      margin-top: 0 !important;
    }
  }

  .page-title-text-align-left.rich-header .rh-title > :only-child {
    margin-bottom: 0 !important;
  }

  /* Posts
      ---------------*/

  .grid #loop-posts .post .post-body,
  .et-shortcode-posts .post .post-body,
  .chess .loop-posts .post .post-body,
  .related-posts .post .post-body {
    padding: 24px;
  }

  .medium .loop-product .product .post-body {
    padding: 0 32px 32px 32px;
  }

  .small .loop-product .product-category .post-body,
  .medium .loop-product .product-category .post-body {
    padding: 24px;
  }

  .chess {
    .loop-posts .post .post-body {
      height: 240px;
    }

    .blog-content #loop-posts .post .post-body {
      height: 353px;
      padding: 48px;
    }
  }

  .small {
    .loop-posts {
      .post,
      .grid-sizer {
        width: 25%;
      }
    }

    .loop-product {
      .post,
      .grid-sizer {
        width: 25%;
      }
    }
  }

  .medium .loop-product {
    .post,
    .grid-sizer {
      width: 25%;
    }
  }

  body:not(.addon-active) ul.products:not(.owl-carousel) li {
    width: 25%;
  }

  .small .layout-content .loop-posts {
    .post,
    .grid-sizer {
      width: 33.33333333333333% !important;
    }
  }

  .medium .layout-content .loop-posts {
    .post,
    .grid-sizer {
      width: 33.33333333333333% !important;
    }
  }

  body:not(.addon-active) .layout-content ul.products:not(.owl-carousel) li {
    width: 33.33333333333333% !important;
  }

  .blog-layout-chess .layout-content .loop-posts {
    .post,
    .grid-sizer {
      width: 50% !important;
    }
  }

  .small .layout-content .loop-product {
    .post,
    .grid-sizer {
      width: 33.33333333333333% !important;
    }
  }

  .medium {
    .layout-content .loop-product {
      .post,
      .grid-sizer {
        width: 33.33333333333333% !important;
      }
    }

    .loop-posts {
      .post,
      .grid-sizer {
        width: 33.33333333333333%;
      }
    }
  }

  .large .loop-posts {
    .post,
    .grid-sizer {
      width: 33.33333333333333%;
    }

    &:not(.loop-product) {
      .post,
      .grid-sizer {
        width: 50%;
      }
    }
  }

  .medium.grid .blog-content .loop-posts {
    .post,
    .grid-sizer {
      width: 50% !important;
    }
  }

  .large .product-content .loop-posts {
    .post,
    .grid-sizer {
      width: 33.33333333333333% !important;
    }
  }

  .list .blog-content .loop-posts .post,
  .full .blog-content .loop-posts .post {
    width: 100% !important;
  }

  .list {
    .loop-product .product,
    .product-content .loop-product .product {
      width: 100% !important;
    }
  }

  .blog-layout-list {
    .post-image {
      width: 40%;
    }

    .post-body {
      width: 60%;
    }
  }

  .layout-sidebar {
    width: 25%;
  }

  .layout-sidebar-left .layout-sidebar {
    padding-right: 24px;
  }

  .layout-sidebar-right .layout-sidebar {
    padding-left: 24px;
  }

  .layout-sidebar-left .blog-sidebar {
    padding-right: 64px;
  }

  .layout-sidebar-right .blog-sidebar {
    padding-left: 64px;
  }

  .blog-sidebar:before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background-color: #e0e0e0;
    position: absolute;
    top: 0;
    left: 32px;
  }

  .layout-sidebar-left .blog-sidebar:before {
    left: auto;
    right: 32px;
  }

  .layout-content {
    width: 75%;
  }

  .layout-sidebar-left .layout-sidebar,
  .layout-sidebar-right .layout-content {
    float: left;
  }

  .layout-sidebar-left .layout-content,
  .layout-sidebar-right .layout-sidebar {
    float: right;
  }

  .blog-sidebar {
    width: 30%;
    position: relative;
  }

  .blog-content {
    width: 70%;
  }

  /* Products
      ---------------*/

  .woocommerce:after {
    content: '';
    display: table;
    clear: both;
  }

  #yith-quick-view-modal .yith-wcqv-main {
    overflow: hidden !important;
  }

  #yith-quick-view-content div {
    &.woocommerce-product-gallery,
    &.summary {
      width: 50% !important;
      opacity: 1 !important;
      float: none !important;
    }
  }

  .wishlist_table .yith-wcqv-button {
    margin-left: 8px;
  }

  #yith-quick-view-content div.summary {
    padding: 32px !important;
    border-left: 1px solid #e0e0e0;
    overflow-y: scroll;
  }

  .large.list-column-2 .product-content .loop-product {
    .product,
    .grid-sizer {
      width: 100% !important;
    }
  }

  .single-product-thumbnails-left .woocommerce-product-gallery {
    .flex-viewport {
      margin-left: 72px;
    }

    .flex-control-nav {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .woo-single-product.single-product-thumbnails-left
    .woocommerce-product-gallery
    .flex-control-nav {
    top: 1px;
    left: 1px;
  }

  .single-product-thumbnails-left
    .woocommerce-product-gallery
    .flex-control-nav
    li {
    display: block;
    margin-right: 0;
    margin-bottom: 8px;
    margin-top: 0;
    padding: 0;
    max-width: 56px;
  }

  .product-content .related-products {
    #loop-products .owl-nav {
      position: absolute !important;
      top: -40px;
      right: 13px;
    }

    .owl-nav > {
      * {
        opacity: 1;
        position: static !important;
        display: inline-block;
        vertical-align: middle;
      }

      .owl-next {
        margin-left: 8px;
      }
    }
  }

  .product .single-product-wrapper {
    .summary {
      padding: 32px;
    }

    .woocommerce-product-gallery {
      padding-right: 32px;
    }
  }

  /* Widgets
      ---------------*/

  .widget {
    .columns-desk-1 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 100%;
      }
    }

    .columns-desk-2 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 50%;
      }
    }

    .columns-desk-3 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 33.33333333333333%;
      }
    }

    .columns-desk-4 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 25%;
      }
    }

    .columns-desk-5 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 20%;
      }
    }

    .columns-desk-6 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 16.66666666666667%;
      }
    }

    .columns-desk-7 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 14.28571428571429%;
      }
    }

    .columns-desk-8 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 12.5%;
      }
    }

    .columns-desk-9 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 11.11111111111111%;
      }
    }

    .columns-desk-10 {
      &.flickr-image-list li,
      &.instagram-image-list li {
        width: 10%;
      }
    }
  }

  /* Car filter
        ----------------*/

  .car-filter.horizontal {
    form > div {
      display: table;
      padding: 0 4px;
      margin-left: -8px;
      margin-right: -8px;
      width: 100%;

      > div {
        display: table-cell;
        vertical-align: middle;
        padding: 0 4px;
        width: 20%;
      }
    }

    &.vin-true form > div > {
      div {
        width: 16%;
      }

      .vin-label {
        width: 4%;
      }
    }
  }

  /* et-instagram
      ---------------*/

  .et-instagram.grid {
    &[data-columns='4'] .instagram-item {
      width: 25%;
    }

    &[data-columns='5'] .instagram-item {
      width: 20%;
    }

    &[data-columns='6'] .instagram-item {
      width: 33.33333333333333%;
    }
  }

  /* et-tabs
      ---------------*/

  .vertical {
    .tabset {
      width: 20%;
    }

    .tabs-container {
      width: 80%;
    }
  }

  /* woo-single-product
      ---------------*/

  .woo-single-product {
    padding: 40px !important;

    .product .single-product-wrapper .summary {
      padding: 0 0 0 32px;
    }

    .woocommerce-product-gallery .flex-control-nav li {
      max-width: 64px;
    }

    .woocommerce-product-gallery__wrapper,
    .flex-viewport {
      max-height: 528px !important;
    }
  }
}

/* Min-width: 1024px and max-width: 1279px
  ---------------*/

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .hide1024-1280 {
    display: none !important;
  }

  .disable1024-1280.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .text1024-1279-align-center {
    text-align: center !important;
  }

  .text1024-1279-align-left {
    text-align: left !important;
  }

  .text1024-1279-align-right {
    text-align: right !important;
  }

  /* Header
      ---------------*/

  .et-desktop.tablet-landscape-false,
  .et-mobile.tablet-landscape-false,
  .tablet-landscape-hide-important:not(.single-header) .et-mobile {
    display: none !important;

    + .header-placeholder {
      display: none !important;
    }
  }

  /* Responsive column padding
      ---------------*/

  .vc_column-inner {
    &[data-1024-1280-left='0'] {
      padding-left: 0 !important;
    }

    &[data-1024-1280-left='5'] {
      padding-left: 5% !important;
    }

    &[data-1024-1280-left='10'] {
      padding-left: 10% !important;
    }

    &[data-1024-1280-left='15'] {
      padding-left: 15% !important;
    }

    &[data-1024-1280-left='20'] {
      padding-left: 20% !important;
    }

    &[data-1024-1280-left='25'] {
      padding-left: 25% !important;
    }

    &[data-1024-1280-left='30'] {
      padding-left: 30% !important;
    }

    &[data-1024-1280-left='35'] {
      padding-left: 35% !important;
    }

    &[data-1024-1280-left='40'] {
      padding-left: 40% !important;
    }

    &[data-1024-1280-left='45'] {
      padding-left: 45% !important;
    }

    &[data-1024-1280-left='50'] {
      padding-left: 50% !important;
    }

    &[data-1024-1280-right='0'] {
      padding-right: 0 !important;
    }

    &[data-1024-1280-right='5'] {
      padding-right: 5% !important;
    }

    &[data-1024-1280-right='10'] {
      padding-right: 10% !important;
    }

    &[data-1024-1280-right='15'] {
      padding-right: 15% !important;
    }

    &[data-1024-1280-right='20'] {
      padding-right: 20% !important;
    }

    &[data-1024-1280-right='25'] {
      padding-right: 25% !important;
    }

    &[data-1024-1280-right='30'] {
      padding-right: 30% !important;
    }

    &[data-1024-1280-right='35'] {
      padding-right: 35% !important;
    }

    &[data-1024-1280-right='40'] {
      padding-right: 40% !important;
    }

    &[data-1024-1280-right='45'] {
      padding-right: 45% !important;
    }

    &[data-1024-1280-right='50'] {
      padding-right: 50% !important;
    }
  }

  /* tablet-font
      ---------------*/

  [data-tablet-landscape-font='14px'] {
    font-size: 14px !important;
  }

  [data-tablet-landscape-font='15px'] {
    font-size: 15px !important;
  }

  [data-tablet-landscape-font='16px'] {
    font-size: 16px !important;
  }

  [data-tablet-landscape-font='17px'] {
    font-size: 17px !important;
  }

  [data-tablet-landscape-font='18px'] {
    font-size: 18px !important;
  }

  [data-tablet-landscape-font='19px'] {
    font-size: 19px !important;
  }

  [data-tablet-landscape-font='20px'] {
    font-size: 20px !important;
  }

  [data-tablet-landscape-font='21px'] {
    font-size: 21px !important;
  }

  [data-tablet-landscape-font='22px'] {
    font-size: 22px !important;
  }

  [data-tablet-landscape-font='23px'] {
    font-size: 23px !important;
  }

  [data-tablet-landscape-font='24px'] {
    font-size: 24px !important;
  }

  [data-tablet-landscape-font='25px'] {
    font-size: 25px !important;
  }

  [data-tablet-landscape-font='26px'] {
    font-size: 26px !important;
  }

  [data-tablet-landscape-font='27px'] {
    font-size: 27px !important;
  }

  [data-tablet-landscape-font='28px'] {
    font-size: 28px !important;
  }

  [data-tablet-landscape-font='29px'] {
    font-size: 29px !important;
  }

  [data-tablet-landscape-font='30px'] {
    font-size: 30px !important;
  }

  [data-tablet-landscape-font='31px'] {
    font-size: 31px !important;
  }

  [data-tablet-landscape-font='32px'] {
    font-size: 32px !important;
  }

  [data-tablet-landscape-font='33px'] {
    font-size: 33px !important;
  }

  [data-tablet-landscape-font='34px'] {
    font-size: 34px !important;
  }

  [data-tablet-landscape-font='35px'] {
    font-size: 35px !important;
  }

  [data-tablet-landscape-font='36px'] {
    font-size: 36px !important;
  }

  [data-tablet-landscape-font='37px'] {
    font-size: 37px !important;
  }

  [data-tablet-landscape-font='38px'] {
    font-size: 38px !important;
  }

  [data-tablet-landscape-font='39px'] {
    font-size: 39px !important;
  }

  [data-tablet-landscape-font='40px'] {
    font-size: 40px !important;
  }

  [data-tablet-landscape-font='41px'] {
    font-size: 41px !important;
  }

  [data-tablet-landscape-font='42px'] {
    font-size: 42px !important;
  }

  [data-tablet-landscape-font='43px'] {
    font-size: 43px !important;
  }

  [data-tablet-landscape-font='44px'] {
    font-size: 44px !important;
  }

  [data-tablet-landscape-font='45px'] {
    font-size: 45px !important;
  }

  [data-tablet-landscape-font='46px'] {
    font-size: 46px !important;
  }

  [data-tablet-landscape-font='47px'] {
    font-size: 47px !important;
  }

  [data-tablet-landscape-font='48px'] {
    font-size: 48px !important;
  }

  [data-tablet-landscape-font='49px'] {
    font-size: 49px !important;
  }

  [data-tablet-landscape-font='50px'] {
    font-size: 50px !important;
  }

  [data-tablet-landscape-font='51px'] {
    font-size: 51px !important;
  }

  [data-tablet-landscape-font='52px'] {
    font-size: 52px !important;
  }

  [data-tablet-landscape-font='53px'] {
    font-size: 53px !important;
  }

  [data-tablet-landscape-font='54px'] {
    font-size: 54px !important;
  }

  [data-tablet-landscape-font='55px'] {
    font-size: 55px !important;
  }

  [data-tablet-landscape-font='56px'] {
    font-size: 56px !important;
  }

  [data-tablet-landscape-font='57px'] {
    font-size: 57px !important;
  }

  [data-tablet-landscape-font='58px'] {
    font-size: 58px !important;
  }

  [data-tablet-landscape-font='59px'] {
    font-size: 59px !important;
  }

  [data-tablet-landscape-font='60px'] {
    font-size: 60px !important;
  }

  [data-tablet-landscape-font='61px'] {
    font-size: 61px !important;
  }

  [data-tablet-landscape-font='62px'] {
    font-size: 62px !important;
  }

  [data-tablet-landscape-font='63px'] {
    font-size: 63px !important;
  }

  [data-tablet-landscape-font='64px'] {
    font-size: 64px !important;
  }

  [data-tablet-landscape-font='65px'] {
    font-size: 65px !important;
  }

  [data-tablet-landscape-font='66px'] {
    font-size: 66px !important;
  }

  [data-tablet-landscape-font='67px'] {
    font-size: 67px !important;
  }

  [data-tablet-landscape-font='68px'] {
    font-size: 68px !important;
  }

  [data-tablet-landscape-font='69px'] {
    font-size: 69px !important;
  }

  [data-tablet-landscape-font='70px'] {
    font-size: 70px !important;
  }

  [data-tablet-landscape-font='71px'] {
    font-size: 71px !important;
  }

  [data-tablet-landscape-font='72px'] {
    font-size: 72px !important;
  }

  [data-tablet-landscape-line-height='14px'] {
    line-height: 14px !important;
  }

  [data-tablet-landscape-line-height='15px'] {
    line-height: 15px !important;
  }

  [data-tablet-landscape-line-height='16px'] {
    line-height: 16px !important;
  }

  [data-tablet-landscape-line-height='17px'] {
    line-height: 17px !important;
  }

  [data-tablet-landscape-line-height='18px'] {
    line-height: 18px !important;
  }

  [data-tablet-landscape-line-height='19px'] {
    line-height: 19px !important;
  }

  [data-tablet-landscape-line-height='20px'] {
    line-height: 20px !important;
  }

  [data-tablet-landscape-line-height='21px'] {
    line-height: 21px !important;
  }

  [data-tablet-landscape-line-height='22px'] {
    line-height: 22px !important;
  }

  [data-tablet-landscape-line-height='23px'] {
    line-height: 23px !important;
  }

  [data-tablet-landscape-line-height='24px'] {
    line-height: 24px !important;
  }

  [data-tablet-landscape-line-height='25px'] {
    line-height: 25px !important;
  }

  [data-tablet-landscape-line-height='26px'] {
    line-height: 26px !important;
  }

  [data-tablet-landscape-line-height='27px'] {
    line-height: 27px !important;
  }

  [data-tablet-landscape-line-height='28px'] {
    line-height: 28px !important;
  }

  [data-tablet-landscape-line-height='29px'] {
    line-height: 29px !important;
  }

  [data-tablet-landscape-line-height='30px'] {
    line-height: 30px !important;
  }

  [data-tablet-landscape-line-height='31px'] {
    line-height: 31px !important;
  }

  [data-tablet-landscape-line-height='32px'] {
    line-height: 32px !important;
  }

  [data-tablet-landscape-line-height='33px'] {
    line-height: 33px !important;
  }

  [data-tablet-landscape-line-height='34px'] {
    line-height: 34px !important;
  }

  [data-tablet-landscape-line-height='35px'] {
    line-height: 35px !important;
  }

  [data-tablet-landscape-line-height='36px'] {
    line-height: 36px !important;
  }

  [data-tablet-landscape-line-height='37px'] {
    line-height: 37px !important;
  }

  [data-tablet-landscape-line-height='38px'] {
    line-height: 38px !important;
  }

  [data-tablet-landscape-line-height='39px'] {
    line-height: 39px !important;
  }

  [data-tablet-landscape-line-height='40px'] {
    line-height: 40px !important;
  }

  [data-tablet-landscape-line-height='41px'] {
    line-height: 41px !important;
  }

  [data-tablet-landscape-line-height='42px'] {
    line-height: 42px !important;
  }

  [data-tablet-landscape-line-height='43px'] {
    line-height: 43px !important;
  }

  [data-tablet-landscape-line-height='44px'] {
    line-height: 44px !important;
  }

  [data-tablet-landscape-line-height='45px'] {
    line-height: 45px !important;
  }

  [data-tablet-landscape-line-height='46px'] {
    line-height: 46px !important;
  }

  [data-tablet-landscape-line-height='47px'] {
    line-height: 47px !important;
  }

  [data-tablet-landscape-line-height='48px'] {
    line-height: 48px !important;
  }

  [data-tablet-landscape-line-height='49px'] {
    line-height: 49px !important;
  }

  [data-tablet-landscape-line-height='50px'] {
    line-height: 50px !important;
  }

  [data-tablet-landscape-line-height='51px'] {
    line-height: 51px !important;
  }

  [data-tablet-landscape-line-height='52px'] {
    line-height: 52px !important;
  }

  [data-tablet-landscape-line-height='53px'] {
    line-height: 53px !important;
  }

  [data-tablet-landscape-line-height='54px'] {
    line-height: 54px !important;
  }

  [data-tablet-landscape-line-height='55px'] {
    line-height: 55px !important;
  }

  [data-tablet-landscape-line-height='56px'] {
    line-height: 56px !important;
  }

  [data-tablet-landscape-line-height='57px'] {
    line-height: 57px !important;
  }

  [data-tablet-landscape-line-height='58px'] {
    line-height: 58px !important;
  }

  [data-tablet-landscape-line-height='59px'] {
    line-height: 59px !important;
  }

  [data-tablet-landscape-line-height='60px'] {
    line-height: 60px !important;
  }

  [data-tablet-landscape-line-height='61px'] {
    line-height: 61px !important;
  }

  [data-tablet-landscape-line-height='62px'] {
    line-height: 62px !important;
  }

  [data-tablet-landscape-line-height='63px'] {
    line-height: 63px !important;
  }

  [data-tablet-landscape-line-height='64px'] {
    line-height: 64px !important;
  }

  [data-tablet-landscape-line-height='65px'] {
    line-height: 65px !important;
  }

  [data-tablet-landscape-line-height='66px'] {
    line-height: 66px !important;
  }

  [data-tablet-landscape-line-height='67px'] {
    line-height: 67px !important;
  }

  [data-tablet-landscape-line-height='68px'] {
    line-height: 68px !important;
  }

  [data-tablet-landscape-line-height='69px'] {
    line-height: 69px !important;
  }

  [data-tablet-landscape-line-height='70px'] {
    line-height: 70px !important;
  }

  [data-tablet-landscape-line-height='71px'] {
    line-height: 71px !important;
  }

  [data-tablet-landscape-line-height='72px'] {
    line-height: 72px !important;
  }

  [data-tablet-landscape-line-height='73px'] {
    line-height: 73px !important;
  }

  [data-tablet-landscape-line-height='74px'] {
    line-height: 74px !important;
  }

  [data-tablet-landscape-line-height='75px'] {
    line-height: 75px !important;
  }

  [data-tablet-landscape-line-height='76px'] {
    line-height: 76px !important;
  }

  [data-tablet-landscape-line-height='77px'] {
    line-height: 77px !important;
  }

  [data-tablet-landscape-line-height='78px'] {
    line-height: 78px !important;
  }

  [data-tablet-landscape-line-height='79px'] {
    line-height: 79px !important;
  }

  [data-tablet-landscape-line-height='80px'] {
    line-height: 80px !important;
  }

  /* Posts
      ---------------*/

  .blog-layout-list .blog-content .post-image {
    min-width: 200px !important;
    max-width: 100%;
  }

  .chess.blog-content #loop-posts .post .post-title {
    font-size: 18px;
    line-height: 24px;
  }

  /* Products
      ---------------*/

  .woocommerce-product-gallery .flex-control-nav li {
    max-width: 81px;
  }

  .product-content .woocommerce-product-gallery .flex-control-nav li {
    max-width: 58px;
  }

  .woocommerce-product-gallery .flex-control-nav li {
    &:last-child,
    &:nth-child(5n + 5) {
      margin-right: 0;
    }
  }

  .slick-thumbnail-navigation ul li {
    max-width: 121px;
  }

  .small.list {
    .product-content .loop-product {
      .product,
      .grid-sizer {
        width: 100% !important;
      }
    }

    &.column-2 .product-content .loop-product {
      .product,
      .grid-sizer {
        width: 100% !important;
      }
    }
  }

  .product-content .product .summary {
    table.variations {
      input,
      select {
        width: 243px;
      }
    }

    .et-timer li {
      width: 50%;

      &:nth-child(3) div {
        padding-left: 0 !important;
      }

      &:nth-child(2) {
        div {
          padding-right: 0 !important;
        }

        span:before {
          display: none !important;
        }
      }
    }
  }

  /* Filter
      ---------------*/

  .layout-content .enovathemes-filter {
    width: 40% !important;

    .catalog-container {
      width: 250%;
    }

    &.column-3 .filter-wrapper .catalog-container > ul,
    &.column-5 .filter-wrapper .catalog-container > ul {
      grid-template-columns: repeat(3, 3fr);
    }
  }

  .shop-cat-list {
    &.column-3,
    &.column-5 {
      grid-template-columns: repeat(3, 3fr);
    }
  }

  .layout-content .enovathemes-filter {
    &.column-2 .filter-wrapper .catalog-container > ul,
    &.column-4 .filter-wrapper .catalog-container > ul {
      grid-template-columns: repeat(2, 2fr);
    }
  }

  .shop-cat-list {
    &.column-2,
    &.column-4 {
      grid-template-columns: repeat(2, 2fr);
    }
  }

  /* et-icon-box
      ---------------*/

  .et-icon-box-container.column-4 {
    grid-template-columns: repeat(2, 2fr);
  }

  .browser-internet .et-icon-box-container.column-4 .et-icon-box {
    width: 25%;
  }

  /* et-step-box
      ---------------*/

  .et-step-box-container.column-4 {
    grid-template-columns: repeat(2, 2fr);
  }

  .browser-internet .et-step-box-container.column-4 .et-step-box {
    width: 25%;
  }

  /* et-pricing-table
        ---------------*/

  .et-pricing-table {
    .price {
      font-size: 48px;
      line-height: 48px;
    }

    .currency {
      bottom: -2px;
    }
  }

  .vc_col-sm-3 .et-pricing-table .price,
  .vc_col-lg-3 .et-pricing-table .price,
  .vc_col-md-3 .et-pricing-table .price,
  .vc_col-sm-3 .et-pricing-table .currency,
  .vc_col-lg-3 .et-pricing-table .currency,
  .vc_col-md-3 .et-pricing-table .currency {
    font-size: 28px;
    line-height: 28px;
  }

  .vc_col-sm-3 .et-pricing-table .currency,
  .vc_col-lg-3 .et-pricing-table .currency,
  .vc_col-md-3 .et-pricing-table .currency {
    bottom: -1px;
  }
}

/* Max-width: 1279px
  ---------------*/

@media only screen and (max-width: 1279px) {
  .text1279-align-center {
    text-align: center !important;
  }

  .text1279-align-left {
    text-align: left !important;
  }

  .text1279-align-right {
    text-align: right !important;
  }

  .header-mobile {
    position: relative;
    z-index: 79;
  }

  .fullscreen-menu {
    height: 480px;
    padding: 30px 0;
  }

  .logo-modal {
    display: none;
  }

  .fullscreen-modal-close {
    top: 0;
    right: 0;
  }

  .zoomImg {
    display: none !important;
  }

  .sidebar-navigation #wrap {
    padding-left: 0;
    padding-right: 0;
  }

  .mobile-container,
  .modal-container {
    overflow-y: scroll;
  }

  /* Post
      ---------------*/

  .post-social-share {
    display: block;
    margin: 0 0 16px 0;
    width: 200px !important;
    position: static !important;

    > .social-links > a {
      margin-right: 8px;
    }
  }
}

/* Min-width: 1280px
  ---------------*/

@media only screen and (min-width: 1280px) {
  .hide1280 {
    display: none !important;
  }

  .disable1280.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .layout-container-wide > .container,
  .product-container-wide > .container {
    width: 100% !important;
    max-width: 100% !important;
  }

  .text1280-align-center {
    text-align: center !important;
  }

  .text1280-align-left {
    text-align: left !important;
  }

  .text1280-align-right {
    text-align: right !important;
  }

  .container,
  .wpml-ls-statics-post_translations,
  .header-menu > .mm-true > ul {
    max-width: 1200px;
  }

  .vc-video-bg {
    .video-container {
      width: 100%;
    }

    &.vc-parallax .video-container {
      -ms-transform: translateY(0);
      transform: translateY(0);
      top: -5px;
      height: calc(100vh * 1.2) !important;
      width: auto !important;
    }
  }

  /* Boxed layout
      ---------------*/

  .layout-boxed:not(.sidebar-navigation) #wrap {
    max-width: 1280px;
    margin: 0 auto;
    overflow: hidden;
  }

  /* Row/column
      ---------------*/

  .vc_row {
    &.vc_column-gap-2 > .container {
      max-width: 1202px;
    }

    &.vc_column-gap-4 > .container {
      max-width: 1204px;
    }

    &.vc_column-gap-8 > .container {
      max-width: 1208px;
    }

    &.vc_column-gap-16 > .container {
      max-width: 1216px;
    }

    &.vc_column-gap-24 > .container {
      max-width: 1224px;
    }

    &.vc_column-gap-32 > .container {
      max-width: 1232px;
    }

    &.vc_column-gap-40 > .container {
      max-width: 1240px;
    }

    &.vc_column-gap-48 > .container {
      max-width: 1248px;
    }

    &.vc_column-gap-56 > .container {
      max-width: 1256px;
    }

    &.vc_column-gap-64 > .container {
      max-width: 1264px;
    }

    &.vc_column-gap-72 > .container {
      max-width: 1272px;
    }

    &.vc_column-gap-80 > .container {
      max-width: 1280px;
    }
  }

  /* Header
      ---------------*/

  .et-desktop.desktop-false,
  .et-mobile.desktop-false {
    display: none !important;

    + .header-placeholder {
      display: none !important;
    }
  }

  .detected .et-mobile.desktop-false {
    display: block !important;

    &.sticky-true.transparent-false.active + .header-placeholder {
      display: block !important;
    }
  }

  .desktop-hide-important:not(.single-header) .et-mobile {
    display: none !important;

    + .header-placeholder {
      display: none !important;
    }
  }

  /* Posts
      ---------------*/

  .grid .loop-posts,
  .et-shortcode-posts .loop-posts,
  .related-posts,
  .loop-product,
  body:not(.addon-active) ul.products {
    margin-left: -12px;
    margin-right: -12px;
  }

  .grid .loop-posts .post .post-inner,
  .et-shortcode-posts .post .post-inner,
  .loop-product .product .post-inner,
  body:not(.addon-active) ul.products .post-inner {
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .shop-content .loop-product {
    margin-left: -12px;
    margin-right: -12px;

    .product .post-inner {
      padding-bottom: 24px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .medium.grid #loop-posts .post .post-body,
  .chess .loop-posts .post .post-body {
    padding: 40px 40px 48px 40px;
  }

  .large .loop-product .product .post-body {
    padding: 0 40px 40px 40px;
  }

  .chess {
    .loop-posts .post .post-body {
      height: 300px;
    }

    .blog-content #loop-posts .post .post-body {
      height: 224px;
      padding: 24px;
    }

    .loop-posts .post .post-excerpt {
      display: block;
    }

    .blog-content #loop-posts .post .post-excerpt {
      display: none;
    }
  }

  .medium {
    .loop-product .product-category .post-body {
      padding: 24px;
    }

    &.grid .blog-content #loop-posts .post .post-body {
      padding: 32px 32px 40px 32px;
    }
  }

  .layout-container-wide .navigation-wraper,
  .product-container-wide .navigation-wraper {
    width: 1200px !important;
    max-width: 1200px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .small {
    .loop-posts {
      .post,
      .grid-sizer {
        width: 25%;
      }
    }

    .loop-product {
      .post,
      .grid-sizer {
        width: 20%;
      }
    }

    .layout-content .loop-product {
      .post,
      .grid-sizer {
        width: 25% !important;
      }
    }
  }

  .medium .loop-posts {
    .post,
    .grid-sizer {
      width: 33.33333333333333%;
    }
  }

  .large .loop-product {
    .post,
    .grid-sizer {
      width: 33.33333333333333%;
    }
  }

  .medium {
    .loop-product {
      .post,
      .grid-sizer {
        width: 25%;
      }
    }

    .layout-content .loop-product {
      .post,
      .grid-sizer {
        width: 25% !important;
      }
    }
  }

  .blog-layout-chess .layout-content .loop-posts {
    .post,
    .grid-sizer {
      width: 25% !important;
    }
  }

  .medium.grid .blog-content .loop-posts {
    .post,
    .grid-sizer {
      width: 50% !important;
    }
  }

  .large .loop-posts:not(.loop-product) {
    .post,
    .grid-sizer {
      width: 50%;
    }
  }

  .blog-layout-list {
    #loop-posts .post .post-title,
    .post .post-title {
      max-width: 70%;
    }

    .blog-content {
      #loop-posts .post .post-title,
      .post .post-title {
        max-width: 100%;
      }
    }

    .post-image {
      width: 30%;
    }

    .post-body {
      width: 70%;
    }
  }

  .post-social-share {
    float: left;
    margin: 0 !important;
    width: 40px;
    position: static;

    &:not(.static) {
      position: absolute;
    }
  }

  .admin-bar .post-social-share.is_stuck {
    padding-top: 32px;
  }

  .single-post-page.social-links-true > .post .post-body-inner {
    margin-left: 72px;
  }

  .post-social-share > .social-links > a {
    margin-bottom: 8px;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Products
      ---------------*/

  .woocommerce-MyAccount-navigation {
    width: 20%;
  }

  .woocommerce-MyAccount-content {
    width: 80%;
  }

  .large {
    .loop-product .product .post-title {
      font-size: 20px;
    }

    &:not(.list) .loop-product .product .post-body-inner {
      text-align: center;
    }

    .loop-product .product {
      .button,
      .added_to_cart {
        margin: 16px auto 0 auto;
      }

      .post-body-inner:before {
        margin-bottom: 32px;
      }
    }
  }

  .medium.list.column-2 {
    .loop-product .product,
    .product-content .loop-product .product,
    .loop-product .grid-sizer,
    .product-content .loop-product .grid-sizer {
      width: 50% !important;
    }
  }

  .small.list.column-2 {
    .loop-product .product,
    .product-content .loop-product .product,
    .loop-product .grid-sizer,
    .product-content .loop-product .grid-sizer {
      width: 50% !important;
    }
  }

  .medium.list.column-2 .product-content {
    .loop-product .product,
    .product-content .loop-product .product,
    .loop-product .grid-sizer,
    .product-content .loop-product .grid-sizer {
      width: 100% !important;
    }
  }

  .small.list.column-1 {
    .product-content .loop-product .product,
    .loop-product .grid-sizer,
    .product-content .loop-product .grid-sizer {
      width: 100% !important;
    }
  }

  .woocommerce-product-gallery .flex-control-nav li {
    &:last-child,
    &:nth-child(5n + 5) {
      margin-right: 0;
    }
  }

  .product-content .woocommerce-product-gallery .flex-control-nav li {
    max-width: 77px !important;

    &:not(:last-child) {
      margin-right: 8px !important;
    }

    &:nth-child(5n + 5) {
      margin-right: 0 !important;
    }
  }

  .woo-single-product .product {
    min-height: 459px;
  }

  /* et-instagram
      ---------------*/

  .et-instagram.grid[data-columns='6'] .instagram-item {
    width: 16.66666666666667%;
  }

  /* et-makes
      ---------------*/

  .et-make-container {
    &.column-7 {
      grid-template-columns: repeat(7, 7fr) !important;
    }

    &.column-8 {
      grid-template-columns: repeat(8, 8fr) !important;
    }
  }

  .browser-internet .et-make-container {
    &.column-7 .et-item {
      width: 14.28571428571429% !important;
    }

    &.column-8 .et-item {
      width: 12.5% !important;
    }
  }
}

/* Min-width: 1280px and max-width 1367px
  ---------------*/

@media only screen and (min-width: 1280px) and (max-width: 1367px) {
  .hide1280-1366 {
    display: none !important;
  }

  .disable1280-1366.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .sidebar-menu > .mm-true > .megamenu {
    max-width: 960px !important;
  }

  /* Responsive column padding
      ---------------*/

  .vc_column-inner {
    &[data-1280-1366-left='0'] {
      padding-left: 0 !important;
    }

    &[data-1280-1366-left='5'] {
      padding-left: 5% !important;
    }

    &[data-1280-1366-left='10'] {
      padding-left: 10% !important;
    }

    &[data-1280-1366-left='15'] {
      padding-left: 15% !important;
    }

    &[data-1280-1366-left='20'] {
      padding-left: 20% !important;
    }

    &[data-1280-1366-left='25'] {
      padding-left: 25% !important;
    }

    &[data-1280-1366-left='30'] {
      padding-left: 30% !important;
    }

    &[data-1280-1366-left='35'] {
      padding-left: 35% !important;
    }

    &[data-1280-1366-left='40'] {
      padding-left: 40% !important;
    }

    &[data-1280-1366-left='45'] {
      padding-left: 45% !important;
    }

    &[data-1280-1366-left='50'] {
      padding-left: 50% !important;
    }

    &[data-1280-1366-right='0'] {
      padding-right: 0 !important;
    }

    &[data-1280-1366-right='5'] {
      padding-right: 5% !important;
    }

    &[data-1280-1366-right='10'] {
      padding-right: 10% !important;
    }

    &[data-1280-1366-right='15'] {
      padding-right: 15% !important;
    }

    &[data-1280-1366-right='20'] {
      padding-right: 20% !important;
    }

    &[data-1280-1366-right='25'] {
      padding-right: 25% !important;
    }

    &[data-1280-1366-right='30'] {
      padding-right: 30% !important;
    }

    &[data-1280-1366-right='35'] {
      padding-right: 35% !important;
    }

    &[data-1280-1366-right='40'] {
      padding-right: 40% !important;
    }

    &[data-1280-1366-right='45'] {
      padding-right: 45% !important;
    }

    &[data-1280-1366-right='50'] {
      padding-right: 50% !important;
    }
  }
}

/* Min-width: 1366px and max-width 1599px
  ---------------*/

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .hide1366-1600 {
    display: none !important;
  }

  .disable1366-1600.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .sidebar-menu > .mm-true > .megamenu {
    max-width: 1024px !important;
  }

  /* Responsive column padding
      ---------------*/

  .vc_column-inner {
    &[data-1366-1600-left='0'] {
      padding-left: 0 !important;
    }

    &[data-1366-1600-left='5'] {
      padding-left: 5% !important;
    }

    &[data-1366-1600-left='10'] {
      padding-left: 10% !important;
    }

    &[data-1366-1600-left='15'] {
      padding-left: 15% !important;
    }

    &[data-1366-1600-left='20'] {
      padding-left: 20% !important;
    }

    &[data-1366-1600-left='25'] {
      padding-left: 25% !important;
    }

    &[data-1366-1600-left='30'] {
      padding-left: 30% !important;
    }

    &[data-1366-1600-left='35'] {
      padding-left: 35% !important;
    }

    &[data-1366-1600-left='40'] {
      padding-left: 40% !important;
    }

    &[data-1366-1600-left='45'] {
      padding-left: 45% !important;
    }

    &[data-1366-1600-left='50'] {
      padding-left: 50% !important;
    }

    &[data-1366-1600-right='0'] {
      padding-right: 0 !important;
    }

    &[data-1366-1600-right='5'] {
      padding-right: 5% !important;
    }

    &[data-1366-1600-right='10'] {
      padding-right: 10% !important;
    }

    &[data-1366-1600-right='15'] {
      padding-right: 15% !important;
    }

    &[data-1366-1600-right='20'] {
      padding-right: 20% !important;
    }

    &[data-1366-1600-right='25'] {
      padding-right: 25% !important;
    }

    &[data-1366-1600-right='30'] {
      padding-right: 30% !important;
    }

    &[data-1366-1600-right='35'] {
      padding-right: 35% !important;
    }

    &[data-1366-1600-right='40'] {
      padding-right: 40% !important;
    }

    &[data-1366-1600-right='45'] {
      padding-right: 45% !important;
    }

    &[data-1366-1600-right='50'] {
      padding-right: 50% !important;
    }
  }
}

/* Min-width: 1600px
  ---------------*/

@media only screen and (min-width: 1600px) {
  .hide1600 {
    display: none !important;
  }

  .disable1600.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }
}

/* Min-width: 1600px and max 1919
  ---------------*/

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .hide1600-1920 {
    display: none;
  }

  .disable1600-1920.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  /* Responsive column padding
      ---------------*/

  .vc_column-inner {
    &[data-1600-1920-left='0'] {
      padding-left: 0 !important;
    }

    &[data-1600-1920-left='5'] {
      padding-left: 5% !important;
    }

    &[data-1600-1920-left='10'] {
      padding-left: 10% !important;
    }

    &[data-1600-1920-left='15'] {
      padding-left: 15% !important;
    }

    &[data-1600-1920-left='20'] {
      padding-left: 20% !important;
    }

    &[data-1600-1920-left='25'] {
      padding-left: 25% !important;
    }

    &[data-1600-1920-left='30'] {
      padding-left: 30% !important;
    }

    &[data-1600-1920-left='35'] {
      padding-left: 35% !important;
    }

    &[data-1600-1920-left='40'] {
      padding-left: 40% !important;
    }

    &[data-1600-1920-left='45'] {
      padding-left: 45% !important;
    }

    &[data-1600-1920-left='50'] {
      padding-left: 50% !important;
    }

    &[data-1600-1920-right='0'] {
      padding-right: 0 !important;
    }

    &[data-1600-1920-right='5'] {
      padding-right: 5% !important;
    }

    &[data-1600-1920-right='10'] {
      padding-right: 10% !important;
    }

    &[data-1600-1920-right='15'] {
      padding-right: 15% !important;
    }

    &[data-1600-1920-right='20'] {
      padding-right: 20% !important;
    }

    &[data-1600-1920-right='25'] {
      padding-right: 25% !important;
    }

    &[data-1600-1920-right='30'] {
      padding-right: 30% !important;
    }

    &[data-1600-1920-right='35'] {
      padding-right: 35% !important;
    }

    &[data-1600-1920-right='40'] {
      padding-right: 40% !important;
    }

    &[data-1600-1920-right='45'] {
      padding-right: 45% !important;
    }

    &[data-1600-1920-right='50'] {
      padding-right: 50% !important;
    }
  }
}

/* Min-width: 1920px
  ---------------*/

@media only screen and (min-width: 1920px) {
  .hide1920 {
    display: none !important;
  }

  .disable1920.etp-parallax[data-parallax='true'] {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }
}

/* IE10+ ONLY
  ---------------*/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .rich-header .parallax-container {
    z-index: 1;
  }

  .vc-parallax {
    .parallax-container {
      z-index: 1;
    }

    .wpb_wrapper {
      z-index: 2;
      position: relative;
    }
  }

  .rich-header .rh-content {
    z-index: 2;
  }

  .custom-font-family {
    font-family: inherit !important;
  }

  #wrap.active {
    transform: none !important;
  }

  .image-container img {
    width: 100%;
  }

  .megamenu-tab {
    .et-icon-box-content ul li,
    .et-icon-list li,
    .mi-link,
    .tagcloud {
      line-height: 20px;
    }
  }

  .arrows-pos-top-right.owl-carousel .owl-nav {
    top: -8px;
  }

  .et-icon-box-container,
  .grid.et-client-container {
    display: table;
    width: 100%;
    padding: 0;
    border-collapse: separate;
    border-spacing: 1px;
  }

  .et-icon-box-container {
    &[data-gap='0'].no-border {
      border-collapse: collapse;
      border-spacing: 0;
    }

    &[data-gap='2'] {
      border-spacing: 2px;
      margin-left: -2px !important;
      margin-right: -2px !important;
      width: calc(100% + 4px);
    }

    &[data-gap='4'] {
      border-spacing: 4px;
      margin-left: -4px !important;
      margin-right: -4px !important;
      width: calc(100% + 8px);
    }

    &[data-gap='6'] {
      border-spacing: 6px;
      margin-left: -6px !important;
      margin-right: -6px !important;
      width: calc(100% + 12px);
    }

    &[data-gap='8'] {
      border-spacing: 8px;
      margin-left: -8px !important;
      margin-right: -8px !important;
      width: calc(100% + 16px);
    }

    &[data-gap='10'] {
      border-spacing: 10px;
      margin-left: -10px !important;
      margin-right: -10px !important;
      width: calc(100% + 20px);
    }

    &[data-gap='12'] {
      border-spacing: 12px;
      margin-left: -12px !important;
      margin-right: -12px !important;
      width: calc(100% + 24px);
    }

    &[data-gap='14'] {
      border-spacing: 14px;
      margin-left: -14px !important;
      margin-right: -14px !important;
      width: calc(100% + 28px);
    }

    &[data-gap='16'] {
      border-spacing: 16px;
      margin-left: -16px !important;
      margin-right: -16px !important;
      width: calc(100% + 32px);
    }

    &[data-gap='18'] {
      border-spacing: 18px;
      margin-left: -18px !important;
      margin-right: -18px !important;
      width: calc(100% + 36px);
    }

    &[data-gap='20'] {
      border-spacing: 20px;
      margin-left: -20px !important;
      margin-right: -20px !important;
      width: calc(100% + 40px);
    }

    &[data-gap='22'] {
      border-spacing: 22px;
      margin-left: -22px !important;
      margin-right: -22px !important;
      width: calc(100% + 44px);
    }

    &[data-gap='24'] {
      border-spacing: 24px;
      margin-left: -24px !important;
      margin-right: -24px !important;
      width: calc(100% + 48px);
    }

    &[data-gap='26'] {
      border-spacing: 26px;
      margin-left: -26px !important;
      margin-right: -26px !important;
      width: calc(100% + 52px);
    }

    &[data-gap='28'] {
      border-spacing: 28px;
      margin-left: -28px !important;
      margin-right: -28px !important;
      width: calc(100% + 56px);
    }

    &[data-gap='30'] {
      border-spacing: 30px;
      margin-left: -30px !important;
      margin-right: -30px !important;
      width: calc(100% + 60px);
    }

    &[data-gap='32'] {
      border-spacing: 32px;
      margin-left: -32px !important;
      margin-right: -32px !important;
      width: calc(100% + 64px);
    }

    &[data-gap='34'] {
      border-spacing: 34px;
      margin-left: -34px !important;
      margin-right: -34px !important;
      width: calc(100% + 68px);
    }

    &[data-gap='36'] {
      border-spacing: 36px;
      margin-left: -36px !important;
      margin-right: -36px !important;
      width: calc(100% + 72px);
    }

    &[data-gap='38'] {
      border-spacing: 38px;
      margin-left: -38px !important;
      margin-right: -38px !important;
      width: calc(100% + 76px);
    }

    &[data-gap='40'] {
      border-spacing: 40px;
      margin-left: -40px !important;
      margin-right: -40px !important;
      width: calc(100% + 80px);
    }

    &[data-gap='42'] {
      border-spacing: 42px;
      margin-left: -42px !important;
      margin-right: -42px !important;
      width: calc(100% + 84px);
    }

    &[data-gap='44'] {
      border-spacing: 44px;
      margin-left: -44px !important;
      margin-right: -44px !important;
      width: calc(100% + 88px);
    }

    &[data-gap='46'] {
      border-spacing: 46px;
      margin-left: -46px !important;
      margin-right: -46px !important;
      width: calc(100% + 92px);
    }

    &[data-gap='48'] {
      border-spacing: 48px;
      margin-left: -48px !important;
      margin-right: -48px !important;
      width: calc(100% + 96px);
    }

    &[data-gap='50'] {
      border-spacing: 50px;
      margin-left: -50px !important;
      margin-right: -50px !important;
      width: calc(100% + 100px);
    }

    &[data-gap='52'] {
      border-spacing: 52px;
      margin-left: -52px !important;
      margin-right: -52px !important;
      width: calc(100% + 104px);
    }

    &[data-gap='54'] {
      border-spacing: 54px;
      margin-left: -54px !important;
      margin-right: -54px !important;
      width: calc(100% + 108px);
    }

    &[data-gap='56'] {
      border-spacing: 56px;
      margin-left: -56px !important;
      margin-right: -56px !important;
      width: calc(100% + 112px);
    }

    &[data-gap='58'] {
      border-spacing: 58px;
      margin-left: -58px !important;
      margin-right: -58px !important;
      width: calc(100% + 116px);
    }

    &[data-gap='60'] {
      border-spacing: 60px;
      margin-left: -60px !important;
      margin-right: -60px !important;
      width: calc(100% + 120px);
    }

    &[data-gap='62'] {
      border-spacing: 62px;
      margin-left: -62px !important;
      margin-right: -62px !important;
      width: calc(100% + 124px);
    }

    &[data-gap='64'] {
      border-spacing: 64px;
      margin-left: -64px !important;
      margin-right: -64px !important;
      width: calc(100% + 128px);
    }

    &[data-gap='66'] {
      border-spacing: 66px;
      margin-left: -66px !important;
      margin-right: -66px !important;
      width: calc(100% + 132px);
    }

    &[data-gap='68'] {
      border-spacing: 68px;
      margin-left: -68px !important;
      margin-right: -68px !important;
      width: calc(100% + 136px);
    }

    &[data-gap='70'] {
      border-spacing: 70px;
      margin-left: -70px !important;
      margin-right: -70px !important;
      width: calc(100% + 140px);
    }

    &[data-gap='72'] {
      border-spacing: 72px;
      margin-left: -72px !important;
      margin-right: -72px !important;
      width: calc(100% + 144px);
    }

    &[data-gap='74'] {
      border-spacing: 74px;
      margin-left: -74px !important;
      margin-right: -74px !important;
      width: calc(100% + 148px);
    }

    &[data-gap='76'] {
      border-spacing: 76px;
      margin-left: -76px !important;
      margin-right: -76px !important;
      width: calc(100% + 152px);
    }

    &[data-gap='78'] {
      border-spacing: 78px;
      margin-left: -78px !important;
      margin-right: -78px !important;
      width: calc(100% + 156px);
    }

    &[data-gap='80'] {
      border-spacing: 80px;
      margin-left: -80px !important;
      margin-right: -80px !important;
      width: calc(100% + 160px);
    }
  }

  .grid.et-client-container {
    &[data-gap='2'] {
      border-spacing: 2px;
      margin-left: -2px !important;
      margin-right: -2px !important;
      width: calc(100% + 4px);
    }

    &[data-gap='4'] {
      border-spacing: 4px;
      margin-left: -4px !important;
      margin-right: -4px !important;
      width: calc(100% + 8px);
    }

    &[data-gap='8'] {
      border-spacing: 8px;
      margin-left: -8px !important;
      margin-right: -8px !important;
      width: calc(100% + 16px);
    }

    &[data-gap='16'] {
      border-spacing: 16px;
      margin-left: -16px !important;
      margin-right: -16px !important;
      width: calc(100% + 32px);
    }

    &[data-gap='24'] {
      border-spacing: 24px;
      margin-left: -24px !important;
      margin-right: -24px !important;
      width: calc(100% + 48px);
    }

    &[data-gap='32'] {
      border-spacing: 32px;
      margin-left: -32px !important;
      margin-right: -32px !important;
      width: calc(100% + 64px);
    }

    &[data-gap='40'] {
      border-spacing: 40px;
      margin-left: -40px !important;
      margin-right: -40px !important;
      width: calc(100% + 80px);
    }
  }

  .et-icon-box-container .row-wrap,
  .et-step-box-container .row-wrap,
  .grid.et-client-container .row-wrap {
    display: table-row;
  }

  .et-icon-box-container {
    &.top .et-icon-box {
      vertical-align: top;
    }

    &.middle .et-icon-box {
      vertical-align: middle;
    }
  }

  .grid.et-client-container .et-client {
    vertical-align: middle;
  }

  .et-icon-box-container {
    &.bottom .et-icon-box {
      vertical-align: bottom;
    }

    &.column-4 {
      padding-right: 0 !important;
    }

    .et-icon-box {
      display: table-cell;
    }
  }

  .et-step-box-container .et-step-box,
  .grid.et-client-container .et-client {
    display: table-cell;
  }

  .et-make-container {
    &.small .et-make .et-icon {
      height: 48px;
      max-width: 48px;
    }

    &.medium .et-make .et-icon {
      height: 56px;
      max-width: 56px;
    }

    &.large .et-make .et-icon {
      height: 64px;
      max-width: 64px;
    }

    &.small .et-make .no-title.et-icon {
      height: 56px;
      max-width: 56px;
    }

    &.medium .et-make .no-title.et-icon {
      height: 64px;
      max-width: 64px;
    }

    &.large .et-make .no-title.et-icon {
      height: 72px;
      max-width: 72px;
    }

    &.small .et-make .et-icon.rev {
      width: 88px;
      max-width: 88px;
    }

    &.medium .et-make .et-icon.rev {
      width: 80px;
      max-width: 80px;
    }

    &.large .et-make .et-icon.rev {
      width: 88px;
      max-width: 88px;
    }

    &.small .et-make .no-title.et-icon.rev {
      width: 80px;
      max-width: 80px;
    }

    &.medium .et-make .no-title.et-icon.rev {
      width: 88px;
      max-width: 88px;
    }

    &.large .et-make .no-title.et-icon.rev {
      width: 96px;
      max-width: 96px;
    }
  }

  .et-make .et-icon.rev svg {
    transform: none;
  }

  .owl-carousel [data-parallax='true'].etp-parallax {
    transform: none !important;
    -ms-transform: none !important;
    position: relative !important;
  }

  .grid.et-client-container .et-client img {
    max-width: 150px;
  }

  .et-step-box-container {
    display: table;
    width: 100%;

    &.column-2 .et-step-box {
      width: 50%;
    }

    &.column-3 .et-step-box {
      width: 33.3333333333%;
    }

    &.column-4 .et-step-box {
      width: 25%;
    }
  }
}
